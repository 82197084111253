import { calculateAutoAllocateDebits } from '../../packages/neb-utils/auto-allocate-util';
import { parseDate } from '../../packages/neb-utils/date-util';

export const ERROR_BANNER_MESSAGE =
  'There was an error when fetching outstanding charges. Try again.';

const getCardTitle = (lineItem, encounters) => {
  let title = `${parseDate(lineItem.dateOfService).format('MMMM D, YYYY')}`;

  const encounter = encounters.find(e => e.id === lineItem.encounter?.id);

  if (encounter?.appointmentType?.name) {
    title += ` - ${encounter.appointmentType?.name}`;
  } else {
    title += ` - Charge ${lineItem.chargeNumber}`;
  }
  return title;
};

const getProviderName = (lineItem, providers) => {
  const provider = providers.find(
    pr => pr.id === lineItem.encounterCharge?.providerId,
  );

  if (!provider) return null;

  return `${provider?.firstName} ${provider?.lastName}`;
};

const getChargeDescription = lineItem =>
  `${lineItem.units} x ${lineItem.code} - ${lineItem.description}`;

const getEncounterData = (li, encounters) =>
  encounters.find(e => e.id === li.encounterCharge?.encounterId);

const getInsuranceData = (lineItem, insurances) => {
  const insurance = insurances.find(
    ins => ins.id === lineItem.primaryInsuranceId,
  );

  if (!insurance) return null;

  return {
    name: insurance.planName,
    payer: insurance.payerPlan?.alias,
    copay: insurance.copays?.length
      ? insurance.copays[0].inNetworkAmount
      : null,
    deductible: insurance.deductibles?.length
      ? insurance.deductibles[0].inNetworkAmount
      : null,
    coinsurance: insurance.coinsurances?.length
      ? insurance.coinsurances[0].inNetworkPercent
      : null,
  };
};

export const calculatePatientResponsibility = lineItem =>
  lineItem.lineItemDebits.reduce(
    (acc, { patientInsuranceId, debit: { amount } }) =>
      patientInsuranceId ? acc : acc + amount,
    0,
  );

export const calculatePayerResponsibility = lineItem =>
  lineItem.lineItemDebits.reduce(
    (acc, { patientInsuranceId, debit: { amount } }) =>
      patientInsuranceId ? acc + amount : acc,
    0,
  );

export const getOutstandingPatientBalance = lineItem => {
  const patientPayments = lineItem.lineItemDebits.reduce(
    (acc, cur) =>
      cur.patientInsuranceId
        ? acc
        : acc +
          cur.debit.allocations.reduce((acc2, cur2) => acc2 + cur2.amount, 0),
    0,
  );

  return calculatePatientResponsibility(lineItem) - patientPayments;
};

export const filterAllocatedLineItems = lineItems =>
  lineItems.filter(li => getOutstandingPatientBalance(li) > 0);

export const selectLineItemsAhead = lineItems => {
  if (lineItems.length) {
    if (lineItems[0].encounter) {
      lineItems.forEach((li, idx) => {
        if (li.encounter?.id === lineItems[0].encounter.id) {
          lineItems[idx].selected = true;
          lineItems[idx].amount = getOutstandingPatientBalance(li);
        }
      });
    } else {
      lineItems[0].selected = true;
      lineItems[0].amount = getOutstandingPatientBalance(lineItems[0]);
    }
  }

  return lineItems;
};

export const calculatePreallocationTotal = lineItems =>
  lineItems.filter(li => li.selected).reduce((acc, cur) => acc + cur.amount, 0);

export const calculateCurrentBalance = (
  lineItems,
  remainingPatientBalance = 0,
) =>
  lineItems.reduce((acc, cur) => acc + calculatePatientResponsibility(cur), 0) -
  remainingPatientBalance -
  calculatePreallocationTotal(lineItems);

export const mapLineItemForAllocation = lineItem => ({
  id: lineItem.id,
  allowedAmount: lineItem.allowedAmount,
  debits: calculateAutoAllocateDebits(
    lineItem.lineItemDebits.filter(li => !li.patientInsuranceId),
    {
      amount: lineItem.amount,
    },
  ),
  adjustments: lineItem.adjustments,
});

export const mapLineItemsToModel = ({
  lineItems = [],
  encounters = [],
  insurances = [],
  providers = [],
}) =>
  lineItems.map(li => ({
    id: li.id,
    title: getCardTitle(li, encounters),
    selected: false,
    amount: 0,
    dateOfService: li.dateOfService,
    appointmentType: li.encounter?.appointmentType?.name,
    chargeNumber: li.chargeNumber,
    lineItemDebits: li.lineItemDebits,
    outstandingPatientBalance: getOutstandingPatientBalance(li),
    providerName: getProviderName(li, providers),
    billType: li.billType,
    chargeDescription: getChargeDescription(li),
    insurance: getInsuranceData(li, insurances),
    patientResponsibility: calculatePatientResponsibility(li),
    payerResponsibility: calculatePayerResponsibility(li),
    encounter: getEncounterData(li, encounters),
  }));
