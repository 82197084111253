import { html, css, LitElement } from 'lit';

import {
  CSS_BORDER_GREY_2,
  CSS_SPACING,
} from '../../../packages/neb-styles/neb-variables';
import '../../../packages/neb-lit-components/src/components/neb-text';
import { createReportUrl } from '../../utils/reports-tab-util';

export const ELEMENTS = {
  reportLink: { selector: '[id^=report-link-]', tag: 'neb-text' },
  pdfLink: { selector: '[id^=report-pdf-]', tag: 'neb-text' },
  excelLink: { selector: '[id^=report-excel-]', tag: 'neb-text' },
  currentLink: { selector: '[id^=report-current-]', tag: 'neb-text' },
  prodLink: { selector: '[id^=report-prod-]', tag: 'neb-text' },
  betaLink: { selector: '[id^=report-beta-]', tag: 'neb-text' },
  category: { id: 'category' },
};

class NebReportLinksCard extends LitElement {
  static get properties() {
    return {
      category: String,
      reports: Array,
      showExcelLink: Boolean,
      showProdVersionLink: Boolean,
    };
  }

  static get styles() {
    return [
      css`
        :host {
          display: flex;
          flex-direction: column;
          border: ${CSS_BORDER_GREY_2};
          border-radius: 5px;
          padding-bottom: ${CSS_SPACING};
        }

        .title {
          gap: ${CSS_SPACING};
          padding: ${CSS_SPACING};
        }

        .titleWithoutFF {
          padding-left: ${CSS_SPACING};
          padding-top: ${CSS_SPACING};
        }

        .report {
          gap: ${CSS_SPACING};
          padding-left: ${CSS_SPACING};
        }

        .reportType {
          display: flex;
          flex-direction: column;
          padding-left: 0px;
          padding-right: ${CSS_SPACING};
          padding-top: 0px;
          padding-bottom: 0px;
        }

        .container {
          display: flex;
          flex-direction: row;
        }

        .card {
          display: flex;
          flex-direction: column;
          gap: ${CSS_SPACING};
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.category = '';
    this.reports = [];
    this.showExcelLink = false;
    this.showProdVersionLink = false;
  }

  __initHandlers() {
    this.__handlers = {
      openReportLink: ({ name: index }) => {
        const { link, title } = this.reports[index];

        const newTab = window.open();

        newTab.location = createReportUrl(link, title);
      },
      openReportPdfLink: ({ name: index }) => {
        const { link, title } = this.reports[index];

        const newTab = window.open();

        newTab.location = createReportUrl(link.pdf, title);
      },
      openReportExcelLink: ({ name: index }) => {
        const { link, title } = this.reports[index];

        const newTab = window.open();

        newTab.location = createReportUrl(link.excel, title);
      },
      openReportProdLink: ({ name: index }) => {
        const { link, title } = this.reports[index];

        const newTab = window.open();
        newTab.location = createReportUrl(link.prod, `${title} - Prod`);
      },
      openReportBetaLink: ({ name: index }) => {
        const { link, title } = this.reports[index];
        const betaLink = link.pdf
          .replace(/(jrs\.report=\/[^/]+)\//, '$1_beta/')
          .replace(/(jrs\.catalog=\/[^/]+)\//, '$1_beta/');

        const newTab = window.open();
        newTab.location = createReportUrl(betaLink, `${title} - Beta`);
      },
    };
  }

  __renderTitle() {
    return html`
      <div class="titleWithoutFF">
        <neb-text bold id="${ELEMENTS.category.id}">${this.category}</neb-text>
      </div>
    `;
  }

  __renderTitleFF() {
    return html`
      <div class="title">
        <neb-text bold id="${ELEMENTS.category.id}">${this.category}</neb-text>
      </div>
    `;
  }

  __renderBetaReportLink(report, index) {
    return this.showProdVersionLink
      ? html` <div class="reportType">
          <neb-text
            link
            id="report-beta-${report.id}"
            .name="${index}"
            .onClick="${this.__handlers.openReportBetaLink}"
            >BETA</neb-text
          >
        </div>`
      : '';
  }

  __renderLinksWithFF(report, index) {
    return html`
      <div class="container">
        ${report.link
          ? html`
              <div class="reportType">
                <neb-text
                  link
                  pdf
                  id="report-pdf-${report.id}"
                  .name="${index}"
                  .onClick="${this.__handlers.openReportPdfLink}"
                  >PDF</neb-text
                >
              </div>
              ${this.__renderBetaReportLink(report, index)}
            `
          : ''}
        ${report.link
          ? html`
              <div class="reportType">
                <neb-text
                  link
                  id="report-excel-${report.id}"
                  .name="${index}"
                  .onClick="${this.__handlers.openReportExcelLink}"
                  >EXCEL</neb-text
                >
              </div>
            `
          : ''}
        ${this.showProdVersionLink && report.link
          ? html`
              <div class="reportType">
                <neb-text
                  link
                  id="report-prod-${report.id}"
                  .name="${index}"
                  .onClick="${this.__handlers.openReportProdLink}"
                  >PROD</neb-text
                >
              </div>
            `
          : ''}
      </div>
    `;
  }

  __renderReportsWithFF() {
    return html`
      ${this.reports.map(
        (report, index) => html`
          <div class="report">
            <neb-text id="report-link-${report.id}" .name="${report.id}"
              >${report.title}</neb-text
            >
            ${this.__renderLinksWithFF(report, index)}
          </div>
        `,
      )}
    `;
  }

  __renderLinks() {
    return html`
      <div class="card">
        ${this.reports.map(
          (report, index) => html`
            <div>
              ${this.showProdVersionLink
                ? html`
                    <neb-text id="report-link-${index}" .name="${index}"
                      >${report.title}</neb-text
                    >
                  `
                : html`
                    <neb-text
                      link
                      id="report-link-${index}"
                      .name="${index}"
                      .onClick="${this.__handlers.openReportLink}"
                      >${report.title}</neb-text
                    >
                  `}
              ${this.showExcelLink &&
              report.title === 'Payment Details by Rendering Provider'
                ? this.__renderExcelLink(index)
                : ''}
              ${this.showProdVersionLink
                ? html`
                    <div class="container">
                      <div class="reportType">
                        <neb-text
                          link
                          id="report-current-${report.id}"
                          .name="${index}"
                          .onClick="${this.__handlers.openReportPdfLink}"
                          >CURRENT</neb-text
                        >
                      </div>
                      <div class="reportType">
                        <neb-text
                          link
                          id="report-beta-${report.id}"
                          .name="${index}"
                          .onClick="${this.__handlers.openReportBetaLink}"
                          >BETA</neb-text
                        >
                      </div>
                      <div class="reportType">
                        <neb-text
                          link
                          id="report-prod-${report.id}"
                          .name="${index}"
                          .onClick="${this.__handlers.openReportProdLink}"
                          >PROD</neb-text
                        >
                      </div>
                    </div>
                  `
                : ''}
            </div>
          `,
        )}
      </div>
    `;
  }

  render() {
    return html`
      ${this.showExcelLink ? this.renderWithFF() : this.renderWithoutFF()}
    `;
  }

  renderWithoutFF() {
    return html` ${this.__renderTitle()} ${this.__renderLinks()} `;
  }

  renderWithFF() {
    return html` ${this.__renderTitleFF()} ${this.__renderReportsWithFF()} `;
  }
}
window.customElements.define('neb-report-links-card', NebReportLinksCard);
