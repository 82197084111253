import { openPopup } from '@neb/popup';
import { html, css } from 'lit';
import '../tables/clearinghouse-settings/neb-table-clearinghouse-settings';

import { validateClientAndNebulaCreds } from '../../../packages/neb-api-client/src/electronic-claims/settings';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../packages/neb-lit-components/src/components/neb-page-collection';
import { testClearinghouseConnection } from '../../../packages/neb-lit-components/src/utils/clearinghouse-sftp-status';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import '../../../packages/neb-lit-components/src/components/neb-text';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { normalizeForSearch } from '../../../packages/neb-utils/formatters';
import { SORT_DIR } from '../../../packages/neb-utils/services/collection';
import { MODE } from '../../../packages/neb-utils/table';
import * as clearinghouseApi from '../../api-clients/clearinghouse';
import { openError, openSuccess } from '../../store';
import {
  BANNER_MESSAGE,
  CLEARINGHOUSES,
} from '../../utils/clearinghouse-settings';

export const ELEMENTS = {
  ...BASE_ELEMENTS,
};

export const ETIN_CLEARINGHOUSES = ['Availity', 'Standard'];

const CONFIG = [
  {
    key: 'partner',
    label: 'Clearinghouse',
    flex: css`1 0 0`,
  },
  {
    key: 'active',
    label: 'Status',
    flex: css`0.2 0 0`,
    formatter: v => (v ? 'Active' : 'Inactive'),
  },
  {
    key: 'testConnectionIcon',
    label: '',
    flex: css`0 0 40px`,
  },
  {
    key: 'actionMenu',
    label: '',
    flex: css`0 0 40px`,
  },
];

class CollectionPageClearinghouseSettings extends CollectionPage {
  static get config() {
    return {
      ...CollectionPage.config,
      description:
        'Review and set up your practice to process electronic claims',
      hideDetailArrow: false,
      inactiveLabel: 'Hide inactive clearinghouses',
      searchLabel: 'Enter the clearinghouse name to filter the list below',
      showInactiveFilter: true,
      singularName: 'New Clearinghouse',
      tableConfig: CONFIG,
      pluralName: 'Clearinghouse Partner Settings',
      unifyForm: false,
      initialSortOrder: SORT_DIR.DESC,
    };
  }

  static get properties() {
    return {
      readOnly: { reflect: true, type: Boolean },
      accessFromSupport: { type: Boolean },
      activeItemCount: { reflect: true, type: Number },
      addOnsSupport: { type: Array },
      testConnectionIcon: { type: String },
      currentRow: { type: String },
      sftpStatus: { type: String },
    };
  }

  initState() {
    super.initState();

    this.readOnly = false;
    this.activeItemCount = 0;
    this.accessFromSupport = false;
    this.testConnectionIcon = '';
    this.currentRow = '';
    this.sftpStatus = '';

    this.setPayerTableConfig = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      swapClearinghouse: async e => {
        const result = await openPopup(POPUP_RENDER_KEYS.SWAP_CLEARINGHOUSE, {
          currentClearinghouse: { ...e, addOnsSupport: this.addOnsSupport },
        });

        if (result) {
          try {
            await clearinghouseApi.swapClearinghouse(
              result.partner,
              result.clearinghouseId,
            );

            await this.__setItems();

            openSuccess(BANNER_MESSAGE.success);
          } catch (err) {
            console.error(err);
            openError(BANNER_MESSAGE.error);
          }
        }
      },

      testConnection: async e => {
        this.__clearTestConnectionIcons();
        const paths = this.__getValidPaths(e);
        await testClearinghouseConnection({
          id: e.id,
          host: e.sftpHost,
          username: e.sftpUsername,
          port: e.sftpPort,
          paths,
          onSuccess: icon => {
            this.sftpStatus = icon;
            this.currentRow = e.id;
          },
          onError: icon => {
            this.sftpStatus = icon;
            this.currentRow = e.id;
          },
        });
      },

      testChcConnection: async () => {
        const res = await validateClientAndNebulaCreds();
        this.__clearTestConnectionIcons();

        if (res.clientStatusCode === 200 && res.vendorStatusCode === 200) {
          this.testConnectionIcon = 'success';
          openSuccess('Connection Success');
        }

        if (res.clientStatusCode !== 200 && res.vendorStatusCode === 200) {
          this.testConnectionIcon = 'error';
          openError('Connection Failed: Check Credentials');
        }

        if (res.vendorStatusCode !== 200) {
          this.testConnectionIcon = 'error';
          openError('Connection Failed: Contact Support');
        }
      },
    };
  }

  __clearTestConnectionIcons() {
    this.currentRow = null;
    this.testConnectionIcon = '';
    this.sftpStatus = '';
    this.requestUpdate();
  }

  __renderAddButton() {
    return this.getConfig().singularName && !this.readOnly
      ? html`
          <div class="row row-margins">
            <neb-button-action
              id="${ELEMENTS.addButton.id}"
              class="cell"
              .label="Add ${this.getConfig().singularName}"
              .onClick="${this.handlers.addItem}"
            ></neb-button-action>
            <div class="cell cell-spacer"></div>
          </div>
        `
      : '';
  }

  buildContext() {
    let existingClearinghouses = [];

    if (this.__tableState && this.__tableState.allItems.length) {
      this.activeItemCount = this.__tableState.allItems.filter(
        item => item.active === true,
      ).length;

      existingClearinghouses = this.__tableState.allItems.map(item => ({
        partner: item.partner,
        alias: item.alias,
      }));
    }

    return {
      readOnly: this.readOnly,
      activeItemCount: this.activeItemCount,
      existingClearinghouses,
    };
  }

  __buildPayerTableConfig(item) {
    return {
      showEtin: ETIN_CLEARINGHOUSES.includes(item.partner),
      isChc: item.partner === CLEARINGHOUSES.CHC,
    };
  }

  __getValidPaths(e) {
    return [
      e.sftpPathClaim,
      e.sftpDownloadActive && e.sftpPathEra,
      e.sftpDownloadActive && e.sftpPathAcknowledgement,
      this.__hasBYOC277FF && e.sftpDownloadActive && e.sftpPath277,
    ].filter(exists => exists);
  }

  async openItemOverlay(item) {
    let resultItem;

    this.__clearTestConnectionIcons();

    if (item) {
      resultItem = await openOverlay(OVERLAY_KEYS.CLEARINGHOUSE, {
        context: this.buildContext(),
        item,
        addOnsSupport: this.addOnsSupport,
        payerTableConfig: this.__buildPayerTableConfig(item),
        isCHC: item.partner === CLEARINGHOUSES.CHC,
      });
    } else {
      resultItem = await openOverlay(OVERLAY_KEYS.ADD_CLEARINGHOUSE, {
        context: this.buildContext(),
        item,
      });
    }

    if (resultItem) {
      await this.__setItems();
    }
  }

  async fetch() {
    const items = await clearinghouseApi.getClearinghousesWithCHC(
      this.addOnsSupport,
    );

    return items.map(item => ({
      ...item,
      accessFromSupport: this.accessFromSupport,
    }));
  }

  async __setItems() {
    const items = await this.fetch();

    this.service.setItems(items);
  }

  formatSearchItem({ partner }) {
    return normalizeForSearch(partner);
  }

  filterSearchItem({ terms, item }) {
    return terms.every(term => item.includes(term));
  }

  renderNoItemsContent() {
    return html` <neb-text>No items.</neb-text> `;
  }

  renderTable() {
    return html`
      <neb-table-clearinghouse-settings
        id="${ELEMENTS.table.id}"
        class="cell-spacer"
        .mode="${MODE.DETAIL}"
        .config="${CONFIG}"
        .layout="${this.layout}"
        .testConnectionIcon="${this.testConnectionIcon}"
        .currentRow="${this.currentRow}"
        .sftpStatus="${this.sftpStatus}"
        .model="${this.__tableState.pageItems}"
        .sortParams="${this.__tableState.sortParams}"
        .onSelectRow="${this.handlers.selectItem}"
        .onSwapClearinghouse="${this.handlers.swapClearinghouse}"
        .onTestConnection="${this.handlers.testConnection}"
        .onTestChcConnection="${this.handlers.testChcConnection}"
        mode="${MODE.DETAIL}"
      ></neb-table-clearinghouse-settings>
    `;
  }
}

window.customElements.define(
  'neb-collection-page-clearinghouse-settings',
  CollectionPageClearinghouseSettings,
);
