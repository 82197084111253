import './neb-diagnosis-tabs-controller';
import './neb-charting-diagnosis-date-of-current-illness';
import './neb-encounter-diagnosis-table';
import '../../../../neb-lit-components/src/components/neb-action-bar';
import '../../../../neb-lit-components/src/components/controls/neb-button-action';

import { LitElement, html, css } from 'lit';

import { fetchOne } from '../../../../../src/api-clients/clinical-codes';
import { DIAGNOSIS_WARNING } from '../../../../../src/utils/user-message';
import { openWarning, openInfo } from '../../../../neb-dialog/neb-banner-state';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../neb-lit-components/src/utils/overlay-constants';
import { store } from '../../../../neb-redux/neb-redux-store';
import {
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../neb-styles/neb-variables';

export const ELEMENTS = {
  actionBar: {
    id: 'action-bar',
  },
  addCodeBundleButton: {
    id: 'add-code-bundle-button',
  },
  diagnosisTable: {
    id: 'table-diagnosis',
  },
  dateOfCurrentIllness: {
    id: 'date-of-current-illness',
  },
  tabController: {
    id: 'tab-controller',
  },
};

class NebChartingDiagnosisDetail extends LitElement {
  static get properties() {
    return {
      __savedCodes: Array,
      __diagnosesDict: Object,

      originalDiagnosesDict: Object,
      encounter: Object,
      originalCodes: Array,
      savedProblems: Array,
      visible: Boolean,
      layout: String,
      icd10Year: Number,
      providerId: String,
      legacyChartingV1: {
        type: Boolean,
        attribute: 'legacy-charting-v1',
        reflect: true,
      },
    };
  }

  static get styles() {
    return css`
      :host {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }

      .container {
        position: relative;

        flex: 1 0 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        overflow: auto;
      }

      .header-container {
        display: flex;
        justify-content: space-between;
        padding: 0 ${CSS_SPACING};
        margin-top: 5px;
      }

      .title {
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
        margin-bottom: ${CSS_SPACING};
      }

      .encounter-diagnosis-container {
        margin-bottom: 56px;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__savedCodes = [];
    this.__diagnosesDict = {};

    this.originalDiagnosesDict = {};
    this.providerId = '';
    this.encounter = null;
    this.layout = '';
    this.originalCodes = [];
    this.savedProblems = [];
    this.visible = false;
    this.icd10Year = null;
    this.legacyChartingV1 = false;

    this.onChangeDirty = () => {};

    this.onSave = () => {};

    this.onCancel = () => {};

    this.onAddToProblems = () => {};

    this.onCodeBundleAdded = () => {};

    this.onUpdateEncounter = () => {};

    this.onRefreshWarning = () => {};
  }

  async __findDiagnosesDescription(code) {
    if (!(code in this.__diagnosesDict)) {
      this.__diagnosesDict[code] = await fetchOne({
        code,
        icd10year: this.icd10Year,
      });
    }
    return this.__diagnosesDict[code];
  }

  async __mapCode(icd10Info) {
    return {
      ...icd10Info,
      ...(icd10Info
        ? {
            showICD10Warning: !(await this.__findDiagnosesDescription(
              icd10Info.code,
            )),
          }
        : {}),
    };
  }

  __mapCodes(codes) {
    return Promise.all(codes.map(code => this.__mapCode(code)));
  }

  __unmapCode(code) {
    const orig = { ...code };
    delete orig.showICD10Warning;
    return orig;
  }

  __unmapCodes(codes) {
    return codes.map(c => this.__unmapCode(c));
  }

  __initHandlers() {
    this.__handlers = {
      addToProblems: icd10Info =>
        this.onAddToProblems(this.__unmapCode(icd10Info)),
      addDiagnosis: async (icd10Info, updateDescription = true) => {
        let icd10Information = { ...icd10Info };

        const foundCodeDescription = await this.__findDiagnosesDescription(
          icd10Info.code,
        );

        if (!foundCodeDescription) {
          store.dispatch(openWarning(DIAGNOSIS_WARNING));
        } else if (
          updateDescription &&
          icd10Info.shortDescription !== foundCodeDescription.shortDescription
        ) {
          icd10Information = {
            ...icd10Information,
            shortDescription: foundCodeDescription.shortDescription,
          };

          store.dispatch(
            openInfo(
              'The diagnosis description has been updated to match the date of service',
            ),
          );
        }

        this.__savedCodes = await this.__mapCodes([
          ...this.__savedCodes,
          icd10Information,
        ]);
      },
      addAllDiagnosis: async icd10Infos => {
        let foundCodeDescription;
        let isValid = true;
        let descriptionUpdated = false;

        const icd10CodesLookup = await Promise.all(
          icd10Infos.map(icd10Info =>
            this.__findDiagnosesDescription(icd10Info.code),
          ),
        );

        icd10Infos.forEach((icd10Info, i) => {
          foundCodeDescription = icd10CodesLookup[i];

          if (!foundCodeDescription) {
            isValid = false;
          } else if (
            icd10Info.shortDescription !== foundCodeDescription.shortDescription
          ) {
            icd10Infos[i] = {
              ...icd10Info,
              shortDescription: foundCodeDescription.shortDescription,
            };

            descriptionUpdated = true;
          }
        });

        if (!isValid) {
          store.dispatch(
            openWarning(
              'One or more diagnosis codes is not active for the date of service',
            ),
          );
        }

        if (descriptionUpdated) {
          store.dispatch(
            openInfo(
              'One or more of the diagnosis descriptions has been updated to match the date of service',
            ),
          );
        }

        this.__savedCodes = await this.__mapCodes([
          ...this.__savedCodes,
          ...icd10Infos,
        ]);
      },
      addCodeBundle: async () => {
        const result = await openOverlay(
          OVERLAY_KEYS.ADD_CODE_BUNDLE_TO_ENCOUNTER,
          { encounter: this.encounter },
        );

        if (result) {
          this.onCodeBundleAdded();
        }
      },
      cancel: () => this.onCancel(),
      removeDiagnosis: icd10Info => this.__removeDiagnosis(icd10Info),
      removeAllDiagnoses: () => this.__removeAllDiagnoses(),
      reorderDiagnoses: () => this.requestUpdate(),
      save: () => {
        this.onSave(this.__unmapCodes(this.__savedCodes), {
          closeAfterSave: false,
        });

        this.onRefreshWarning({ diagnosesDict: this.__diagnosesDict });
      },
      saveAndClose: () => {
        this.onSave(this.__unmapCodes(this.__savedCodes), {
          closeAfterSave: true,
        });

        this.onRefreshWarning({ diagnosesDict: this.__diagnosesDict });
      },
      updateEncounter: () => this.onUpdateEncounter(),
    };
  }

  __removeDiagnosis(icd10Info) {
    const savedCodes = Object.assign([], this.__savedCodes);
    const icd10infoIndex = savedCodes.map(c => c.code).indexOf(icd10Info.code);

    if (icd10infoIndex !== -1) {
      savedCodes.splice(icd10infoIndex, 1);
      this.__savedCodes = savedCodes;
    }
  }

  __removeAllDiagnoses() {
    this.__savedCodes = [];
  }

  isDirty() {
    return (
      JSON.stringify(this.__unmapCodes(this.__savedCodes)) !==
      JSON.stringify(this.originalCodes)
    );
  }

  async clear() {
    this.__savedCodes = await this.__mapCodes([...this.originalCodes]);
  }

  firstUpdated() {
    this.__elements = {
      tabController: this.shadowRoot.getElementById(ELEMENTS.tabController.id),
    };
  }

  async updated(changedProps) {
    if (changedProps.has('originalCodes')) {
      this.__savedCodes = await this.__mapCodes([...this.originalCodes]);
    }

    if (changedProps.has('__savedCodes')) {
      this.onChangeDirty(this.isDirty());
    }

    if (changedProps.has('originalDiagnosesDict')) {
      this.__diagnosesDict = this.originalDiagnosesDict;
    }
  }

  __renderActionBar() {
    return this.legacyChartingV1
      ? html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .onConfirm="${this.__handlers.save}"
            .onCancel="${this.__handlers.cancel}"
            confirmLabel="Save Diagnosis"
          ></neb-action-bar>
        `
      : html`
          <neb-action-bar
            id="${ELEMENTS.actionBar.id}"
            .onConfirm="${this.__handlers.save}"
            .onCancel="${this.__handlers.saveAndClose}"
            .onRemove="${this.__handlers.cancel}"
            confirmLabel="Save Diagnosis"
            cancelLabel="Save and Close"
            removeLabel="Cancel"
          ></neb-action-bar>
        `;
  }

  render() {
    const isDirty = this.isDirty();

    return html`
      <div class="container">
        <div class="header-container">
          <div>
            <div class="title">Encounter Diagnosis</div>
            <neb-button-action
              id="${ELEMENTS.addCodeBundleButton.id}"
              leadingIcon="codeBundle"
              label="Add Code Bundle"
              ?disabled="${isDirty}"
              .onClick="${this.__handlers.addCodeBundle}"
            ></neb-button-action>
          </div>
          <neb-charting-diagnosis-date-of-current-illness
            id="${ELEMENTS.dateOfCurrentIllness.id}"
            .encounter="${this.encounter}"
            .onUpdateEncounter="${this.__handlers.updateEncounter}"
          ></neb-charting-diagnosis-date-of-current-illness>
        </div>

        <div class="encounter-diagnosis-container">
          <neb-encounter-diagnosis-table
            id="${ELEMENTS.diagnosisTable.id}"
            .savedCodes="${this.__savedCodes}"
            .savedProblems="${this.savedProblems}"
            .onAddToProblems="${this.__handlers.addToProblems}"
            .onRemoveDiagnosis="${this.__handlers.removeDiagnosis}"
            .onRemoveAllDiagnoses="${this.__handlers.removeAllDiagnoses}"
            .onReorderDiagnoses="${this.__handlers.reorderDiagnoses}"
            .showAddToProblems="${true}"
          >
          </neb-encounter-diagnosis-table>
        </div>

        <neb-diagnosis-tabs-controller
          id="${ELEMENTS.tabController.id}"
          .providerId="${this.providerId}"
          .layout="${this.layout}"
          .savedCodes="${this.__savedCodes}"
          .savedProblems="${this.savedProblems}"
          .encounter="${this.encounter}"
          .onAddDiagnosis="${this.__handlers.addDiagnosis}"
          .onAddAllDiagnosis="${this.__handlers.addAllDiagnosis}"
          .visible="${this.visible}"
          .icd10Year="${this.icd10Year}"
          .initialIcd10Year="${this.icd10Year}"
        ></neb-diagnosis-tabs-controller>
      </div>

      ${isDirty ? this.__renderActionBar() : ''}
    `;
  }
}

customElements.define(
  'neb-charting-diagnosis-detail',
  NebChartingDiagnosisDetail,
);
