export const getPracticeInfo = ({
  item: { id: tenantId, name: practiceName, salesforceId, tier, addOns },
}) => ({ practiceName, salesforceId, tenantId, tier, addOns });

export const getPracticeInformationFromState = ({ practiceInformation }) =>
  practiceInformation;

export const getSessionInfo = session => {
  const {
    item: {
      firstName,
      idToken: {
        payload: { email },
      },
      lastName,
      tenantId,
    },
  } = session;

  const {
    item: {
      access: {
        [tenantId]: { r: role },
      },
    },
  } = session;

  return {
    email,
    firstName,
    lastName,
    role,
  };
};

export const isPracticeInformationReady = ({ item }) => Boolean(item);
