import '../neb-popup-header';
import '../neb-selection-card';
import '../neb-text';

import { html, css } from 'lit';

import {
  OVERLAY_WIDTH_LARGE,
  CSS_SPACING,
  layoutStyles,
} from '../../../../../src/styles';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
  REFRESH_PATIENT_KEY,
  NEBULA_REFRESH_EVENT,
} from '../../../../neb-utils/neb-refresh';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';

import Overlay from './neb-overlay';

export const ELEMENTS = {
  header: { id: 'header' },
  description: { id: 'description' },
  cards: { selector: 'neb-selection-card' },
  patientInsuranceCard: { id: 'patient-insurance' },
  realTimeEligibilityCard: { id: 'real-time-eligibility' },
};

class OverlayAddInsurance extends Overlay {
  static get styles() {
    return [
      super.styles,
      layoutStyles,
      css`
        .grid {
          padding: ${CSS_SPACING};
          grid-gap: ${CSS_SPACING};
        }

        .content {
          width: ${OVERLAY_WIDTH_LARGE};
        }
      `,
    ];
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      addPatientInsurance: async () => {
        const result = await openOverlay(OVERLAY_KEYS.PATIENT_INSURANCE_ADD, {
          patientId: this.model.patientId,
          patientInsurances: this.model.patientInsurances,
          context: this.model.context,
          forCheckIn: this.model.forCheckIn || false,
        });

        if (result) {
          await this.__showViewInsurance(result);

          this.onClose();
        } else if (result === null) {
          this.dismiss(null);
        }
      },

      addRealTimeEligibility: async () => {
        const result = await openOverlay(OVERLAY_KEYS.ADD_INSURANCE_RTE, {
          patientId: this.model.patientId,
          patientInsurances: this.model.patientInsurances,
          context: this.model.context,
          providerId: this.model.providerId ? this.model.providerId : null,
          forCheckIn: this.model.forCheckIn || false,
        });

        if (result) {
          sendRefreshNotification(
            [
              REFRESH_CHANGE_TYPE.PATIENT_INSURANCE,
              REFRESH_CHANGE_TYPE.PATIENT,
            ],
            this.model.patientId,
          );

          await this.__showViewInsurance(result);

          this.onClose();
        } else if (result === null) {
          this.dismiss(null);
        }
      },

      refresh: ({ detail }) => {
        if (!this.model.patientId) return;

        const { changed } = detail[REFRESH_PATIENT_KEY][this.model.patientId];

        if (changed.includes(REFRESH_CHANGE_TYPE.PATIENT_INSURANCE)) {
          this.requestUpdate();
        }
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    window.addEventListener(NEBULA_REFRESH_EVENT, this.handlers.refresh);
  }

  disconnectedCallback() {
    window.removeEventListener(NEBULA_REFRESH_EVENT, this.handlers.refresh);

    super.disconnectedCallback();
  }

  async __showViewInsurance(insurance) {
    await openOverlay(OVERLAY_KEYS.PATIENT_INSURANCE_VIEW, {
      patientId: this.model.patientId,
      patientInsurance: { id: insurance.id },
    });

    this.requestUpdate();

    sendRefreshNotification(
      [REFRESH_CHANGE_TYPE.PATIENT_INSURANCE, REFRESH_CHANGE_TYPE.PATIENT],
      this.model.patientId,
    );
  }

  renderContent() {
    return html`
      <div class="grid">
        <div>
          <neb-popup-header
            id="${ELEMENTS.header.id}"
            title="Add Insurance"
            .onCancel="${this.handlers.dismiss}"
            showCancelButton
          ></neb-popup-header>

          <neb-text id="${ELEMENTS.description.id}"
            >Choose whether you would like to manually enter insurance
            information or use real-time eligibility.</neb-text
          >
        </div>

        <neb-selection-card
          id="${ELEMENTS.patientInsuranceCard.id}"
          title="Input Insurance"
          icon="insurance"
          .onClick="${this.handlers.addPatientInsurance}"
        >
          <p>Manually enter the patient's insurance information.</p>
        </neb-selection-card>

        <neb-selection-card
          id="${ELEMENTS.realTimeEligibilityCard.id}"
          title="Use Real-Time Eligibility"
          icon="realTimeEligibility"
          .onClick="${this.handlers.addRealTimeEligibility}"
        >
          <p>
            Enter a minimal amount of data to verify the patient's insurance.
            The payer will return policy and benefits information that will be
            saved to the patient's record.
          </p>
        </neb-selection-card>
      </div>
    `;
  }
}

customElements.define('neb-overlay-add-insurance', OverlayAddInsurance);
