import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../cards/neb-preallocate-line-item-card';
import { html, css, LitElement } from 'lit';

import { BUTTON_ROLE } from '../../../../packages/neb-lit-components/src/components/neb-button';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import {
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../packages/neb-styles/neb-variables';
import { centsToCurrency } from '../../../../packages/neb-utils/formatters';
import {
  CSS_SPACING,
  CSS_COLOR_GREY_4,
  CSS_COLOR_GREY_2,
  CSS_BANNER_INFO_BACKGROUND_COLOR,
  CSS_COLOR_HIGHLIGHT,
  CSS_BANNER_INFO_COLOR,
} from '../../../styles';
import {
  calculateCurrentBalance,
  calculatePreallocationTotal,
  mapLineItemForAllocation,
} from '../../../utils/preallocate-payment-util';

export const ELEMENTS = {
  lineItemList: {
    id: 'line-item-list',
  },
  lineItemCard: {
    selector: '[id^=line-item-card-]',
  },
  summary: {
    id: 'summary',
  },
  totalDueToday: {
    id: 'total-due-today',
  },
  emptyList: {
    id: 'empty-list',
  },
  confirmButton: {
    id: 'confirm-button',
  },
};

class NebFormPreallocatePayment extends LitElement {
  static get properties() {
    return {
      preallocateItems: Array,
      remainingPatientBalance: Number,
      patient: Object,
    };
  }

  __onChange(lineItem, idx) {
    this.preallocateItems[idx] = {
      ...this.preallocateItems[idx],
      ...lineItem,
    };

    this.preallocateItems[idx].selected = lineItem.selected;
    this.requestUpdate('preallocateItems');
  }

  async __makePayment() {
    const lineItems = this.preallocateItems
      .filter(li => li.selected)
      .map(li => mapLineItemForAllocation(li));

    const amount = calculatePreallocationTotal(this.preallocateItems);

    const result = await openOverlay(OVERLAY_KEYS.ADD_PATIENT_PAYMENT, {
      patientId: this.patient.id,
      patient: this.patient,
      autoAllocate: {
        lineItems,
        keepOpenAfterAllocation: true,
      },
      minimunAmount: amount,
      chargeInfo: {
        amount,
        paymentType: { label: '' },
        alwaysShowActionBar: true,
        allowEditPaymentTypeAndAmount: true,
        hideVoidRefundButton: true,
      },
    });

    if (result?.paid) {
      this.onDismiss(result);
    }
  }

  static get styles() {
    return css`
      #summary {
        flex: 3;
        display: flex;
        flex-direction: column;
        gap: ${CSS_SPACING};
        background: ${CSS_COLOR_GREY_4};
        padding: ${CSS_SPACING};
        border-left: 1px solid ${CSS_COLOR_GREY_2};
      }

      #line-item-list {
        padding: ${CSS_SPACING};
        gap: ${CSS_SPACING};
        overflow-y: scroll;
      }

      #empty-list {
        color: ${CSS_BANNER_INFO_COLOR};
        align-items: center;
        text-align: center;
        justify-content: center;
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
        font-size: ${CSS_FONT_SIZE_HEADER};
      }

      .total-due-today-box {
        color: ${CSS_BANNER_INFO_COLOR};
        background: ${CSS_BANNER_INFO_BACKGROUND_COLOR};
        padding: ${CSS_SPACING};
        border: 1px solid ${CSS_COLOR_HIGHLIGHT};
        padding: ${CSS_SPACING};
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
      }

      .form {
        display: flex;
        flex-direction: row;
        height: 100%;
      }

      .celebration-icon {
        height: 125px;
        width: 125px;
        fill: ${CSS_BANNER_INFO_COLOR};
      }

      .list {
        flex: 7;
        flex-direction: column;
        display: flex;
      }

      .summary-title {
        margin: 0;
      }

      .flex-separator {
        display: flex;
        justify-content: space-between;
      }

      .spacer {
        flex: 1 0 0;
      }

      .line-item-card {
        padding: ${CSS_SPACING};
        border: 1px solid black;
      }

      .button {
        width: fit-content;
      }
    `;
  }

  __renderEmptyList() {
    if (this.preallocateItems === null) return html`<div class="list"></div>`;

    return html`
      <div id="${ELEMENTS.emptyList.id}" class="list">
        <neb-icon class="celebration-icon" icon="neb:celebration"></neb-icon>
        <div>
          Great news!
          <br />
          There are no outstanding balances for this patient at this time.
        </div>
      </div>
    `;
  }

  __renderCardList() {
    return html`
      <div id="${ELEMENTS.lineItemList.id}" class="list">
        ${this.preallocateItems.map(
          (li, idx) => html`
            <neb-preallocate-line-item-card
              id="line-item-card-${idx}"
              .preallocateItem="${li}"
              .onChange="${item => this.__onChange(item, idx)}"
            ></neb-preallocate-line-item-card>
          `,
        )}
      </div>
    `;
  }

  __renderSummary() {
    return html`
      <div id="${ELEMENTS.summary.id}">
        <h2 class="summary-title">Summary</h2>
        <div class="flex-separator total-due-today-box">
          <span> Total Due Today: </span>
          <span id="${ELEMENTS.totalDueToday.id}">
            ${centsToCurrency(
              calculatePreallocationTotal(this.preallocateItems || []),
            )}
          </span>
        </div>
        <div class="flex-separator">
          <span>Remaining Patient Balance:</span>
          <span
            >${centsToCurrency(
              calculateCurrentBalance(
                this.preallocateItems || [],
                this.remainingPatientBalance,
              ),
            )}</span
          >
        </div>
        <div class="spacer"></div>
        <neb-button
          id="${ELEMENTS.confirmButton.id}"
          class="button"
          label="Make Payment"
          .role="${BUTTON_ROLE.CONFIRM}"
          .disabled="${this.__isMakePaymentDisabled()}"
          .onClick="${() => this.__makePayment()}"
          unelevated
        ></neb-button>
      </div>
    `;
  }

  __isMakePaymentDisabled() {
    return !!(
      this.preallocateItems?.length &&
      !this.preallocateItems.some(item => item.selected)
    );
  }

  __renderContent() {
    return this.preallocateItems?.length
      ? this.__renderCardList()
      : this.__renderEmptyList();
  }

  render() {
    return html`
      <div class="form">
        ${this.__renderContent()} ${this.__renderSummary()}
      </div>
    `;
  }
}

customElements.define(
  'neb-form-preallocate-payment',
  NebFormPreallocatePayment,
);
