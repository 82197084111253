import { parseDate } from '../../packages/neb-utils/date-util';
import {
  BILL_TYPE,
  ITEM_INSURANCE,
  ITEM_PACKAGE,
  ITEM_SELF,
  ITEM_SELF_PAY,
} from '../../packages/neb-utils/neb-ledger-util';

export const formatBillType = billType => {
  switch (billType) {
    case BILL_TYPE.SELF:
      return 'Self';
    case BILL_TYPE.INSURANCE:
      return 'Insurance';
    case BILL_TYPE.PACKAGE:
      return 'Care Package';
    default:
      return billType;
  }
};

const formatBillTypeToForm = billType => {
  switch (billType) {
    case ITEM_SELF.label:
      return {
        label: ITEM_SELF_PAY.label,
        id: BILL_TYPE.SELF,
      };
    case ITEM_INSURANCE.label:
      return {
        label: ITEM_INSURANCE.label,
        id: BILL_TYPE.INSURANCE,
      };
    case ITEM_PACKAGE.label:
      return {
        label: ITEM_PACKAGE.label,
        id: BILL_TYPE.PACKAGE,
      };
    default:
      return {
        label: null,
        id: null,
      };
  }
};

const formatPayerName = payerName =>
  payerName === 'self' ? 'Self' : payerName;

const formatGuarantor = guarantor =>
  guarantor === 'self' ? 'Self' : guarantor;

const formatCaseField = (caseName, onsetSymptomsDate) => {
  if (caseName) {
    return onsetSymptomsDate
      ? `${caseName} - ${parseDate(onsetSymptomsDate).format('MM/DD/YYYY')}`
      : `${caseName} - Gradual`;
  }

  return null;
};

const formatAuthorizationField = (authNumber, approvedFrom, approvedTo) => {
  const dateRange =
    approvedFrom && approvedTo
      ? ` (${parseDate(approvedFrom).format('MM/DD/YYYY')} - ${parseDate(
          approvedTo,
        ).format('MM/DD/YYYY')})`
      : '';

  return authNumber ? `${authNumber}${dateRange}` : null;
};

// eslint-disable-next-line complexity
export const formatPayerInformation = payerInformation => ({
  billType: formatBillType(payerInformation.billType),
  payerName: formatPayerName(payerInformation.payerName),
  payerId: payerInformation.payerId || null,
  primaryInsurance: payerInformation.primaryInsurance,
  primaryInsuranceId: payerInformation.primaryInsuranceId || null,
  secondaryInsurance: payerInformation.secondaryInsurance,
  secondaryInsuranceId: payerInformation.secondaryInsuranceId || null,
  secondaryPayerPlanId: payerInformation.secondaryPayerPlanId,
  guarantor: formatGuarantor(payerInformation.guarantor),
  guarantorId: payerInformation.guarantorId || null,
  case: formatCaseField(
    payerInformation.case,
    payerInformation.onsetSymptomsDate,
  ),
  caseId: payerInformation.caseId || null,
  authorizationId: payerInformation.authorizationId || null,
  authorizationNumber: formatAuthorizationField(
    payerInformation.authorizationNumber,
    payerInformation.authorizationApprovedFrom,
    payerInformation.authorizationApprovedTo,
  ),
  patientPackageName: payerInformation.patientPackageName || null,
  patientPackageId: payerInformation.patientPackageId || null,
});

export const formatPayerInformationToSave = payerInfo => ({
  billType: payerInfo.billType.id,
  caseId: payerInfo.case.id,
  guarantorId: payerInfo.guarantor.id,
  insuranceId: payerInfo.primaryInsurance.id,
  patientAuthorizationId: payerInfo.authorization.id,
  packageId:
    payerInfo.multiCarePackageEnabled &&
    payerInfo.billType.id === BILL_TYPE.PACKAGE
      ? 'multi'
      : payerInfo.patientPackage.id,
  payerId: payerInfo.payer.id,
  secondaryInsuranceId: payerInfo.secondaryInsurance.id,
  secondaryPayerPlanId: payerInfo.secondaryPayerPlanId,
});

export const formatPayerInformationToForm = payerInfo => ({
  billType: formatBillTypeToForm(payerInfo.billType),
  authorization: {
    id: payerInfo.authorizationId,
    label: payerInfo.authorizationNumber,
  },
  case: {
    id: payerInfo.caseId,
    label: payerInfo.case,
  },
  guarantor: {
    id: payerInfo.guarantorId,
    label: payerInfo.guarantor,
  },
  primaryInsurance: {
    id: payerInfo.primaryInsuranceId,
    label: payerInfo.primaryInsurance,
  },
  secondaryInsurance: {
    id: payerInfo.secondaryInsuranceId,
    label: payerInfo.secondaryInsurance,
  },
  payer: {
    id: payerInfo.payerId,
    label: payerInfo.payerName,
  },
  patientPackage: {
    id: payerInfo.patientPackageId,
    label: payerInfo.patientPackageName,
  },
});
