import { getAppointmentById } from '../../../../../neb-api-client/src/appointment-api-client';
import { fetchOne } from '../../../../../neb-api-client/src/patient-cases';

const getAuthorizedProcedures = async appointmentId => {
  const { appointment } = await getAppointmentById(appointmentId);

  if (!appointment.caseId || !appointment.patientAuthorizationId) {
    return null;
  }

  const { patientAuthorizations } = await fetchOne(
    appointment.patientId,
    appointment.caseId,
  );

  const patientAuthorization = patientAuthorizations.find(
    ({ id }) => id === appointment.patientAuthorizationId,
  );

  if (!patientAuthorization) {
    return null;
  }

  return patientAuthorization.charges.map(({ procedure }) => procedure);
};

export default async ({ appointmentId = '' } = {}) => {
  const authorizedProcedures = await getAuthorizedProcedures(appointmentId);

  return {
    authorizedProcedures,
  };
};
