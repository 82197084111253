import { html, css, LitElement } from 'lit';
import '../controls/inputs/neb-checkbox';
import '../../../packages/neb-lit-components/src/components/inputs/neb-textfield';

import {
  centsToCurrency,
  currencyToCents,
} from '../../../packages/neb-utils/formatters';
import { currency } from '../../../packages/neb-utils/masks';
import { formatBilled } from '../../formatters/line-items';
import {
  CSS_SPACING,
  CSS_BANNER_INFO_COLOR,
  CSS_COLOR_GREY_1,
} from '../../styles';
import {
  calculatePatientResponsibility,
  calculatePayerResponsibility,
} from '../../utils/preallocate-payment-util';

export const ELEMENTS = {
  lineItemCard: {
    id: 'line-preallocateItem-card',
  },
  header: {
    id: 'header',
  },
  title: {
    id: 'title',
  },
  subtitle: {
    id: 'sub-title',
  },
  charge: {
    id: 'charge',
  },
  chargeDetails: {
    id: 'charge-details',
  },
  chargeAmount: {
    id: 'charge-amount',
  },
  checkboxLineItem: {
    id: 'checkbox-line-preallocateItem',
  },
  amountTextField: {
    id: 'amount-text-field',
  },
};

export const ACTIONS = Object.freeze({
  checkboxUpdate: 'CHECKBOX_UPDATE',
  amountUpdate: 'AMOUNT_UPDATE',
});

class NebPreallocateLineItemCard extends LitElement {
  static get properties() {
    return {
      preallocateItem: Object,
      __value: Number,
    };
  }

  static get styles() {
    return css`
      #line-preallocateItem-card {
        padding: ${CSS_SPACING};
        box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.14);
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        gap: ${CSS_SPACING};
      }

      #title {
        color: ${CSS_BANNER_INFO_COLOR};
      }

      #charge-details {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      #charge-amount {
        display: flex;
        gap: ${CSS_SPACING};
        align-items: center;
      }

      .flex-separator {
        display: flex;
        justify-content: space-between;
      }

      .small {
        font-size: 12px;
        color: ${CSS_COLOR_GREY_1};
      }
    `;
  }

  updated(changedProps) {
    super.updated(changedProps);

    if (changedProps.has('preallocateItem') && this.preallocateItem) {
      this.__value = centsToCurrency(this.preallocateItem.amount);
    }
  }

  __onChange(e) {
    const { outstandingPatientBalance } = this.preallocateItem;
    const li = {
      amount: this.preallocateItem.amount,
      selected: this.preallocateItem.selected,
    };

    switch (e.name) {
      case ACTIONS.checkboxUpdate:
        li.selected = e.value;

        if (!li.selected) {
          li.amount = 0;
        } else {
          li.amount = outstandingPatientBalance;
        }

        this.onChange(li);

        break;
      case ACTIONS.amountUpdate:
        if (e.event === 'blur') {
          let centsValue = currencyToCents(e.value);

          li.selected = centsValue > 0;

          if (centsValue > outstandingPatientBalance) {
            centsValue = outstandingPatientBalance;
          }

          li.amount = centsValue;
          e.value = centsToCurrency(centsValue);

          this.onChange(li);
        }

        this.__value = e.value;

        break;
      default:
        break;
    }
  }

  __renderTitle() {
    return html`<h3 id="${ELEMENTS.title.id}">
      ${this.preallocateItem.title}
    </h3>`;
  }

  __renderHeader() {
    return html`
      <div id="${ELEMENTS.header.id}">
        ${this.__renderTitle()} ${this.__renderSubtitle()}
      </div>
    `;
  }

  __renderSubtitle() {
    return html`
      <div id="${ELEMENTS.subtitle.id}" class="flex-separator">
        <div>${this.preallocateItem.providerName}</div>
        <div>
          <b>
            Estimated Owed:
            ${centsToCurrency(
              this.preallocateItem.outstandingPatientBalance,
            )}</b
          >
        </div>
      </div>
    `;
  }

  __renderCharge() {
    return html`
      <div id="${ELEMENTS.charge.id}" class="flex-separator">
        <div id="${ELEMENTS.chargeDetails.id}">
          <span> ${this.preallocateItem.chargeDescription} </span>
          <span class="small">Bill Type: ${this.preallocateItem.billType}</span>
        </div>
        <div id="${ELEMENTS.chargeAmount.id}">
          <neb-checkbox
            id="${ELEMENTS.checkboxLineItem.id}"
            name="${ACTIONS.checkboxUpdate}"
            .onChange="${e => this.__onChange(e)}"
            ?checked="${this.preallocateItem.selected}"
          ></neb-checkbox>
          <neb-textfield
            label=" "
            helper=" "
            id="${ELEMENTS.amountTextField.id}"
            name="${ACTIONS.amountUpdate}"
            .value="${this.__value}"
            .mask="${currency}"
            .inputMode="${'numeric'}"
            ?disabled="${!this.preallocateItem.selected}"
            .onChange="${e => this.__onChange(e)}"
          ></neb-textfield>
        </div>
      </div>
    `;
  }

  __renderInsuranceData() {
    return html`
      <div>
        <h4>Insurance ${this.lineItem.insurance?.planName}</h4>
        <p>Payer: ${this.lineItem.insurance?.payerPlan?.alias}</p>

        ${this.lineItem.insurance?.copays?.length
          ? html`<p>
              Co-pay:
              ${centsToCurrency(
                this.lineItem.insurance.copays[0].inNetworkAmount,
              )}
            </p>`
          : ''}
        ${this.lineItem.insurance?.deductibles?.length
          ? html`<p>
              Ded:
              ${centsToCurrency(
                this.lineItem.insurance.deductibles[0].inNetworkAmount,
              )}
            </p>`
          : ''}
        ${this.lineItem.insurance?.coinsurances?.length
          ? html`<p>
              Co-ins:
              ${this.lineItem.insurance.coinsurances[0].inNetworkPercent}%
            </p>`
          : ''}

        <p>
          Patient Responsibility:
          ${centsToCurrency(calculatePatientResponsibility(this.lineItem))}
        </p>
        <p>
          Payer Responsibility:
          ${centsToCurrency(calculatePayerResponsibility(this.lineItem))}
        </p>
      </div>
    `;
  }

  __renderChargeDetails() {
    return html`
      <div>
        <h4>Charges Data</h4>
        <p>Type: ${this.lineItem.billType}</p>

        ${this.lineItem.encounterCharge?.suppressFromClaim
          ? html`<b>Non-covered</b>`
          : ''}

        <p>Modifiers: ${this.lineItem.modifiers?.filter(m => m).join(', ')}</p>
        <p>Units: ${this.lineItem.units}</p>
        <p>Billed Status: ${formatBilled(this.lineItem)}</p>
        <p>Code: ${this.lineItem.code}</p>
        <p>Description: ${this.lineItem.description}</p>
        <p>Invoice: ${this.lineItem.invoice?.invoiceNumber}</p>
        <p>PatientId: ${this.lineItem.patientId}</p>
        <p>Charge Type: ${this.lineItem.codeType}</p>
        <p>
          Adjustments:
          ${this.lineItem.adjustments?.map(
            a => html`[${a.codeWriteOff.code}: ${centsToCurrency(a.amount)}] `,
          )}
        </p>
        <p>Tax amount: ${this.lineItem.taxAmount}</p>
      </div>
    `;
  }

  __renderAllocations() {
    const allocations = this.lineItem.lineItemDebits.reduce(
      (acc, cur) => [...acc, ...cur.debit.allocations],
      [],
    );

    return html`
      <div>
        <h4>Allocations</h4>
        ${allocations.map(
          ({
            amount,
            credit: {
              payment: { paymentMethod, paymentNumber, codeDiscountId },
            },
          }) => html`
            <p>
              Payment ${codeDiscountId ? 'D-' : ''}${paymentNumber}:
              ${centsToCurrency(amount)}
            </p>
            <p>Payment Method: ${paymentMethod}</p>
          `,
        )}
      </div>
    `;
  }

  __renderAppointment() {
    return html`
      <div>
        <h4>Encounter Data</h4>
        <p>Encounter Number: ${this.lineItem.encounter?.encounterNumber}</p>
        <p>Service Date: ${this.lineItem.encounter?.serviceDate}</p>
        <p>
          Appointment Type: ${this.lineItem.encounter?.appointmentType?.name}
        </p>
        <p>Location Id: ${this.lineItem.encounter?.locationId}</p>
      </div>
    `;
  }

  render() {
    if (!this.preallocateItem) return '';
    return html`
      <div id="${ELEMENTS.lineItemCard.id}">
        ${this.__renderHeader()} ${this.__renderCharge()}
      </div>
    `;
  }
}

customElements.define(
  'neb-preallocate-line-item-card',
  NebPreallocateLineItemCard,
);
