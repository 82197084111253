import { openPopup } from '@neb/popup';

import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { PROCEDURE_NOT_AUTHORIZED_POPUP_MODEL } from '../../../../neb-utils/patientAuthorization';

export default async (authorizedProcedures, charges) => {
  if (!authorizedProcedures.length) {
    return true;
  }

  const allChargesAreAuthorized = charges.every(charge =>
    authorizedProcedures.find(procedure => procedure === charge.procedure),
  );

  if (allChargesAreAuthorized) {
    return true;
  }

  const result = await openPopup(
    POPUP_RENDER_KEYS.CONFIRM,
    PROCEDURE_NOT_AUTHORIZED_POPUP_MODEL,
  );

  return Boolean(result);
};
