/* eslint-disable complexity */
import '../../../neb-lit-components/src/components/controls/neb-button-action';
import '../../../../src/components/filters/neb-filters-patient-appointments';
import '../../../../src/components/filters/neb-filters-patient-appointments-mobile';
import '../../../neb-lit-components/src/components/neb-button-actions';
import '../../../neb-appointment/neb-appointments';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { LitElement, html, css } from 'lit';
import { classMap } from 'lit/directives/class-map.js';

import * as rteInsuranceApi from '../../../../src/api-clients/insurance-status';
import NebFormAppointment from '../../../../src/components/forms/appointments/neb-form-appointment';
import { ADD_ONS, hasAddOn } from '../../../../src/utils/add-ons';
import { SELECT_LOCATION_MESSAGE_FOR_APPTS } from '../../../../src/utils/user-message';
import { fetchMany } from '../../../neb-api-client/src/patient-cases';
import { getSchedulingPermissions } from '../../../neb-api-client/src/permissions-api-client';
import { fetchManyRooms } from '../../../neb-api-client/src/rooms-api-client';
import {
  FUTURE_QUERY_META,
  PAST_QUERY_META,
} from '../../../neb-appointment/neb-appointment-query-meta';
import { queryAppointments } from '../../../neb-appointment/neb-appointments-query';
import { KEPT_STATUSES } from '../../../neb-lit-components/src/components/scheduling/neb-appointment-options';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../neb-lit-components/src/utils/overlay-constants';
import printUpcomingAppointments from '../../../neb-lit-components/src/utils/pdf/print-upcoming-appointments';
import { POPUP_RENDER_KEYS } from '../../../neb-popup/src/renderer-keys';
import { LocationsService } from '../../../neb-redux/services/locations';
import { CSS_SPACING } from '../../../neb-styles/neb-variables';
import { parseDate } from '../../../neb-utils/date-util';
import { URL_NO_ACCESS } from '../../../neb-utils/neb-request-security';

const APPOINTMENT_TABLE_TYPES = {
  FUTURE: 'future',
  PAST: 'past',
};

export const ELEMENTS = {
  addAppointmentButton: {
    id: 'add-appointment-button',
  },
  printUpcomingApptsButton: {
    id: 'print-upcoming-appts-button',
  },
  buttonActions: {
    id: 'button-actions',
  },
  pastAppointments: {
    id: 'past-appointments-table',
  },
  futureAppointments: {
    id: 'future-appointments-table',
  },
  filters: {
    id: 'filters',
  },
  filtersMobile: {
    id: 'filters-mobile',
  },
};

export function isDateInFuture(from, to) {
  const currentDate = parseDate().startOf('day').toISOString();

  if (
    (!to &&
      from &&
      (from.isSame(currentDate, 'day') || from.isAfter(currentDate))) ||
    (to &&
      from &&
      (from.isSame(currentDate, 'day') || from.isAfter(currentDate)) &&
      to.isAfter(currentDate))
  ) {
    return true;
  }

  return false;
}

export function isDateInPast(from, to) {
  const currentDate = parseDate().startOf('day').toISOString();

  if (
    (!from && to && to.isBefore(currentDate)) ||
    (from && from.isBefore(currentDate) && to && to.isBefore(currentDate))
  ) {
    return true;
  }

  return false;
}

export function getFutureDateFilter(from, to) {
  const currentDate = parseDate().startOf('day').toISOString();

  let start = currentDate;

  if (from && from.isAfter(currentDate)) {
    start = from.toISOString();
  }

  let end = null;

  if (to && to.isAfter(currentDate)) {
    end = to.toISOString();
  }

  return { start, end };
}

export function getPastDateFilter(from, to) {
  const currentDate = parseDate().startOf('day').toISOString();

  let startBefore = currentDate;

  if (to && to.isBefore(currentDate)) {
    startBefore = to.toISOString();
  }

  let start = null;

  if (from && from.isBefore(currentDate)) {
    start = from.toISOString();
  }

  return { startBefore, start };
}

class NebPatientAppointments extends LitElement {
  static get properties() {
    return {
      active: {
        type: Boolean,
      },
      patientId: {
        type: String,
      },
      title: {
        type: String,
      },
      layout: {
        type: String,
        reflect: true,
      },
      __futureAppointments: {
        type: Object,
      },
      __pastAppointments: {
        type: Object,
      },
      __futureQuery: {
        type: Object,
      },
      __pastQuery: {
        type: Object,
      },
      __resetPage: {
        type: Boolean,
      },
      __locations: {
        type: Array,
      },
      __cases: {
        type: Array,
      },
      __rooms: {
        type: Array,
      },
      filter: {
        type: String,
      },
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
    this.__initServices();
  }

  __initState() {
    this.active = false;
    this.patientId = '';
    this.layout = '';

    this.__futureQuery = FUTURE_QUERY_META();
    this.__pastQuery = PAST_QUERY_META();
    this.__resetPage = true;

    this.__futureAppointments = {
      appointments: [],
      queryMetadata: {},
      count: 0,
    };

    this.__pastAppointments = {
      appointments: [],
      queryMetadata: {},
      count: 0,
    };

    this.__menuItems = Object.freeze({
      SELECT_LOCATION: {
        label: 'Select Location',
        onSelect: () => this.__handlers.printUpcomingAppointmentsForLocation(),
      },
    });

    this.__loadingAppointments = false;
    this.__locations = [];
    this.__cases = [];
  }

  __initHandlers() {
    this.__handlers = {
      openNewAppointment: async () => {
        if (getSchedulingPermissions()) {
          await this.__openAppointmentOverlay();
        } else {
          navigate(URL_NO_ACCESS);
        }
      },
      futureTableUpdated: queryParam => {
        const from = parseDate(
          this.__pastAppointments.queryMetadata.start ||
            this.__futureAppointments.queryMetadata.start,
        );
        const to = parseDate(
          this.__futureAppointments.queryMetadata.end ||
            this.__pastAppointments.queryMetadata.startBefore,
        );

        if (isDateInPast(from, to)) {
          this.__futureAppointments = {
            appointments: [],
            queryMetadata: queryParam,
            count: 0,
          };
        } else {
          this.__tableUpdated(queryParam, APPOINTMENT_TABLE_TYPES.FUTURE);
        }
      },
      pastTableUpdated: queryParam => {
        const from = parseDate(
          this.__pastAppointments.queryMetadata.start ||
            this.__futureAppointments.queryMetadata.start,
        );
        const to = parseDate(
          this.__futureAppointments.queryMetadata.end ||
            this.__pastAppointments.queryMetadata.startBefore,
        );

        if (isDateInFuture(from, to)) {
          this.__pastAppointments = {
            appointments: [],
            queryMetadata: queryParam,
            count: 0,
          };
        } else {
          this.__tableUpdated(queryParam, APPOINTMENT_TABLE_TYPES.PAST);
        }
      },

      printUpcomingAppointmentsForLocation: () =>
        this.__printUpcomingAppointmentsForLocation(),
      printUpcomingAppointments: () =>
        printUpcomingAppointments({
          patient: { id: this.patientId },
        }),
      // eslint-disable-next-line complexity
      filter: model => {
        const {
          locationIds,
          providerIds,
          caseIds,
          status,
          date,
          includeRescheduled,
        } = model;
        const { to, from } = date;

        const futureDateFilter = getFutureDateFilter(from, to);

        const pastDateFilter = getPastDateFilter(from, to);

        const filters = {
          status,
          caseIds,
          locationIds,
          providerIds,
          includeRescheduled,
        };

        const futureFilter = { ...filters, ...futureDateFilter };

        const pastFilter = { ...filters, ...pastDateFilter };

        const futureQuery = this.__requiresBuild(
          this.__futureAppointments.queryMetadata,
        )
          ? this.__buildDefaultQueryParam({
              ...FUTURE_QUERY_META(),
              ...(futureFilter && { filters: futureFilter }),
              offset: 0,
            })
          : {
              ...this.__futureAppointments.queryMetadata,
              ...futureFilter,
              offset: 0,
            };

        const pastQuery = this.__requiresBuild(
          this.__pastAppointments.queryMetadata,
        )
          ? this.__buildDefaultQueryParam({
              ...PAST_QUERY_META(),
              ...(pastFilter && { filters: pastFilter }),
              offset: 0,
            })
          : {
              ...this.__pastAppointments.queryMetadata,
              ...pastFilter,
              offset: 0,
            };

        if (from && to && from.isAfter(to)) {
          this.__futureAppointments = {
            appointments: [],
            queryMetadata: futureQuery,
            count: 0,
          };

          this.__pastAppointments = {
            appointments: [],
            queryMetadata: pastQuery,
            count: 0,
          };

          return;
        }

        if (isDateInFuture(from, to)) {
          this.__tableUpdated(futureQuery, APPOINTMENT_TABLE_TYPES.FUTURE);
          this.__pastAppointments = {
            appointments: [],
            queryMetadata: pastQuery,
            count: 0,
          };
        } else if (isDateInPast(from, to)) {
          this.__tableUpdated(pastQuery, APPOINTMENT_TABLE_TYPES.PAST);
          this.__futureAppointments = {
            appointments: [],
            queryMetadata: futureQuery,
            count: 0,
          };
        } else {
          this.__tableUpdated(futureQuery, APPOINTMENT_TABLE_TYPES.FUTURE);
          this.__tableUpdated(pastQuery, APPOINTMENT_TABLE_TYPES.PAST);
        }
        this.__resetPage = !this.__resetPage;
      },
    };
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      const inactiveLocations = locations.filter(loc => !loc.active);

      this.__locations = [
        ...locations.filter(loc => loc.active),
        ...inactiveLocations,
      ];
    });
  }

  __formatLocations(locations) {
    return locations.map(location => ({ ...location, label: location.name }));
  }

  async __fetchCases() {
    this.__cases = await fetchMany(this.patientId, true);
  }

  async __fetchRooms() {
    this.__rooms = await fetchManyRooms();
  }

  __buildDefaultQueryParam(query) {
    const {
      sort: { field, dir },
      pageSize,
      filters,
    } = query;

    return {
      patientId: this.patientId,
      sortField: field,
      sortDir: dir,
      secondarySortField: field,
      secondarySortDir: dir,
      limit: pageSize,
      expand: 'appointmentType,splits',
      status: KEPT_STATUSES,
      ...filters,
    };
  }

  __requiresBuild(obj) {
    return !Object.keys(obj).length || obj.patientId !== this.patientId;
  }

  __futureAndPastTablesUpdated() {
    const futureQuery = this.__requiresBuild(
      this.__futureAppointments.queryMetadata,
    )
      ? this.__buildDefaultQueryParam(FUTURE_QUERY_META())
      : this.__futureAppointments.queryMetadata;

    const pastQuery = this.__requiresBuild(
      this.__pastAppointments.queryMetadata,
    )
      ? this.__buildDefaultQueryParam(PAST_QUERY_META())
      : this.__pastAppointments.queryMetadata;

    const from = parseDate(pastQuery.start || futureQuery.start);
    const to = parseDate(futureQuery.end || pastQuery.startBefore);

    if (from && to && from.isAfter(to)) {
      this.__futureAppointments = {
        appointments: [],
        queryMetadata: futureQuery,
        count: 0,
      };

      this.__pastAppointments = {
        appointments: [],
        queryMetadata: pastQuery,
        count: 0,
      };

      return;
    }

    if (isDateInFuture(from, to)) {
      this.__tableUpdated(futureQuery, APPOINTMENT_TABLE_TYPES.FUTURE);
      this.__pastAppointments = {
        appointments: [],
        queryMetadata: pastQuery,
        count: 0,
      };
    } else if (isDateInPast(from, to)) {
      this.__tableUpdated(pastQuery, APPOINTMENT_TABLE_TYPES.PAST);
      this.__futureAppointments = {
        appointments: [],
        queryMetadata: futureQuery,
        count: 0,
      };
    } else {
      this.__tableUpdated(futureQuery, APPOINTMENT_TABLE_TYPES.FUTURE);
      this.__tableUpdated(pastQuery, APPOINTMENT_TABLE_TYPES.PAST);
    }
  }

  async __updateRTEStatuses(apt) {
    const insurancesStatuses = await rteInsuranceApi.fetchMany(
      apt.appointments,
    );

    if (insurancesStatuses.length) {
      return {
        ...apt,
        appointments: apt.appointments.map(appointment => ({
          ...appointment,
          realTimeEligibilityStatus: insurancesStatuses.find(insurance =>
            Object.values(appointment).includes(insurance.appointmentId),
          ).status,
        })),
      };
    }

    return apt;
  }

  async __tableUpdated(queryParam, type) {
    if (queryParam) {
      this.__loadingAppointments = true;

      const { appointments, queryMetadata, count } = await queryAppointments({
        ...queryParam,
        standardSort: true,
      });

      if (type === APPOINTMENT_TABLE_TYPES.FUTURE) {
        const futureAppointments = {
          appointments,
          queryMetadata,
          count,
        };

        if (this.__hasCTVerify) {
          this.__futureAppointments =
            await this.__updateRTEStatuses(futureAppointments);
        } else {
          this.__futureAppointments = futureAppointments;
        }
      } else {
        const pastAppointments = {
          appointments,
          queryMetadata,
          count,
        };

        if (this.__hasCTVerify) {
          this.__pastAppointments =
            await this.__updateRTEStatuses(pastAppointments);
        } else {
          this.__pastAppointments = pastAppointments;
        }
      }

      this.__loadingAppointments = false;
    } else {
      this.__futureAndPastTablesUpdated();
    }
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__locationsService.connect();
    this.__hasCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
    await Promise.all([this.__fetchCases(), this.__fetchRooms()]);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__locationsService.disconnect();
  }

  __getMenuItems() {
    return [this.__menuItems.SELECT_LOCATION];
  }

  async __openAppointmentOverlay() {
    const accepted = await openOverlay(OVERLAY_KEYS.APPOINTMENT_FORM, {
      ...NebFormAppointment.createModel(),
      patientId: this.patientId,
    });

    if (accepted) {
      await this.__fetchCases();
      this.__futureAndPastTablesUpdated();
    }
  }

  async __printUpcomingAppointmentsForLocation() {
    const res = await openPopup(POPUP_RENDER_KEYS.SELECT_LOCATION, {
      message: SELECT_LOCATION_MESSAGE_FOR_APPTS,
    });

    if (!res.canceled) {
      await printUpcomingAppointments({
        patient: { id: this.patientId },
        location: res.location,
      });
    }
  }

  update(changedProps) {
    if (
      (changedProps.has('active') || changedProps.has('patientId')) &&
      this.active &&
      this.patientId
    ) {
      this.__futureAndPastTablesUpdated();
    }

    super.update(changedProps);
  }

  static get styles() {
    return [
      css`
        :host {
          display: flex;
          flex-direction: column;
        }

        * {
          margin-bottom: ${CSS_SPACING};
        }

        .table-flex {
          flex-shrink: 0;
        }

        .min-wid {
          min-width: 1350px;
        }

        .hide-deleted-checkbox {
          width: fit-content;
        }

        .add-appointment-button {
          margin-right: 10px;
          margin-bottom: 0px;
        }

        .print-upcoming-appts-button {
          margin-left: 10px;
        }

        .button-actions {
          width: 10px;
        }

        :host([layout='small']) .print-upcoming-appts-button {
          margin-left: 0px;
        }
      `,
    ];
  }

  __renderAppointmentFilters() {
    return this.layout !== 'small'
      ? html`
          <neb-filters-patient-appointments
            id="${ELEMENTS.filters.id}"
            class="filters min-wid"
            .locations="${this.__locations}"
            .cases="${this.__cases}"
            .patientId="${this.patientId}"
            .onApply="${this.__handlers.filter}"
            .expanded="${false}"
          ></neb-filters-patient-appointments>
        `
      : html`
          <neb-filters-patient-appointments-mobile
            id="${ELEMENTS.filtersMobile.id}"
            class="filters"
            .onApply="${this.__handlers.filter}"
          ></neb-filters-patient-appointments-mobile>
        `;
  }

  __renderDropdownMenu() {
    return html`
      <neb-button-actions
        id="${ELEMENTS.buttonActions.id}"
        class="button-actions"
        align="right"
        maxVisibleItems="10"
        vertical
        .value="${this.__getMenuItems()}"
        iconHeight="20px"
        iconWidth="20px"
      ></neb-button-actions>
    `;
  }

  __renderAppointmentActionButtons() {
    return html`
      <div class="appointment-action-buttons">
        <neb-button-action
          id="${ELEMENTS.addAppointmentButton.id}"
          class="${ELEMENTS.addAppointmentButton.id}"
          label="Add New Appointment"
          .onClick="${this.__handlers.openNewAppointment}"
        ></neb-button-action>
        <neb-button-action
          id="${ELEMENTS.printUpcomingApptsButton.id}"
          class="${ELEMENTS.printUpcomingApptsButton.id}"
          label="Print Upcoming Appointments"
          leadingIcon="print"
          .onClick="${this.__handlers.printUpcomingAppointments}"
        ></neb-button-action>
        ${this.__renderDropdownMenu()}
      </div>
    `;
  }

  render() {
    return html`
      ${this.__renderAppointmentActionButtons()}
      ${this.__renderPastAndFutureAppointmentsTable()}
    `;
  }

  __renderFutureAppointments() {
    const futureAppointmentsClasses = {
      'table-flex': true,
      'min-wid': this.layout !== 'small',
    };

    return html`
      <neb-appointments
        id="${ELEMENTS.futureAppointments.id}"
        class="${classMap(futureAppointmentsClasses)}"
        title="Future Appointments"
        emptyMessage="No Future Appointments"
        .active="${this.active}"
        .isPatientAppointments="${true}"
        .patientId="${this.patientId}"
        .query="${this.__futureQuery}"
        .model="${this.__futureAppointments}"
        .resetPage="${this.__resetPage}"
        .cases="${this.__cases}"
        .rooms="${this.__rooms}"
        .locations="${this.__locations}"
        .onTableUpdated="${this.__handlers.futureTableUpdated}"
      >
      </neb-appointments>
    `;
  }

  __renderPastAppointments() {
    const pastAppointmentsClasses = {
      'table-flex': true,
      'min-wid': this.layout !== 'small',
    };

    return html`
      <neb-appointments
        id="${ELEMENTS.pastAppointments.id}"
        class="${classMap(pastAppointmentsClasses)}"
        title="Past Appointments"
        emptyMessage="No Past Appointments"
        .active="${this.active}"
        .isPatientAppointments="${true}"
        .patientId="${this.patientId}"
        .query="${this.__pastQuery}"
        .model="${this.__pastAppointments}"
        .resetPage="${this.__resetPage}"
        .cases="${this.__cases}"
        .rooms="${this.__rooms}"
        .locations="${this.__locations}"
        .onTableUpdated="${this.__handlers.pastTableUpdated}"
      >
      </neb-appointments>
    `;
  }

  __renderPastAndFutureAppointmentsTable() {
    return html`
      ${this.__renderAppointmentFilters()}
      ${this.__renderFutureAppointments()}${this.__renderPastAppointments()}
    `;
  }
}

customElements.define('neb-patient-appointments', NebPatientAppointments);
