import { css, html } from 'lit';

import '../../../../../packages/neb-lit-components/src/components/tables/neb-table-encounter-charges';
import '../../../../../packages/neb-www-practice-charting/src/components/diagnosis/neb-encounter-diagnosis-table';
import '../../../../../packages/neb-lit-components/src/components/neb-dropdown-menu';
import { BUTTON_ROLE } from '../../../../../packages/neb-lit-components/src/components/neb-button';
import '../../../../../packages/neb-treatment-plan/components/neb-treatment-plan-table';
import {
  CSS_COLOR_GREY_2,
  CSS_COLOR_GREY_4,
  CSS_COLOR_WHITE,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../styles';
import {
  NebFormCheckIn,
  ELEMENTS as CHECK_IN_ELEMENTS,
} from '../check-in/neb-form-check-in';

export const ELEMENTS = {
  ...CHECK_IN_ELEMENTS,
  visitSummaryButton: { id: 'visit-summary-button' },
  requestNextAppointmentButton: { id: 'request-next-appointment-button' },
  printUpcomingAppointmentsButton: { id: 'print-upcoming-appointments-button' },
  discountButton: { id: 'discount-button' },
  addDiagnosisButton: { id: 'add-diagnosis-button' },
  addChargeButton: { id: 'add-charge-button' },
  autoPointButton: { id: 'auto-point-button' },

  printAppointmentsEllipsis: { id: 'print-appointments-ellipsis' },
  printDropdown: { id: 'print-dropdown' },

  encounterBalanceTable: { id: 'encounter-balance-table' },
  treatmentPlanTable: { id: 'treatment-plan-table' },
  diagnosisTable: { id: 'diagnosis-table' },
  encounterChargesTable: { id: 'encounter-charges-table' },

  nextAppointmentText: { id: 'next-appointment-text' },
};

export class NebFormCheckOut extends NebFormCheckIn {
  static createModel() {
    return {
      ...NebFormCheckIn.createModel(),
      encounterBalance: {
        totalCharges: '$0.00',
        adjustments: '$0.00',
        patientPayment: '$0.00',
        discounts: '$0.00',
        patientEncounterBalance: '$0.00',
      },
      chargeDetails: [],
      dxDetails: [],
    };
  }

  static createMetaData() {
    return {
      ...NebFormCheckIn.createMetaData(),
      treatmentPlan: [],
    };
  }

  initState() {
    super.initState();

    this.metaData = NebFormCheckOut.createMetaData();
  }

  static get styles() {
    return [
      super.styles,
      css`
        .container-form {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 40px;
          padding: 0 5px ${CSS_SPACING} ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
        }

        .container-bottom {
          display: grid;
          grid-template-rows: 1fr 1fr;
          grid-gap: ${CSS_SPACING};
          background-color: ${CSS_COLOR_WHITE};
        }

        .normal {
          font-weight: normal;
        }

        .two-line-section {
          display: grid;
          padding: ${CSS_SPACING} 0;
          gap: ${CSS_SPACING};
        }

        .padding-bottom-small {
          padding-bottom: 5px;
        }

        .encounter-balance-section {
          background-color: ${CSS_COLOR_GREY_4};
          border: 1px solid ${CSS_COLOR_GREY_2};
          border-radius: 10px;
          padding: ${CSS_SPACING};
          margin-bottom: ${CSS_SPACING};
        }

        .encounter-balance-table {
          width: 100%;
        }

        table tr:last-child td {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          padding-top: 10px;
        }

        tr td:nth-child(2) {
          text-align: right;
        }

        .print-appointments-ellipsis {
          margin-left: -25px;
        }

        .table-add {
          padding: 10px 0 ${CSS_SPACING} ${CSS_SPACING};
        }

        .charges-table {
          padding-top: 0px;
        }

        .padding-left {
          padding-left: ${CSS_SPACING};
        }

        .payment-buttons-container {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        .payment-buttons-inner {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: ${CSS_SPACING};
          align-items: center;
        }
      `,
    ];
  }

  __renderEncounterBalanceTable() {
    const {
      encounterBalance: {
        totalCharges,
        adjustments,
        patientPayment,
        discounts,
        patientEncounterBalance,
        payerOwed,
        patientOwed,
        coveredCharges,
      },
    } = this.state;
    return html`
      <div class="encounter-balance-section padding-bottom">
        <p class="appointment-details bold padding-bottom-small">
          Patient Encounter Balance
        </p>
        <table
          id="${ELEMENTS.encounterBalanceTable.id}"
          class="encounter-balance-table"
        >
          <tr>
            <td>Total Charges</td>
            <td>${totalCharges}</td>
          </tr>
          <tr>
            <td>Adjustments</td>
            <td>${adjustments}</td>
          </tr>
          ${payerOwed
            ? html` <tr>
                <td>Payer Owed</td>
                <td>${payerOwed}</td>
              </tr>`
            : ''}
          ${patientOwed
            ? html` <tr>
                <td>Patient Owed</td>
                <td>${patientOwed}</td>
              </tr>`
            : ''}
          ${coveredCharges
            ? html` <tr>
                <td>Covered by Care Package</td>
                <td>${coveredCharges}</td>
              </tr>`
            : ''}
          <tr>
            <td>Patient Payments</td>
            <td>${patientPayment}</td>
          </tr>
          <tr>
            <td>Discounts</td>
            <td>${discounts}</td>
          </tr>
          <tr>
            <td>Patient Encounter Balance</td>
            <td>${patientEncounterBalance}</td>
          </tr>
        </table>
        <div></div>
      </div>
    `;
  }

  __renderColumnLeft() {
    const {
      appointmentDetails: {
        appointmentTypeId,
        patientName,
        date,
        time,
        locationName,
        providerId,
        calendarResourceName,
        nextAppointment,
      },
    } = this.state;
    return html`
      <div class="column-left">
        <div class="appointment-details">
          <p class="section-header padding-bottom">Appointment Details</p>
          <p class="padding-bottom">${patientName}</p>
          <p>${date}</p>
          <p>${time}</p>
          <div class="two-line-section">
            <div>
              <p>${locationName}</p>
              <p class="normal">${calendarResourceName}</p>
            </div>
            <div>
              <p>${appointmentTypeId.label}</p>
              <p class="normal">${providerId.label}</p>
            </div>
          </div>
        </div>
        ${this.__renderEncounterBalanceTable()}
        <neb-button-action
          id="${ELEMENTS.visitSummaryButton.id}"
          class="button padding-bottom"
          label="Open Visit Summary"
          leadingIcon="noteAdd"
        ></neb-button-action>
        <p class="section-header padding-bottom-slim">
          Request Next Appointment
        </p>
        <p id="${ELEMENTS.nextAppointmentText.id}" class="padding-bottom-slim">
          ${nextAppointment}
        </p>
        <div>
          <neb-button-action
            id="${ELEMENTS.requestNextAppointmentButton.id}"
            class="button"
            label="Add New Appointment"
            leadingIcon="plus"
          ></neb-button-action>
          <neb-button-action
            id="${ELEMENTS.printUpcomingAppointmentsButton.id}"
            class="button"
            label="Print Upcoming Appointments"
            leadingIcon="print"
          ></neb-button-action>
          <neb-button-actions
            id="${ELEMENTS.printAppointmentsEllipsis.id}"
            class="print-appointments-ellipsis"
            vertical
            maxVisibleItems="10"
            iconHeight="20px"
            iconWidth="20px"
          ></neb-button-actions>
        </div>
        <p class="section-header padding-bottom-slim padding-top-slim">
          Treatment Plan (${this.metaData.treatmentPlan.length})
        </p>
        <neb-treatment-plan-table
          id="${ELEMENTS.treatmentPlanTable.id}"
          class="treatment-plan"
          .items="${this.metaData.treatmentPlan}"
          summary
          sticky-total
        ></neb-treatment-plan-table>
      </div>
    `;
  }

  __renderMakePaymentSection() {
    return html`
      <div class="payment-buttons-container">
        <div class="payment-buttons-inner">
          <neb-button
            id="${ELEMENTS.makePaymentButton.id}"
            class="payment-button"
            label="MAKE PAYMENT"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.handlers.makePayment}"
          ></neb-button>
          <neb-button-action
            id="${ELEMENTS.discountButton.id}"
            class="bold"
            label="Discount"
            leadingIcon="discount"
          ></neb-button-action>
          <neb-dropdown-menu
            id="${ELEMENTS.printDropdown.id}"
            class="normal"
            label="Print"
            leadingIcon="print"
            align="${this.layout !== 'large' ? 'left' : 'right'}"
          ></neb-dropdown-menu>
        </div>
        <div></div>
      </div>
    `;
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.containerForm.id}" class="container-form">
        ${this.__renderColumnLeft()} ${this.__renderColumnRight()}
      </div>
      <div class="container-bottom">
        <div>
          <div class="padding-bottom border-bottom">
            <p class="section-header padding-left">Diagnoses</p>
          </div>
          <neb-button-action
            id="${ELEMENTS.addDiagnosisButton.id}"
            class="table-add button"
            label="Add Diagnosis"
            leadingIcon="plus"
          ></neb-button-action>
          <neb-encounter-diagnosis-table
            id="${ELEMENTS.diagnosisTable.id}"
            .savedCodes="${this.state.dxDetails}"
          ></neb-encounter-diagnosis-table>
        </div>
        <div>
          <div class="padding-bottom border-bottom">
            <p class="section-header padding-left">Encounter Charges</p>
          </div>
          <div>
            <neb-button-action
              id="${ELEMENTS.addChargeButton.id}"
              class="table-add button"
              label="Add New Charge"
              leadingIcon="plus"
            ></neb-button-action>
            <neb-button-action
              id="${ELEMENTS.autoPointButton.id}"
              class="table-add button"
              label="Auto Point Diagnoses"
              leadingIcon="autoPoint"
            ></neb-button-action>
          </div>
          <neb-table-encounter-charges
            id="${ELEMENTS.encounterChargesTable.id}"
            class="charges-table"
            .model="${this.state.chargeDetails}"
            .errors="${this.errors.chargeDetails}"
            .layout="${this.layout}"
            .diagnoses="${this.state.dxDetails}"
            .reorder="${this.layout !== 'small'}"
            .showCarePackage="${true}"
            .showCost="${true}"
            .checkedIn="${true}"
          ></neb-table-encounter-charges>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-form-check-out', NebFormCheckOut);
