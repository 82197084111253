import { css, LitElement } from 'lit';

import { connect, store } from '../../../packages/neb-redux/neb-redux-store';
import { initialize, identifyUser } from '../../library/userpilot';
import {
  getPracticeInfo,
  getPracticeInformationFromState,
  getSessionInfo,
  isPracticeInformationReady,
} from '../../utils/session-util';

class NebUserpilot extends connect(store)(LitElement) {
  static get properties() {
    return {
      initialized: Boolean,
      practiceInformation: Object,
      session: Object,
    };
  }

  static get styles() {
    return css`
      :host {
        display: none;
      }
    `;
  }

  constructor() {
    super();

    this.__initState();
  }

  __initState() {
    this.initialized = false;
    this.session = null;
  }

  _stateChanged(state) {
    const { session } = state;
    this.practiceInformation = getPracticeInformationFromState(state);
    this.session = session;
  }

  connectedCallback() {
    super.connectedCallback();

    initialize();
  }

  shouldUpdate() {
    if (this.initialized) {
      return false;
    }

    const { isAuthenticated } = this.session;

    if (!isAuthenticated) {
      return false;
    }

    if (!isPracticeInformationReady(this.practiceInformation)) {
      return false;
    }

    return true;
  }

  update() {
    const { email, firstName, lastName, role } = getSessionInfo(this.session);

    const { practiceName, salesforceId, tenantId, tier, addOns } =
      getPracticeInfo(this.practiceInformation);

    identifyUser({
      email,
      firstName,
      lastName,
      practiceName,
      role,
      salesforceId,
      tenantId,
      ...(tier && addOns && { tier, addOns }),
    });

    this.initialized = true;
  }
}

customElements.define('neb-userpilot', NebUserpilot);
