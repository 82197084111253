import { formatDollarAmount } from './formatters';
import { formatPaymentNumber } from './neb-ledger-util';

export const mapLineItemsForDiscounts = (
  lineItems,
  discountPaymentId = -1,
  hasTaxDiscountFF = false,
) =>
  lineItems.map(li => {
    const paymentDetails = li.lineItemDebits
      .filter(lid => !lid.patientInsuranceId)
      .reduce(
        (memo, lid) => {
          const { amount } = lid.debit;

          const paid = lid.debit.allocations.reduce(
            (aSum, alloc) => aSum + alloc.amount,
            0,
          );

          const discounts = lid.debit.allocations.reduce(
            (oDiscounts, alloc) => {
              if (alloc.credit.payment?.codeDiscountId) {
                oDiscounts += alloc.amount;
              }
              return oDiscounts;
            },
            0,
          );

          memo.patientOwed += amount;
          memo.patientPaid += paid;
          memo.otherDiscounts += discounts;

          const debitPayments = lid.debit.allocations.reduce((aMemo, alloc) => {
            const { credit } = alloc;

            const { payment } = credit;

            if (payment) {
              const { id: paymentId } = payment;

              const paymentNumber = formatPaymentNumber(payment);

              aMemo.push({ paymentId, paymentNumber });

              if (payment.id === discountPaymentId) {
                memo.discountAllocated += alloc.amount;
                memo.patientPaid -= alloc.amount;
                memo.otherDiscounts -= alloc.amount;
              }
            }

            return aMemo;
          }, []);

          memo.payments.push(...debitPayments);

          return memo;
        },

        {
          patientOwed: 0,
          patientPaid: 0,
          otherDiscounts: 0,
          discountAllocated: 0,
          payments: [],
        },
      );

    const uniquePayments = paymentDetails.payments.reduce((memo, payment) => {
      const { paymentId } = payment;

      if (!memo[paymentId]) {
        memo[paymentId] = payment;
      }

      return memo;
    }, {});

    let { patientOwed } = paymentDetails;

    const { patientPaid, discountAllocated, otherDiscounts } = paymentDetails;

    const paymentIds = Object.values(uniquePayments);

    const { billedAmount, taxAmount, taxRate, taxName } = li;

    const adjustments = li.adjustments.reduce(
      (aSum, adjust) => aSum + adjust.amount,
      0,
    );

    let discountDisabled = false;

    if (hasTaxDiscountFF) {
      patientOwed -= taxAmount;

      const hasPatientInsuranceDebit = li.lineItemDebits.some(
        lid => lid.patientInsuranceId,
      );

      discountDisabled =
        !!taxRate && (hasPatientInsuranceDebit || patientOwed <= 0);
    }
    const patientBalance = patientOwed - patientPaid - discountAllocated;
    const newPatientBalance = hasTaxDiscountFF
      ? patientBalance + taxAmount
      : patientBalance;

    return {
      selected: false,
      id: li.id,
      invoiceId: li.invoiceId,
      dateOfService: li.dateOfService,
      procedure: li.code,
      description: li.description,
      patientOwed,
      patientPaid,
      paymentIds,
      patientBalance,
      discountAllocated,
      newPatientBalance,
      ...(hasTaxDiscountFF
        ? {
            adjustments,
            billedAmount,
            otherDiscounts,
            taxAmount,
            taxRate,
            taxName,
            discountDisabled,
            hasTaxDiscountFF,
          }
        : {}),
    };
  });

export const formatTaxBreakdown = ({
  billedAmount,
  otherDiscounts,
  discountAllocated,
  adjustments,
  taxRate,
  taxName,
}) => {
  const patientOwed = formatDollarAmount(billedAmount - adjustments);
  const totalDiscounts = formatDollarAmount(otherDiscounts + discountAllocated);

  const netPatientBalance = formatDollarAmount(
    billedAmount - adjustments - otherDiscounts - discountAllocated,
  );

  return `${patientOwed} (Patient Owed) - ${totalDiscounts} (Discounts) = ${netPatientBalance} x ${
    taxRate || 0
  }% (${taxName || 'None'})`;
};

export const formatPaidDiscountBreakdown = (patientPaid, otherDiscounts) => {
  const patientPaidOnly = formatDollarAmount(patientPaid - otherDiscounts);
  const discounts = formatDollarAmount(otherDiscounts);

  return `${patientPaidOnly} (Patient Paid)/${discounts} (Discounts)`;
};
