import { parseDate } from '../../packages/neb-utils/date-util';
import {
  centsToCurrency,
  objToName,
} from '../../packages/neb-utils/formatters';

import { formatBilled } from './line-items';
import { formatBillType } from './payer-information';

const formatServiceDate = ({ min, max }) => {
  const formattedMax = parseDate(max).format('MM/DD/YYYY');
  const formattedMin = parseDate(min).format('MM/DD/YYYY');

  return formattedMax === formattedMin
    ? formattedMax
    : `${formattedMin} - ${formattedMax}`;
};

export function formatArHubInvoices(invoices) {
  return invoices.map(invoice => {
    const billedStatus = {
      billedPaidInFull: invoice.billedPaidInFull,
      billedPaymentReceived: invoice.billedPaymentReceived,
      billedPrimary: invoice.billedPrimary,
      billedSecondary: invoice.billedSecondary,
      billedWaiting: invoice.billedWaiting,
    };

    const dateRanges = {
      max: invoice.dateOfServiceMax,
      min: invoice.dateOfServiceMin,
    };

    return {
      adjustmentAmount: centsToCurrency(invoice.adjustmentAmount),
      allowedAmount: centsToCurrency(invoice.allowedAmount),
      balance: centsToCurrency(invoice.balance),
      billType: formatBillType(invoice.billType),
      billedAmount: centsToCurrency(invoice.billedAmount),
      billedStatus: formatBilled(billedStatus),
      serviceDate: formatServiceDate(dateRanges),
      patient: objToName(
        { first: invoice.firstName, last: invoice.lastName },
        { reverse: true },
      ),
      invoiceNumber: invoice.invoiceNumber,
      lastBilled: invoice.lastBilled
        ? parseDate(invoice.lastBilled).format('MM/DD/YYYY')
        : null,
      patientBalance: centsToCurrency(invoice.patientBalance),
      payerBalance: centsToCurrency(invoice.payerBalance),
      primaryPayer: invoice.primaryPayer,
      secondaryPayer: invoice.secondaryPayer,
    };
  });
}
