import { css, html, LitElement } from 'lit';

import { number } from '../../../../packages/neb-utils/masks';
import { baseStyles, layoutStyles, CSS_SPACING } from '../../../styles';
import '../../../../packages/neb-lit-components/src/components/controls/neb-switch';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-textfield';
import '../../../../packages/neb-lit-components/src/components/neb-header';
import '../../../../packages/neb-lit-components/src/components/inputs/neb-select';
import { ONE_DAY, PERIODS } from '../../../utils/clearinghouse-settings';

export const ELEMENTS = {
  selectRealTimeEligibilityPeriod: { id: 'select-period' },
  textfieldRealTimeEligibilityFrequency: { id: 'textfield-frequency' },
  switchRealTimeEligibilityEnabled: { id: 'switch-rte-enabled' },
  switchRteAutoUpdate: { id: 'switch-rte-auto-update' },
  textfieldChcSubmitterId: { id: 'textfield-chc-submitter-id' },
  textfieldPassword: { id: 'textfield-password' },
  textfieldUsername: { id: 'textfield-username' },
};

class ChangeHealthcareSettings extends LitElement {
  static get properties() {
    return {
      readOnly: { reflect: true, type: Boolean },
      errors: Object,
      model: Object,
      hasAddOnCtVerify: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      layoutStyles,
      css`
        .layout {
          padding-top: ${CSS_SPACING};
        }

        .textfield {
          width: 240px;
        }

        .select {
          width: 280px;
        }

        .switch-container {
          display: flex;
          flex-direction: row;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.readOnly = false;
    this.errors = {
      chcPassword: '',
      chcSubmitterId: '',
      chcUserName: '',
      realTimeEligibilityEnabled: '',
      realTimeEligibilityPeriod: '',
      realTimeEligibilityFrequency: '',
    };

    this.model = {
      chcPassword: '',
      chcSubmitterId: '',
      chcUserName: '',
      realTimeEligibilityEnabled: false,
      realTimeEligibilityAutoUpdate: false,
      realTimeEligibilityPeriod: ONE_DAY,
      realTimeEligibilityFrequency: '7',
    };

    this.onChange = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      change: e => this.onChange(e),
    };
  }

  __renderSwitchRTE() {
    return html`
      <neb-switch
        id="${ELEMENTS.switchRteAutoUpdate.id}"
        class="pad"
        name="realTimeEligibilityAutoUpdate"
        label="Enable Auto-Update Insurance Policy Benefits Coverage"
        .on="${this.model.realTimeEligibilityAutoUpdate}"
        .onChange="${this.__handlers.change}"
        ?disabled="${this.readOnly}"
      ></neb-switch>
    `;
  }

  __renderRTE() {
    return this.hasAddOnCtVerify
      ? html`
          <neb-header
            class="spacer-top"
            label="Real-Time Eligibility Settings"
            description="Enable Real-Time Eligibility to occur 1, 2 or 3 days prior to the scheduled appointment."
          ></neb-header>

          <div class="switch-container">
            <neb-switch
              id="${ELEMENTS.switchRealTimeEligibilityEnabled.id}"
              class="pad"
              name="realTimeEligibilityEnabled"
              label="Enable Auto-Check"
              .on="${this.model.realTimeEligibilityEnabled}"
              .onChange="${this.__handlers.change}"
              ?disabled="${this.readOnly}"
            ></neb-switch>

            ${this.__renderSwitchRTE()}
          </div>

          <neb-select
            id="${ELEMENTS.selectRealTimeEligibilityPeriod.id}"
            class="select pad"
            style="margin-top: 8px;"
            name="realTimeEligibilityPeriod"
            label="Number of days prior to an appointment"
            .error="${this.errors.realTimeEligibilityPeriod}"
            .helper="${this.model.realTimeEligibilityEnabled
              ? 'Required'
              : ' '}"
            .items="${PERIODS}"
            .value="${this.model.realTimeEligibilityPeriod}"
            .onChange="${this.__handlers.change}"
            ?disabled="${!this.model.realTimeEligibilityEnabled ||
            this.readOnly}"
          ></neb-select>

          <neb-textfield
            id="${ELEMENTS.textfieldRealTimeEligibilityFrequency.id}"
            class="select pad"
            name="realTimeEligibilityFrequency"
            .helper="${this.model.realTimeEligibilityEnabled
              ? 'Required'
              : ' '}"
            label="Number of days before checking again"
            .mask="${number}"
            .error="${this.errors.realTimeEligibilityFrequency}"
            .value="${this.model.realTimeEligibilityFrequency}"
            .onChange="${this.__handlers.change}"
            ?disabled="${!this.model.realTimeEligibilityEnabled ||
            this.readOnly}"
          ></neb-textfield>
        `
      : '';
  }

  render() {
    return html`
      <div class="layout">
        <div class="grid grid-2">
          <neb-textfield
            id="${ELEMENTS.textfieldChcSubmitterId.id}"
            class="textfield"
            name="chcSubmitterId"
            helper="Required"
            label="Practice Submitter ID"
            .error="${this.errors.chcSubmitterId}"
            .value="${this.model.chcSubmitterId}"
            .onChange="${this.__handlers.change}"
            ?disabled="${this.readOnly}"
          ></neb-textfield>
        </div>

        <div class="grid grid-2" style="width: max-content;">
          <neb-textfield
            id="${ELEMENTS.textfieldUsername.id}"
            class="textfield"
            name="chcUserName"
            helper="Required"
            label="Username"
            .error="${this.errors.chcUserName}"
            .value="${this.model.chcUserName}"
            .onChange="${this.__handlers.change}"
            ?disabled="${this.readOnly}"
          ></neb-textfield>

          <neb-textfield
            id="${ELEMENTS.textfieldPassword.id}"
            class="textfield"
            name="chcPassword"
            helper="Required"
            label="Password"
            type="password"
            .error="${this.errors.chcPassword}"
            .value="${this.model.chcPassword}"
            .onChange="${this.__handlers.change}"
            ?disabled="${this.readOnly}"
          ></neb-textfield>
        </div>

        ${this.__renderRTE()}
      </div>
    `;
  }
}

window.customElements.define(
  'neb-change-healthcare-settings',
  ChangeHealthcareSettings,
);
