/* eslint-disable import/no-named-default */
import { default as Userpilot } from 'userpilot';

const isEnabled = ({
  NEB_ENV: { USERPILOT_API_KEY, USERPILOT_ENABLED } = {},
}) => USERPILOT_ENABLED === 'true' && USERPILOT_API_KEY !== '';

function initialize() {
  if (!isEnabled(window)) {
    return;
  }

  const {
    NEB_ENV: { USERPILOT_API_KEY },
  } = window;

  Userpilot.Userpilot.initialize(USERPILOT_API_KEY);
}

function identifyUser({
  email,
  firstName,
  lastName,
  practiceName,
  role,
  salesforceId,
  tenantId,
  tier,
  addOns,
}) {
  if (!isEnabled(window)) {
    return;
  }

  Userpilot.Userpilot.identify(email, {
    name: `${firstName} ${lastName}`,
    email,
    company: {
      id: tenantId,
      name: practiceName,
      salesforceId,
      tier,
      addOns,
    },
    role,
  });
}

function logout() {
  if (!isEnabled(window)) {
    return;
  }

  Userpilot.Userpilot.clean();
}

export { initialize, identifyUser, logout };
