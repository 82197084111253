import { css, html, LitElement } from 'lit';

import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import { CSS_SMALL_SPACING, CSS_SPACING } from '../../../styles';

export const ELEMENTS = {
  table: { id: 'table' },
  showHideColumnsButton: { id: 'show-hide-columns-button' },
};

const CONFIG = [
  {
    key: 'patient',
    label: 'Patient',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'serviceDate',
    label: 'Service Date',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'billType',
    label: 'Bill Type',
    flex: css`1 0 0`,
  },
  {
    key: 'primaryPayer',
    label: 'Primary',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'secondaryPayer',
    label: 'Secondary',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'invoiceNumber',
    label: 'Invoice',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'billedStatus',
    label: 'Billed Status',
    flex: css`1 0 0`,
  },
  {
    key: 'lastBilled',
    label: 'Last Billed',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'billedAmount',
    label: 'Billed Amount',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'allowedAmount',
    label: 'Allowed Amount',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'adjustmentAmount',
    label: 'Adjustment Amount',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'payerBalance',
    label: 'Payer Balance',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'patientBalance',
    label: 'Patient Balance',
    flex: css`1 0 0`,
    sortable: true,
  },
  {
    key: 'balance',
    label: 'Balance',
    flex: css`1 0 0`,
    sortable: true,
  },
];

class NebPageArHubNewCustomView extends LitElement {
  static get properties() {
    return {
      model: Array,
      sortParams: Array,
      loading: Boolean,
    };
  }

  static get styles() {
    return css`
      .button-container {
        display: flex;
        justify-content: flex-end;
        margin-bottom: ${CSS_SMALL_SPACING};
        padding: 0 ${CSS_SPACING};
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.model = [];
    this.sortParams = [];
    this.loading = true;

    this.onChangeSort = () => {};
  }

  __initHandlers() {
    this.__handlers = {
      onSort: (_name, result) => {
        this.sortParams = [result[0]];
        const { key, dir } = result[0];
        this.onChangeSort(key, dir);
      },
    };
  }

  __renderButtons() {
    return html`
      <div class="button-container">
        <neb-button-action
          id="${ELEMENTS.showHideColumnsButton.id}"
          label="Show/Hide Columns"
          leadingIcon="visibilityOff"
        ></neb-button-action>
      </div>
    `;
  }

  __renderTable() {
    const message = this.loading ? 'Loading...' : 'No items';

    return html`
      <neb-table
        id="${ELEMENTS.table.id}"
        .config="${CONFIG}"
        .emptyMessage="${message}"
        .model="${this.model}"
        .onSort="${this.__handlers.onSort}"
        .sortParams="${this.sortParams}"
      ></neb-table>
    `;
  }

  render() {
    return html`
      <div class="content">
        ${this.__renderButtons()} ${this.__renderTable()}
      </div>
    `;
  }
}

customElements.define(
  'neb-page-ar-hub-new-custom-view',
  NebPageArHubNewCustomView,
);
