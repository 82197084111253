import '../../misc/neb-icon';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-icon';

import { navigate } from '@neb/router';
import { html, css } from 'lit';
import moment from 'moment-timezone';

import Table, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
  CSS_SPACING,
} from '../../../../packages/neb-styles/neb-variables';
import { MERCHANT_PROVIDERS } from '../../../../packages/neb-utils/constants';

export const ELEMENTS = {
  ...BASE_ELEMENTS,

  defaultCheckboxes: {
    selector: '[id^=default-checkbox-]',
  },

  editDescriptionIcons: {
    selector: '[id^=edit-description-icon-]',
  },

  patientLinks: {
    selector: '[id^=patient-link-]',
  },
};

const CONFIG = [
  {
    key: 'isDefault',
    label: 'Default',
    flex: css`0 0 60px`,
  },
  {
    key: 'name',
    label: 'Type',
    flex: css`1 0 0`,
  },
  {
    key: 'editDescriptionIcon',
    label: '',
    flex: css`0 0 16px`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`1 0 0`,
  },
  {
    truncate: true,
    key: 'patientName',
    label: 'Card Belongs To',
    flex: css`1 0 0`,
  },
  {
    key: 'expiryDate',
    label: 'Expiration',
    flex: css`0 0 200px`,
    formatter: v => moment(v).format('MM/YYYY'),
  },
  {
    key: 'merchantAccount.merchantProvider',
    label: 'Merchant Provider',
    flex: css`0 0 200px`,
  },
];

class NebTablePaymentMethods extends Table {
  static get properties() {
    return {
      patientId: String,
    };
  }

  initState() {
    super.initState();

    this.patientId = '';
    this.showRemoveButton = true;
    this.config = CONFIG;

    this.onEditDescription = () => {};

    this.onToggleDefault = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,

      toggleDefault: ({ name: rowIndex, value: isDefault }) => {
        this.onToggleDefault(this.model[rowIndex], isDefault);
      },

      editDescription: rowIndex => {
        this.onEditDescription(this.model[rowIndex]);
      },

      clickPatient: e => {
        e.stopPropagation();
        const rowIndex = e.currentTarget.getAttribute('index');
        const { holderId: patientId } = this.model[rowIndex];

        return navigate(`/patients/${patientId}/billing/general`);
      },
    };
  }

  shouldEnableRemoveButton(item) {
    if (
      this.patientId.length &&
      item.holderId &&
      this.patientId !== item.holderId
    ) {
      return false;
    }

    return true;
  }

  static get styles() {
    return [
      super.styles,
      css`
        .patient-text {
          margin-right: 6px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          display: inline-block;
          white-space: nowrap;
          max-width: calc(100% - ${CSS_SPACING});
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .icon {
          width: 15px;
          height: 15px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .button {
          display: flex;
          align-items: center;
          cursor: pointer;
          inline-size: min-content;
          overflow: hidden;
          max-width: calc(100% - ${CSS_SPACING});
        }

        .card-belongs-to-read-only {
          white-space: normal;
          word-break: break-word;
        }

        .cell:not(:last-child) {
          margin-right: 10px;
        }

        .edit-description-icon {
          width: 16px;
          height: 16px;
        }
      `,
    ];
  }

  renderDataCell(value, columnConfig, rowIndex) {
    switch (columnConfig.key) {
      case 'isDefault':
        return html`
          <neb-checkbox
            id="default-checkbox-${rowIndex}"
            .name="${rowIndex}"
            .onChange="${this.handlers.toggleDefault}"
            ?checked="${this.model[rowIndex].isDefault}"
            ?disabled="${!this.shouldEnableRemoveButton(this.model[rowIndex])}"
          ></neb-checkbox>
        `;

      case 'patientName':
        if (this.patientId === this.model[rowIndex].holderId) {
          return html`
            <span class="card-belongs-to-read-only">${value}</span>
          `;
        }

        return html`
          <div
            id="patient-link-${rowIndex}"
            class="button"
            index="${rowIndex}"
            @click="${this.handlers.clickPatient}"
          >
            <span class="patient-text"
              >${this.model[rowIndex].patientName}</span
            >
            <neb-icon class="icon" icon="neb:open"></neb-icon>
          </div>
        `;

      case 'merchantAccount.merchantProvider':
        return value || MERCHANT_PROVIDERS.GLOBAL_PAYMENTS;

      case 'editDescriptionIcon':
        return html`
          <neb-button-icon
            id="edit-description-icon-${rowIndex}"
            class="edit-description-icon"
            icon="neb:edit"
            ?disabled="${!this.shouldEnableRemoveButton(this.model[rowIndex])}"
            .name="${rowIndex}"
            .onClick="${this.handlers.editDescription}"
          ></neb-button-icon>
        `;

      default:
        return value;
    }
  }
}

customElements.define('neb-table-payment-methods', NebTablePaymentMethods);
