export const ERA_EOB_STATUS = { OPEN: 'Open', CLOSED: 'Closed' };

export const getEraEobStatuses = ({ currentStatus }) => {
  const statuses = Object.values({
    WORKING: 'Working',
    ...ERA_EOB_STATUS,
  });

  return currentStatus === 'Working' ? statuses.reverse() : statuses;
};

export const ERA_CLAIM_STATUSES = Object.freeze({
  1: 'Processed as Primary',
  2: 'Processed as Secondary',
  3: 'Processed as Tertiary',
  4: 'Denied',
  19: 'Processed as Primary, Forwarded to Additional Payer(s)',
  20: 'Processed as Secondary, Forwarded to Additional Payer(s)',
  21: 'Processed as Tertiary, Forwarded to Additional Payer(s)',
  22: 'Reversal of Previous Payment',
  23: 'Not Our Claim, Forwarded to Additional Payer(s)',
  25: 'Predetermination Pricing Only - No Payment',
});

export const PROCESSED_AS_PRIMARY = ERA_CLAIM_STATUSES[1];
export const DENIED = ERA_CLAIM_STATUSES[4];
export const REVERSAL_OF_PREVIOUS_PAYMENT = ERA_CLAIM_STATUSES[22];
