/* eslint-disable complexity */
import {
  getCheckInSummary,
  getCheckOutSummary,
} from '../../../../packages/neb-api-client/src/appointment-api-client';
import { openError } from '../../../../packages/neb-dialog/neb-banner-state';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  hasFeatureOrBeta,
  FEATURE_FLAGS,
} from '../../../../packages/neb-utils/feature-util';

export const GET_SUMMARY_ERROR =
  'An error occurred when fetching appointment summary';

export const openOverlayCheckInOut = async (overlayKey, model) => {
  const { mode, appointment, patientSummaryModel } = model;

  const featureFlag =
    mode === 'checkIn' ? FEATURE_FLAGS.DH_CHECK_IN : FEATURE_FLAGS.DH_CHECK_OUT;
  const overlayKeyToUse =
    mode === 'checkIn' ? OVERLAY_KEYS.CHECK_IN : OVERLAY_KEYS.CHECK_OUT;
  const getSummary =
    mode === 'checkIn' ? getCheckInSummary : getCheckOutSummary;

  const hasFeatureFlag = await hasFeatureOrBeta(featureFlag);

  if (hasFeatureFlag) {
    let summary;

    try {
      summary = await getSummary(appointment.id);
    } catch (e) {
      console.error(e);
      return store.dispatch(openError(GET_SUMMARY_ERROR));
    }

    const { roomId } = appointment;

    if (
      mode === 'checkIn' &&
      roomId &&
      roomId !== summary.form.appointmentDetails.roomId
    ) {
      summary.form.appointmentDetails.roomId = appointment.roomId;
    }

    return openOverlay(overlayKeyToUse, {
      ...summary,
      patientSummaryModel,
    });
  }

  return openOverlay(overlayKey, model);
};
