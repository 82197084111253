import '../../neb-popup-header';
import '../../forms/neb-form-patient-insurance-add';

import { html, css } from 'lit';

import { openDirtyPopup } from '../../../../../neb-popup';
import { CSS_SPACING } from '../../../../../neb-styles/neb-variables';
import {
  FEATURE_FLAGS,
  hasFeatureOrBeta,
} from '../../../../../neb-utils/feature-util';
import Overlay from '../neb-overlay';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  formAddInsurance: {
    id: 'form-add-insurance',
  },
};

class NebAddPatientInsuranceOverlay extends Overlay {
  static get properties() {
    return {
      patientId: String,
      forCheckIn: Boolean,
      patientInsurances: Array,

      __hasBillTypeModalFF: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          display: flex;
          width: 875px;
          flex-flow: column nowrap;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .form {
          flex: 1 0 0;
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.patientId = '';
    this.patientInsurances = [];
    this.forCheckIn = false;

    this.__hasBillTypeModalFF = false;
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      save: obj => {
        this.__result = obj;
        this.handlers.dirty(false);

        if (
          this.model.context.cancel === 'cancel' &&
          this.model.context.hasAddOnCTVerify
        ) {
          this.onClose();
        }

        this.handlers.dismiss(obj);
      },
      cancel: async () => {
        if (this.isDirty) {
          if (await openDirtyPopup()) this.isDirty = false;
        }

        if (!this.isDirty) {
          this.onClose();
          if (this.model.context.hasAddOnCTVerify) this.handlers.dismiss();
        }
      },
      cancelAll: () => {
        this.handlers.dismissAll();
      },
    };
  }

  dismissWithBlocker() {
    this.dismiss(null);
  }

  async connectedCallback() {
    this.__hasBillTypeModalFF = await hasFeatureOrBeta(
      FEATURE_FLAGS.DH_BILL_TYPE_MODAL,
    );

    super.connectedCallback();
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      const { patientId, patientInsurances, forCheckIn } = this.model;
      this.patientId = patientId || '';
      this.patientInsurances = patientInsurances || [];
      this.forCheckIn = forCheckIn || false;
    }

    super.update(changedProps);
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Add Insurance"
        .onCancel="${this.handlers[this.model.context.cancel]}"
        .onBack="${this.handlers.dismiss}"
        ?showBackButton="${this.model.context.showBackButton}"
        showCancelButton
      ></neb-popup-header>

      <neb-form-patient-insurance-add
        id="${ELEMENTS.formAddInsurance.id}"
        class="form"
        .layout="${this.layout}"
        .hasBillTypeModalFF="${this.__hasBillTypeModalFF}"
        .patientInsurances="${this.patientInsurances}"
        .patientId="${this.patientId}"
        .forCheckIn="${this.forCheckIn}"
        .onCancel="${this.handlers.dismiss}"
        .onSave="${this.handlers.save}"
        .onChangeDirty="${this.handlers.dirty}"
      ></neb-form-patient-insurance-add>
    `;
  }
}

window.customElements.define(
  'neb-overlay-patient-insurance-add',
  NebAddPatientInsuranceOverlay,
);
