import '../../../packages/neb-lit-components/src/components/inputs/neb-select-search';

import { html, css } from 'lit';

import { getActiveProviderUsers } from '../../../packages/neb-api-client/src/practice-users-api-client';
import { BUTTON_ROLE } from '../../../packages/neb-lit-components/src/components/neb-button';
import NebPopup from '../../../packages/neb-popup/src/neb-popup';
import {
  CSS_FONT_FAMILY,
  CSS_SPACING,
} from '../../../packages/neb-styles/neb-variables';

export const ELEMENTS = {
  buttonConfirm: {
    id: 'button-confirm',
  },
  buttonCancel: {
    id: 'button-cancel',
  },
  selectProvider: {
    id: 'select-provider',
  },
};

class NebPopupUpdateProvider extends NebPopup {
  static get properties() {
    return {
      ...super.properties,
      model: Object,
      __providers: Array,
      __providerSearchItems: Array,
      __selectedProviderId: String,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          max-width: 650px;
          font-family: ${CSS_FONT_FAMILY};
        }

        .content-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 650px;
          height: 100%;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          margin-top: ${CSS_SPACING};
        }

        .button:not(:last-child) {
          margin-right: 10px;
        }

        .provider-select {
          display: flex;
          margin-bottom: ${CSS_SPACING};
        }
      `,
    ];
  }

  initState() {
    super.initState();

    this.dynamic = true;
    this.title = 'Update Provider';
    this.model = {};

    this.__selectedProviderId = '';
    this.__providerSearchItems = [];
    this.__providers = [];
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      change: ({ value }) => {
        this.__selectedProviderId = value ? value.id : '';
      },
      search: ({ value }) => {
        const searchTerms = value.toLowerCase();

        this.__providerSearchItems = this.__providers.filter(type =>
          type.label.toLowerCase().includes(searchTerms),
        );
      },
      confirm: () => {
        this.onClose({
          providerId: this.__selectedProviderId,
        });
      },
    };
  }

  updated(changedProps) {
    if (changedProps.has('model') && this.model.providerId) {
      this.__selectedProviderId = this.__providers.find(
        type => type.id === this.model.providerId,
      ).id;
    }
  }

  async connectedCallback() {
    await this.__loadProviders();
    super.connectedCallback();
  }

  async __loadProviders() {
    const providers = await getActiveProviderUsers();

    this.__providers = providers.map(data => ({
      label: `${data.lastName}, ${data.firstName}`,
      ...data,
    }));
  }

  __getSelectedProvider() {
    return this.__providers.find(type => type.id === this.__selectedProviderId);
  }

  renderContent() {
    return html`
      <div class="content-container">
        <div class="container-select">
          <neb-select-search
            id="${ELEMENTS.selectProvider.id}"
            class="provider-select"
            label="Provider"
            .items="${this.__providerSearchItems}"
            .value="${this.__getSelectedProvider()}"
            .emptyMessage="No Providers Found"
            .onSearch="${this.handlers.search}"
            .onChange="${this.handlers.change}"
            showSearch
          ></neb-select-search>
        </div>

        <div class="button-container">
          <neb-button
            id="${ELEMENTS.buttonConfirm.id}"
            class="button"
            label="Save"
            .role="${BUTTON_ROLE.CONFIRM}"
            .onClick="${this.handlers.confirm}"
            ?disabled="${!this.__selectedProviderId}"
          ></neb-button>

          <neb-button
            id="${ELEMENTS.buttonCancel.id}"
            class="button"
            label="Cancel"
            .role="${BUTTON_ROLE.OUTLINE}"
            .onClick="${this.handlers.cancel}"
          ></neb-button>
        </div>
      </div>
    `;
  }
}

customElements.define('neb-popup-update-provider', NebPopupUpdateProvider);
