import { ERA_DETAILS_TYPES, CHARGE_WARNINGS, CLAIM_WARNINGS } from '../utils';

import { itemHasSpecificWarning } from './utils';

const checkAllLineItemsAreFullyWrittenOff = ({ lineItems }) =>
  lineItems.every(
    ({ errorMessage = [] }) =>
      errorMessage.length === 0 ||
      itemHasSpecificWarning(
        errorMessage,
        CHARGE_WARNINGS.LINE_ITEM_FULLY_WRITTEN_OFF,
      ),
  );

const checkClaimLevel = ({ statusMessage, processedAsPrimary, claim }) =>
  processedAsPrimary &&
  itemHasSpecificWarning(
    statusMessage,
    CLAIM_WARNINGS.CLAIM_WITH_FULLY_WRITTEN_OFF_LINE_ITEM,
  ) &&
  checkAllLineItemsAreFullyWrittenOff(claim);

export default ({
  statusMessage,
  selectedNodeType,
  processedAsPrimary,
  claim,
}) => {
  if (selectedNodeType === ERA_DETAILS_TYPES.ERA_LEVEL) {
    return false;
  }

  return selectedNodeType === ERA_DETAILS_TYPES.LINE_ITEM_LEVEL
    ? itemHasSpecificWarning(
        statusMessage,
        CHARGE_WARNINGS.LINE_ITEM_FULLY_WRITTEN_OFF,
      )
    : checkClaimLevel({ statusMessage, processedAsPrimary, claim });
};
