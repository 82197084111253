import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../packages/neb-lit-components/src/components/neb-unsupported-page';
import './neb-page-ar-hub-new-custom-view';

import { openPopup } from '@neb/popup';
import { css, html, LitElement } from 'lit';

import { openError } from '../../../../packages/neb-dialog/neb-banner-state';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import {
  CSS_COLOR_GREY_4,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_WEIGHT_BOLD,
} from '../../../../packages/neb-styles/neb-variables';
import { getArHubInvoices } from '../../../api-clients/ar-hub';
import { formatArHubInvoices } from '../../../formatters/ar-hub';
import { CSS_COLOR_WHITE, CSS_SMALL_SPACING } from '../../../styles';
import { SORT_DIR } from '../../../utils/sort/sort';

export const ELEMENTS = {
  manageViewsButton: { id: 'manage-views-button' },
  saveViewAsButton: { id: 'save-view-as-button' },
  untitledViewButton: { id: 'untitled-view-button' },
  unsupportedPage: { id: 'unsupported-page' },
  customViewPage: { id: 'custom-view-page' },
  viewTabs: { id: 'view-tabs' },
  viewTab: { selector: '[id^="view-tab-"]' },
};

const AR_HUB_RESOLUTION_MESSAGE =
  'You can access AR HUB by resizing your browser to fit the resolution of a desktop/laptop.';

const AR_HUB_ERROR_MESSAGE = 'An error occurred while fetching invoices.';

const DEFAULT_LIMIT = 15;

class NebPageArHub extends LitElement {
  static get properties() {
    return {
      layout: {
        reflect: true,
        type: String,
      },
      showTable: Boolean,
      selectedViews: Array,
      selectedTab: Object,
      tableModel: Array,
      viewName: String,
      viewOrderBy: String,
      viewSortAsc: Boolean,
      sortParams: Array,
      loading: Boolean,
    };
  }

  static get styles() {
    return css`
      :host {
        height: 100%;
        background-color: ${CSS_COLOR_WHITE};
      }

      .container {
        background-color: ${CSS_COLOR_WHITE};
      }

      .links {
        display: flex;
        padding: ${CSS_SMALL_SPACING};
        flex-wrap: wrap;
        gap: ${CSS_SMALL_SPACING};
        align-items: center;
      }

      .tabs {
        display: flex;
        flex-wrap: wrap;
        gap: ${CSS_SMALL_SPACING};
      }

      .default-tab {
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 14px;
        padding: 5px 10px;
        cursor: pointer;
      }

      .tab {
        background-color: ${CSS_COLOR_GREY_4};
        color: ${CSS_COLOR_HIGHLIGHT};
      }

      .view-name {
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
      }

      .selected-tab {
        background-color: ${CSS_COLOR_HIGHLIGHT};
        color: ${CSS_COLOR_WHITE};
      }

      .selected-view-name {
        font-weight: ${CSS_FONT_WEIGHT_BOLD};
      }
    `;
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.showTable = false;
    this.selectedViews = [];
    this.selectedTab = {};
    this.tableModel = [];
    this.viewName = '';
    this.viewOrderBy = '';
    this.viewSortAsc = false;
    this.sortParams = [];
    this.loading = true;
  }

  __initHandlers() {
    this.__handlers = {
      openManageViews: async () => {
        const result = await openOverlay(OVERLAY_KEYS.MANAGE_VIEWS);

        if (result) {
          const { showNewView, selectedViews } = result;

          if (showNewView) {
            this.viewName = '';
          }

          this.showTable = showNewView || selectedViews.length > 0;
          this.selectedViews = selectedViews;
          this.__setSelectedTab(selectedViews[0]);

          if (this.showTable) {
            const params = this.selectedTab
              ? {
                  limit: null,
                  sortAsc: this.selectedTab.sortAsc,
                  orderBy: this.selectedTab.orderBy,
                }
              : { limit: DEFAULT_LIMIT };

            await this.__getInvoices(params);
          }
        }
      },
      openSaveView: async () => {
        const viewTitle = await openPopup(
          POPUP_RENDER_KEYS.AR_HUB_SAVE_NEW_VIEW,
          {
            viewOrderBy: this.viewOrderBy,
            viewSortAsc: this.viewSortAsc,
          },
        );

        if (viewTitle) {
          const invoices = await getArHubInvoices({
            limit: null,
            orderBy: this.viewOrderBy,
            sortAsc: this.viewSortAsc,
          });

          this.tableModel = formatArHubInvoices(invoices);
          this.viewName = viewTitle;
        }
      },
      selectTab: async tab => {
        const view = tab.currentTarget.value;
        this.__setSelectedTab(view);

        await this.__getInvoices({
          limit: null,
          sortAsc: this.selectedTab.sortAsc,
          orderBy: this.selectedTab.orderBy,
        });
      },
      onChangeSort: async (key, dir) => {
        this.viewOrderBy = key;
        this.viewSortAsc = dir === SORT_DIR.ASC;

        this.__setSortParams({
          orderBy: this.viewOrderBy,
          sortAsc: this.viewSortAsc,
        });

        const invoices = await getArHubInvoices({
          limit: this.viewName ? null : DEFAULT_LIMIT,
          orderBy: this.viewOrderBy,
          sortAsc: this.viewSortAsc,
        });

        this.tableModel = formatArHubInvoices(invoices);
      },
    };
  }

  async __getInvoices(params) {
    try {
      const invoices = await getArHubInvoices(params);
      this.tableModel = formatArHubInvoices(invoices);
      this.loading = false;
    } catch (error) {
      store.dispatch(openError(AR_HUB_ERROR_MESSAGE));
      console.error(error);
    }
  }

  __setSelectedTab(selectedTab) {
    this.selectedTab = selectedTab;
    this.__setSortParams(selectedTab);
  }

  __setSortParams(selectedTab) {
    let dir = '';

    if (selectedTab) {
      dir = selectedTab.sortAsc ? SORT_DIR.ASC : SORT_DIR.DESC;
    }

    this.sortParams = [
      {
        key: selectedTab?.orderBy || '',
        dir,
      },
    ];
  }

  __renderUnsupportedPage() {
    return html`
      <neb-unsupported-page
        id="${ELEMENTS.unsupportedPage.id}"
        .layout="${this.layout}"
        icon="wallet"
        location="AR Hub"
        specificMessage="${AR_HUB_RESOLUTION_MESSAGE}"
      ></neb-unsupported-page>
    `;
  }

  __renderCustomViewPage() {
    return this.showTable
      ? html`
          <neb-page-ar-hub-new-custom-view
            id="${ELEMENTS.customViewPage.id}"
            .model="${this.tableModel}"
            .onChangeSort="${this.__handlers.onChangeSort}"
            .sortParams="${this.sortParams}"
            .loading="${this.loading}"
          ></neb-page-ar-hub-new-custom-view>
        `
      : '';
  }

  __renderNewViewLink() {
    return this.showTable && !this.selectedTab
      ? html` <div
          id="${ELEMENTS.untitledViewButton.id}"
          class="selected-tab default-tab"
        >
          <span class="selected-view-name"
            >${this.viewName ? this.viewName : 'Untitled View'}</span
          >
        </div>`
      : '';
  }

  __renderTabs() {
    if (this.selectedViews.length) {
      const tabs = this.selectedViews.map((view, index) => {
        const isSelected = view.id === this.selectedTab.id;
        return html`<div
          id="view-tab-${index}"
          class="${isSelected ? 'selected-tab' : 'tab'} default-tab"
          .value="${view}"
          @click=${this.__handlers.selectTab}
        >
          <span class="${isSelected ? 'selected-view-name' : 'view-name'}"
            >${view.name}</span
          >
        </div>`;
      });

      return html`<div class="tabs" id="view-tabs">${tabs}</div>`;
    }

    return '';
  }

  __renderButtonLinks() {
    return html`
      <neb-button-action
        id="${ELEMENTS.manageViewsButton.id}"
        label="Manage Views"
        .onClick="${this.__handlers.openManageViews}"
      ></neb-button-action>
      <neb-button-action
        id="${ELEMENTS.saveViewAsButton.id}"
        label="Save View As"
        leadingIcon="save"
        .onClick="${this.__handlers.openSaveView}"
        .disabled="${!this.showTable}"
      ></neb-button-action>
    `;
  }

  __renderLinks() {
    return html`
      <div class="links">
        ${this.__renderTabs()} ${this.__renderNewViewLink()}
        ${this.__renderButtonLinks()}
      </div>
    `;
  }

  render() {
    return this.layout !== 'large'
      ? html` ${this.__renderUnsupportedPage()} `
      : html` <div class="container">
          ${this.__renderLinks()} ${this.__renderCustomViewPage()}
        </div>`;
  }
}

customElements.define('neb-page-ar-hub', NebPageArHub);
