/* eslint-disable complexity */
import { html, css } from 'lit';

import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';
import Overlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { CSS_SPACING, CSS_COLOR_GREY_2 } from '../../../../styles';
import '../../../../../packages/neb-lit-components/src/components/patients/neb-patient-summary-controller';
import { getDefaultInsurances } from '../../utils/neb-form-check-in-util';

import { NebFormCheckOut } from './neb-form-check-out';

export const ELEMENTS = {
  patientSummary: {
    id: 'patient-summary',
  },
  header: {
    id: 'header',
  },
  formCheckOut: {
    id: 'form-check-out',
  },
};

class NebOverlayCheckOut extends Overlay {
  static get properties() {
    return {
      formModel: Object,
      formMetaData: Object,
      __savingError: Boolean,
      __defaultInsurances: Object,
    };
  }

  initState() {
    super.initState();

    this.formModel = NebFormCheckOut.createModel();
    this.formMetaData = NebFormCheckOut.createMetaData();

    this.__savingError = false;

    this.__defaultInsurances = null;
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      cancel: () =>
        this.dismiss({
          success: true,
          model: { id: this.formMetaData.appointment.id || '' },
          cancelled: true,
        }),
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          flex-flow: row nowrap;
          width: 100%;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .content-left {
          padding: ${CSS_SPACING};
          border-right: 1px solid ${CSS_COLOR_GREY_2};
        }

        .content-right {
          display: flex;
          flex-flow: column nowrap;
          width: 100%;
        }

        .form {
          flex: 1 0 0;
        }
      `,
    ];
  }

  updated(changedProps) {
    if (changedProps.has('model') && this.model.meta?.appointment?.id) {
      const { form, meta } = this.model;
      this.formModel = form;
      this.formMetaData = meta;

      this.__defaultInsurances = getDefaultInsurances(
        this.formMetaData.insurances,
      );
    }
  }

  renderContent() {
    return html`
      ${this.layout !== 'small'
        ? html`
            <neb-patient-summary-controller
              id="${ELEMENTS.patientSummary.id}"
              class="content-left"
              .patientId="${this.model.form.appointmentDetails.patientId}"
              .showTreatmentPlanLink="${true}"
              .initialModel="${this.model.patientSummaryModel}"
            >
            </neb-patient-summary-controller>
          `
        : ''}

      <div class="content-right">
        <neb-popup-header
          id="${ELEMENTS.header.id}"
          class="header"
          title="Check Out"
          .onBack="${this.handlers.cancel}"
          .onCancel="${this.handlers.cancel}"
          .showBackButton="${this.layout === 'small'}"
          .showCancelButton="${this.layout !== 'small'}"
        ></neb-popup-header>

        <neb-form-check-out
          id="${ELEMENTS.formCheckOut.id}"
          class="form"
          .layout="${this.layout}"
          .model="${this.formModel}"
          .metaData="${this.formMetaData}"
          .savingError="${this.__savingError}"
          .defaultInsurances="${this.__defaultInsurances}"
          .onCancel="${this.handlers.cancel}"
          .onChangeDirty="${this.handlers.dirty}"
          .onSave="${this.handlers.save}"
        ></neb-form-check-out>
      </div>
    `;
  }
}

customElements.define('neb-overlay-check-out', NebOverlayCheckOut);
