import { matchRouteSwitch, navigate } from '@neb/router';
import { LitElement, html, css } from 'lit';

import { baseStyles } from '../../../../../packages/neb-styles/neb-styles';
// eslint-disable-next-line import/order
import {
  CSS_COLOR_WHITE,
  CSS_SPACING,
} from '../../../../../packages/neb-styles/neb-variables';

import '../../../collection-pages/neb-collection-page-epayment-file';
import '../../../collection-pages/neb-page-acknowledgement-file';
import '../../../collection-pages/neb-page-277-file';

import '../../../tables/practice/clearinghouse/neb-table-clearinghouse-files-download';
import '../../../filters/neb-filters-eclaim-file-download';
import '../../../collection-pages/neb-page-claim-file-downloads';
import '../../../collection-pages/neb-page-claim-file';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-tab-group';

export const ELEMENTS = {
  tabs: { id: 'tabs' },
  pageEclaimFiles: { id: 'page-eclaim-files' },
  pageEpaymentFiles: { id: 'page-epayment-files' },
  pageEclaimFileDownloads: { id: 'page-eclaim-file-downloads' },
  pageAcknowledgementFiles: {
    id: 'page-acknowledgement-files',
  },
  page277Files: {
    id: 'page-277-files',
  },
};

export const TABS = {
  CLAIM_FILES: 'claim-files',
  CLAIM_DOWNLOADS: 'claim-downloads',
  ERA_FILES: 'era-files',
  ACKNOWLEDGEMENT_FILES: 'acknowledgement-files',
  STATUS_FILES: 'status-files',
};

class NebPracticeLedgerClearinghouseFilesController extends LitElement {
  static get properties() {
    return {
      __selectedTab: String,
      __navItems: Array,

      layout: String,
      route: String,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        :host {
          display: flex;
          flex-direction: column;
          background-color: ${CSS_COLOR_WHITE};
          flex: 1 0 0;
        }

        .tabs {
          padding-top: 10px;
          flex: 0 0 auto;
        }

        .filters {
          margin-bottom: ${CSS_SPACING};
        }

        .pagination {
          display: flex;
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.layout = '';
    this.route = '';

    this.__navItems = this.genNavItems();
    this.__selectedTab = TABS.CLAIM_FILES;
  }

  __initHandlers() {
    this.__handlers = {
      selectTab: tab => {
        this.__selectedTab = tab;
        navigate(this.__buildRoute(tab));
      },
    };
  }

  __buildRoute(tab) {
    return `#/practice-ledger/clearinghouse-files/${tab}`;
  }

  update(changedProps) {
    if (changedProps.has('route') && this.route) {
      navigate(this.__buildRoute(this.__selectedTab));
    }

    super.update(changedProps);
  }

  __renderTabs() {
    return html`
      <neb-tab-group
        id="${ELEMENTS.tabs.id}"
        class="tabs"
        .selectedId="${this.__selectedTab}"
        .items="${this.__navItems}"
        .onSelect="${this.__handlers.selectTab}"
      ></neb-tab-group>
    `;
  }

  genNavItems() {
    return [
      {
        label: 'Claim 837 Files',
        id: TABS.CLAIM_FILES,
        path: `/clearinghouse-files/${TABS.CLAIM_FILES}`,
        resolver: () => html`
          <neb-page-claim-file
            id="${ELEMENTS.pageEclaimFiles.id}"
            .layout="${this.layout}"
          ></neb-page-claim-file>
        `,
      },
      {
        label: 'Claim 837 Downloads',
        id: TABS.CLAIM_DOWNLOADS,
        path: `/clearinghouse-files/${TABS.CLAIM_DOWNLOADS}`,
        resolver: () => html`
          <neb-page-claim-file-downloads
            id="${ELEMENTS.pageEclaimFileDownloads.id}"
            .layout="${this.layout}"
          ></neb-page-claim-file-downloads>
        `,
      },
      {
        label: 'ERA 835 & Other Files',
        id: TABS.ERA_FILES,
        path: `/clearinghouse-files/${TABS.ERA_FILES}`,
        resolver: () => html`
          <neb-collection-page-epayment-file
            id="${ELEMENTS.pageEpaymentFiles.id}"
            .layout="${this.layout}"
          ></neb-collection-page-epayment-file>
        `,
      },
      {
        label: '999 Files',
        id: TABS.ACKNOWLEDGEMENT_FILES,
        path: `/clearinghouse-files/${TABS.ACKNOWLEDGEMENT_FILES}`,
        resolver: () => html`
          <neb-page-acknowledgement-file
            id="${ELEMENTS.pageAcknowledgementFiles.id}"
            .layout="${this.layout}"
          ></neb-page-acknowledgement-file>
        `,
      },
      {
        label: '277 Files',
        id: TABS.STATUS_FILES,
        path: `/clearinghouse-files/${TABS.STATUS_FILES}`,
        resolver: () => html`
          <neb-page-277-file
            id="${ELEMENTS.page277Files.id}"
            .layout="${this.layout}"
          ></neb-page-277-file>
        `,
      },
    ];
  }

  render() {
    return html`
      ${this.__renderTabs()} ${matchRouteSwitch(this.__navItems, this.route)}
    `;
  }
}

customElements.define(
  'neb-practice-clearinghouse-files-controller',
  NebPracticeLedgerClearinghouseFilesController,
);
