import '../../../../packages/neb-lit-components/src/components/neb-button-actions';
import { html, css } from 'lit';

import '../../misc/neb-icon';
import NebTable from '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import {
  CSS_SPACING,
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_SUCCESS_COLOR,
} from '../../../../packages/neb-styles/neb-variables';
import {
  CLEARINGHOUSES,
  hasByocAddons,
} from '../../../utils/clearinghouse-settings';

export const ELEMENTS = {
  actionButtons: { selector: 'neb-button-actions' },
  popoverContainer: {
    id: 'popover-container',
  },
  testConnectionIcon: { selector: '[id^=test-connection-icon-]' },
};

class NebTableClearinghouseSettings extends NebTable {
  static get styles() {
    return [
      super.styles,
      css`
        .button-action {
          height: auto;
          --menu-min-width: var(--action-menu-min-width);
        }

        :host {
          --action-menu-min-width: 210px;
        }

        .popover-text {
          overflow-wrap: break-word;
          max-width: 300px;
          height: 100%;
          white-space: normal;
        }

        .icon-warn-container {
          display: flex;
          flex-direction: row;
        }

        .tooltip-icon {
          display: flex;
          flex: 0 0 30px;
        }

        .icon {
          height: 20px;
          width: 20px;
          margin-left: ${CSS_SPACING};
        }

        .icon-error {
          fill: ${CSS_BANNER_ERROR_COLOR};
        }

        .icon-checkCircle {
          fill: ${CSS_BANNER_SUCCESS_COLOR};
        }
      `,
    ];
  }

  static get properties() {
    return {
      testConnectionIcon: { type: String },
      currentRow: { type: String },
      sftpStatus: { type: String },

      __hasMaxClear: { type: Boolean },
    };
  }

  initState() {
    super.initState();

    this.currentRow = null;
    this.sftpStatus = '';
    this.testConnectionIcon = '';

    this.__hasMaxClear = false;

    this.onSwapClearinghouse = () => {};

    this.onTestConnection = () => {};

    this.onTestChcConnection = () => {};
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__hasMaxClear = (await hasByocAddons()).CT_MAXCLEAR;
  }

  renderDataCell(value, columnConfig, rowIndex) {
    const row = this.model[rowIndex];

    if (
      columnConfig.key === 'actionMenu' &&
      this.__shouldShowChcActionMenu(row)
    ) {
      return this.__renderActionButton(row);
    }

    if (columnConfig.key === 'testConnectionIcon') {
      return this.__renderTestConnectionIcon(row);
    }

    if (
      columnConfig.key === 'partner' &&
      row.partner !== CLEARINGHOUSES.CHC &&
      row.alias
    ) {
      const partnerAlias = `${row.partner} (${row.alias})`;
      return super.renderDataCell(partnerAlias, columnConfig);
    }

    return super.renderDataCell(value, columnConfig);
  }

  __shouldShowChcActionMenu(row) {
    if (row.partner !== CLEARINGHOUSES.CHC) return true;
    return row.partner === CLEARINGHOUSES.CHC && this.__hasMaxClear;
  }

  __getIconProps(status) {
    return {
      icon: status === 'success' ? 'neb:checkCircle' : 'neb:error',
      class: status === 'success' ? 'icon-checkCircle' : 'icon-error',
    };
  }

  __renderTestConnectionIcon(row) {
    const isChc = row.partner === CLEARINGHOUSES.CHC;
    const status = isChc ? this.testConnectionIcon : this.sftpStatus;

    if ((status && isChc) || (status && this.currentRow === row.id)) {
      const iconProps = this.__getIconProps(status);
      return html`
        <neb-icon
          id="test-connection-icon-${row.id}"
          class="icon ${iconProps.class}"
          icon="${iconProps.icon}"
        ></neb-icon>
      `;
    }
    return '';
  }

  __genMenuItems(row) {
    return [
      ...(row.partner !== CLEARINGHOUSES.CHC
        ? [
            {
              icon: '',
              label: 'Swap Clearinghouse',
              onSelect: () => this.onSwapClearinghouse(row),
            },
          ]
        : []),
      ...(row.sftpActive
        ? [
            {
              icon: '',
              label: 'Test SFTP Connection',
              onSelect: () => {
                this.onTestConnection(row);
              },
            },
          ]
        : []),
      ...(row.partner === CLEARINGHOUSES.CHC
        ? [
            {
              icon: '',
              label: 'Test CHC Connection',
              onSelect: () => this.onTestChcConnection(row),
            },
          ]
        : []),
    ];
  }

  __renderActionButton(row) {
    return html`
      <neb-button-actions
        tabindex="1"
        class="cell button-action"
        .value="${this.__genMenuItems(row)}"
        vertical
      ></neb-button-actions>
    `;
  }
}
customElements.define(
  'neb-table-clearinghouse-settings',
  NebTableClearinghouseSettings,
);
