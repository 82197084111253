import { openPopup } from '@neb/popup';
import { css, html, LitElement } from 'lit';
import { map } from 'lit/directives/map.js';

import '../../misc/neb-icon';
import '../../../../packages/neb-lit-components/src/components/tree/neb-tree-view';
import '../../../../packages/neb-lit-components/src/components/tables/neb-table';
import '../../controls/inputs/neb-checkbox';
import '../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../tables/era-eob/payments/neb-table-payment-details';

import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../../packages/neb-utils/feature-util';
import { currencyToCents } from '../../../../packages/neb-utils/formatters';
import { CSS_BORDER_GREY_1, CSS_SPACING } from '../../../styles';
import { ERA_CLAIM_STATUSES } from '../../../utils/era';
import { EDIT_MODE } from '../../../utils/neb-charges-util';
import {
  MATCH_CHARGE,
  MATCH_CLAIM,
  NO_REMIT_LEVEL_ADJUSTMENTS_PROVIDED,
  TOTAL_REVERSALS,
} from '../../../utils/user-message';

import checkRenderMergeButton from './neb-era-report-data/check-render-merge-button';
import checkRenderPostButtonForWrittenOff from './neb-era-report-data/check-render-post-button-for-written-off';
import getClaimErrorMessage from './neb-era-report-data/get-claim-error-message';
import getLineItemErrorMessage from './neb-era-report-data/get-line-item-error-message';
import postEra from './neb-era-report-data/post-era';
import {
  checkFilterByMatchStatus,
  checkMatchButtonEnabled,
  checkClaimFiltered,
  ERA_REPORT_ACTIONS,
  getLevelActions,
  getNextMatchLineItemReportId,
  checkManualPostButtonEnabled,
  checkMarkAsDeniedButtonEnabled,
  checkReversalButtonEnabled,
  checkDisablePostButtonWhenInsufficientPaymentBalance,
} from './neb-era-report-data/utils';
import {
  buildERADetailsModel,
  ERA_ADJUSTMENTS_TABLE_CONFIG,
  ERA_DETAILS_TABLE_CONFIG,
  CLAIM_DETAILS_TABLE_CONFIG,
  ERA_DETAILS_TYPES,
  ERA_PAYMENT_DETAILS_TABLE_CONFIG,
  ERA_STATUSES,
  getClaimStatus,
  getERAStatus,
  getLineItemStatus,
  hasErrorMessage,
  iconByStatus,
  LEVEL_DESCRIPTION_BY_SELECTED_NODE_TYPE,
} from './utils';

export const ELEMENTS = {
  eraTreeView: { id: 'era-tree-view' },
  eraDetailsTable: { id: 'era-details-table' },
  eraAdjustmentsTable: { id: 'era-adjustments-table' },
  paymentDetailsTable: { id: 'payment-details-table' },
  totalReversals: { id: 'total-reversals' },
  claimDetailsTable: { id: 'claim-details-table' },
  status: { id: 'status' },
  statusMessage: { selector: '[id^=status-message]' },
  postedCheckbox: { id: 'posted-checkbox', tag: 'neb-checkbox' },
  readyCheckbox: { id: 'ready-checkbox', tag: 'neb-checkbox' },
  warningCheckbox: { id: 'warning-checkbox', tag: 'neb-checkbox' },
  reprocessButton: { id: 'reprocess-button' },
  previousWarningIcon: {
    id: 'previous-warning-icon',
    icon: 'chevronBold',
  },
  previousWarningButton: {
    id: 'previous-warning-button',
    text: 'Previous Warning',
  },
  nextWarningIcon: {
    id: 'next-warning-icon',
    icon: 'chevronBold',
  },
  nextWarningButton: {
    id: 'next-warning-button',
    text: 'Next Warning',
  },
  updateStatusToPostedButton: { id: 'update-status-to-posted-button' },
  postButton: { id: 'post-button' },
  mergeButton: { id: 'merge-button' },
  reverseButton: { id: 'reverse-button' },
  resetStatusButton: { id: 'reset-status' },
  matchButton: { id: 'match-button' },
  manualPostButton: { id: 'manual-post-button' },
  markAsDenied: { id: 'mark-as-denied' },
};

const PROCESSED_AS_PRIMARY_STATUSES = [
  ERA_CLAIM_STATUSES[1],
  ERA_CLAIM_STATUSES[19],
];

export const MERGE_ERA_POPUP_MESSAGE = {
  message: html`
    <div>
      Merging line items will combine all unposted line items in the claim with
      a matching procedure code and date of service.
    </div>
    <br />
    <div>After merging, you can post the merged line item.</div>
    <br />
    <div>Are you sure that you want to continue?</div>
  `,
  title: 'Merge Line Items',
  confirmText: 'Yes',
  cancelText: 'No',
};

class NebEraReportData extends LitElement {
  static get properties() {
    return {
      __icons: Object,
      __details: Array,
      __lastSelectedNodeType: String,
      __selectedNodeType: {
        type: String,
        reflect: true,
        attribute: 'node-type',
      },
      __processedAsPrimary: {
        type: Boolean,
        reflect: true,
        attribute: 'processed-as-primary',
      },
      __treeDataStructure: Object,
      __showPosted: Boolean,
      __showReadyToPost: Boolean,
      __showWarning: Boolean,
      __selectedItemId: String,
      __selectedClaimReportId: String,
      __selectedReportItemId: String,
      __eraStatus: String,
      __currentlySelected: String,
      __expandedDict: Object,
      __filteredLineItemIds: Array,
      __isLineItemMatchInProgress: Boolean,
      __nextMatchLineItem: Object,
      __visibleReportLineItems: Object,
      __hasRcmEraReversalsFF: Boolean,

      model: Object,
      reportData: Object,
      actionButtonLabel: String,
      editMode: String,
      hasEraOverviewNavigationFF: Boolean,
      hasOwlRemitOffsetEnhancementsFF: Boolean,
    };
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .container {
          display: flex;
          flex-direction: column;
          padding: 5px 20px;
        }

        .spacer {
          display: block;
          margin: auto;
        }

        .era-report-container,
        .header-container {
          display: grid;
          grid-template-columns: 1fr 2fr;
        }

        .update-status {
          display: flex;
          flex-direction: column;
          padding: 0 20px;
          text-align: center;
          gap: 10px;
        }

        :host([node-type='Line Item Level'][processed-as-primary])
          .update-status-buttons {
          display: grid;
          grid-template-columns: 1.35fr 1fr;
          padding: 0px;
        }

        .update-status-buttons {
          display: grid;
          grid-template-columns: 1fr;
          padding: 0 65px;
        }

        .buttons-container {
          display: flex;
          flex-direction: row-reverse;
        }

        .tree-container {
          width: 100%;
          display: flex;
          overflow: auto;
          border: ${CSS_BORDER_GREY_1};
          max-height: 500px;
        }

        .tree-view {
          width: 100%;
          height: 100%;
          padding: 0 10px;
        }

        .level-details {
          display: flex;
          flex-direction: column;
        }

        .era-payment-details,
        .era-details,
        .era-details-plus {
          padding-top: 10px;
          border: 1px solid rgb(149, 149, 149);
        }

        :host(:not([node-type='ERA Level'])) .era-payment-details {
          height: 100%;
        }

        .era-adjustments-container {
          display: flex;
          border: 1px solid rgb(149, 149, 149);
          height: 100%;
        }

        .total-reversals-container {
          display: flex;
          flex-direction: column;
          border-right: 1px solid #dedfe0;
          padding-top: 10px;
          flex: 0.25 0 0;
        }

        .total-reversals-title {
          font-weight: 700;
          width: auto;
          border-bottom: 1px solid #dedfe0;
          padding: 0 70px 10px 10px;
        }

        .total-reversals-value {
          height: 100%;
          display: flex;
          padding: 10px 20px 0 10px;
        }

        .era-adjustments-table {
          width: 100%;
          padding-top: 10px;
          flex: 1 0 0;
        }

        .era-details-plus {
          padding: 10px 0 10px 10px;
        }

        .status-title {
          font-weight: bold;
        }

        .view-header {
          display: flex;
          flex-direction: column;
        }

        .button-flex {
          display: flex;
          margin-right: ${CSS_SPACING};
        }

        .button-action-container {
          margin-left: ${CSS_SPACING};
        }

        .line-item-button {
          flex-direction: column-reverse;
          margin-left: 14px;
        }

        .button-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          margin-top: 10px;
        }

        .checkbox-spacing {
          margin-right: 28px;
          padding: 5px 0px 5px;
        }

        .warning-icons {
          display: flex;
          align-self: center;
          height: 15px;
          width: 10px;
        }

        .warning-buttons {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 0px 7px 0px 8px;
        }

        .previous-warning-icon {
          transform: scale(1.4) rotate(180deg);
        }

        .previous-warning-button {
          display: flex;
          justify-content: space-around;
          padding-left: 10px;
          margin: -2px -14px;
        }

        .next-warning-icon {
          transform: scale(1.4);
        }

        .next-warning-button {
          display: flex;
          margin: -2px -4px 0px auto;
        }

        .status-messages {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        .status-message-container {
          display: flex;
          gap: 5px;
        }

        .status-container {
          align-items: flex-end;
          display: flex;
          gap: 5px;
        }
      `,
    ];
  }

  constructor() {
    super();

    this.__initState();
    this.__initHandlers();
  }

  __initState() {
    this.__icons = {};
    this.__details = [];
    this.__selectedNodeType = ERA_DETAILS_TYPES.ERA_LEVEL;
    this.__lastSelectedNodeType = '';
    this.__treeDataStructure = {};
    this.__showPosted = true;
    this.__showReadyToPost = true;
    this.__showWarning = true;
    this.__selectedItemId = '';
    this.__selectedLineItemReportId = '';
    this.__selectedClaimReportId = '';
    this.__currentlySelected = '';
    this.__expandedDict = {};
    this.__eraStatus = '';
    this.__filteredLineItemIds = [];
    this.__isLineItemMatchInProgress = false;
    this.__nextMatchLineItem = {};
    this.__visibleReportLineItems = {};
    this.__hasRcmEraReversalsFF = false;

    this.model = {};
    this.reportData = {};
    this.actionButtonLabel = '';
    this.editMode = EDIT_MODE.DISABLED;
    this.hasEraOverviewNavigationFF = false;
    this.hasOwlRemitOffsetEnhancementsFF = false;

    this.onReprocessERA = () => {};

    this.onSelectLevel = () => {};

    this.onUpdateERAPosted = async () => {};

    this.onPostERA = () => {};

    this.onUpdateModel = () => {};

    this.onResetStatus = () => {};

    this.onMergeLineItems = async () => {};

    this.onStartMatch = () => {};

    this.onStopMatch = () => {};

    this.onMatchClaim = () => {};

    this.onStartManualPost = () => {};

    this.onMarkAsDenied = () => {};

    this.onReverseClaim = () => {};
  }

  async connectedCallback() {
    super.connectedCallback();

    const features = await getFeatures();

    this.__hasRcmEraReversalsFF = features.includes(
      FEATURE_FLAGS.OWL_RCM_ERA_REVERSALS,
    );

    if (this.hasEraOverviewNavigationFF) {
      document.addEventListener('keydown', this.__handlers.keydown);
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    if (this.hasEraOverviewNavigationFF) {
      document.removeEventListener('keydown', this.__handlers.keydown);
    }
  }

  __initHandlers() {
    this.__handlers = {
      keydown: e => {
        if (this.editMode !== EDIT_MODE.DISABLED) return null;

        if (e.key === 'ArrowUp') {
          return this.__previousItem(false, true);
        }

        if (e.key === 'ArrowDown') {
          return this.__nextItem(false, true);
        }

        if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
          return this.isClaimLevel() && this.__openItem();
        }

        return null;
      },
      selectKey: ({ key, nodePath = [], ignoreMatchInProgress = false }) => {
        if (
          this.editMode === EDIT_MODE.MANUAL_POST ||
          this.editMode === EDIT_MODE.TABLE ||
          (this.__isLineItemMatchInProgress && !ignoreMatchInProgress)
        ) {
          return;
        }
        let model = {};
        let filteredLineItemIds;

        const [, claimKey, lineItemKey] = nodePath;

        this.__currentlySelected = key;

        if (lineItemKey) {
          this.__selectedNodeType = ERA_DETAILS_TYPES.LINE_ITEM_LEVEL;
          model = this.__buildSelectedLineItemLevelModel(lineItemKey);
          filteredLineItemIds = model.lineItemIds;
          this.__selectedItemId = model.lineItemIds[0];
          this.__selectedLineItemReportId = model.lineItemReportId;
          this.__selectedClaimReportId = model.claim.claimReportId;
        } else if (claimKey) {
          this.__selectedNodeType = ERA_DETAILS_TYPES.CLAIM_LEVEL;
          model = this.__buildSelectedClaimLevelModel(claimKey);
          filteredLineItemIds = model.lineItemIds;
          this.__selectedItemId = model.id;

          this.__selectedLineItemReportId = '';
          this.__selectedClaimReportId = model.claimReportId;
        } else {
          this.__selectedNodeType = ERA_DETAILS_TYPES.ERA_LEVEL;
          this.__selectedLineItemReportId = '';
          this.__selectedClaimReportId = '';
          model = this.__buildSelectedEraLevelModel();
        }

        this.__details = buildERADetailsModel({
          model,
          selectedNodeType: this.__selectedNodeType,
        });

        this.__filteredLineItemIds = filteredLineItemIds;

        this.onSelectLevel(this.__filteredLineItemIds, this.__selectedNodeType);
      },
      filter: ({ name, value }) => {
        if (!name) return;
        this.__setEraData(name, value);
      },
      updateERAPosted: () =>
        this.onUpdateERAPosted({
          lineItemReportId: this.__selectedLineItemReportId,
          claimReportId: this.__selectedClaimReportId,
        }),
      postERA: () => {
        const {
          payments: [{ available }],
        } = this.model;
        const [{ paid }] = this.__details;

        return postEra({
          selectedNodeType: this.__selectedNodeType,
          isERALevelAvailableToAllocate: this.__isERALevelAvailableToAllocate,
          lineItemReportId: this.__selectedLineItemReportId || null,
          claimReportId: this.__selectedClaimReportId || null,
          unbalancedAmount: currencyToCents(paid) - available,
          callback: this.onPostERA,
        });
      },
      mergeERA: async ({ lineItemReportId, claimReportId }) => {
        const confirmed = await openPopup(
          POPUP_RENDER_KEYS.CONFIRM,
          MERGE_ERA_POPUP_MESSAGE,
        );

        if (!confirmed) return false;

        await this.onMergeLineItems(lineItemReportId, claimReportId);

        return true;
      },
      previousItem: () => {
        this.__previousItem();
      },
      nextItem: () => {
        this.__nextItem();
      },
      toggleNode: path => {
        if (
          this.__isLineItemMatchInProgress ||
          this.editMode === EDIT_MODE.MANUAL_POST ||
          this.editMode === EDIT_MODE.TABLE
        ) {
          return;
        }
        const current = this.__expandedDict[path];
        this.__expandedDict = {
          ...this.__expandedDict,
          [path]: !current,
        };
      },
      resetStatus: () => {
        this.onResetStatus({
          lineItemReportId: this.__selectedLineItemReportId,
          claimReportId: this.__selectedClaimReportId,
        });
      },
      matchSelectedItem: () =>
        this.__selectedNodeType === ERA_DETAILS_TYPES.LINE_ITEM_LEVEL
          ? this.__setUpMatchLineItem()
          : this.onMatchClaim(this.__selectedClaimReportId),
      startManualPost: () => {
        this.onStartManualPost({
          lineItemReportId: this.__selectedLineItemReportId,
          claimReportId: this.__selectedClaimReportId,
        });
      },
      markAsDenied: () => {
        this.onMarkAsDenied({
          claimReportId: this.__selectedClaimReportId,
        });
      },
      reverseClaim: () => {
        this.onReverseClaim({
          claimId: this.__selectedItemId,
          issuedAmount: this.__details[0].issued,
          claimReportId: this.__selectedClaimReportId,
        });
      },
    };
  }

  get __isERALevelAvailableToAllocate() {
    const [{ paid }] = this.__details;
    const {
      payments: [{ available }],
    } = this.model;
    return available - currencyToCents(paid) >= 0;
  }

  __setInitialERALevel() {
    const [key] = Object.keys(this.__treeDataStructure);
    this.__handlers.selectKey({ key, nodePath: [key] });
  }

  __setUpMatchLineItem() {
    this.__isLineItemMatchInProgress = true;
    this.__setEraData(ERA_STATUSES.MATCH);
    this.onStartMatch(
      this.__selectedLineItemReportId,
      this.__selectedClaimReportId,
    );

    this.__nextMatchLineItem = getNextMatchLineItemReportId(
      this.__visibleReportLineItems,
      this.__selectedLineItemReportId,
    );
  }

  __buildNavigationTree(onlyWarnings) {
    const tree = Object.values(this.__treeDataStructure);
    const liMap = {};

    const filteredTree = Object.entries(tree[0])
      .flatMap(([claimName, lineItems]) => {
        const filteredLineItems = Object.entries(lineItems).filter(
          ([lineItemName, lineItem]) =>
            !onlyWarnings ||
            lineItem.lineItemStatus === 'Warning' ||
            hasErrorMessage(lineItem.errorMessage) ||
            lineItemName === this.__currentlySelected,
        );

        if (
          !filteredLineItems.length &&
          claimName !== this.__currentlySelected
        ) {
          return [];
        }
        liMap[claimName] = claimName;
        return [
          claimName,
          filteredLineItems.map(lineItemEntries => {
            liMap[lineItemEntries[0]] = claimName;
            return lineItemEntries[0];
          }),
        ];
      })
      .flat();

    return {
      liMap,
      filteredTree,
    };
  }

  __getAllLineItems() {
    return this.reportData.claims.flatMap(claim =>
      claim.lineItems.map(lineItem => ({
        ...lineItem,
        claimReportId: claim.claimReportId,
      })),
    );
  }

  __triggerNavigation({ model, filteredLineItemIds, nodePath }) {
    this.__details = buildERADetailsModel({
      model,
      selectedNodeType: this.__selectedNodeType,
    });

    this.__filteredLineItemIds = filteredLineItemIds;

    this.onSelectLevel(filteredLineItemIds, this.__selectedNodeType);

    if (!this.__expandedDict[nodePath]) {
      this.__handlers.toggleNode(nodePath);
    }
  }

  __previousItem(onlyWarnings = true, skipClosed = false) {
    const { liMap, filteredTree } = this.__buildNavigationTree(onlyWarnings);
    const currentIndex = filteredTree.indexOf(this.__currentlySelected);

    const prevView = this.__handleItem(
      filteredTree,
      currentIndex,
      'previous',
      skipClosed,
    );

    this.__currentlySelected = prevView;

    if (currentIndex === -1) {
      this.__currentlySelected = filteredTree[filteredTree.length - 1];
    }

    if (currentIndex === 0) {
      this.__setEraData();
      this.__currentlySelected = `ERA - ${this.reportData.authEFT}`;
      return this.__buildSelectedEraLevelModel();
    }

    let model = {};
    let filteredLineItemIds;

    if (!prevView) {
      this.__selectedNodeType = ERA_DETAILS_TYPES.LINE_ITEM_LEVEL;
      model = this.__buildSelectedLineItemLevelModel(this.__currentlySelected);
      filteredLineItemIds = model.lineItemIds;
      this.__selectedItemId = model.lineItemIds[0];
      this.__selectedLineItemReportId = model.lineItemReportId;
      this.__selectedClaimReportId = model.claim.claimReportId;
    } else if (prevView === liMap[prevView]) {
      this.__selectedNodeType = ERA_DETAILS_TYPES.CLAIM_LEVEL;
      model = this.__buildSelectedClaimLevelModel(
        liMap[this.__currentlySelected],
      );

      filteredLineItemIds = model.lineItemIds;
      this.__selectedItemId = model.id;
      this.__selectedLineItemReportId = '';
      this.__selectedClaimReportId = model.claimReportId;
    } else {
      this.__selectedNodeType = ERA_DETAILS_TYPES.LINE_ITEM_LEVEL;
      model = this.__buildSelectedLineItemLevelModel(this.__currentlySelected);
      filteredLineItemIds = model.lineItemIds;
      this.__selectedItemId = model.lineItemIds[0];
      this.__selectedLineItemReportId = model.lineItemReportId;
      this.__selectedClaimReportId = model.claim.claimReportId;
    }

    const nodePath = [
      `ERA - ${this.reportData.authEFT}.${liMap[this.__currentlySelected]}`,
    ];

    return this.__triggerNavigation({
      model,
      filteredLineItemIds,
      nodePath,
    });
  }

  __currentItem() {
    const baseNodePath = `ERA - ${this.reportData.authEFT}`;
    const selectedNode = this.__currentlySelected;
    const claimReportId = this.__selectedClaimReportId;

    const nodePathMap = {
      [ERA_DETAILS_TYPES.ERA_LEVEL]: [baseNodePath],
      [ERA_DETAILS_TYPES.CLAIM_LEVEL]: [baseNodePath, selectedNode],
      [ERA_DETAILS_TYPES.LINE_ITEM_LEVEL]: [
        baseNodePath,
        claimReportId,
        selectedNode,
      ],
    };

    this.__handlers.selectKey({
      key: selectedNode,
      nodePath: nodePathMap[this.__lastSelectedNodeType],
    });
  }

  __getClaim(filteredTree, currentIndex, direction) {
    let startIndex;
    let endCondition;

    if (currentIndex < 0 || currentIndex >= filteredTree.length) {
      return null;
    }

    if (direction === 'previous') {
      startIndex = currentIndex - 1;
      endCondition = -1;
    } else {
      startIndex = currentIndex + 1;
      endCondition = filteredTree.length;
    }

    for (
      let i = startIndex;
      i !== endCondition;
      direction === 'previous' ? i-- : i++
    ) {
      if (filteredTree[i].startsWith('Claim')) {
        return filteredTree[i];
      }
    }
    return null;
  }

  isClaimLevel() {
    return this.__selectedNodeType === ERA_DETAILS_TYPES.CLAIM_LEVEL;
  }

  isClaimExpanded() {
    const path = [`ERA - ${this.reportData.authEFT}`, this.__currentlySelected];
    return this.__expandedDict[path] || false;
  }

  __handleItem(filteredTree, currentIndex, direction, skipClosed) {
    const current = this.isClaimExpanded();
    const isClaimLevel = this.isClaimLevel();

    const newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;

    if (isClaimLevel && skipClosed && !current) {
      return this.__getClaim(filteredTree, currentIndex, direction);
    }

    return filteredTree[newIndex];
  }

  __openItem() {
    const nodePath = [
      `ERA - ${this.reportData.authEFT}`,
      this.__currentlySelected,
    ];
    this.__handlers.toggleNode(nodePath);
  }

  __nextItem(onlyWarnings = true, skipClosed = false) {
    const { liMap, filteredTree } = this.__buildNavigationTree(onlyWarnings);

    const currentIndex = filteredTree.indexOf(this.__currentlySelected);

    if (currentIndex === filteredTree.length - 1) {
      this.__setEraData();
    }

    const nextView = this.__handleItem(
      filteredTree,
      currentIndex,
      'next',
      skipClosed,
    );

    this.__currentlySelected = nextView;

    let model = {};
    let filteredLineItemIds;

    if (!nextView) {
      this.__selectedNodeType = ERA_DETAILS_TYPES.ERA_LEVEL;
      this.__selectedLineItemReportId = '';
      this.__selectedClaimReportId = '';
      model = this.__buildSelectedEraLevelModel();
      this.__currentlySelected = `ERA - ${this.reportData.authEFT}`;
    } else if (nextView === liMap[nextView]) {
      this.__selectedNodeType = ERA_DETAILS_TYPES.CLAIM_LEVEL;
      model = this.__buildSelectedClaimLevelModel(liMap[nextView]);
      filteredLineItemIds = model.lineItemIds;
      this.__selectedItemId = model.id;
      this.__selectedLineItemReportId = '';
      this.__selectedClaimReportId = model.claimReportId;
    } else {
      this.__selectedNodeType = ERA_DETAILS_TYPES.LINE_ITEM_LEVEL;
      model = this.__buildSelectedLineItemLevelModel(nextView);
      filteredLineItemIds = model.lineItemIds;
      this.__selectedItemId = model.lineItemIds[0];
      this.__selectedLineItemReportId = model.lineItemReportId;
      this.__selectedClaimReportId = model.claim.claimReportId;
    }

    const nodePath = `ERA - ${this.reportData.authEFT}.${
      liMap[this.__currentlySelected]
    }`;

    return this.__triggerNavigation({
      model,
      filteredLineItemIds,
      nodePath,
    });
  }

  __buildFilter() {
    const filterPosted = this.__showPosted ? '' : 'Posted';
    const filterReadyToPost = this.__showReadyToPost ? '' : 'Ready to Post';
    const filterWarning = this.__showWarning ? '' : 'Warning';
    const filterMatch = this.__isLineItemMatchInProgress ? 'Match' : '';

    return [filterPosted, filterReadyToPost, filterWarning, filterMatch].filter(
      filter => Boolean(filter),
    );
  }

  __setupChecked(filteredStatus, checked) {
    switch (filteredStatus) {
      case 'POSTED': {
        this.__showPosted = checked;
        break;
      }

      case 'READY_TO_POST': {
        this.__showReadyToPost = checked;
        break;
      }

      case 'WARNING': {
        this.__showWarning = checked;
        break;
      }

      default: {
        break;
      }
    }
  }

  __disableWarningButtons() {
    return (
      this.editMode === EDIT_MODE.MANUAL_POST ||
      this.__isLineItemMatchInProgress ||
      this.__eraStatus !== 'Warning' ||
      !Object.keys(this.__treeDataStructure).length ||
      !this.__showWarning
    );
  }

  __setEraData(filteredStatus, checked) {
    this.__setupChecked(filteredStatus, checked);
    const filteredStatuses = this.__buildFilter();
    this.__treeDataStructure = this.__buildTreeDataStructure(filteredStatuses);

    if (filteredStatus === ERA_STATUSES.MATCH) return;

    this.__lastSelectedNodeType = this.__selectedNodeType;
    this.__selectedNodeType = ERA_DETAILS_TYPES.ERA_LEVEL;

    this.__details = buildERADetailsModel({
      model: this.reportData,
      selectedNodeType: this.__selectedNodeType,
    });
  }

  __calculateProcessedAsPrimary() {
    if (this.__selectedNodeType === ERA_DETAILS_TYPES.ERA_LEVEL) {
      return this.reportData.claims.every(({ claimStatus }) =>
        PROCESSED_AS_PRIMARY_STATUSES.includes(claimStatus),
      );
    }

    const [{ claimStatus }] = this.__details;

    return PROCESSED_AS_PRIMARY_STATUSES.includes(claimStatus);
  }

  __buildSelectedEraLevelModel() {
    const {
      provider = {},
      paymentInfo = {},
      errorMessage,
      ...eraLevelDetails
    } = this.reportData;

    return {
      ...eraLevelDetails,
      ...paymentInfo,
      providerName: provider && provider.name,
      providerNPI: provider && provider.npi,
      errorMessage: eraLevelDetails.posted ? '' : errorMessage,
    };
  }

  __buildSelectedClaimLevelModel(claimKey) {
    const [claimLabel, claimReportId] = claimKey.split('/');
    const [, patientName] = claimLabel.split(' - ');

    const claims = this.reportData.claims || [];
    const {
      patientControlNumber,
      renderingProvider = {},
      paymentInfo = {},
      posted,
      ...claim
    } = claims.find(c => c.claimReportId === claimReportId) || {};

    const lineItemIds = claim.lineItems
      .map(({ id }) => id)
      .filter(lineItemId => Boolean(lineItemId));

    const errorMessage = getClaimErrorMessage(
      claim,
      this.reportData.errorMessage,
    );

    return {
      ...claim,
      patientName,
      claimId: patientControlNumber,
      providerName: renderingProvider && renderingProvider.name,
      providerNPI: renderingProvider && renderingProvider.npi,
      ...paymentInfo,
      lineItemIds: [...new Set(lineItemIds)],
      posted,
      errorMessage,
      patientControlNumber,
    };
  }

  __buildSelectedLineItemLevelModel(key) {
    const header = key.split('/');
    const lineItemReportId = header.pop();

    const claims = this.reportData.claims || [];
    const lineItems = claims.flatMap(({ lineItems: li, ...claim }) =>
      li.map(item => ({ ...item, claim })),
    );
    const lineItem = lineItems.find(
      ({ lineItemReportId: reportId }) => reportId === lineItemReportId,
    );
    const errorMessage =
      getLineItemErrorMessage(lineItem) || this.reportData.errorMessage || '';

    return {
      ...lineItem,
      errorMessage,
      lineItemIds: lineItem.id ? [lineItem.id] : [],
      claimStatus: lineItem.claim.claimStatus,
    };
  }

  __buildTreeDataStructure(filteredStatuses) {
    this.__icons = {};
    this.__visibleReportLineItems = {};

    const {
      authEFT = '',
      claims = [],
      errorMessage: eraErrorMessage,
    } = this.reportData;

    const eraKey = `ERA - ${authEFT}`;
    this.__eraStatus = getERAStatus(this.reportData);
    this.__icons[eraKey] = iconByStatus[this.__eraStatus];
    const orderedClaims = claims.toSorted(
      (a, b) =>
        a.patientName.localeCompare(b.patientName) ||
        a.patientControlNumber.localeCompare(b.patientControlNumber),
    );

    return {
      [eraKey]: {
        ...orderedClaims.reduce((accClaim, claim) => {
          const {
            errorMessage: claimErrorMessage,
            claimReportId,
            patientName,
            patientControlNumber = '',
            lineItems = [],
            patientMedicalRecordNumber,
          } = claim;

          const claimKey = `Claim - ${patientName} - ${patientControlNumber}/${claimReportId}`;
          const claimStatus = getClaimStatus(eraErrorMessage, claim);
          const isClaimFiltered = checkClaimFiltered({
            filteredStatuses,
            eraFailed: eraErrorMessage,
            claim,
          });

          if (isClaimFiltered) {
            return accClaim;
          }
          this.__icons[claimKey] = iconByStatus[claimStatus];

          accClaim[claimKey] = {
            ...lineItems.reduce(
              (
                acc,
                {
                  id,
                  procedureCode,
                  units,
                  lineItemReportId,
                  serviceDate,
                  paymentInfo,
                  renderingNPI,
                  patientAdjustments,
                  adjustments,
                  remarkCodes,
                  errorMessage,
                  posted,
                  isUpdating,
                },
              ) => {
                const lineItemPath = `${serviceDate} - ${procedureCode} - ${units} units/${lineItemReportId}`;
                const lineItemStatus = getLineItemStatus({
                  eraFailed: eraErrorMessage,
                  claimFailed: claimErrorMessage,
                  lineItemFailed: errorMessage,
                  posted,
                  isUpdating,
                });

                const isLineItemFiltered = filteredStatuses.includes(
                  ERA_STATUSES.MATCH,
                )
                  ? checkFilterByMatchStatus(patientMedicalRecordNumber, id)
                  : filteredStatuses.includes(lineItemStatus);

                if (isLineItemFiltered) {
                  return acc;
                }

                this.__icons[lineItemPath] = iconByStatus[lineItemStatus];
                this.__visibleReportLineItems = {
                  ...this.__visibleReportLineItems,
                  [lineItemReportId]: {
                    key: lineItemPath,
                    nodePath: [eraKey, claimKey, lineItemPath],
                  },
                };

                return {
                  ...acc,
                  [lineItemPath]: {
                    lineItemStatus,
                    errorMessage,
                    serviceDate,
                    procedureCode,
                    units,
                    paymentInfo,
                    renderingNPI,
                    patientAdjustments,
                    adjustments,
                    remarkCodes,
                  },
                };
              },
              {},
            ),
          };

          return accClaim;
        }, {}),
      },
    };
  }

  __updateReportData() {
    const hasLineItemsToBeMatched = Object.keys(
      this.__nextMatchLineItem,
    ).length;

    if (this.__isLineItemMatchInProgress && !hasLineItemsToBeMatched) {
      this.__isLineItemMatchInProgress = false;
      this.onStopMatch();
    }

    this.__setEraData();
    this.__currentItem();

    if (!this.__isLineItemMatchInProgress) return;

    const nextMatchLineItemNodeData = this.__nextMatchLineItem;
    this.__handlers.selectKey({
      ...nextMatchLineItemNodeData,
      ignoreMatchInProgress: true,
    });

    this.__setUpMatchLineItem();
  }

  __checkCancelMatchInProgress(changedProps) {
    if (!changedProps.has('editMode')) return;
    if (this.editMode === EDIT_MODE.MATCH) return;
    if (!this.__isLineItemMatchInProgress) return;
    this.__isLineItemMatchInProgress = false;
    this.onStopMatch();
  }

  update(changedProps) {
    if (changedProps.has('reportData') && Object.keys(this.reportData).length) {
      this.__updateReportData();
    }

    if (changedProps.has('__details')) {
      this.__processedAsPrimary = this.__details.length
        ? this.__calculateProcessedAsPrimary()
        : false;
    }

    if (changedProps.has('__treeDataStructure') && !this.__currentlySelected) {
      this.__setInitialERALevel();
    }

    this.__checkCancelMatchInProgress(changedProps);

    super.update(changedProps);
  }

  __renderUpdateStatusToPostedButton() {
    if (this.__details?.length) {
      const [{ status = null, isUpdating }] = this.__details;

      const shouldDisableButton = this.editMode === EDIT_MODE.MANUAL_POST;
      const shouldNotRender =
        isUpdating ||
        this.__isLineItemMatchInProgress ||
        ![ERA_STATUSES.READY_TO_POST, ERA_STATUSES.WARNING].includes(status);

      return !shouldNotRender
        ? html`
            <neb-button-action
              id="${ELEMENTS.updateStatusToPostedButton.id}"
              label="Mark As Posted"
              leadingIcon="loop"
              ?disabled="${shouldDisableButton}"
              .onClick="${this.__handlers.updateERAPosted}"
            ></neb-button-action>
          `
        : '';
    }

    return '';
  }

  __renderResetStatusButton() {
    if (this.__selectedNodeType === ERA_DETAILS_TYPES.LINE_ITEM_LEVEL) {
      if (this.__processedAsPrimary) {
        const [{ isUpdating }] = this.__details;
        const shouldNotRender = isUpdating || this.__isLineItemMatchInProgress;
        const shouldDisableButton = this.editMode === EDIT_MODE.MANUAL_POST;
        return !shouldNotRender
          ? html`
              <neb-button-action
                id="${ELEMENTS.resetStatusButton.id}"
                label="Reset Status"
                leadingIcon="loop"
                ?disabled="${shouldDisableButton}"
                .onClick="${this.__handlers.resetStatus}"
              ></neb-button-action>
            `
          : '';
      }
    }
    return '';
  }

  __renderUpdateStatusButtons() {
    return html`
      <div class="update-status">
        <strong>Update Status</strong>
        <div class="update-status-buttons">
          ${this.__renderUpdateStatusToPostedButton()}
          ${this.__renderResetStatusButton()}
        </div>
      </div>
    `;
  }

  __checkPostButtonDisabled(isUpdating, lineItemIds) {
    return (
      isUpdating ||
      this.editMode === EDIT_MODE.MANUAL_POST ||
      this.__isLineItemMatchInProgress ||
      !this.__processedAsPrimary ||
      (this.__filteredLineItemIds &&
        !lineItemIds.includes(this.__filteredLineItemIds[0]))
    );
  }

  __isPostButtonDisabled({ statusMessage, status, lineItemIds, isUpdating }) {
    const claim = this.reportData.claims.find(
      ({ claimReportId }) => claimReportId === this.__selectedClaimReportId,
    );

    if (
      checkRenderPostButtonForWrittenOff({
        statusMessage,
        selectedNodeType: this.__selectedNodeType,
        processedAsPrimary: this.__processedAsPrimary,
        claim,
      })
    ) {
      return false;
    }

    if (this.__checkPostButtonDisabled(isUpdating, lineItemIds)) {
      return true;
    }

    return checkDisablePostButtonWhenInsufficientPaymentBalance({
      availableToAllocate: this.__isERALevelAvailableToAllocate,
      status,
      hasOwlRemitOffsetEnhancementsFF: this.hasOwlRemitOffsetEnhancementsFF,
      selectedNodeType: this.__selectedNodeType,
      statusMessage,
    });
  }

  __renderReverseButton() {
    const reverseButtonEnabled = checkReversalButtonEnabled(
      this.__details[0],
      this.__selectedNodeType,
      this.__hasRcmEraReversalsFF,
    );
    return reverseButtonEnabled
      ? html`
          <neb-button-action
            id="${ELEMENTS.reverseButton.id}"
            class="line-item-button"
            label="Reverse"
            leadingIcon="undo"
            .onClick="${this.__handlers.reverseClaim}"
          ></neb-button-action>
        `
      : '';
  }

  __renderPostButton() {
    if (!this.__details.length) return '';

    const [{ status, statusMessage, isUpdating }] = this.__details;
    const { lineItemIds = [] } = this.model;

    const shouldNotRender = this.__isPostButtonDisabled({
      statusMessage,
      status,
      lineItemIds,
      isUpdating,
    });

    const levelDescription =
      LEVEL_DESCRIPTION_BY_SELECTED_NODE_TYPE[this.__selectedNodeType] || '';

    return !shouldNotRender
      ? html`
          <neb-button-action
            id="${ELEMENTS.postButton.id}"
            class="line-item-button"
            label="Post ${levelDescription}"
            leadingIcon="priceCheck"
            .onClick="${this.__handlers.postERA}"
          ></neb-button-action>
        `
      : '';
  }

  __renderMergeButton() {
    const checkParams = {
      selectedItem: this.__details,
      lineItems: this.__getAllLineItems(),
      editMode: this.editMode,
      isLineItemMatchInProgress: this.__isLineItemMatchInProgress,
      claimReportId: this.__selectedClaimReportId,
      claims: this.reportData.claims,
    };

    return checkRenderMergeButton(checkParams)
      ? html`
          <neb-button-action
            id="${ELEMENTS.mergeButton.id}"
            class="line-item-button"
            label="Merge"
            leadingIcon="merge"
            .onClick="${() =>
              this.__handlers.mergeERA({
                lineItemReportId: this.__selectedLineItemReportId,
                claimReportId: this.__selectedClaimReportId,
              })}"
          ></neb-button-action>
        `
      : '';
  }

  __renderMatchButton() {
    const buttonEnabled =
      checkMatchButtonEnabled({
        item: this.__details[0],
        nodeType: this.__selectedNodeType,
        claims: this.reportData.claims,
      }) && !this.__isLineItemMatchInProgress;

    const label =
      this.__selectedNodeType === ERA_DETAILS_TYPES.LINE_ITEM_LEVEL
        ? MATCH_CHARGE
        : MATCH_CLAIM;

    return buttonEnabled
      ? html`
          <neb-button-action
            id="${ELEMENTS.matchButton.id}"
            class="line-item-button"
            label="${label}"
            leadingIcon="matchCharge"
            .onClick="${this.__handlers.matchSelectedItem}"
          ></neb-button-action>
        `
      : '';
  }

  __renderManualPostButton() {
    const buttonEnabled =
      this.editMode === EDIT_MODE.DISABLED &&
      checkManualPostButtonEnabled(
        this.__details[0],
        this.__selectedNodeType,
        this.__hasRcmEraReversalsFF,
      );

    const levelDescription =
      this.__selectedNodeType === ERA_DETAILS_TYPES.LINE_ITEM_LEVEL
        ? ' Line Item'
        : '';

    return buttonEnabled
      ? html`
          <div class="button-flex">
            <div></div>
            <neb-button-action
              id="${ELEMENTS.manualPostButton.id}"
              class="line-item-button"
              label="Manually Post${levelDescription}"
              leadingIcon="priceCheck"
              .onClick="${this.__handlers.startManualPost}"
            ></neb-button-action>
          </div>
        `
      : '';
  }

  __getClaimStatus() {
    const { claims: reportClaims = [] } = this.reportData;
    const { claims } = this.model;
    const claim = reportClaims.find(
      ({ claimReportId }) => claimReportId === this.__selectedClaimReportId,
    );

    return claims?.find(({ id }) => id === claim.id)?.status;
  }

  __renderMarkAsDeniedButton() {
    const claimStatus = this.__getClaimStatus();
    const buttonEnabled =
      this.editMode === EDIT_MODE.DISABLED &&
      checkMarkAsDeniedButtonEnabled(
        this.__details[0],
        this.__selectedNodeType,
        claimStatus,
      );

    return buttonEnabled
      ? html`
          <div class="button-flex">
            <div></div>
            <neb-button-action
              id="${ELEMENTS.markAsDenied.id}"
              label="Mark As Denied"
              leadingIcon="paperClaims"
              .onClick="${this.__handlers.markAsDenied}"
            ></neb-button-action>
          </div>
        `
      : '';
  }

  __renderActionButton(action) {
    if (action === ERA_REPORT_ACTIONS.MARK_AS_DENIED) {
      return this.__renderMarkAsDeniedButton();
    }

    if (action === ERA_REPORT_ACTIONS.MANUAL_POST) {
      return this.__renderManualPostButton();
    }

    if (action === ERA_REPORT_ACTIONS.MATCH) {
      return this.__renderMatchButton();
    }

    if (action === ERA_REPORT_ACTIONS.MERGE) {
      return this.__renderMergeButton();
    }

    if (action === ERA_REPORT_ACTIONS.POST) {
      return this.__renderPostButton();
    }

    if (action === ERA_REPORT_ACTIONS.REVERSE) {
      return this.__renderReverseButton();
    }

    return '';
  }

  __renderLeftActionButtons() {
    const actions = getLevelActions({
      level: this.__selectedNodeType,
      hasRcmEraReversalsFF: this.__hasRcmEraReversalsFF,
    });

    return html` ${actions.map(action => this.__renderActionButton(action))} `;
  }

  __renderButtons() {
    return html`
      <div class="buttons-container">${this.__renderUpdateStatusButtons()}</div>
    `;
  }

  __renderCheckboxes() {
    const disabled =
      this.editMode === EDIT_MODE.MANUAL_POST ||
      this.__isLineItemMatchInProgress;
    return html`
      <div class="button-container">
        <div class="checkbox-spacing">
          <neb-checkbox
            id="${ELEMENTS.postedCheckbox.id}"
            class="checkbox"
            name="POSTED"
            label="Posted"
            .onChange="${this.__handlers.filter}"
            ?checked="${this.__showPosted}"
            .disabled="${disabled}"
          ></neb-checkbox>
        </div>
        <div class="checkbox-spacing">
          <neb-checkbox
            id="${ELEMENTS.readyCheckbox.id}"
            class="checkbox"
            name="READY_TO_POST"
            label="Ready To Post"
            .onChange="${this.__handlers.filter}"
            ?checked="${this.__showReadyToPost}"
            .disabled="${disabled}"
          ></neb-checkbox>
        </div>
        <div class="checkbox-spacing">
          <neb-checkbox
            id="${ELEMENTS.warningCheckbox.id}"
            class="checkbox"
            name="WARNING"
            label="Warning"
            .onChange="${this.__handlers.filter}"
            ?checked="${this.__showWarning}"
            .disabled="${disabled}"
          ></neb-checkbox>
        </div>
      </div>
    `;
  }

  __renderTreeView() {
    return html`
      <div class="tree-container">
        <neb-tree-view
          id="${ELEMENTS.eraTreeView.id}"
          class="tree-view"
          .data="${this.__treeDataStructure}"
          .icons="${this.__icons}"
          .showNodePreview="${false}"
          .onSelectKey="${this.__handlers.selectKey}"
          .selectedKey="${this.__currentlySelected}"
          .expandedDict="${this.__expandedDict}"
          .onToggleNode="${this.__handlers.toggleNode}"
        ></neb-tree-view>
      </div>
    `;
  }

  __renderEraAdjustments() {
    if (
      this.__selectedNodeType === ERA_DETAILS_TYPES.ERA_LEVEL &&
      this.__details.length
    ) {
      const [{ eraAdjustments = [], reversals }] = this.__details;

      return html`
        <div class="era-adjustments-container">
          <div class="total-reversals-container">
            <span class="total-reversals-title">${TOTAL_REVERSALS}</span>
            <span
              class="total-reversals-value"
              id="${ELEMENTS.totalReversals.id}"
              >$${reversals}</span
            >
          </div>

          <neb-table
            id="${ELEMENTS.eraAdjustmentsTable.id}"
            .model="${eraAdjustments}"
            .config="${ERA_ADJUSTMENTS_TABLE_CONFIG}"
            emptyMessage="${NO_REMIT_LEVEL_ADJUSTMENTS_PROVIDED}"
            class="era-adjustments-table"
            preview
            allowPropagation
          ></neb-table>
        </div>
      `;
    }

    return '';
  }

  __renderClaimDetails() {
    return this.isClaimLevel()
      ? html`
          <neb-table
            id="${ELEMENTS.claimDetailsTable.id}"
            .model="${this.__details}"
            .config="${CLAIM_DETAILS_TABLE_CONFIG}"
            class="era-details"
            emptyMessage="Select an item to see details."
            preview
            allowPropagation
          ></neb-table>
        `
      : '';
  }

  __renderPaymentDetails() {
    return html`
      <neb-table-payment-details
        id="${ELEMENTS.paymentDetailsTable.id}"
        .model="${this.__details}"
        .config="${ERA_PAYMENT_DETAILS_TABLE_CONFIG()[this.__selectedNodeType]}"
        class="era-payment-details"
      ></neb-table-payment-details>
    `;
  }

  __renderEraTable() {
    return html`
      ${this.__renderClaimDetails()}
      <neb-table
        id="${ELEMENTS.eraDetailsTable.id}"
        .model="${this.__details}"
        .config="${ERA_DETAILS_TABLE_CONFIG()[this.__selectedNodeType]}"
        class="era-details"
        emptyMessage="Select an item to see details."
        preview
        allowPropagation
      ></neb-table>

      ${this.__renderPaymentDetails()} ${this.__renderEraAdjustments()}
    `;
  }

  __shouldRenderStatus() {
    if (this.__details[0].status) {
      return html`
        <div class="status-container">
          <span class="status-title">Status: </span
          ><span id="${ELEMENTS.status.id}">${this.__details[0].status}</span>
          ${this.__renderLeftActionButtons()}
        </div>
      `;
    }
    return html``;
  }

  __renderStatusMessage() {
    const { statusMessage } = this.__details[0];
    const errorMessages = Array.isArray(statusMessage)
      ? statusMessage
      : [statusMessage];

    return html`
      <div class="status-messages">
        ${map(
          errorMessages,
          (message, index) => html`
            <span id="status-message-${index}">${message}</span>
          `,
        )}
      </div>
    `;
  }

  __shouldRenderStatusMessage() {
    if (hasErrorMessage(this.__details[0].statusMessage)) {
      return html`
        <div class="status-message-container">
          <span class="status-title">Status Message: </span>
          ${this.__renderStatusMessage()}
        </div>
      `;
    }
    return html``;
  }

  __renderEraDetails() {
    return html`
      <div class="era-details-plus">
        ${this.__shouldRenderStatus()}${this.__shouldRenderStatusMessage()}
      </div>
    `;
  }

  __renderEmptyEraDetails() {
    return html` <div class="era-details-plus"></div> `;
  }

  __renderTableView() {
    return this.__details.length
      ? html`
          <div class="level-details">
            ${this.__renderEraDetails()}${this.__renderEraTable()}
          </div>
        `
      : html`
          <div class="level-details">
            ${this.__renderEmptyEraDetails()}${this.__renderEraTable()}
          </div>
        `;
  }

  __renderReprocessButton() {
    const [{ isUpdating } = {}] = this.__details;
    const shouldNotRender = isUpdating || this.__isLineItemMatchInProgress;
    const shouldDisableButton = this.editMode === EDIT_MODE.MANUAL_POST;
    return !shouldNotRender
      ? html`
          <div class="button-flex">
            <div class="title-container"><b>ERA Report</b></div>
            <div class="button-action-container">
              <neb-button-action
                id="${ELEMENTS.reprocessButton.id}"
                label="Reprocess"
                leadingIcon="refresh"
                ?disabled="${shouldDisableButton}"
                .onClick="${this.onReprocessERA}"
              ></neb-button-action>
            </div>
          </div>
        `
      : '';
  }

  __renderPreviousWarning() {
    return html`
      <neb-button-action
        id="${ELEMENTS.previousWarningIcon.id}"
        class="warning-icons previous-warning-icon"
        label=""
        leadingIcon="${ELEMENTS.previousWarningIcon.icon}"
        ?disabled="${this.__disableWarningButtons()}"
        .onClick="${this.__handlers.previousItem}"
      ></neb-button-action>

      <neb-button-action
        id="${ELEMENTS.previousWarningButton.id}"
        label="${ELEMENTS.previousWarningButton.text}"
        class="previous-warning-button"
        leadingIcon=""
        ?disabled="${this.__disableWarningButtons()}"
        .onClick="${this.__handlers.previousItem}"
      ></neb-button-action>
    `;
  }

  __renderNextWarning() {
    return html`
      <neb-button-action
        id="${ELEMENTS.nextWarningButton.id}"
        label="${ELEMENTS.nextWarningButton.text}"
        class="next-warning-button"
        leadingIcon=""
        ?disabled="${this.__disableWarningButtons()}"
        .onClick="${this.__handlers.nextItem}"
      ></neb-button-action>

      <neb-button-action
        id="${ELEMENTS.nextWarningIcon.id}"
        class="warning-icons next-warning-icon"
        label=""
        leadingIcon="${ELEMENTS.nextWarningIcon.icon}"
        ?disabled="${this.__disableWarningButtons()}"
        .onClick="${this.__handlers.nextItem}"
      ></neb-button-action>
    `;
  }

  render() {
    return html`
      <div class="container">
        <div class="header-container">
          <div class="view-header">
            ${this.__renderReprocessButton()} ${this.__renderCheckboxes()}
          </div>

          ${this.__renderButtons()}
          <div class="warning-buttons">
            ${this.__renderPreviousWarning()}${this.__renderNextWarning()}
          </div>
        </div>

        <div class="era-report-container">
          ${this.__renderTreeView()} ${this.__renderTableView()}
        </div>
      </div>
    `;
  }
}

customElements.define('neb-era-report-data', NebEraReportData);
