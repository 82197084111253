import {
  Method,
  RESPONSE_TYPE,
} from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

const PATH = '/api/v1/tenants/:tenantId/invoices/ar-hub';

export const billingApiClient = new ApiClientV2({ microservice: 'billing' });

export const getArHubInvoices = async (body, optOutLoadingIndicator = true) => {
  const { data } = await billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.POST,
    body,
    path: `${PATH}/ar-hub-invoices`,
    version: 1,
  });

  return data;
};

export const saveArHubCustomView = (body, optOutLoadingIndicator = true) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.POST,
    body,
    path: `${PATH}/ar-hub-view`,
    version: 1,
    responseType: RESPONSE_TYPE.RAW,
  });

export const getArHubCustomViews = (optOutLoadingIndicator = true) =>
  billingApiClient.makeRequest({
    optOutLoadingIndicator,
    method: Method.GET,
    path: `${PATH}/ar-hub-view`,
    version: 1,
  });
