import '../inputs/neb-select';
import '../inputs/neb-textarea';
import '../neb-radio-button';
import '../tables/neb-table';

import { navigate } from '@neb/router';
import { css, html } from 'lit';

import { openPayment } from '../../../../../src/utils/payment-util';
import { fetch } from '../../../../neb-api-client/src/billing-codes';
import { getLedgerInvoiceItems } from '../../../../neb-api-client/src/invoice-api-client';
import { getPaymentDetail } from '../../../../neb-api-client/src/payments-api-client';
import { openDirtyPopup } from '../../../../neb-popup';
import { store } from '../../../../neb-redux/neb-redux-store';
import { CSS_SPACING } from '../../../../neb-styles/neb-variables';
import { CODE_DISCOUNTS } from '../../../../neb-utils/constants';
import { parseDate } from '../../../../neb-utils/date-util';
import {
  formatPaidDiscountBreakdown,
  formatTaxBreakdown,
} from '../../../../neb-utils/discounts';
import {
  currencyToCents,
  formatDollarAmount,
  objToName,
} from '../../../../neb-utils/formatters';
import {
  currency,
  numberNoLeadingZeroAllowZero,
} from '../../../../neb-utils/masks';
import * as selectors from '../../../../neb-utils/selectors';
import { OVERLAY_KEYS, openOverlay } from '../../utils/overlay-constants';
import '../controls/neb-tab-group';
import { POPOVER_POSITION } from '../neb-date-picker';
import '../tables/neb-table-discount-allocation';
import '../tables/neb-table-discount-transaction';

import NebForm, { ELEMENTS as ELEMENTS_BASE } from './neb-form';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  discountType: {
    id: 'discount-type',
  },
  discountBalanceTable: {
    id: 'discount-balance-table',
  },
  dollarRadioButton: {
    id: 'dollar-radio-button',
  },
  dollarInputField: {
    id: 'dollar-input-field',
  },
  percentRadioButton: {
    id: 'percent-radio-button',
  },
  percentInputField: {
    id: 'percent-input-field',
  },
  noteField: {
    id: 'note-field',
  },
  tabGroup: {
    id: 'tab-group',
  },
  outstandingLabel: {
    id: 'outstanding-label',
  },
  dateOfTransactionFrom: {
    id: 'date-of-transaction-from',
  },
  dateOfTransactionTo: {
    id: 'date-of-transaction-to',
  },
  outstandingChargesTable: {
    id: 'outstanding-charges-table',
  },
  allocatedLabel: {
    id: 'allocated-label',
  },
  allocatedChargesTable: {
    id: 'allocated-charges-table',
  },
  removeAllocationButton: {
    id: 'remove-allocation-button',
  },
  transactionDetailsTable: {
    id: 'transaction-details-table',
  },
  voidDetailsTable: {
    id: 'void-details-table',
  },
  providerDropdown: { id: 'provider-dropdown' },
  locationDropdown: { id: 'location-dropdown' },
};

export const DISCOUNT_TYPES = {
  FIXED_DOLLAR: 'fixedDollar',
  PERCENTAGE: 'percentage',
};

const DISCOUNT_CONFIG = [
  {
    key: 'discountAmount',
    label: 'Discount Amount',
    flex: css`3 0 0`,
  },
  {
    key: 'discountAllocated',
    label: 'Discount Allocated',
    flex: css`3 0 0`,
  },
  {
    key: 'discountAvailable',
    label: 'Discount Available',
    flex: css`2 0 0`,
  },
];

const TX_CONFIG = [
  {
    key: 'transactionDate',
    label: 'Transaction Date',
    flex: css`2 0 0`,
    formatter: v => (v ? parseDate(v).format('MM/DD/YYYY') : ''),
  },
  {
    key: 'payer',
    label: 'Payer',
    flex: css`2 0 0`,
  },
  {
    key: 'patient',
    label: 'Patient',
    flex: css`2 0 0`,
  },
  {
    key: 'method',
    label: 'Method',
    flex: css`2 0 0`,
  },
  {
    key: 'postedBy',
    label: 'Posted By',
    flex: css`1 0 0`,
  },
];

const VOID_DETAILS_CONFIG = [
  {
    key: 'voidedDate',
    label: 'Voided Date',
    flex: css`3 0 0`,
    formatter: v => (v ? parseDate(v).format('MM/DD/YYYY') : ''),
  },
  {
    key: 'voidedBy',
    label: 'Voided By',
    flex: css`3 0 0`,
  },
  {
    key: 'voidReason',
    label: 'Void Reason',
    flex: css`3 0 0`,
  },
  {
    key: 'voidedAmount',
    label: 'Voided Amount',
    flex: css`3 0 0`,
    formatter: v => (v ? formatDollarAmount(v) : ''),
  },
];

const DEFAULT_AMOUNT = '$0.00';

const formatTotals = ledgerItems => {
  const totals = {
    dateOfService: '',
    patientBalance: 0,
    discountAllocated: 0,
    taxAmount: 0,
    newPatientBalance: 0,
  };

  ledgerItems.forEach(item => {
    if (item.paymentId) {
      totals.credit += item.amount;
    } else {
      totals.patientBalance += item.patientBalance;
      totals.discountAllocated += item.discountAllocated;
      totals.taxAmount += item.taxAmount;
      totals.newPatientBalance += item.newPatientBalance;
    }
  });

  return {
    dateOfService: 'Totals',
    patientBalance: formatDollarAmount(totals.patientBalance),
    discountAllocated: formatDollarAmount(totals.discountAllocated),
    taxAmount: formatDollarAmount(totals.taxAmount),
    newPatientBalance: formatDollarAmount(totals.newPatientBalance),
  };
};

export const OUTSTANDING_CHARGES_CONFIG = [
  {
    key: 'selected',
    label: '',
    flex: css`2`,
    formatter: (v, s) => ({ checked: v, disabled: s.discountDisabled }),
  },
  {
    key: 'dateOfService',
    label: 'DOS',
    flex: css`8`,
    formatter: v => (v ? parseDate(v).format('MM/DD/YYYY') : ''),
  },
  {
    key: 'procedure',
    label: 'Procedure',
    flex: css`6`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`9`,
  },
  {
    key: 'patientOwed',
    label: 'Patient Owed',
    flex: css`6`,
    formatter: v => formatDollarAmount(v),
  },
  {
    key: 'patientPaid',
    label: 'Patient Paid/ Other Discounts',
    flex: css`7`,
    formatter: (v, s) => ({
      value: formatDollarAmount(v),
      title: s.hasTaxDiscountFF
        ? formatPaidDiscountBreakdown(s.patientPaid, s.otherDiscounts)
        : '',
    }),
  },
  {
    key: 'paymentIds',
    label: 'Pymt ID(s)',
    flex: css`10`,
  },
  {
    key: 'patientBalance',
    label: 'Patient Balance',
    flex: css`10`,
    formatter: v => formatDollarAmount(v),
  },
  {
    key: 'discountAllocated',
    label: 'This Discount Allocated',
    flex: css`10`,
    formatter: v => formatDollarAmount(v),
  },
  {
    key: 'taxAmount',
    label: 'Tax',
    flex: css`3`,
    formatter: (
      v,
      {
        billedAmount,
        otherDiscounts,
        discountAllocated,
        adjustments,
        taxRate,
        taxName,
        hasTaxDiscountFF,
      },
    ) => ({
      value: formatDollarAmount(v),
      title: hasTaxDiscountFF
        ? formatTaxBreakdown({
            billedAmount,
            otherDiscounts,
            discountAllocated,
            adjustments,
            taxRate,
            taxName,
          })
        : '',
    }),
  },
  {
    key: 'newPatientBalance',
    label: 'New Patient Balance',
    flex: css`10`,
    formatter: v => formatDollarAmount(v),
  },
];

const ALLOCATED_CHARGES_CONFIG = [
  {
    key: 'selected',
    label: '',
    flex: css`2`,
    formatter: (v, s) => ({ checked: v, disabled: s.discountDisabled }),
  },
  {
    key: 'dateOfService',
    label: 'DOS',
    flex: css`8`,
    formatter: v => (v ? parseDate(v).format('MM/DD/YYYY') : ''),
  },
  {
    key: 'procedure',
    label: 'Procedure',
    flex: css`8`,
  },
  {
    key: 'description',
    label: 'Description',
    flex: css`10`,
  },
  {
    key: 'patientOwed',
    label: 'Patient Owed',
    flex: css`6`,
    formatter: v => formatDollarAmount(v),
  },
  {
    key: 'patientPaid',
    label: 'Patient Paid/ Other Discounts',
    flex: css`6`,
    formatter: (v, s) => ({
      value: formatDollarAmount(v),
      title: s.hasTaxDiscountFF
        ? formatPaidDiscountBreakdown(s.patientPaid, s.otherDiscounts)
        : '',
    }),
  },
  {
    key: 'paymentIds',
    label: 'Pymt ID(s)',
    flex: css`10`,
  },
  {
    key: 'discountAllocated',
    label: 'This Discount Allocated',
    flex: css`10`,
    formatter: v => formatDollarAmount(v),
  },
  {
    key: 'taxAmount',
    label: 'Tax',
    flex: css`3`,
    formatter: (
      v,
      {
        billedAmount,
        otherDiscounts,
        discountAllocated,
        adjustments,
        taxRate,
        taxName,
        hasTaxDiscountFF,
      },
    ) => ({
      value: formatDollarAmount(v),
      title: hasTaxDiscountFF
        ? formatTaxBreakdown({
            billedAmount,
            otherDiscounts,
            discountAllocated,
            adjustments,
            taxRate,
            taxName,
          })
        : '',
    }),
  },
  {
    key: 'newPatientBalance',
    label: 'Patient Balance',
    flex: css`10`,
    formatter: v => formatDollarAmount(v),
  },
];

const fixedAutoAmount = (totalRemaining, rowRemaining) => {
  if (rowRemaining <= totalRemaining) return rowRemaining;
  return totalRemaining > 0 ? totalRemaining : 0;
};

export const TABS = {
  ALLOCATED: 'allocated',
  OUTSTANDING: 'outstanding',
};

export default class NebFormDiscount extends NebForm {
  static get properties() {
    return {
      __discountCodeItems: Array,
      __openCharges: Array,
      __selectedTab: String,
      __totals: Object,
      __discountAmount: Number,
      __discountAllocated: Number,
      __discountRemaining: Number,
      __billingPermission: Boolean,
      hasTaxDiscountFF: Boolean,
      transactionDateFrom: Date,
      transactionDateTo: Date,
      patientId: String,
      paymentModel: Object,
      practiceUsers: Array,
      topData: Object,
      providerItems: Array,
      locationItems: Array,
      preferredProvider: Object,
      preferredLocation: Object,
      hasFitInvoiceOverlayPerformanceFF: Boolean,
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .fields-container-add {
          padding: 0 10px;
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          grid-template-rows: repeat(3, 1fr);
          grid-column-gap: 5px;
          grid-row-gap: 0px;
        }

        .fields-container-edit {
          padding: 0 10px;
          display: grid;
          grid-template-columns: 110px 30px 330px;
          grid-column-gap: 5px;
          grid-row-gap: 0px;
        }

        .combo-field {
          display: flex;
        }

        .discount-type-label {
          padding-left: 10px;
          margin-top: 10px;
          margin-right: 20px;
        }

        .label {
          grid-column: 1 / 3;
          padding-top: 16px;
        }

        .input {
          grid-column: 3 / 7;
          padding-top: 16px;
        }

        .input-edit {
          grid-column: 3;
          padding-top: 16px;
        }

        .percent {
          padding: 10px 5px;
        }

        .text-percent {
          width: 100%;
        }

        .ledger-note {
          padding: 0 ${CSS_SPACING} 72px;
          grid-column: 7 / 13;
          grid-row: 2 / 4;
        }

        .date-pickers {
          display: grid;
          padding: 0 ${CSS_SPACING};
          grid-template-columns: repeat(12, 1fr);
          grid-template-rows: repeat(1, 1fr);
          grid-column-gap: 20px;
          grid-row-gap: 0px;
        }

        .sub-title {
          padding: 0 ${CSS_SPACING};
        }

        .date-picker-from {
          width: 100%;
          grid-column: 1 / 4;
        }

        .date-picker-to {
          width: 100%;
          grid-column: 4 / 7;
        }

        .remove-allocation {
          width: max-content;
          padding-left: ${CSS_SPACING};
        }

        .dropdown-provider {
          padding-left: 10px;
          grid-column: 1 / 7;
        }

        .dropdown-location {
          padding: 0 ${CSS_SPACING};
          grid-column: 7 / 13;
        }
      `,
    ];
  }

  static createModel() {
    return {
      id: '',
      codeDiscountId: '',
      type: DISCOUNT_TYPES.PERCENTAGE,
      percentageAmount: '',
      fixedDollarAmount: 0,
      amount: 0,
      note: '',
      outstandingCharges: [],
      allocatedCharges: [],
      provider: '',
      location: '',
    };
  }

  initState() {
    super.initState();

    this.onFromDateChange = () => {};

    this.onToDateChange = () => {};

    this.onRemoveAllocation = () => {};

    this.__discountCodeItems = [];
    this.transactionDateFrom = null;
    this.transactionDateTo = null;
    this.__selectedTab = TABS.OUTSTANDING;
    this.__openCharges = [];
    this.__totals = {};
    this.__discountAmount = 0;
    this.__discountAllocated = 0;
    this.__discountRemaining = 0;
    this.__firstValidated = false;
    this.__billingPermission = false;
    this.hasTaxDiscountFF = false;

    this.patientId = '';
    this.paymentModel = {
      allocations: [],
      id: '',
      patientId: '',
      amount: 0,
      payerName: '',
      patientName: '',
      paymentMethod: '',
      postedById: '',
      transactionDate: null,
      available: 0,
    };

    this.topData = {};
    this.practiceUsers = [];

    this.providerItems = [];
    this.locationItems = [];
    this.allLocations = [];
    this.preferredProvider = {};
    this.preferredLocation = {};
    this.hasFitInvoiceOverlayPerformanceFF = false;

    this.onClickLink = () => {};
  }

  initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      toggle: ({ name, value }) => {
        if (value !== this.state.type) {
          this.state.outstandingCharges.forEach((_, index) => {
            if (this.state.outstandingCharges[index].selected) {
              this.handlers.clickCheckbox({
                name: `outstandingCharges.${index}.selected`,
                value: false,
              });
            }
          });

          this.formService.apply(name, value);

          if (value === DISCOUNT_TYPES.FIXED_DOLLAR) {
            this.formService.apply('percentageAmount', '');
          } else {
            this.formService.apply('fixedDollarAmount', DEFAULT_AMOUNT);
          }
          this.__discountAmount = 0;
          this.__recalcOutstandingTotals();
        }
      },
      outstandingChargesAllocationChange: e => {
        const row = e.name.split('.')[1];

        this.formService.apply(e.name, currencyToCents(e.value));

        let taxAmount = 0;

        if (this.hasTaxDiscountFF) {
          const { billedAmount, otherDiscounts, discountAllocated, taxRate } =
            this.model.outstandingCharges[row];
          const oldTaxAmount =
            (billedAmount - otherDiscounts - discountAllocated) *
            (taxRate / 100);

          taxAmount = Math.round(
            oldTaxAmount -
              (this.state.outstandingCharges[row].discountAllocated -
                this.model.outstandingCharges[row].discountAllocated) *
                (this.model.outstandingCharges[row].taxRate / 100),
          );

          this.formService.apply(
            `outstandingCharges.${row}.taxAmount`,
            taxAmount,
          );
        }

        const updatedNewPatientBalance =
          this.state.outstandingCharges[row].patientBalance -
          this.state.outstandingCharges[row].discountAllocated +
          this.model.outstandingCharges[row].discountAllocated +
          taxAmount;

        this.formService.apply(
          `outstandingCharges.${row}.newPatientBalance`,
          updatedNewPatientBalance,
        );

        this.__recalcOutstandingTotals();
        this.__validateFixedDollarAmount();
        this.__validateChargesDiscountAllocated('outstandingCharges');
      },
      allocatedChargesAllocationChange: e => {
        const row = e.name.split('.')[1];

        this.formService.apply(e.name, currencyToCents(e.value));

        let taxAmount = 0;

        if (this.hasTaxDiscountFF) {
          const { billedAmount, otherDiscounts, discountAllocated, taxRate } =
            this.model.allocatedCharges[row];
          const oldTaxAmount =
            (billedAmount - otherDiscounts - discountAllocated) *
            (taxRate / 100);

          taxAmount = Math.round(
            oldTaxAmount -
              (this.state.allocatedCharges[row].discountAllocated -
                this.model.allocatedCharges[row].discountAllocated) *
                (this.model.allocatedCharges[row].taxRate / 100),
          );

          this.formService.apply(
            `allocatedCharges.${row}.taxAmount`,
            taxAmount,
          );
        }

        const updatedNewPatientBalance =
          this.state.allocatedCharges[row].patientBalance -
          this.state.allocatedCharges[row].discountAllocated +
          this.model.allocatedCharges[row].discountAllocated +
          taxAmount;

        this.formService.apply(
          `allocatedCharges.${row}.newPatientBalance`,
          updatedNewPatientBalance,
        );

        this.__recalcAllocatedTotals();
        this.__validateChargesDiscountAllocated('allocatedCharges');
      },
      clickCheckbox: e => {
        const table = e.name.split('.')[0];
        const row = e.name.split('.')[1];

        this.formService.apply(e.name, e.value);

        if (table === 'allocatedCharges') {
          this.handlers.allocatedChargesAllocationChange({
            name: `allocatedCharges.${row}.discountAllocated`,
            value: formatDollarAmount(
              this.model.allocatedCharges[row].discountAllocated,
            ),
          });
        } else if (!this.state.id) {
          if (!e.value) {
            this.handlers.outstandingChargesAllocationChange({
              name: `outstandingCharges.${row}.discountAllocated`,
              value: '$0.00',
            });
          } else if (this.state.type === DISCOUNT_TYPES.FIXED_DOLLAR) {
            this.handlers.outstandingChargesAllocationChange({
              name: `outstandingCharges.${row}.discountAllocated`,
              value: formatDollarAmount(
                fixedAutoAmount(
                  this.__discountRemaining,
                  this.state.outstandingCharges[row].patientBalance,
                ),
              ),
            });
          } else {
            const calculatedDiscount = Math.round(
              this.state.outstandingCharges[row].patientBalance *
                ((this.state.percentageAmount || 0) * 0.01),
            );

            this.handlers.outstandingChargesAllocationChange({
              name: `outstandingCharges.${row}.discountAllocated`,
              value: formatDollarAmount(calculatedDiscount),
            });
          }
        } else {
          this.__resetDiscountAllocatedValue(row);
        }

        this.formService.validateKey(['percentageAmount']);
      },
      clickCharge: async (lineItemId, invoiceId) => {
        if (this.__dirty) {
          const proceed = await openDirtyPopup();

          if (!proceed) return;
          this.formService.reset();
        }

        let lineItemIds = [lineItemId];

        const patient = { id: this.patientId };

        if (invoiceId) {
          const ledgerInvoiceItems = await getLedgerInvoiceItems(invoiceId);
          lineItemIds = ledgerInvoiceItems.data.map(li => li.id);
        }

        const overlayKey = this.hasFitInvoiceOverlayPerformanceFF
          ? OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES_V2
          : OVERLAY_KEYS.LEDGER_VIEW_SELECTED_CHARGES;

        await openOverlay(overlayKey, {
          patient,
          lineItemIds,
          selectedIds: [],
        });

        this.onClickLink();
      },
      clickPayment: async paymentId => {
        if (this.__dirty) {
          const proceed = await openDirtyPopup();

          if (!proceed) return;
          this.formService.reset();
        }

        const payment = await getPaymentDetail(paymentId);

        if (!this.__billingPermission) {
          navigate('#/no-access');
        }

        if (payment.codeDiscountId) {
          await openOverlay(OVERLAY_KEYS.DISCOUNT, {
            patientId: this.patientId,
            id: paymentId,
          });
        } else {
          await openPayment({ payment, readonly: false });
        }

        this.onClickLink();
      },
      dollarChange: e => {
        this.formService.apply(e.name, e.value);
        this.__recalcOutstandingTotals();
        this.__validateFixedDollarAmount();
        this.__validateChargesDiscountAllocated('outstandingCharges');
      },
      percentChange: e => {
        if (e.value === this.state.percentageAmount) return;
        this.formService.apply(e.name, e.value);

        this.state.outstandingCharges.forEach((oc, idx) => {
          if (oc.selected) {
            this.handlers.clickCheckbox({
              name: `outstandingCharges.${idx}.selected`,
              value: true,
            });
          }
        });

        this.__recalcOutstandingTotals();
        this.__validateChargesDiscountAllocated('outstandingCharges');
      },
      changeFromDate: async ({ value }) => {
        this.transactionDateFrom = value !== null ? value.toISOString() : value;

        await this.onFromDateChange({
          value,
          outstandingCharges: this.state.outstandingCharges,
          codeDiscountId: this.state.codeDiscountId,
          note: this.state.note,
          type: this.state.type,
          percentageAmount: this.state.percentageAmount,
          fixedDollarAmount: currencyToCents(this.state.fixedDollarAmount),
        });
      },
      changeToDate: async ({ value }) => {
        this.transactionDateTo = value !== null ? value.toISOString() : value;

        await this.onToDateChange({
          value,
          outstandingCharges: this.state.outstandingCharges,
          codeDiscountId: this.state.codeDiscountId,
          note: this.state.note,
          type: this.state.type,
          percentageAmount: this.state.percentageAmount,
          fixedDollarAmount: currencyToCents(this.state.fixedDollarAmount),
        });
      },
      navigateToPatientLedger: async () => {
        if (this.__dirty) {
          const proceed = await openDirtyPopup();

          if (!proceed) return;
          this.onChangeDirty(false);
        }
        navigate(`/patients/${this.patientId}/ledger/activity/`);
      },
      removeAllocation: () => {
        this.onRemoveAllocation({
          selectedItems: this.state.allocatedCharges.filter(ac => ac.selected),
        });
      },
      selectTab: async selectedTab => {
        if (this.__selectedTab !== selectedTab) {
          if (this.__dirty) {
            const proceed = await openDirtyPopup();

            if (!proceed) return;
            this.formService.reset();
          }

          this.__selectedTab = selectedTab;

          if (this.__selectedTab === TABS.OUTSTANDING) {
            this.__recalcOutstandingTotals();
          } else {
            this.__recalcAllocatedTotals();
          }
        }
      },
    };
  }

  createSelectors() {
    return {
      children: {
        codeDiscountId: selectors.select(
          this.__discountCodeItems,
          this.__discountCodeItems.find(
            dc => dc.data.id === CODE_DISCOUNTS.GENERAL.id,
          ),
        ),
        percentageAmount: {
          validators: [
            {
              error: 'Must be 1-100',
              validate: v =>
                (v > 0 &&
                  v <= 100 &&
                  this.state.type === DISCOUNT_TYPES.PERCENTAGE) ||
                this.state.type === DISCOUNT_TYPES.FIXED_DOLLAR,
            },
            {
              error: 'Select at least one charge',
              validate: () =>
                this.state.type === DISCOUNT_TYPES.FIXED_DOLLAR ||
                !this.__firstValidated ||
                this.model.id ||
                this.state.outstandingCharges.filter(o => o.selected).length,
            },
            {
              error: 'Discount amount must be greater than $0.00',
              validate: () =>
                this.state.type === DISCOUNT_TYPES.FIXED_DOLLAR ||
                !this.__firstValidated ||
                this.model.id ||
                (this.state.outstandingCharges.filter(o => o.selected).length &&
                  this.__discountAmount > 0),
            },
          ],
        },
        fixedDollarAmount: selectors.currency({
          validators: [
            {
              error: 'Required',
              validate: v =>
                (currencyToCents(v) > 0 &&
                  this.state.type === DISCOUNT_TYPES.FIXED_DOLLAR) ||
                this.state.type === DISCOUNT_TYPES.PERCENTAGE,
            },
            {
              error: 'Total Discount Allocated cannot exceed discount',
              validate: () => this.__discountAllocated <= this.__discountAmount,
            },
          ],
        }),
        outstandingCharges: {
          unsafe: true,
          createItem: () => ({
            id: '',
            selected: false,
            invoiceId: '',
            dateOfService: null,
            procedure: '',
            description: '',
            patientOwed: 0,
            patientPaid: 0,
            otherDiscounts: 0,
            billedAmount: 0,
            taxAmount: 0,
            taxRate: 0,
            paymentIds: [],
            patientBalance: 0,
            discountAllocated: 0,
            newPatientBalance: 0,
          }),
          children: {
            $: {
              children: {
                paymentIds: {
                  unsafe: true,
                  clipPristine: true,
                },
                discountAllocated: {
                  validators: [
                    {
                      error: 'Cannot exceed balance',
                      validate: (v, keyPath, state) => {
                        if (!v) return true;

                        const idx = keyPath[1];

                        return (
                          v <=
                          state.outstandingCharges[idx].patientBalance +
                            this.model.outstandingCharges[idx].discountAllocated
                        );
                      },
                    },
                    {
                      error: this.state.id ? 'Cannot exceed discount' : ' ',
                      validate: () =>
                        this.__discountAllocated <= this.__discountAmount,
                    },
                  ],
                },
              },
            },
          },
        },
        allocatedCharges: {
          unsafe: true,
          children: {
            $: {
              children: {
                paymentIds: {
                  unsafe: true,
                  clipPristine: true,
                },
                discountAllocated: {
                  validators: [
                    {
                      error: 'Cannot exceed balance',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          v <=
                          state.allocatedCharges[idx].patientBalance +
                            this.model.allocatedCharges[idx].discountAllocated
                        );
                      },
                    },
                    {
                      error: 'Cannot exceed discount',
                      validate: (v, keyPath, state) => {
                        const idx = keyPath[1];

                        return (
                          !state.allocatedCharges[idx].selected ||
                          this.__discountAllocated <= this.__discountAmount
                        );
                      },
                    },
                  ],
                },
              },
            },
          },
        },
        provider: {
          ...selectors.select(this.providerItems, this.__setDefaultProvider()),
          validators: this.__setValidator(),
        },
        location: {
          ...selectors.select(this.locationItems, this.__setDefaultLocation()),
          validators: this.__setValidator(),
        },
      },
    };
  }

  save({ closeAfterSave = false } = {}) {
    this.__firstValidated = true;

    if (this.formService.validate()) {
      if (!this.model.id) {
        if (this.state.type === DISCOUNT_TYPES.FIXED_DOLLAR) {
          this.formService.apply(
            'amount',
            currencyToCents(this.state.fixedDollarAmount),
          );
        } else {
          const percentageAmount = this.state.outstandingCharges
            .filter(f => f.selected)
            .reduce((sum, a) => sum + a.discountAllocated, 0);

          this.formService.apply('amount', percentageAmount);
        }
      }

      const model = this.formService.build();

      let lineItems;

      if (this.__selectedTab === TABS.OUTSTANDING) {
        lineItems = model.outstandingCharges;
      } else {
        lineItems = model.allocatedCharges;
      }

      const saveModel = {
        ...model,
        lineItems: lineItems
          .filter(f => f.selected)
          .map(li => ({
            id: li.id,
            allocated: li.discountAllocated,
            ...(this.hasTaxDiscountFF ? { taxAmount: li.taxAmount } : {}),
          })),
      };

      this.onSave(saveModel, closeAfterSave);
    }
  }

  __setDefaultProvider() {
    return !this.model.id ? this.preferredProvider : '';
  }

  __setDefaultLocation() {
    return !this.model.id ? this.__getLocation() : '';
  }

  __setValidator() {
    return [
      {
        error: 'Required',
        validate: v => v,
      },
    ];
  }

  __getLocation() {
    const allLocations = this.allLocations.map(location => ({
      label: location.name,
      data: location,
    }));

    return this.model.id
      ? allLocations.find(location => location.data.id === this.model.location)
      : this.preferredLocation;
  }

  __validateChargesDiscountAllocated(table) {
    this.state[table].forEach((_, idx) => {
      this.formService.validateKey([table, idx, 'discountAllocated']);
    });
  }

  __validateFixedDollarAmount() {
    if (this.state.type === DISCOUNT_TYPES.FIXED_DOLLAR) {
      this.formService.validateKey(['fixedDollarAmount']);
    }
  }

  __resetDiscountAllocatedValue(row) {
    this.handlers.outstandingChargesAllocationChange({
      name: `outstandingCharges.${row}.discountAllocated`,
      value: formatDollarAmount(
        this.model.outstandingCharges[row].discountAllocated,
      ),
    });
  }

  async connectedCallback() {
    this.__discountCodeItems = await this.__fetchDiscountCodes();
    this.__setBillingPermission();

    super.connectedCallback();
  }

  firstUpdated() {
    this.reload();
    this.__sideLoading = false;
    this.__reloadPromise = null;

    if (this.model.id) {
      this.__selectedTab = TABS.ALLOCATED;

      this.enableSaveAndClose = true;
      this.cancelLabel = 'Save and Close';
      this.removeLabel = 'Cancel';
    }
  }

  __updateTopData() {
    if (this.topData.codeDiscountId) {
      this.formService.apply('codeDiscountId', this.topData.codeDiscountId);
      this.formService.apply('type', this.topData.type);
      this.formService.apply('note', this.topData.note);
      this.formService.apply('percentageAmount', this.topData.percentageAmount);
      this.formService.apply(
        'fixedDollarAmount',
        this.topData.fixedDollarAmount
          ? formatDollarAmount(this.topData.fixedDollarAmount)
          : '$0.00',
      );
    }

    this.__recalcOutstandingTotals();
  }

  updated(changed) {
    if (changed.has('paymentModel')) {
      if (this.paymentModel.id) {
        this.__recalcAllocatedTotals();
      }
    }

    if (changed.has('topData')) {
      this.__updateTopData();
    }

    super.updated(changed);
  }

  __formatDiscountCode(dc) {
    return {
      label: `${dc.code} - ${dc.description}`,
      data: {
        id: dc.id,
        code: dc.code,
        description: dc.description,
      },
    };
  }

  async __fetchDiscountCodes() {
    const discountCodes = await fetch('discount');

    return discountCodes.reduce((memo, dc) => {
      if (dc.active) memo.push(this.__formatDiscountCode(dc));

      return memo;
    }, []);
  }

  __renderPercentSection() {
    return this.paymentModel.id
      ? ''
      : html`
          <neb-radio-button
            id="${ELEMENTS.percentRadioButton.id}"
            class="label"
            label="Percentage"
            name="type"
            .value="${DISCOUNT_TYPES.PERCENTAGE}"
            .checked="${this.state.type === DISCOUNT_TYPES.PERCENTAGE}"
            .onChange="${this.handlers.toggle}"
          >
          </neb-radio-button>

          <div class="input combo-field">
            <neb-textfield
              id="${ELEMENTS.percentInputField.id}"
              class="text-percent"
              name="percentageAmount"
              helper=" "
              maxLength="3"
              .mask="${numberNoLeadingZeroAllowZero}"
              .inputMode="${'numeric'}"
              .value="${this.state.percentageAmount}"
              .error="${this.errors.percentageAmount}"
              .onChange="${this.handlers.percentChange}"
              ?disabled="${this.state.type !== DISCOUNT_TYPES.PERCENTAGE}"
            ></neb-textfield>
            <div class="percent">%</div>
          </div>
        `;
  }

  __renderDollarSection() {
    return this.paymentModel.id
      ? ''
      : html`
          <neb-radio-button
            id="${ELEMENTS.dollarRadioButton.id}"
            class="label"
            label="Fixed Dollar"
            name="type"
            .value="${DISCOUNT_TYPES.FIXED_DOLLAR}"
            .checked="${this.state.type === DISCOUNT_TYPES.FIXED_DOLLAR}"
            .onChange="${this.handlers.toggle}"
          >
          </neb-radio-button>

          <neb-textfield
            id="${ELEMENTS.dollarInputField.id}"
            class="input"
            name="fixedDollarAmount"
            helper="${''}"
            maxLength="13"
            .mask="${currency}"
            .inputMode="${'numeric'}"
            .value="${this.state.fixedDollarAmount}"
            .error="${this.errors.fixedDollarAmount}"
            .onChange="${this.handlers.dollarChange}"
            ?disabled="${this.state.type !== DISCOUNT_TYPES.FIXED_DOLLAR}"
          ></neb-textfield>
        `;
  }

  __renderDiscountBalanceSection() {
    return html`
      <neb-table
        id="${ELEMENTS.discountBalanceTable.id}"
        .config="${DISCOUNT_CONFIG}"
        .model="${[
          {
            discountAmount: formatDollarAmount(this.__discountAmount),
            discountAllocated: formatDollarAmount(this.__discountAllocated),
            discountAvailable: formatDollarAmount(this.__discountRemaining),
          },
        ]}"
        .emptyMessage=""
      ></neb-table>
    `;
  }

  __getProvider(id) {
    const user = this.practiceUsers.find(pv => pv.id === id);

    return user ? objToName(user.name, { reverse: true }) : '-';
  }

  __setBillingPermission() {
    const { id } = store.getState().session.item;
    const currentUser = this.practiceUsers.find(user => user.id === id);
    this.__billingPermission = currentUser.permissions.find(
      p => p.name === 'billing',
    ).access;
  }

  __isDiscountVoided() {
    return (
      this.paymentModel.status === 'Voided' && this.paymentModel.voidPayment.id
    );
  }

  __renderTxDetailsSection() {
    return this.paymentModel.id
      ? html`
          <neb-table-discount-transaction
            id="${ELEMENTS.transactionDetailsTable.id}"
            .config="${TX_CONFIG}"
            .model="${[
              {
                transactionDate: this.paymentModel.transactionDate,

                payer: this.paymentModel.payerName,
                patient: this.paymentModel.patientName,
                method: this.paymentModel.paymentMethod,
                postedBy: this.__getProvider(this.paymentModel.postedById),
              },
            ]}"
            .onSelectPatient="${this.handlers.navigateToPatientLedger}"
            .emptyMessage=""
          ></neb-table-discount-transaction>
        `
      : '';
  }

  __renderVoidDetailsSection() {
    return this.__isDiscountVoided()
      ? html`
          <neb-table
            id="${ELEMENTS.voidDetailsTable.id}"
            .config="${VOID_DETAILS_CONFIG}"
            .model="${[
              {
                voidedDate: this.paymentModel.voidPayment.createdAt,
                voidedBy: this.__getProvider(
                  this.paymentModel.voidPayment.voidedById,
                ),
                voidReason: `${
                  this.paymentModel.voidPayment.codeRefund.code
                } - ${this.paymentModel.voidPayment.codeRefund.description}`,
                voidedAmount: this.paymentModel.amount,
              },
            ]}"
            .emptyMessage=""
          ></neb-table>
        `
      : '';
  }

  __getTabs() {
    const outstandingTab = {
      id: TABS.OUTSTANDING,
      label: 'Outstanding Charges',
    };

    if (this.model.id) {
      return [
        { id: TABS.ALLOCATED, label: 'Allocated Charges' },
        outstandingTab,
      ];
    }

    return [outstandingTab];
  }

  __getActiveProviderItems() {
    return this.providerItems.filter(provider => provider.data.active);
  }

  __getActiveLocationItems() {
    return this.locationItems.filter(location => location.data.active);
  }

  __renderTabs() {
    return html`
      <neb-tab-group
        id="${ELEMENTS.tabGroup.id}"
        class="tabs"
        .items="${this.__getTabs()}"
        .selectedId="${this.__selectedTab}"
        .onSelect="${this.handlers.selectTab}"
      ></neb-tab-group>
    `;
  }

  __recalcAllocatedTotals() {
    const totals = {
      dateOfService: '',
      patientBalance: 0,
      discountAllocated: 0,
      taxAmount: 0,
      newPatientBalance: 0,
    };

    let fixedAllocatedAmount = 0;

    this.state.allocatedCharges.forEach(item => {
      totals.patientBalance += item.patientBalance;
      totals.discountAllocated += item.discountAllocated;
      totals.taxAmount += item.taxAmount;
      totals.newPatientBalance += item.newPatientBalance;

      fixedAllocatedAmount += item.discountAllocated;
    });

    this.__discountAmount =
      this.state.type === DISCOUNT_TYPES.FIXED_DOLLAR
        ? currencyToCents(this.state.fixedDollarAmount)
        : this.state.allocatedCharges.reduce(
            (sum, a) => sum + a.discountAllocated,
            0,
          );

    this.__discountAllocated = fixedAllocatedAmount;
    this.__discountRemaining = this.__isDiscountVoided()
      ? 0
      : this.__discountAmount - fixedAllocatedAmount;

    return {
      dateOfService: 'Totals',
      patientBalance: formatDollarAmount(totals.patientBalance),
      discountAllocated: formatDollarAmount(totals.discountAllocated),
      ...(this.hasTaxDiscountFF
        ? { taxAmount: formatDollarAmount(totals.taxAmount) }
        : {}),
      newPatientBalance: formatDollarAmount(totals.newPatientBalance),
    };
  }

  __recalcOutstandingTotals() {
    const totals = {
      dateOfService: '',
      patientBalance: 0,
      discountAllocated: 0,
      taxAmount: 0,
      newPatientBalance: 0,
    };

    let fixedAllocatedAmount = 0;

    this.state.outstandingCharges.forEach(item => {
      totals.patientBalance += item.patientBalance;
      totals.discountAllocated += item.discountAllocated;
      totals.taxAmount += item.taxAmount;
      totals.newPatientBalance += item.newPatientBalance;

      fixedAllocatedAmount += item.discountAllocated;
    });

    const missingFromOutstanding = this.model.allocatedCharges.filter(
      a => !this.state.outstandingCharges.map(o => o.id).includes(a.id),
    );

    missingFromOutstanding.forEach(item => {
      totals.discountAllocated += item.discountAllocated;

      fixedAllocatedAmount += item.discountAllocated;
    });

    this.__discountAmount =
      this.state.type === DISCOUNT_TYPES.FIXED_DOLLAR
        ? currencyToCents(this.state.fixedDollarAmount)
        : this.state.outstandingCharges.reduce(
            (sum, a) => sum + a.discountAllocated,
            0,
          );

    this.__discountAllocated = fixedAllocatedAmount;
    this.__discountRemaining = this.__isDiscountVoided()
      ? 0
      : this.__discountAmount - fixedAllocatedAmount;

    return {
      dateOfService: 'Totals',
      patientBalance: formatDollarAmount(totals.patientBalance),
      discountAllocated: formatDollarAmount(totals.discountAllocated),
      ...(this.hasTaxDiscountFF
        ? { taxAmount: formatDollarAmount(totals.taxAmount) }
        : {}),
      newPatientBalance: formatDollarAmount(totals.newPatientBalance),
    };
  }

  __renderTable() {
    if (this.__selectedTab === TABS.OUTSTANDING) {
      this.__totals = formatTotals(
        this.state.outstandingCharges,
        this.hasTaxDiscountFF,
      );

      const config = this.hasTaxDiscountFF
        ? OUTSTANDING_CHARGES_CONFIG
        : OUTSTANDING_CHARGES_CONFIG.filter(col => col.key !== 'taxAmount');

      return html`
        <neb-table-discount-allocation
          id="${ELEMENTS.outstandingChargesTable.id}"
          name="outstandingCharges"
          .config="${config}"
          .model="${this.state.outstandingCharges}"
          .errors="${this.errors.outstandingCharges}"
          .editMode="${!!this.state.id}"
          .type="${this.state.type}"
          .totals="${this.__totals}"
          .paymentId="${this.model.id}"
          .onClickCheckbox="${this.handlers.clickCheckbox}"
          .onAllocationChange="${this.handlers
            .outstandingChargesAllocationChange}"
          .onClickCharge="${this.handlers.clickCharge}"
          .onClickPayment="${this.handlers.clickPayment}"
        ></neb-table-discount-allocation>
      `;
    }
    this.__totals = formatTotals(
      this.state.allocatedCharges,
      this.hasTaxDiscountFF,
    );

    const config = this.hasTaxDiscountFF
      ? ALLOCATED_CHARGES_CONFIG
      : ALLOCATED_CHARGES_CONFIG.filter(col => col.key !== 'taxAmount');

    return html`
      <neb-table-discount-allocation
        id="${ELEMENTS.allocatedChargesTable.id}"
        name="allocatedCharges"
        .config="${config}"
        .model="${this.state.allocatedCharges}"
        .errors="${this.errors.allocatedCharges}"
        .type="${this.state.type}"
        .totals="${this.__totals}"
        .paymentId="${this.model.id}"
        .onClickCheckbox="${this.handlers.clickCheckbox}"
        .onAllocationChange="${this.handlers.allocatedChargesAllocationChange}"
        .onClickCharge="${this.handlers.clickCharge}"
        .onClickPayment="${this.handlers.clickPayment}"
      ></neb-table-discount-allocation>
    `;
  }

  __updateDateOfTransactionFromSelectable() {
    return date => {
      if (this.transactionDateTo) {
        return date.isSameOrBefore(
          parseDate(this.transactionDateTo).startOf('day'),
        );
      }

      return this.transactionDateTo === null;
    };
  }

  __updateDateOfTransactionToSelectable() {
    return date => {
      if (this.transactionDateFrom) {
        return date.isSameOrAfter(
          parseDate(this.transactionDateFrom).startOf('day'),
        );
      }

      return this.transactionDateFrom === null;
    };
  }

  __renderSubtitle() {
    if (this.__selectedTab === TABS.ALLOCATED) {
      return html`
        <neb-text class="sub-title" id="${ELEMENTS.allocatedLabel.id}">
          Select the charge(s) to remove the Allocation or edit Allocated
          amounts, if applicable.
        </neb-text>
      `;
    }
    return this.model.id
      ? html`
          <neb-text class="sub-title" id="${ELEMENTS.outstandingLabel.id}">
            Select the charge(s) applicable to the discount.
          </neb-text>
        `
      : html`
          <neb-text class="sub-title" id="${ELEMENTS.outstandingLabel.id}">
            Select the charge(s) applicable to the discount. If applying a fixed
            dollar discount, enter in the amount you want to allocate and click
            "Save".
          </neb-text>
        `;
  }

  __renderFilters() {
    return this.__selectedTab === TABS.OUTSTANDING
      ? html`
          <div class="date-pickers">
            <neb-date-picker
              id="${ELEMENTS.dateOfTransactionFrom.id}"
              name="dateOfTransactionFrom"
              class="date-picker-from"
              label="Transaction From Date"
              helperText=" "
              manualPopoverPosition="${POPOVER_POSITION.CENTER}"
              .selectedDate="${this.transactionDateFrom
                ? parseDate(this.transactionDateFrom)
                : null}"
              .isDateSelectable="${this.__updateDateOfTransactionFromSelectable()}"
              .onChange="${this.handlers.changeFromDate}"
              momentFlag
            ></neb-date-picker>

            <neb-date-picker
              id="${ELEMENTS.dateOfTransactionTo.id}"
              name="dateOfTransactionTo"
              class="date-picker-to"
              label="Transaction To Date"
              helperText=" "
              manualPopoverPosition="${POPOVER_POSITION.CENTER}"
              .selectedDate="${this.transactionDateTo
                ? parseDate(this.transactionDateTo)
                : null}"
              .isDateSelectable="${this.__updateDateOfTransactionToSelectable()}"
              .onChange="${this.handlers.changeToDate}"
              momentFlag
            ></neb-date-picker>
          </div>
        `
      : '';
  }

  __allocatedChargesNotSelected() {
    return !this.state.allocatedCharges.some(charge => charge.selected);
  }

  __renderRemoveAllocationButton() {
    return this.__selectedTab === TABS.OUTSTANDING
      ? ''
      : html`
          <neb-button
            id="${ELEMENTS.removeAllocationButton.id}"
            class="remove-allocation"
            role="outline"
            label="Remove Allocation"
            ?disabled="${this.__allocatedChargesNotSelected()}"
            .onClick="${this.handlers.removeAllocation}"
          ></neb-button>
        `;
  }

  __renderBottomHalf() {
    return html`
      ${this.__renderTabs()} ${this.__renderSubtitle()}
      ${this.__renderFilters()} ${this.__renderRemoveAllocationButton()}
      ${this.__renderTable()}
    `;
  }

  __renderNoteField() {
    return !this.state.id
      ? html`
          <neb-textarea
            id="${ELEMENTS.noteField.id}"
            class="ledger-note"
            label="Ledger Note"
            name="note"
            maxLength="1000"
            showCount
            .value="${this.state.note}"
            .onChange="${this.handlers.change}"
            ?disabled="${this.__isDiscountVoided()}"
          ></neb-textarea>
        `
      : '';
  }

  __renderDropdowns() {
    return html`
      <neb-select
        id="${ELEMENTS.providerDropdown.id}"
        class="dropdown-provider"
        name="provider"
        label="Provider"
        helper="Required"
        .error="${this.errors.provider}"
        .items="${this.__getActiveProviderItems()}"
        .value="${this.state.provider}"
        ?disabled="${this.__isDiscountVoided()}"
        .onChange="${this.handlers.change}"
      ></neb-select>
      <neb-select
        id="${ELEMENTS.locationDropdown.id}"
        class="dropdown-location"
        name="location"
        label="Location"
        helper="Required"
        .error="${this.errors.location}"
        .items="${this.__getActiveLocationItems()}"
        .value="${this.state.location}"
        ?disabled="${this.__isDiscountVoided()}"
        .onChange="${this.handlers.change}"
      ></neb-select>
    `;
  }

  __renderTopHalf() {
    return html`
      <div
        class="${!this.state.id
          ? 'fields-container-add'
          : 'fields-container-edit'}"
      >
        ${this.__renderDropdowns()}

        <div class="label discount-type-label">Discount Type</div>
        <neb-select
          id="${ELEMENTS.discountType.id}"
          class="${!this.state.id ? 'input' : 'input-edit'}"
          name="codeDiscountId"
          .value="${this.state.codeDiscountId}"
          .items="${this.__discountCodeItems}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.__isDiscountVoided()}"
        ></neb-select>

        ${this.__renderPercentSection()} ${this.__renderDollarSection()}
        ${this.__renderNoteField()}
      </div>

      ${this.__renderVoidDetailsSection()}
      ${this.__renderDiscountBalanceSection()}
      ${this.__renderTxDetailsSection()}
    `;
  }

  renderContent() {
    return html`
      ${this.__renderTopHalf()}
      ${this.__isDiscountVoided() ? '' : this.__renderBottomHalf()}
    `;
  }
}

customElements.define('neb-form-discount', NebFormDiscount);
