import { openPopup } from '@neb/popup';
import equal from 'fast-deep-equal';
import { LitElement, html, css } from 'lit';

import {
  getChangedLineItemsForAllocationV2,
  getPreviewCharges,
} from '../../../../packages/neb-api-client/src/allocation-api-client';
import {
  createEncounterHistory,
  startEncounterHistory,
} from '../../../../packages/neb-api-client/src/encounters-api-client';
import { formatLineItemBase } from '../../../../packages/neb-api-client/src/formatters/line-item-details';
import {
  getInvoiceSupplementalInfo,
  getLedgerInvoiceItems,
  updateInvoiceSupplementalInfo,
} from '../../../../packages/neb-api-client/src/invoice-api-client';
import {
  getLineItems,
  getLineItemDetails,
  saveLineItems,
} from '../../../../packages/neb-api-client/src/ledger/line-items';
import * as patientApiClient from '../../../../packages/neb-api-client/src/patient-api-client';
import { getPayerPlans } from '../../../../packages/neb-api-client/src/payer-plan-api-client';
import { getProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import {
  openError,
  openSuccess,
  openInfo,
} from '../../../../packages/neb-dialog/neb-banner-state';
import { TABS } from '../../../../packages/neb-lit-components/src/components/forms/neb-form-allocation-charges';
import { handleBulkAction } from '../../../../packages/neb-lit-components/src/utils/ledger-charges-bulk-actions';
import {
  openOverlay,
  OVERLAY_KEYS,
} from '../../../../packages/neb-lit-components/src/utils/overlay-constants';
import { openDirtyPopup } from '../../../../packages/neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../packages/neb-popup/src/renderer-keys';
import {
  endRequest,
  startRequest,
} from '../../../../packages/neb-redux/actions/global-loading-actions';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { LocationsService } from '../../../../packages/neb-redux/services/locations';
import { baseStyles } from '../../../../packages/neb-styles/neb-styles';
import { CSS_SPACING } from '../../../../packages/neb-styles/neb-variables';
import {
  CODE_QUALIFIER,
  REPORT_TYPE_CODE,
  REPORT_TRANSMISSION_CODE,
} from '../../../../packages/neb-utils/claims';
import { getPatientDisplayName } from '../../../../packages/neb-utils/neb-charting-util';
import {
  EPSDT_ITEMS,
  fetchAdjustments,
  fetchFeeSchedules,
  fetchInsuranceItems,
  fetchPackageItems,
  fetchPatientFeeSchedules,
  fetchPayerItems,
  fetchPaymentTypeItems,
  fetchPracticeUsers,
  fetchTaxRateItems,
  LINE_ITEM_TYPE,
} from '../../../../packages/neb-utils/neb-ledger-util';
import {
  sendRefreshNotification,
  REFRESH_CHANGE_TYPE,
} from '../../../../packages/neb-utils/neb-refresh';
import { autoAllocatePayments } from '../../../api-clients/auto-allocation';
import { getMany } from '../../../api-clients/charges';
import { getLowInventoryMessage } from '../../../api-clients/inventory';
import { formatToTransferBalanceModel } from '../../../formatters/transfer-balance';
import { ADD_ONS, hasAddOn } from '../../../utils/add-ons';
import { formatAllocationLineItemsV2 } from '../../../utils/allocate-charges/neb-allocate-charge-util';
import { getAutoAllocationBanner } from '../../../utils/auto-allocation';
import { viewERAsEOBs } from '../../../utils/era-eob';
import {
  LOCATION_KEYS,
  getLocationValue,
} from '../../../utils/locations/location-util';
import {
  API_VERSION,
  EDIT_MODE,
  CHARGES_ORIGIN,
} from '../../../utils/neb-charges-util';
import {
  ERROR_AUTO_ALLOCATE_PAYMENT,
  ERROR_FETCHING_PREVIEW_CHARGES,
  ERROR_FETCH_ERA_DATA,
  SUPPLEMENTAL_INFO_ERROR,
  SUPPLEMENTAL_INFO_SUCCESS,
} from '../../../utils/user-message';
import { NebFormChargesManagement } from '../../forms/charges/neb-form-charges-management';

import {
  getPatientPayments,
  getPayerPayments,
} from './neb-charges-controller/neb-charges-controller-util';

export const ELEMENTS = {
  form: { id: 'charges-form-management' },
};

function pushSuccess(message) {
  store.dispatch(openSuccess(message));
}

function pushError(message, _err) {
  store.dispatch(openError(message));
}

class NebChargesControllerV2 extends LitElement {
  static get properties() {
    return {
      editMode: String,
      __associateMode: Boolean,
      __inputModel: Object,
      __displayItems: Array,
      __detailItems: Array,
      __locations: Array,
      __formLoading: Boolean,
      __chartingPermission: Boolean,
      __hasFifo: Boolean,
      __filteredItems: Array,
      __filteredModel: Array,
      __itemsMap: Object,
      __itemsForAllocation: Array,
      __filteredItemsForAllocation: Array,

      model: Object,
      patient: Object,
      origin: String,
      toggleHeaderCheckbox: Boolean,
      paymentLinks: Boolean,
      filteredLineItemIds: Array,
      matchLineItemReportId: String,

      payerPlans: Array,
      practiceUsers: Array,
      paymentTypes: Array,
      feeSchedules: Array,
      patientFeeSchedules: Array,
      adjustments: Array,
      taxRates: Array,
    };
  }

  constructor() {
    super();
    this.__initState();
    this.__initServices();

    this.initHandlers();
  }

  static get styles() {
    return [
      baseStyles,
      css`
        .content {
          width: 100%;
        }

        .header {
          padding: ${CSS_SPACING};
        }
      `,
    ];
  }

  __initState() {
    this.editMode = EDIT_MODE.DISABLED;
    this.matchLineItemReportId = '';
    this.__associateMode = false;
    this.__inputModel = NebFormChargesManagement.createModel();
    this.__displayItems = [];
    this.__filteredItems = [];
    this.__detailItems = [];
    this.__locations = [];
    this.__paymentDetail = {};
    this.__chartingPermission = false;
    this.__formLoading = false;
    this.__hasFifo = false;
    this.__itemsMap = NebFormChargesManagement.createModelItemsMap();
    this.__filteredModel = NebFormChargesManagement.createModel();
    this.__itemsForAllocation = [];
    this.__filteredItemsForAllocation = [];
    this.__providersForPreviewCharges = [];
    this.__payerPlansForPreviewCharges = [];

    this.toggleHeaderCheckbox = true;

    this.payerPlans = [];
    this.practiceUsers = [];
    this.paymentTypes = [];
    this.feeSchedules = [];
    this.patientFeeSchedules = [];
    this.adjustments = [];
    this.taxRates = [];

    this.origin = '';
    this.paymentLinks = true;

    this.onDirtyChange = () => {};

    this.onDismiss = () => {};

    this.onRefreshSummary = () => {};

    this.onUpdateModel = async () => {};

    this.onRemoveCharges = async () => {};

    this.onChangeEditMode = () => {};

    this.onCreateSecondaryClaims = async () => {};

    this.onMatchCharge = () => {};

    this.onManualPost = async () => {};
  }

  initHandlers() {
    this.__handlers = {
      selectAll: () => {
        this.toggleHeaderCheckbox = !this.toggleHeaderCheckbox;
        this.__setCheckboxes(this.toggleHeaderCheckbox);
      },
      dirty: dirty => this.onDirtyChange(dirty),
      addCharge: async () => {
        const [{ invoiceId, guarantorId }] = this.__displayItems;
        const encounterIds = this.getEncounterIds();

        await openOverlay(OVERLAY_KEYS.PATIENT_ADD_CHARGE, {
          patientId: this.patient ? this.patient.id : this.model.patient.id,
          addToLedgerGrouping: true,
          charges: this.__displayItems,
          invoiceId,
          encounterIds,
          guarantorId,
          chartingPermission: this.__chartingPermission,
        });

        const invoiceItems = await getLedgerInvoiceItems(invoiceId);

        const lineItemIds = invoiceItems.data.map(item => item.id);

        if (!lineItemIds.length) {
          this.onDismiss();
          return null;
        }

        await this.__fetch({
          lineItemIds,
          selectedIds: this.getSelectedIds(),
        });

        return this.onRefreshSummary(this.__displayItems);
      },

      transferBalance: async () => {
        const selectedIds = this.getSelectedIds();
        const selectedLineItems = this.getSelectedFilteredItems();

        const transferBalanceItems =
          formatToTransferBalanceModel(selectedLineItems);

        const result = await openPopup(POPUP_RENDER_KEYS.TRANSFER_BALANCE, {
          transferBalanceItems,
        });

        if (result) {
          await this.__fetch({
            selectedIds,
          });

          this.onRefreshSummary(this.__displayItems);
        }
      },

      cancel: async () => {
        if (this.isDirty) {
          if (await openDirtyPopup()) {
            this.__inputModel = { ...this.__inputModel };
          }
        }
        this.onChangeEditMode(EDIT_MODE.DISABLED);
      },

      editCharges: () => {
        this.onChangeEditMode(EDIT_MODE.TABLE);
      },

      allocatePayment: async () => {
        const selectedLineItems = this.__displayItems
          .filter(item => item.checked)
          .reduce(
            (accum, row) => [
              ...accum,
              {
                id: row.id,
                patientId: row.patientId,
                chargeNumber: row.chargeNumber,
                hasBalance: row.balance > 0,
              },
            ],
            [],
          );

        const [{ patientId }] = selectedLineItems;
        const selectedLineItemIds = selectedLineItems.map(item => item.id);

        const fromEraEobPage = this.__cameFromEraEobPage();

        await openOverlay(OVERLAY_KEYS.ALLOCATE_PAYMENT, {
          patientId,
          selectedTab: TABS.OUTSTANDING,
          selectedLineItemIds,
          fromEraEobPage,
          origin: this.origin,
          reportId: this.model.reportId,
          eobId: this.model.id,
        });

        this.__fetch({
          selectedIds: this.getSelectedIds(),
          refreshSummary: true,
        });

        if (fromEraEobPage) this.onUpdateModel();
      },

      autoAllocatePayment: async () => {
        const selectedIds = this.getSelectedIds();
        this.__formLoading = true;

        try {
          const { data: allocations } = await autoAllocatePayments({
            lineItemIds: selectedIds,
          });

          store.dispatch(getAutoAllocationBanner(allocations));

          await this.__fetch({
            selectedIds,
            refreshSummary: true,
          });
        } catch (e) {
          console.error(e);
          store.dispatch(openError(ERROR_AUTO_ALLOCATE_PAYMENT));
        }

        this.__formLoading = false;
      },

      manageEncounter: async () => {
        const encounterIds = this.getEncounterIds();
        const params = {
          patientId: this.patient ? this.patient.id : this.model.patient.id,
          invoiceId: this.invoice.id,
          encounterIds,
        };

        await openOverlay(OVERLAY_KEYS.MANAGE_ENCOUNTER, params);

        const invoiceItems = await getLedgerInvoiceItems(this.invoice.id);

        const lineItemIds = invoiceItems.data.map(item => item.id);

        if (!lineItemIds.length) {
          this.onDismiss();
          return null;
        }

        await this.__fetch({
          lineItemIds,
          selectedIds: this.getSelectedIds(),
        });

        return this.onRefreshSummary(this.__displayItems);
      },
      editSupplementalInformation: async () => {
        const createSupplementalInformationSelectModel = ({
          value,
          fieldType,
        }) => ({
          label: value ? `${value} - ${fieldType[value]}` : '',
          data: { id: value || null },
        });

        try {
          const supplementalInfo = await getInvoiceSupplementalInfo(
            this.invoice.id,
          );

          const {
            reportTypeCode,
            reportTransmissionCode,
            codeQualifier,
            identificationNumber,
          } = supplementalInfo.data[0];

          const supplementalInfoModel = {
            reportTypeCode: createSupplementalInformationSelectModel({
              value: reportTypeCode,
              fieldType: REPORT_TYPE_CODE,
            }),
            reportTransmissionCode: createSupplementalInformationSelectModel({
              value: reportTransmissionCode,
              fieldType: REPORT_TRANSMISSION_CODE,
            }),
            codeQualifier: createSupplementalInformationSelectModel({
              value: codeQualifier,
              fieldType: CODE_QUALIFIER,
            }),
            identificationNumber: identificationNumber || null,
          };

          const result = await openPopup(
            POPUP_RENDER_KEYS.SUPPLEMENTAL_INFORMATION,
            supplementalInfoModel,
          );

          if (result) {
            await updateInvoiceSupplementalInfo(result, {
              invoiceId: this.invoice.id,
            });

            pushSuccess(SUPPLEMENTAL_INFO_SUCCESS);
          }
        } catch (e) {
          pushError(SUPPLEMENTAL_INFO_ERROR);
        }
      },
      performBulkAction: async ({ actionId }) => {
        if (this.__handlers[actionId]) {
          return this.__handlers[actionId]();
        }

        const selectedLineItemIds = this.getSelectedIds();
        const selectedLineItems = this.getSelectedItems();
        const someAllocated = selectedLineItems.some(
          item => item.primaryPaid || item.secondaryPaid || item.patientPaid,
        );

        const actionDetails = {
          selectedLineItemIds,
          patientId: this.model.patient
            ? this.model.patient.id
            : this.patient.id,
          invoiceIds: [this.invoice.id],
          someAllocated,
        };
        await handleBulkAction(
          actionId,
          actionDetails,
          async ({
            lineItemIds,
            selectedLineItemIds: selectedIds,
            removeSelectedItems = false,
          }) => {
            if (removeSelectedItems) {
              this.__displayItems = this.__displayItems
                .filter(item => !selectedLineItemIds.find(id => id === item.id))
                .map(item => ({ ...item, checked: false }));

              this.__detailItems = this.__detailItems.filter(
                item => !selectedLineItemIds.find(id => id === item.id),
              );
            }

            if (!this.__displayItems.length) {
              this.onDismiss();
            } else {
              await this.__fetch({ selectedIds, lineItemIds });
              this.onRefreshSummary(this.__displayItems);
            }
          },
        );

        return undefined;
      },
      associate: () => {
        this.onChangeEditMode(EDIT_MODE.ASSOCIATE);
      },
      removeCharges: async () => {
        const selectedDetailItems = await this.getSelectedDetailItems();
        const mappedSelectedDetailItems = selectedDetailItems.map(item => ({
          id: item.id,
          chargeNumber: item.chargeNumber,
          claim: {
            id: item.claimId,
            status: item.claimStatus,
            isElectronic: item.claimIsElectronic,
            claimNumber: item.claimNumber,
            claimCharges: [{ lineItemId: item.id }],
            claimStatuses: [
              {
                effectiveDate: item.claimStatusEffectiveDate,
                status: item.claimStatus,
              },
            ],
          },
        }));

        const result = await this.onRemoveCharges(mappedSelectedDetailItems);

        if (result) {
          this.__fetch({
            lineItemIds: this.lineItemIds,
            selectedIds: this.model.selectedIds,
          });
        }
      },
      cancelAssociate: () => {
        this.onChangeEditMode(EDIT_MODE.DISABLED);
        this.onUpdateModel();
      },
      save: async (model, disableTable = true) => {
        let postedChargeIds = [];
        let postedChargeCodes = [];

        try {
          if (!equal(model, this.__filteredModel)) {
            if (!equal(model.items, this.__filteredModel.items)) {
              const editedAndSignedEncounterChargeIds =
                this.__getEditedAndSignedEncounterChargeIds(
                  model.items,
                  this.__filteredModel.items,
                );

              await Promise.all(
                editedAndSignedEncounterChargeIds.map(id =>
                  startEncounterHistory(id, true),
                ),
              );

              ({ postedChargeIds, postedChargeCodes } = await saveLineItems(
                model.items,
              ));

              await Promise.all(
                editedAndSignedEncounterChargeIds.map(id =>
                  createEncounterHistory(id, true),
                ),
              );
            }

            await this.__fetch({
              selectedIds: this.getSelectedIds(),
            });

            this.onRefreshSummary(this.__displayItems);
            this.__inputModel = model;
          }

          if (disableTable) {
            this.onChangeEditMode(EDIT_MODE.DISABLED);
          }
          pushSuccess('Charges saved successfully');

          await this.__lowInventoryMessage({
            postedChargeIds,
            postedChargeCodes,
          });

          this.onUpdateModel();

          sendRefreshNotification([REFRESH_CHANGE_TYPE.LEDGER]);

          if (this.closeOnSave) {
            this.onDismiss();
          }
        } catch (e) {
          console.error('error: ', e);
          this.__inputModel = { ...model };
          pushError('An error has occurred when saving charges', e);
        }
      },
      toggleItem: e => {
        const index = Number(e.name.split('.')[0]);

        this.__filteredItems[index].checked = e.value;
        this.__filteredItems = [...this.__filteredItems];

        const checked = e.value;

        this.toggleHeaderCheckbox = checked
          ? this.__filteredItems.every(c => c.checked)
          : false;
      },
      onError: () => {
        const form = this.shadowRoot.getElementById(ELEMENTS.form.id);

        if (form) {
          form.scrollLeft();
        }
      },
      createSecondaryClaims: () =>
        this.onCreateSecondaryClaims(this.getSelectedIds()),
      refetch: async () => {
        const reloadEntireModel = this.editMode !== EDIT_MODE.TABLE;
        if (reloadEntireModel) await this.onUpdateModel();

        const lineItemsToRefetch =
          this.editMode === EDIT_MODE.MANUAL_POST
            ? this.filteredLineItemIds
            : this.lineItemIds;

        await this.__fetch({
          lineItemIds: reloadEntireModel ? lineItemsToRefetch : undefined,
          selectedIds: this.getSelectedIds(),
        });
      },
      selectBulkAction: checked => {
        this.__displayItems = this.__displayItems.map(item => ({
          ...item,
          checked,
        }));
      },
      viewERAsEOBs: async ({
        associatedERAsAndEOBs,
        lineItemId,
        refetchData,
      }) => {
        const response = await viewERAsEOBs({
          associatedERAsAndEOBs,
          lineItemId,
        });

        if (response && refetchData) {
          await this.__handlers.refetch();
        }
      },
      manualPost: model => {
        const changedLineItems = getChangedLineItemsForAllocationV2(
          this.__itemsForAllocation,
          model,
        );

        if (changedLineItems.length === 0) return;

        this.onManualPost(changedLineItems);
      },
    };
  }

  get invoice() {
    const { invoiceId: id, invoiceNumber: number } = this.__displayItems[0];

    return {
      id,
      number,
    };
  }

  __setCheckboxes(checked) {
    this.__displayItems = [...this.__displayItems].map(item => ({
      ...item,
      checked,
    }));
  }

  __cameFromEraEobPage() {
    return (
      (this.origin === CHARGES_ORIGIN.EOB ||
        this.origin === CHARGES_ORIGIN.ERA) &&
      !this.model.isLegacy
    );
  }

  __getModifiersAndUnits(charge) {
    const {
      modifier_1: modifier1,
      modifier_2: modifier2,
      modifier_3: modifier3,
      modifier_4: modifier4,
      units,
    } = charge;

    return {
      modifiers: [modifier1, modifier2, modifier3, modifier4],
      units,
    };
  }

  __getEditedAndSignedEncounterChargeIds(charges, pristineCharges) {
    const editedEncounterChargeIds = charges.reduce((accum, charge, index) => {
      if (charge.encounterCharge && charge.encounterCharge.signed) {
        const pristineCharge = this.__getModifiersAndUnits(
          pristineCharges[index],
        );
        const editedCharge = this.__getModifiersAndUnits(charge);

        if (!equal(pristineCharge, editedCharge)) {
          accum.push(charge.encounterCharge.encounterId);
        }
      }
      return accum;
    }, []);

    return [...new Set(editedEncounterChargeIds)];
  }

  async __lowInventoryMessage(args) {
    const { postedChargeIds, postedChargeCodes } = args;

    const inventoryMessage = await getLowInventoryMessage({
      chargeIds: postedChargeIds,
      codes: postedChargeCodes,
    });

    if (inventoryMessage.length) {
      store.dispatch(openInfo(inventoryMessage));
    }
  }

  __getPayerFromPayment() {
    if (this.model.payments && this.model.payments.length) {
      return {
        id: this.model.payments[0].payerPlanId,
        alias: this.model.payments[0].alias,
        name: this.model.payments[0].payerName,
      };
    }
    return this.model.payer;
  }

  __addEraEobModelInfo() {
    if (
      this.origin === CHARGES_ORIGIN.EOB ||
      this.origin === CHARGES_ORIGIN.ERA
    ) {
      this.__inputModel = {
        ...this.__inputModel,
        reportId: this.model.id,
        payer: this.model.payer,
        ...(this.origin === CHARGES_ORIGIN.ERA && {
          eClaimERAReportId: this.model.reportId,
          payer: this.__getPayerFromPayment(),
        }),
      };
    }
  }

  __mapSummaryItemsToField(summaryItems, id, field) {
    return summaryItems.find(x => x.id === id)[field];
  }

  async __setItemsForAllocation(previewCharges, providers, payerPlans) {
    if (!previewCharges?.length) return;

    const patientHash = this.__detailItems.reduce((accum, item) => {
      accum[item.patient.id] = item.patient;
      return accum;
    }, {});

    this.__itemsForAllocation = await formatAllocationLineItemsV2(
      previewCharges,
      providers,
      payerPlans,
      this.__paymentDetail,
      patientHash,
    );
  }

  async __fetchPreviewCharges() {
    try {
      if (this.origin !== CHARGES_ORIGIN.ERA) {
        return [];
      }

      const previewCharges = await getPreviewCharges({
        paymentId: this.__paymentDetail.id,
      });

      return previewCharges;
    } catch (e) {
      console.error('error: ', e);
      pushError(ERROR_FETCHING_PREVIEW_CHARGES, e);
      return [];
    }
  }

  async __getPayerPlans() {
    if (this.payerPlans.length) return;

    const { payerPlan: payerPlans } = await getPayerPlans(
      { hideInactive: false },
      undefined,
      true,
    );
    this.payerPlans = payerPlans;
  }

  async __getPracticeUsers() {
    if (this.practiceUsers.length) return;

    this.practiceUsers = await fetchPracticeUsers();
  }

  async __fetchPaymentTypeItems() {
    if (this.paymentTypes.length) return;

    this.paymentTypes = await fetchPaymentTypeItems();
  }

  async __fetchFeeSchedules() {
    if (this.feeSchedules.length) return;

    this.feeSchedules = await fetchFeeSchedules(true, 4);
  }

  async __fetchPatientFeeSchedules() {
    const patientId = this.patient ? this.patient.id : this.model.patient?.id;

    if (this.patientFeeSchedules.length) return;

    this.patientFeeSchedules = await fetchPatientFeeSchedules(patientId);
  }

  async __fetchAdjustments() {
    if (this.adjustments.length) return;

    this.adjustments = await fetchAdjustments(true);
  }

  async __fetchTaxRateItems() {
    if (this.taxRates.length) return;

    this.taxRates = await fetchTaxRateItems(true);
  }

  async __fetch({
    lineItemIds = this.__displayItems.map(({ id }) => id),
    selectedIds = [],
    refreshSummary = false,
  } = {}) {
    if ((lineItemIds || []).length <= 0) {
      this.__detailItems = [];
      this.__displayItems = [];
      this.__inputModel = {
        payerInfo: [],
        items: [],
      };

      this.__addEraEobModelInfo();

      return;
    }

    try {
      store.dispatch(startRequest());

      const [providers, summaryItems, detailItems] = await Promise.all([
        getProviderUsers(true),
        getLineItems(lineItemIds, API_VERSION[this.origin]),
        getLineItemDetails({}, { lineItemIds }, 3),
        this.__getPayerPlans(),
        this.__getPracticeUsers(),
      ]);

      this.__providersForPreviewCharges = providers;
      this.__payerPlansForPreviewCharges = this.payerPlans;

      const associatedERAsAndEOBsDict = summaryItems.reduce((accum, item) => {
        accum[item.id] = item.associatedERAsAndEOBs || [];
        return accum;
      }, {});

      const { id } = store.getState().session.item;
      const currentUser = this.practiceUsers.find(user => user.data.id === id);
      this.__chartingPermission = currentUser.data.permissions.find(
        p => p.name === 'charting',
      ).access;

      const patients = await this.__getPatientsMap(summaryItems);

      const formattedLineItems = await Promise.all(
        detailItems.map(item => ({
          ...formatLineItemBase(item, providers, this.payerPlans),
          associatedERAsAndEOBs: associatedERAsAndEOBsDict[item.id],
        })),
      );

      this.__detailItems = formattedLineItems.map(item => ({
        ...item,
        location: getLocationValue(
          this.__locations,
          item.encounterCharge.locationId,
          LOCATION_KEYS.NAME,
        ),
        patient: patients[item.patientId],
        claimId: this.__mapSummaryItemsToField(
          summaryItems,
          item.id,
          'claimId',
        ),
        claimNumber: this.__mapSummaryItemsToField(
          summaryItems,
          item.id,
          'claimNumber',
        ),
        claimStatus: this.__mapSummaryItemsToField(
          summaryItems,
          item.id,
          'claimStatus',
        ),
        claimIsElectronic: this.__mapSummaryItemsToField(
          summaryItems,
          item.id,
          'claimIsElectronic',
        ),
        claimStatusEffectiveDate: this.__mapSummaryItemsToField(
          summaryItems,
          item.id,
          'claimStatusEffectiveDate',
        ),
        invoiceNumber: this.__mapSummaryItemsToField(
          summaryItems,
          item.id,
          'invoiceNumber',
        ),
      }));

      this.__displayItems = summaryItems.map(li => ({
        ...li,
        ...(patients[li.patientId] && {
          patientName: getPatientDisplayName(patients[li.patientId], {
            middle: true,
            middleInitial: true,
            reverse: true,
            preferred: false,
            suffix: true,
          }),
          patientMRN: patients[li.patientId].medicalRecordNumber,
        }),
        checked:
          this.__displayItems.length === 0 ||
          this.__displayItems.length !== selectedIds.length
            ? true
            : selectedIds.includes(li.id),

        payerPayments: getPayerPayments(li, this.__detailItems),
        patientPayments: getPatientPayments(li, this.__detailItems),
        statementId: this.__detailItems.find(item => item.id === li.id)
          ?.statementId,
      }));

      if (this.__displayItems.every(c => c.checked)) {
        this.toggleHeaderCheckbox = true;
      }

      const items = this.__inputModel.items.length
        ? this.getSelectedDetailItems()
        : this.__detailItems;

      this.__inputModel = {
        payerInfo: this.getPayerInfo(),
        items,
      };

      this.__addEraEobModelInfo();

      await this.__loadItemsMap();

      if (refreshSummary) {
        this.onRefreshSummary(this.__displayItems, true);
      }
    } catch (e) {
      console.error(e);
      pushError(ERROR_FETCH_ERA_DATA, e);
      this.onDismiss();
    } finally {
      store.dispatch(endRequest());
    }
  }

  getInvoiceId() {
    return this.__displayItems[0].invoiceId;
  }

  getSelectedItems() {
    return this.__displayItems.filter(item => item.checked);
  }

  getSelectedIds() {
    const selectedItems = this.getSelectedItems();
    return selectedItems.map(item => item.id);
  }

  getSelectedFilteredItems() {
    const selectedIds = this.getSelectedIds();
    return this.__filteredItems.filter(filteredItem =>
      selectedIds.includes(filteredItem.id),
    );
  }

  getSelectedFilteredLineItemIds() {
    const filteredLineItems = this.getSelectedFilteredItems();

    return filteredLineItems.map(({ id }) => id);
  }

  getSelectedDetailItems() {
    const selectedIds = this.getSelectedFilteredLineItemIds();

    return this.__detailItems.filter(item => selectedIds.includes(item.id));
  }

  getPayerInfo() {
    const [item] = this.__displayItems;

    const allocations = this.__detailItems.flatMap(curr =>
      curr.lineItemDebits.flatMap(lid => lid.debit.allocations),
    );

    const datesOfService = this.__detailItems.map(
      ({ dateOfService }) => dateOfService,
    );

    const secondaryInsuranceIdsSet = new Set();
    this.__detailItems.forEach(lineItem => {
      lineItem.lineItemDebits.forEach(({ patientInsuranceId }) => {
        if (patientInsuranceId && patientInsuranceId !== item.primaryPlanId) {
          secondaryInsuranceIdsSet.add(patientInsuranceId);
        }
      });
    });

    return [
      {
        billType: item.billType,
        payerId: item.primaryPayerId,
        caseId: item.patientCaseId,
        packageId: item.patientPackageId,
        insuranceId: item.primaryPlanId,
        guarantorId: item.guarantorId,
        invoiceId: item.invoiceId,
        secondaryInsuranceId: item.secondaryPlanId,
        allocations,
        datesOfService,
        secondaryInsuranceIds: Array.from(secondaryInsuranceIdsSet),
      },
    ];
  }

  getEncounterIds() {
    return Array.from(
      new Set(
        this.__detailItems.reduce(
          (acc, curr) =>
            curr.type === LINE_ITEM_TYPE.ENCOUNTER_CHARGE
              ? [...acc, curr.encounterCharge.encounterId]
              : acc,
          [],
        ),
      ),
    );
  }

  __initServices() {
    this.__locationsService = new LocationsService(({ locations }) => {
      this.__locations = locations;
    });
  }

  async connectedCallback() {
    super.connectedCallback();
    this.__locationsService.connect();

    this.__hasFifo = await hasAddOn(ADD_ONS.CT_FIFO);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.__locationsService.disconnect();
  }

  async __getPatientsMap(lineItems) {
    const patientIds = [...new Set(lineItems.map(li => li.patientId))];

    const patients = await patientApiClient.fetchSome(patientIds, {}, true);

    return patients.reduce((acc, patient) => {
      acc[patient.id] = patient;
      return acc;
    }, {});
  }

  __checkModelHasLineItems() {
    return (
      this.model && this.model.lineItemIds && this.model.lineItemIds.length > 0
    );
  }

  __resetChargesFromEraEobManagement() {
    if (!this.__cameFromEraEobPage()) return;

    this.__detailItems = [];
    this.__displayItems = [];
  }

  get __encountersDx() {
    const encounterDiagnoses = this.__inputModel.items.reduce(
      (acc, { encounterCharge: { encounterId, diagnoses } = {} }) =>
        encounterId ? { ...acc, [encounterId]: diagnoses } : acc,
      {},
    );

    return Object.entries(encounterDiagnoses).reduce(
      (acc, [encounterId, diagnoses]) => {
        diagnoses.forEach(dx =>
          acc.push({
            code: dx.code,
            encounterId,
            description: dx.description,
          }),
        );

        return acc;
      },
      [],
    );
  }

  async __loadItemsMap() {
    const [{ payerId, packageId } = {}] = this.__inputModel.payerInfo;

    const [payers, settingsCharges] = await Promise.all([
      fetchPayerItems(payerId, this.payerPlans),
      getMany({ optOutLoadingIndicator: true }),
      this.__getPracticeUsers(),
      this.__fetchPaymentTypeItems(),
      this.__fetchFeeSchedules(),
      this.__fetchPatientFeeSchedules(),
      this.__fetchAdjustments(),
      this.__fetchTaxRateItems(),
    ]);

    const patientIds = [
      ...new Set(this.__displayItems.map(li => li.patientId)),
    ];

    const insurances = await Promise.all(
      patientIds.length
        ? patientIds.map(patId => fetchInsuranceItems(patId))
        : [],
    );

    let packages = [];

    if (packageId) {
      const allPatientPackages = await Promise.all(
        patientIds.length
          ? patientIds.map(patId =>
              fetchPackageItems(patId, { includeShared: true }, true),
            )
          : [],
      );

      const patientPackagesDict = allPatientPackages
        .flat()
        .reduce((memo, pp) => {
          memo[pp.data.id] = pp;
          return memo;
        }, {});

      packages = Object.values(patientPackagesDict);
    }

    this.__itemsMap = {
      payers,
      cases: [],
      packages,
      insurances: insurances.flat(),
      guarantors: [],
      users: this.practiceUsers,
      adjustments: this.adjustments,
      dx: this.__encountersDx,
      taxRates: this.taxRates,
      epsdt: EPSDT_ITEMS,
      paymentTypes: this.paymentTypes,
      feeSchedules: this.feeSchedules,
      patientFeeSchedules: this.patientFeeSchedules,
      charges: settingsCharges,
    };
  }

  __updateItemsForAllocation() {
    this.__filteredItemsForAllocation = this.filteredLineItemIds
      ? this.__itemsForAllocation.filter(({ id }) =>
          this.filteredLineItemIds.includes(id),
        )
      : this.__itemsForAllocation;
  }

  __updateDisplayItems() {
    this.__filteredItems = this.filteredLineItemIds
      ? this.__displayItems.filter(({ id }) =>
          this.filteredLineItemIds.includes(id),
        )
      : this.__displayItems;
  }

  __updateInputModel() {
    const filteredItems = this.filteredLineItemIds
      ? this.__inputModel.items.filter(({ id }) =>
          this.filteredLineItemIds.includes(id),
        )
      : this.__inputModel.items;
    this.__filteredModel = { ...this.__inputModel, items: filteredItems };
  }

  __setupFilteredLineItems(changedProps) {
    if (changedProps.has('__inputModel')) {
      this.__updateInputModel();
    }

    if (changedProps.has('__displayItems')) {
      this.__updateDisplayItems();
    }

    if (changedProps.has('__itemsForAllocation')) {
      this.__updateItemsForAllocation();
    }

    if (
      changedProps.has('filteredLineItemIds') &&
      this.editMode === EDIT_MODE.DISABLED
    ) {
      this.__updateInputModel();
      this.__updateDisplayItems();
      this.__updateItemsForAllocation();
    }
  }

  __checkAssociateMode() {
    const associateModes = [EDIT_MODE.ASSOCIATE, EDIT_MODE.MATCH];
    return associateModes.includes(this.editMode);
  }

  update(changedProps) {
    if (changedProps.has('model')) {
      const [paymentDetail = {}] = this.model.payments || [];
      this.__paymentDetail = paymentDetail;

      this.__inputModel = {
        ...this.__inputModel,
        payment: paymentDetail,
      };

      this.__addEraEobModelInfo();

      if (this.__checkModelHasLineItems()) {
        this.__fetch({
          lineItemIds: this.lineItemIds,
          selectedIds: this.model.selectedIds,
        });
      } else {
        this.__resetChargesFromEraEobManagement();
      }
    }

    if (changedProps.has('editMode')) {
      this.__associateMode = this.__checkAssociateMode();

      if (this.__detailItems.length) {
        const addItems = this.editMode === EDIT_MODE.TABLE;

        this.__inputModel = {
          ...this.__inputModel,
          items: addItems ? this.getSelectedDetailItems() : [],
        };
      }
    }

    this.__setupFilteredLineItems(changedProps);

    super.update(changedProps);
  }

  async updated(changedProps) {
    if (changedProps.has('editMode')) {
      if (this.editMode === EDIT_MODE.MANUAL_POST) {
        await this.__loadAndSetPreviewCharges();
      }
    }

    super.updated(changedProps);
  }

  async __loadAndSetPreviewCharges() {
    const previewCharges = await this.__fetchPreviewCharges();
    await this.__setItemsForAllocation(
      previewCharges,
      this.__providersForPreviewCharges,
      this.__payerPlansForPreviewCharges,
    );
  }

  render() {
    return html`
      <neb-form-charges-management
        id="${ELEMENTS.form.id}"
        class="charges-form-management"
        .layout="${this.layout}"
        .patient="${this.patient}"
        .editMode="${this.editMode}"
        .associateMode="${this.__associateMode}"
        .model="${this.__filteredModel}"
        .onCancel="${this.__handlers.cancel}"
        .displayItems="${this.__filteredItems}"
        .onBulkActionClick="${this.__handlers.performBulkAction}"
        .onToggleItem="${this.__handlers.toggleItem}"
        .origin="${this.origin}"
        .onSave="${this.__handlers.save}"
        .onChangeDirty="${this.__handlers.dirty}"
        .onEditPayerInfo="${this.__handlers.editPayerInfo}"
        .onError="${this.__handlers.onError}"
        .onCancelAssociate="${this.__handlers.cancelAssociate}"
        .hasChartingPermission="${this.__chartingPermission}"
        .hasFifo="${this.__hasFifo}"
        .loading="${this.__formLoading}"
        .onRefetch="${this.__handlers.refetch}"
        .onBulkSelect="${this.__handlers.selectBulkAction}"
        .onSelectAll="${this.__handlers.selectAll}"
        .toggleHeaderCheckbox="${this.__toggleHeaderCheckbox}"
        .onClickPaymentId="${this.onClickPaymentId}"
        .paymentLinks="${this.paymentLinks}"
        .onClickERAsEOBs="${this.__handlers.viewERAsEOBs}"
        .itemsMap="${this.__itemsMap}"
        .matchLineItemReportId="${this.matchLineItemReportId}"
        .onMatchCharge="${this.onMatchCharge}"
        .paymentDetail="${this.__paymentDetail}"
        .itemsForAllocation="${this.__filteredItemsForAllocation}"
        .onManualPost="${this.__handlers.manualPost}"
      ></neb-form-charges-management>
    `;
  }
}

window.customElements.define(
  'neb-charges-controller-v2',
  NebChargesControllerV2,
);
