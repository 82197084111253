import { Method } from '../../packages/neb-api-client/src/utils/api-client-utils';
import ApiClientV2 from '../../packages/neb-api-client/src/utils/api-client-v2';

const apiClient = new ApiClientV2({ microservice: 'billing' });

export const getPreallocateLineItems = (
  patientId,
  optOutLoadingIndicator = false,
) =>
  apiClient.makeRequest({
    path: `/api/v1/tenants/:tenantId/patients/${patientId}/preallocate-payment/line-items`,
    method: Method.GET,
    headers: {
      'Content-Type': 'application/json',
    },
    optOutLoadingIndicator,
  });
