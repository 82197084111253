import moment from 'moment-timezone';

import * as userpilot from '../../src/library/userpilot';
import { SELECTED_PROVIDERS_KEY } from '../neb-lit-components/src/components/encounter/neb-encounter-list';
import {
  clearSession,
  renew,
  requiresAuthentication,
} from '../neb-login/neb-session-state';
import { clear as clearTimeoutWarning } from '../neb-login/neb-timeout-state';
import { KEY_LOCAL_PRACTICE_TIMEZONE } from '../neb-practice-information/actions/practice-information';
import { store } from '../neb-redux/neb-redux-store';

export const NEB_LOGIN_LOCAL = 'neb-login-local';

export const performLogout = async () => {
  window.localStorage.removeItem(NEB_LOGIN_LOCAL);
  window.localStorage.removeItem(KEY_LOCAL_PRACTICE_TIMEZONE);
  window.localStorage.removeItem(SELECTED_PROVIDERS_KEY);
  window.location.hash = '#/';
  window.nebDisableDirty = true;

  userpilot.logout();

  await store.dispatch(requiresAuthentication());
  await store.dispatch(clearSession());
  return store.dispatch(clearTimeoutWarning());
};

window.addEventListener('storage', event => {
  if (window.IS_WTR_TESTING_CONTEXT) {
    return;
  }

  if (event.key !== NEB_LOGIN_LOCAL) {
    return;
  }

  if (event.oldValue && !event.newValue) {
    performLogout();

    window.location.reload(true);
  } else if (
    JSON.parse(event.oldValue).tenantId !== JSON.parse(event.newValue).tenantId
  ) {
    window.location.reload(true);
  }
});

export const getLocalSession = () =>
  JSON.parse(window.localStorage.getItem(NEB_LOGIN_LOCAL));

export const storeSessionToLocalStorage = () => {
  const { recommendPasswordChange, item } = store.getState().session;

  if (item && item.tenantId) {
    const { accessToken, tenantId, idToken, refreshToken, type } = item;
    window.localStorage.setItem(
      NEB_LOGIN_LOCAL,
      JSON.stringify({
        accessToken,
        tenantId,
        idToken,
        refreshToken,
        recommendPasswordChange,
        type,
      }),
    );
  }
};

export const tokenExpired = session => {
  if (!session || !session.idToken) return false;

  const expiryDate = session.idToken.payload.exp;
  const expireDate = moment(expiryDate * 1000);

  const now = moment();
  const expiredMinutes = moment
    .utc(expireDate)
    .diff(moment.utc(now), 'minutes');

  return expiredMinutes <= 0;
};

export const performRenew = async session => {
  await store.dispatch(renew(session));
  return storeSessionToLocalStorage();
};

export const renewTokenIfNeeded = () => {
  const localSession = getLocalSession();
  const tokenExpiring = tokenExpired(localSession, 5);

  if (tokenExpiring) {
    return performRenew(localSession);
  }

  return undefined;
};

const isValidLocalSession = (session, cognitoType) =>
  session &&
  !session.recommendPasswordChange &&
  (session.accessToken || session.idToken) &&
  session.type === cognitoType;

export const checkSession = cognitoType => {
  const localSession = getLocalSession();
  const isSessionValid = isValidLocalSession(localSession, cognitoType);

  if (isSessionValid) {
    return tokenExpired(localSession)
      ? performLogout()
      : performRenew(localSession);
  }

  return undefined;
};
