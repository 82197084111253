/* eslint-disable no-await-in-loop */
import { openPopup } from '@neb/popup';

import { fetchPdf } from '../../../packages/neb-api-client/src/ledger-superbill-api-client';
import {
  getElectronicPayment,
  refundElectronicPayment as refundElectronicPaymentApi,
} from '../../../packages/neb-api-client/src/payments/electronic-payments-api-client';
import {
  voidPayment as voidPaymentApi,
  updateERAPayment as updateERAPaymentApi,
  refundPayment as refundPaymentApi,
} from '../../../packages/neb-api-client/src/payments-api-client';
import {
  openError,
  openSuccess,
} from '../../../packages/neb-dialog/neb-banner-state';
import { TABS } from '../../../packages/neb-lit-components/src/components/forms/neb-form-allocation-charges';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../packages/neb-lit-components/src/utils/overlay-constants';
import { VOIDED_BANNER_MESSAGE } from '../../../packages/neb-patient/src/components/ledger/payment/neb-patient-payment-controller';
import { REFUNDED_BANNER_MESSAGE } from '../../../packages/neb-patient/src/components/ledger/payment/neb-payments-list-page';
import { PAYMENT_ACTION_KEYS } from '../../../packages/neb-popup/src/neb-popup-payment-action';
import { POPUP_RENDER_KEYS } from '../../../packages/neb-popup/src/renderer-keys';
import { store } from '../../../packages/neb-redux/neb-redux-store';
import {
  ELECTRONIC_PAYMENT_TYPES,
  GENIUS_SALE_TYPES,
  REFUND_MESSAGES,
} from '../../../packages/neb-utils/electronic-payments-util';
import { centsToCurrency } from '../../../packages/neb-utils/formatters';
import {
  is500Error,
  isElectronicPayment,
  isRefunded,
} from '../../../packages/neb-utils/neb-payment-util';
import { printPdf } from '../../../packages/neb-utils/neb-pdf-print-util';
import { openPayfacReaderPopup } from '../../features/payfac/utils';
import {
  UPDATE_ERA_PAYMENT_ERROR,
  UPDATE_ERA_PAYMENT_SUCCESS,
} from '../user-message';

const printReport = ({ s3key }) => {
  printPdf(fetchPdf(s3key));
};

const allocatePayment = async ({
  payment,
  fromEraEobPage,
  origin,
  reportId,
  eobId,
}) => {
  await openOverlay(OVERLAY_KEYS.ALLOCATE_PAYMENT, {
    payments: [payment],
    selectedTab: TABS.OUTSTANDING,
    fromEraEobPage,
    showAdditionalCharges: true,
    origin,
    reportId,
    eobId,
  });

  return true;
};

const splitPayment = async ({ payment, fromEraEobPage }) => {
  await openOverlay(OVERLAY_KEYS.SPLIT_PAYMENT, {
    payment,
    fromEraEobPage,
  });

  return true;
};

const reviewExistingAllocation = async ({
  payment,
  fromEraEobPage,
  origin,
  reportId,
  eobId,
}) => {
  await openOverlay(OVERLAY_KEYS.ALLOCATE_PAYMENT, {
    payments: [payment],
    selectedTab: TABS.ALLOCATED,
    showAdditionalCharges: true,
    fromEraEobPage,
    origin,
    reportId,
    eobId,
  });

  return true;
};

const previewAllocation = ({ payment, hasPreviewAllocationPerformanceFF }) =>
  hasPreviewAllocationPerformanceFF
    ? openOverlay(OVERLAY_KEYS.PREVIEW_ALLOCATION, {
        readonly: false,
        payment,
      })
    : openOverlay(OVERLAY_KEYS.PAYMENT_DETAIL, {
        isPreviewPayerPayment: true,
        readonly: false,
        payment,
      });

const voidPayment = async ({ payment }) => {
  if (isElectronicPayment(payment)) return false;

  const paymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
    action: PAYMENT_ACTION_KEYS.VOID,
    paymentId: payment.paymentNumber,
    payer: payment.payerName,
    amount: centsToCurrency(payment.amount),
  });

  if (!paymentAction) return false;

  try {
    await voidPaymentApi({
      paymentId: payment.id,
      codeRefundId: paymentAction.selectedReason.id,
    });

    store.dispatch(openSuccess(VOIDED_BANNER_MESSAGE.success));
    return true;
  } catch (e) {
    console.error(e);
    store.dispatch(openError(VOIDED_BANNER_MESSAGE.error));
    return false;
  }
};

const updateERAPayment = async ({ payment }) => {
  const lowerBound = Math.max(payment.paymentAllocated, 0);
  const updatedPayment = await openPopup(POPUP_RENDER_KEYS.UPDATE_ERA_PAYMENT, {
    amount: payment.amount,
    lowerBound,
    upperBound: payment.eRA.originalAmount,
    warn: payment.eRA.warn,
  });

  if (!updatedPayment) return false;

  try {
    await updateERAPaymentApi(payment.id, updatedPayment);
    store.dispatch(openSuccess(UPDATE_ERA_PAYMENT_SUCCESS));
    return true;
  } catch (e) {
    console.error(e);
    store.dispatch(openError(UPDATE_ERA_PAYMENT_ERROR));
    return false;
  }
};

// eslint-disable-next-line complexity
const refundPayment = async ({ payment }, skipElectronic = false) => {
  let refundPaymentAction;

  if (isElectronicPayment(payment) && !skipElectronic) {
    // eslint-disable-next-line no-use-before-define
    refundPaymentAction = await refundElectronicPayment(payment);

    if (
      refundPaymentAction &&
      refundPaymentAction.action !== PAYMENT_ACTION_KEYS.REFUND
    ) {
      return true;
    }
  } else {
    refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
      action: PAYMENT_ACTION_KEYS.REFUND,
      paymentId: payment.paymentNumber,
      payer: payment.payerName,
      paymentMethod: isElectronicPayment(payment)
        ? `${payment.paymentMethod} - ${payment.maskedCardDescription}`
        : payment.paymentMethod,
      amount: centsToCurrency(payment.amount),
      patientId: payment.patientId,
      parentPaymentId: payment.parentPaymentId,
      id: payment.id,
    });
  }

  if (refundPaymentAction) {
    try {
      await refundPaymentApi({
        paymentId: payment.id,
        codeRefundId: refundPaymentAction.selectedReason.id,
        refundMethod: refundPaymentAction.refundMethod,
        amount: refundPaymentAction.amount,
      });

      store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));

      return true;
    } catch (e) {
      console.error(e);
      store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));
      return false;
    }
  }
  return true;
};

// eslint-disable-next-line complexity
const refundElectronicPayment = async (payment, opts = {}) => {
  const ePayment = await getElectronicPayment(payment.electronicPaymentId);

  let refundPaymentAction;

  if (payment.payerPlan) {
    refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
      action: PAYMENT_ACTION_KEYS.REFUND,
      paymentId: payment.paymentNumber,
      payer: payment.payerName,
      paymentMethod: `${payment.paymentMethod} - ${
        payment.maskedCardDescription
      }`,
      amount: centsToCurrency(payment.amount),
      selectedReader: opts.selectedReader,
      selectedReason: opts.selectedReason,
    });

    if (refundPaymentAction && refundPaymentAction.selectedReason) {
      const refundPaymentBody = {
        paymentId: payment.id,
        codeRefundId: refundPaymentAction.selectedReason.id,
        refundMethod: refundPaymentAction.refundMethod,
        amount: refundPaymentAction.amount,
      };

      try {
        await refundPaymentApi(refundPaymentBody);
        store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));
      } catch (e) {
        console.error(e);
        store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));
      }

      return undefined;
    }
  } else {
    refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
      action: PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC,
      paymentId: payment.paymentNumber,
      payer: payment.payerName,
      paymentMethod: `${payment.paymentMethod} - ${
        payment.maskedCardDescription
      }`,
      amount: centsToCurrency(payment.amount),
      selectedReader: opts.selectedReader,
      selectedReason: opts.selectedReason,
      ePayment,
    });
  }

  if (refundPaymentAction) {
    if (refundPaymentAction.action === PAYMENT_ACTION_KEYS.REFUND) {
      return refundPaymentAction;
    }

    let done = false;
    let paymentSuccess;
    let geniusRefund;
    let refundPaymentResult;

    while (!done) {
      done = true;

      if (ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT) {
        geniusRefund = await openPayfacReaderPopup({
          amount: refundPaymentAction.amount / 100,
          merchantAccounts: [
            {
              id: ePayment.merchantAccountId,
              cardReaders: [refundPaymentAction.selectedReader],
              active: true,
            },
          ],
          holderId: payment.patientId,
          title: 'Merchant Account Details',
          subheader:
            'Select the merchant account details for this transaction, then follow the prompts on the card reader.',
          pendingModel: {
            action: 'refund',
            patientId: payment.patientId || null,
            payerPlanId: payment.payerPlanId,
            amount: payment.amount,
            note: payment.note,
            authEFT: payment.authEFT,
            cardSaleId: payment.cardSaleId,
            referenceId: payment.referenceId,
            codePaymentId: payment.codePaymentId,
            paymentMethod: payment.paymentMethod,
            dateOfServiceTo: payment.dateOfServiceTo,
            dateOfServiceFrom: payment.dateOfServiceFrom,
            transactionDate: payment.transactionDate,
            electronicPaymentId: payment.electronicPaymentId,
            electronicReferenceId: payment.electronicReferenceId,
            maskedCardDescription: payment.maskedCardDescription,
            codeRefundId: refundPaymentAction.selectedReason,
          },
          message: 'Payment Amount',
          confirmLabel: 'Submit',
          holderType: 'patient',
          geniusSaleType: GENIUS_SALE_TYPES.RETURN,
          logContent: {
            ...ePayment,
            patientId: payment.patientId || null,
            payerPlanId: payment.payerPlanId || null,
            action: 'refund - cardReader - neb-overlay-payment-detail',
          },
        });

        if (!geniusRefund) {
          refundElectronicPayment(payment, refundPaymentAction);

          return {};
        }

        if (geniusRefund.status === 'DECLINED') {
          const response = await openPopup(
            POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
            {
              ...(ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                ? REFUND_MESSAGES.FAILED_400_MANUAL
                : REFUND_MESSAGES.FAILED_400),
              subheader: geniusRefund.status,
              hyperLink:
                ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                  ? 'Process Refund Manually'
                  : '',
            },
          );

          if (response.action === 'link') {
            refundPayment({ payment }, true);
          }

          return {};
        }
      }

      try {
        const result = await openPopup(
          POPUP_RENDER_KEYS.TRANSACTION_PROCESSING,
          {
            promise: refundElectronicPaymentApi(
              ePayment.merchantAccountId,
              ePayment.id,
              {
                ...geniusRefund,
                amount: refundPaymentAction.amount,
                logContent: {
                  action: 'cardOnFile - neb-overlay-payment-detail',
                  rawModel: ePayment,
                },
              },
            ),
          },
        );

        if (result.success) {
          paymentSuccess = true;
          const refundPaymentBody = {
            paymentId: payment.id,
            codeRefundId: refundPaymentAction.selectedReason.id,
            refundMethod: refundPaymentAction.refundMethod,
            cardRefundId: result.refund.returnId || result.refund.saleId,
            amount: refundPaymentAction.amount,
          };

          refundPaymentResult = await refundPaymentApi(refundPaymentBody);
        }

        if (result) {
          let response;

          if (result.success) {
            if (isRefunded(result.status)) {
              response = await openPopup(
                POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                REFUND_MESSAGES.APPROVED,
              );
            } else {
              response = await openPopup(
                POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                REFUND_MESSAGES.VOIDED,
              );
            }
          } else if (is500Error(result.code)) {
            response = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
              ...REFUND_MESSAGES.FAILED_500,
              subheader: result.status,
            });

            if (response.action === 'confirm') {
              done = false;
            }
          } else {
            response = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
              ...(ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                ? REFUND_MESSAGES.FAILED_400_MANUAL
                : REFUND_MESSAGES.FAILED_400),
              subheader: result.status,
              hyperLink:
                ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                  ? 'Process Refund Manually'
                  : '',
            });

            if (response.action === 'link') {
              refundPayment({ payment }, true);
            }
          }
        }
      } catch (e) {
        store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));
      }
    }

    if (paymentSuccess) {
      store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));

      payment = refundPaymentResult;
      return true;
    }
  }

  return refundPaymentAction;
};

const actions = {
  voidPayment,
  reviewExistingAllocation,
  previewAllocation,
  allocatePayment,
  printReport,
  updateERAPayment,
  refundPayment,
  splitPayment,
};

export default async (actionName, payload) => {
  const action = actions[actionName];
  if (!action) return false;

  const result = await action(payload);
  return result;
};
