export const POPUP_RENDER_KEYS = {
  ADD_TO_INVOICE: 'add-to-invoice',
  APPOINTMENT_CANCEL: 'appointment-cancel',
  APPOINTMENT_CHANGE_ROOM: 'appointment-change-room',
  APPOINTMENT_DRAG_DROP_CONFIRM: 'appointment-drag-drop-confirm',
  ARRIVED: 'arrived',
  ALLOCATED_PAYMENTS: 'allocated-payments',
  BILLING_HEADER: 'billing-header',
  BILL_TYPE_SET_DEFAULT: 'bill-type-set-default',
  CARD_ON_FILE: 'card-on-file',
  CARD_READER: 'card-reader',
  CHANGE_PASSWORD: 'change-password',
  CHANGE_PRACTICE: 'change-practice',
  CHANGE_PAPER_CLAIM_STATUS: 'change-paper-claim-status',
  CHARGE_GROUP: 'charge-group',
  CHARTING_NOTE_COPY: 'charting-note-copy',
  CLAIM_BILLING_PROVIDER: 'claim-billing-provider',
  CLAIM_LINE_ITEMS: 'claim-line-items',
  CLAIM_DATE_RANGE: 'claim-date-range',
  CLAIM_DROPDOWN: 'claim-dropdown',
  CLAIM_DROPDOWN_TEXT_INPUT: 'claim-dropdown-text-input',
  CLAIM_FEDERAL_TAX_ID: 'claim-federal-tax-id',
  CLAIM_MULTI_SELECT_INPUT: 'claim-multi-select-input',
  CLAIM_OUTSIDE_LABS: 'claim-outside-labs',
  CLAIM_PRINT_SETTINGS: 'claim-print-settings',
  CLAIM_RADIO_NAME_FIELDS: 'claim-radio-name-fields',
  CLAIM_RADIO_DATE_PICKER: 'claim-radio-date-picker',
  CLAIM_BILLING_PROVIDER_INFORMATION: 'claim-billing-provider-information',
  CLAIM_SERVICE_FACILITY: 'claim-service-facility',
  CLAIM_SINGLE_SELECT: 'claim-single-select',
  CLAIM_SPINAL_MANIPULATION: 'claim-spinal-manipulation',
  CLAIM_TEXT_INPUT: 'claim-text-input',
  CLAIM_YES_NO: 'claim-yes-no',
  CLAIM_SUPPLEMENTAL_INFORMATION: 'claim-supplemental-information',
  CLIENT_ID: 'client-id',
  CONFIRM: 'confirm',
  COPY: 'copy',
  COPY_AUTOSALT: 'copy-autosalt',
  COPY_EXISTING_PAYER: 'copy-existing-payer',
  COPY_PRIOR_ENCOUNTER: 'copy-prior-encounter',
  COPY_ENCOUNTER: 'copy-encounter',
  DELETE_BLOCKED_OFF_TIME: 'delete-blocked-off-time',
  DELETE_ENCOUNTER: 'delete-encounter',
  DIALOG: 'dialog',
  DOCUMENT: 'document',
  EMAIL_RECEIPT: 'email-receipt',
  ENCOUNTER_PROVIDER_SELECTION: 'encounter-provider-selection',
  ENCOUNTER_SIGN: 'encounter-sign',
  ENCOUNTER_REOPEN: 'encounter-reopen',
  FILE_UPLOAD: 'file-upload',
  FULLSCREEN_CONTENT: 'fullscreen-content',
  IMPORT_MACRO_SET: 'import-macro-set',
  INVITE_PATIENT: 'invite-patient',
  SUPPLEMENTAL_INFORMATION: 'supplemental-information',
  ORDERING_PROVIDER: 'ordering-provider',
  INDIVIDUAL_CODE_AND_AMOUNT: 'individual-code-and-amount',
  MACRO_EDIT_CHOICE_TEMPLATE: 'macro-edit-choice-template',
  MACRO_LINK_CHOICE_TEMPLATE: 'macro-link-choice-template',
  MACRO_REGION_CHOICE_SETUP: 'macro-region-choice-setup',
  MACROS_BASIC_FIELDS: 'macros-basic-fields',
  MACROS_BODY_DIAGRAM: 'macros-body-diagram',
  MACROS_DATE_PICKER: 'macros-date-picker',
  MACROS_FREE_TEXT_ENTRY: 'macros-free-text-entry',
  MACROS_MULTI_SELECT: 'macros-multi-select',
  MACROS_NUMERIC_ENTRY: 'macros-numeric-entry',
  MACROS_SINGLE_SELECT: 'macros-single-select',
  MACROS_SPINE: 'macros-spine',
  MATCH_ONLINE_BOOKING_ACCOUNT: 'match-online-booking-account',
  MATCH_PATIENT_ACCOUNT: 'match-patient-account',
  MERCHANT_RESPONSE: 'merchant-response',
  MESSAGE: 'message',
  MODIFIERS_EDIT: 'modifiers-edit',
  NATIONAL_DRUG_CODE: 'national-drug-code',
  NEW_STATEMENT: 'new-statement',
  PASSWORD: 'password',
  PATIENT_DEMOGRAPHIC_REPORT_PARAMS: 'patient-demographic-report-params',
  PATIENT_ONLINE_PAYMENT: 'patient-online-payment',
  PATIENT_ONLINE_PAYMENT_STATUS: 'patient-online-payment-status',
  PAYFAC_IFRAME: 'payfac-iframe',
  PAYFAC_READER: 'payfac-reader',
  PAYMENT_ACTION: 'payment-action',
  PROBLEMS: 'problems',
  PROFILE_PREVIEW: 'profile-preview',
  QUICK_CHECK_IN: 'quick-check-in',
  RECURRING: 'recurring',
  REPLACE_DOCUMENT_TAG: 'replace-document-tag',
  REVIEW_MATCH_SELECTION: 'review-match-selection',
  SELECT_LOCATION: 'select-location',
  SIGNATURE_CREATE: 'signature-create',
  SOAP_NOTE_APPEND_REPLACE: 'soap-note-append-replace',
  SOAP_NOTE_COPY: 'soap-note-copy',
  SPLIT_PAYMENT: 'split-payment',
  SUBJECTIVE_QUESTIONNAIRE: 'subjective-questionnaire',
  SUBJECTIVE_QUESTIONNAIRE_PREVIEW: 'subjective-questionnaire-preview',
  SUBJECTIVE_QUESTIONNAIRE_COPY: 'subjective-questionnaire-copy',
  SUBJECTIVE_QUESTIONNAIRE_PROVIDER_COPY:
    'subjective-questionnaire-provider-copy',
  SUBJECTIVE_QUESTIONNAIRE_APPEND_REPLACE:
    'subjective-questionnaire-append-replace',
  SWAP_CLEARINGHOUSE: 'swap-clearinghouse',
  TEXT_FIELD: 'text-field',
  TRANSFER_BALANCE: 'transfer-balance',
  TIMEOUT: 'timeout',
  TIMEOUT_WARNING: 'timeout-warning',
  LOCATIONS_WARNING: 'locations-warning',
  TRANSACTION_PROCESSING: 'transaction-processing',
  UPLOAD_PROCESSING: 'upload-processing',
  UPDATE_ERA_PAYMENT: 'update-era-payment',
  UPDATE_PAYMENT_PROVIDER_LOCATION: 'update-payment-provider-location',
  VISIT_SUMMARY_PATIENT_DETAIL: 'visit-summary-patient-detail',
  NEW_CASE_STATEMENT: 'new-case-statement',
  LEDGER_INSURANCE_OUT_OF_COVERAGE: 'ledger-insurance-out-of-coverage',
  UPDATE_ASSOCIATED_CASE: 'update-associated-case',
  UPDATE_APPOINTMENT_TYPE: 'update-appointment-type',
  UPDATE_PROVIDER: 'update-provider',
  SCAN: 'scan',
  NEW_SUPERBILL: 'new-superbill',
  SELECT_ERA_EOB: 'select-era-eob',
  ORIGINAL_REFERENCE_NUMBER: 'original-reference-number',
  MATCH_CLAIM: 'match-claim',
  CLAIM_ACTION: 'claim-action',
  AR_HUB_SAVE_NEW_VIEW: 'ar-hub-save-new-vew',
};
