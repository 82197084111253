import { css, html } from 'lit';

import '../../../../../packages/neb-lit-components/src/components/neb-action-bar';
import '../../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../../packages/neb-lit-components/src/components/controls/neb-button-action';
import '../../../../../packages/neb-lit-components/src/components/tables/neb-table';
import Overlay from '../../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { moveItem } from '../../../../../packages/neb-utils/utils';
import { getArHubCustomViews } from '../../../../api-clients/ar-hub';
import { CSS_SPACING } from '../../../../styles';

export const ELEMENTS = {
  header: { id: 'header' },
  buildNewViewButton: { id: 'build-new-view-button' },
  table: { id: 'table' },
  actionBar: { id: 'action-bar' },
};

export const AR_HUB_CUSTOM_VIEWS_TABLE_CONFIG = [
  {
    key: 'name',
    label: '',
    flex: css`1 0 0`,
    formatter: (value, row) => html`
      <div>
        <neb-text bold>${row.name}</neb-text>
        <div>${row.description}</div>
      </div>
    `,
  },
];

class NebOverlayManageViews extends Overlay {
  static get properties() {
    return {
      savedViews: { type: Array },
      selectedViews: { type: Array },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
        .content {
          width: 500px;
        }

        .header {
          padding: ${CSS_SPACING};
        }

        .button {
          padding: 0 0 ${CSS_SPACING} ${CSS_SPACING};
        }

        neb-table::part(cell-data) {
          padding: ${CSS_SPACING};
        }

        neb-table::part(row-header) {
          display: none;
        }

        neb-table {
          overflow-y: auto;
        }
      `,
    ];
  }

  constructor() {
    super();
    this.__initState();
    this.__initHandlers();
  }

  async connectedCallback() {
    super.connectedCallback();
    const views = await getArHubCustomViews();
    this.savedViews = views.map(view => ({
      ...view,
      selected: false,
    }));
  }

  __initState() {
    super.initState();

    this.savedViews = [];
  }

  __initHandlers() {
    super.initHandlers();

    this.handlers = {
      ...this.handlers,
      buildNewView: () => {
        this.dismiss({ showNewView: true, selectedViews: [] });
      },
      reorder: (fromIndex, toIndex) => {
        this.savedViews = moveItem(this.savedViews, fromIndex, toIndex);
      },
      selectCheckbox: (_, index) => {
        const newSavedViews = [...this.savedViews];
        newSavedViews[index] = {
          ...newSavedViews[index],
          selected: !newSavedViews[index].selected,
        };

        this.savedViews = newSavedViews;
      },
      apply: () =>
        this.dismiss({
          showNewView: false,
          selectedViews: this.getSelectedViewsItems(),
        }),
    };
  }

  getSelectedViews() {
    return this.savedViews.map(item => item.selected);
  }

  getSelectedViewsItems() {
    return this.savedViews.filter(item => item.selected);
  }

  __renderHeader() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Manage Views"
        subTitle="Choose which views to display in the AR HUB."
        .onCancel="${this.handlers.dismiss}"
        showCancelButton
      ></neb-popup-header>
    `;
  }

  renderContent() {
    return html`
      ${this.__renderHeader()}
      <neb-button-action
        id="${ELEMENTS.buildNewViewButton.id}"
        class="button"
        label="Build New View"
        .onClick="${this.handlers.buildNewView}"
      ></neb-button-action>
      <neb-table
        id="${ELEMENTS.table.id}"
        .config="${AR_HUB_CUSTOM_VIEWS_TABLE_CONFIG}"
        .model="${this.savedViews}"
        .reorder="${true}"
        .onReorder="${this.handlers.reorder}"
        .showSelectAll="${true}"
        .onSelectCheckbox="${this.handlers.selectCheckbox}"
        .selectedItems="${this.getSelectedViews()}"
      ></neb-table>
      <neb-action-bar
        id="${ELEMENTS.actionBar.id}"
        confirmLabel="Apply"
        .onConfirm="${this.handlers.apply}"
        .onCancel="${this.handlers.dismiss}"
      ></neb-action-bar>
    `;
  }
}

customElements.define('neb-overlay-manage-views', NebOverlayManageViews);
