import { CHARGES_ORIGIN, EDIT_MODE } from '../../../../utils/neb-charges-util';

export const isEraOrEOB = origin =>
  origin === CHARGES_ORIGIN.ERA || origin === CHARGES_ORIGIN.EOB;

const getSelectedIds = displayItems =>
  displayItems.filter(item => item.checked).map(item => item.id);

const __hasInvoice = displayItems =>
  Boolean(displayItems[0] && displayItems[0].invoiceId);

const __getSelectedDisplayItems = displayItems => {
  const selectedIds = getSelectedIds(displayItems);
  return displayItems.filter(item => selectedIds.includes(item.id));
};

const __hasZeroBalance = displayItems => {
  const balanceTotal = __getSelectedDisplayItems(displayItems);
  return balanceTotal.some(items => items.balance > 0);
};

const __voidChargeButtonIsDisabled = displayItems => {
  const selectedIds = getSelectedIds(displayItems);

  return (
    !selectedIds.length ||
    selectedIds.some(id => {
      const { primaryPaid, patientPaid, secondaryPaid, encounterNumber } =
        displayItems.find(item => item.id === id);

      return primaryPaid || patientPaid || secondaryPaid || encounterNumber;
    })
  );
};

const __returnToChartingButtonIsDisabled = displayItems => {
  const selectedIds = getSelectedIds(displayItems);

  return (
    !selectedIds.length ||
    selectedIds.some(id => {
      const { encounterNumber } = displayItems.find(item => item.id === id);

      const isNotEncounterCharge = !encounterNumber;

      return isNotEncounterCharge;
    })
  );
};

const __hasEncounterCharges = displayItems =>
  displayItems.some(({ encounterId }) => encounterId);

// eslint-disable-next-line complexity
const getDefaultButtons = ({
  editing,
  hasSelection,
  hasZero,
  origin,
  hideEditCharges = false,
}) => [
  ...(hideEditCharges
    ? []
    : [
        {
          disabled: editing || !hasSelection,
          name: 'editCharges',
          icon: 'edit',
          label: 'Edit Charges',
          pos: 2,
        },
      ]),
  ...(origin === 'ledger'
    ? [
        {
          disabled: editing || !hasSelection,
          name: 'allocatePayment',
          icon: 'checkmarkOutline',
          label: 'Allocate Payment',
          pos: 3,
        },
      ]
    : []),
  {
    disabled: !hasSelection || editing || hasZero === false,
    name: 'transferBalance',
    icon: 'replace',
    label: 'Transfer Balance',
    pos: 13,
  },
];

const getLedgerChargesButtons = ({
  editing,
  hasInvoice,
  displayItems,
  autoPostCharges,
}) => [
  {
    disabled: editing || !hasInvoice,
    name: 'addCharge',
    icon: 'plus',
    label: 'Add New Charge',
    pos: 0,
  },
  {
    disabled:
      editing || __voidChargeButtonIsDisabled(displayItems) || hasInvoice,
    name: 'voidCharges',
    icon: 'void',
    label: 'Void Charges',
    pos: 7,
  },
  ...(!autoPostCharges
    ? [
        {
          disabled: editing || __returnToChartingButtonIsDisabled(displayItems),
          name: 'unpost',
          icon: 'return',
          label: 'Unpost',
          pos: 8,
        },
      ]
    : []),
];

const getLedgerInvoiceButtons = ({
  editing,
  hasSelection,
  hasInvoice,
  hasEncounterCharges,
}) => [
  {
    disabled: !editing && hasSelection ? hasInvoice : true,
    name: 'createInvoice',
    icon: 'noteAdd',
    label: 'Create Invoice',
    pos: 5,
  },
  {
    disabled: !editing && hasSelection ? !hasInvoice : true,
    name: 'removeFromInvoice',
    icon: 'minus',
    label: 'Remove From Invoice',
    pos: 6,
  },
  {
    disabled: editing || !hasInvoice || !hasEncounterCharges,
    name: 'editSupplementalInformation',
    icon: 'plus',
    label: 'Supplemental Information',
    pos: 10,
  },
];

const getLedgerChartingButtons = ({
  hasChartingPermission,
  editing,
  hasEncounterCharges,
}) =>
  hasChartingPermission
    ? [
        {
          disabled: editing || !hasEncounterCharges,
          name: 'manageEncounter',
          icon: 'charting',
          label: 'Manage Encounter',

          pos: 9,
        },
      ]
    : [];

const getLedgerButtons = ({
  origin,
  editing,
  hasInvoice,
  hasSelection,
  displayItems,
  hasEncounterCharges,
  hasChartingPermission,
  autoPostCharges,
}) =>
  origin === CHARGES_ORIGIN.LEDGER
    ? [
        ...getLedgerInvoiceButtons({
          editing,
          hasSelection,
          hasInvoice,
          hasEncounterCharges,
        }),
        ...getLedgerChargesButtons({
          editing,
          hasInvoice,
          displayItems,
          autoPostCharges,
        }),
        ...getLedgerChartingButtons({
          hasChartingPermission,
          editing,
          hasEncounterCharges,
        }),
      ]
    : [];

const getFifoButtons = ({ hasFifo, editing, hasSelection }) =>
  hasFifo
    ? [
        {
          disabled: editing || !hasSelection,
          name: 'autoAllocatePayment',
          icon: 'flashAuto',
          label: 'Auto Allocate Payment',
          pos: 4,
        },
      ]
    : [];

const getEraEobButtons = ({ origin, hasSelection, editing }) =>
  isEraOrEOB(origin)
    ? [
        {
          disabled: editing || !hasSelection,
          name: 'removeCharges',
          icon: 'minus',
          label: `Remove From ${origin.toUpperCase()}`,
          pos: 11,
        },
        {
          disabled: editing || !hasSelection,
          name: 'createSecondaryClaims',
          icon: 'paperClaims',
          label: 'Create Secondary Claims',
          pos: 12,
        },
      ]
    : [];

export const getChargesManagementButtons = ({
  displayItems,
  editMode,
  origin,
  hasFifo,
  hasChartingPermission,
  autoPostCharges,
  hideEditCharges = false,
}) => {
  const hasInvoice = __hasInvoice(displayItems);
  const hasSelection = Boolean(getSelectedIds(displayItems).length);
  const hasZero = __hasZeroBalance(displayItems);
  const editing = editMode !== EDIT_MODE.DISABLED;
  const hasEncounterCharges = __hasEncounterCharges(displayItems);

  return [
    ...getDefaultButtons({
      editing,
      hasSelection,
      hasZero,
      origin,
      hideEditCharges,
    }),
    ...getLedgerButtons({
      origin,
      editing,
      hasInvoice,
      hasSelection,
      displayItems,
      hasChartingPermission,
      hasEncounterCharges,
      autoPostCharges,
    }),
    ...getFifoButtons({ hasFifo, editing, hasSelection }),
    ...getEraEobButtons({
      origin,
      hasSelection,
      editing,
    }),
  ]
    .sort(({ pos: a }, { pos: b }) => a - b)
    .map(({ pos, ...button }) => button);
};
