/* 
    WARNING!!!!

  This component extends NebLightDom which does not use shadowDom.

  Any styling or element changes can have global effects.
*/

import '../../../neb-dialog/neb-banner-controller';
import '../../../neb-lit-components/src/components/global-loading/neb-global-loading-indicator';
import '../../../neb-lit-components/src/components/login/neb-login-controller';
import '../../../neb-lit-components/src/components/neb-pendo';
import '../../../neb-login/neb-timeout';
import '../../../neb-styles/neb-styles';
import '../../../neb-utils/fetch-tracker';
import '../../../neb-www-practice-charting/src/components/summary/neb-encounter-overlay-summary';
import '../../../../src/components/integrations/neb-userpilot';

import { configure as configurePopupStack, popPopup } from '@neb/popup';
import { EVENT_ROUTE_CHANGE } from '@neb/router';
import { html, css, unsafeCSS } from 'lit';

import { LightDom } from '../../../../src/components/misc/neb-light-dom';
import { DatadogRumService } from '../../../../src/services/dd-rum';
import { PracticeSessionService } from '../../../../src/services/practice-session';
import { RENDERER_CONTEXT } from '../../../../src/utils/context/renderers';
import { initDuplicateApiCallsLog } from '../../../../src/utils/feature-init';
import { getPracticeUser } from '../../../neb-api-client/src/permissions-api-client';
import { fetchPracticeInformation } from '../../../neb-api-client/src/practice-information';
import { APP_PAGE } from '../../../neb-app-layout/neb-app-layout';
import { initialize as initLayoutMedia } from '../../../neb-app-layout/neb-layout-media-store';
import { initialize as initNavSubscribe } from '../../../neb-app-layout/neb-nav-store';
import { fetchAppointmentTypes } from '../../../neb-appointment-type/actions/appointment-type';
import {
  fetchAppointments,
  initSubscribe as initAppointmentsSubscribe,
} from '../../../neb-calendar/neb-appointments-state';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../neb-lit-components/src/utils/overlay-constants';
import { setItem } from '../../../neb-login/neb-session-state';
import nebMaterialDesign from '../../../neb-material-design/src/neb-material-design';
import { mapPopupStackRenderers } from '../../../neb-popup/src/renderers';
import { fetchProviderAvailability } from '../../../neb-provider/neb-provider-availability-state';
import { authFetchProviders } from '../../../neb-provider/neb-provider-state';
import { store, connect } from '../../../neb-redux/neb-redux-store';
import { CollapsingPopupService } from '../../../neb-redux/services/collapsing-popup';
import { navigate } from '../../../neb-route/neb-route-state';
import { createRouteParser } from '../../../neb-route/neb-route-util';
import { importedFonts } from '../../../neb-styles/neb-ckeditor-fonts';
import {
  hasFeatureOrBeta,
  FEATURE_FLAGS,
} from '../../../neb-utils/feature-util';
import {
  storeSessionToLocalStorage,
  performLogout,
  performRenew,
} from '../../../neb-utils/session';

export const ELEMENTS = {
  appLayout: {
    id: 'neb-practice-app-layout',
  },
  bannerController: {
    id: 'neb-practice-app-message-dialog',
  },
  globalLoadingOverlay: {
    id: 'neb-practice-app-global-loading-overlay',
  },
  loginPage: {
    id: 'neb-practice-app-login-page',
  },
};

const ROUTE_PARSER = createRouteParser('/:app');

const RENDERER_POPUPS = mapPopupStackRenderers();
configurePopupStack(store);

const APP_MENU_LEVEL2_ITEMS = Object.values(APP_PAGE)
  .filter(x => x.level === 2)
  .map(x => ({ name: x.name, pathHash: `#${x.path}` }));

class NebPracticeApp extends connect(store)(LightDom) {
  static get properties() {
    return {
      __appointmentTypes: Array,
      __currentOverlayKey: String,
      __hasSession: Boolean,
      __layout: String,
      __page: String,
      __popupCollapsed: {
        type: Boolean,
        reflect: true,
        attribute: 'popupCollapsed',
      },
      __requiresTenantSelection: Boolean,
      __route: String,
      __session: Object,
      __user: Object,
    };
  }

  constructor() {
    super();

    this.__initState();
    this.__initServices();
    this.__initHandlers();

    nebMaterialDesign.init();
  }

  __initState() {
    this.__appointmentTypes = [];
    this.__currentOverlayKey = '';
    this.__hasSession = false;
    this.__requiresTenantSelection = false;
    this.__layout = '';
    this.__menuOptions = {
      'Edit Profile': ({ userId }) => this.__updateUser(userId),
      'Change Practice': ({ tenantId }) => this.__changePractice(tenantId),
      'Log Out': () => this.__logout(),
    };

    this.__numPopups = 0;
    this.__page = '';
    this.__popupCollapsed = false;
    this.__route = '';
    this.__session = null;
    this.__user = null;
    this.__hasAdvancedPracticeMenuFeature = false;
  }

  __initServices() {
    this.__collapsingPopupService = new CollapsingPopupService(v => {
      this.__popupCollapsed = v;
    });

    this.__practiceSessionService = new PracticeSessionService(
      ({ hasSession, requiresTenantSelection, user }) => {
        this.__hasSession = hasSession;
        this.__requiresTenantSelection = requiresTenantSelection;
        this.__user = user;
      },
    );

    this.__ddRumService = new DatadogRumService();
  }

  __initHandlers() {
    this.__handlers = {
      logout: () => {
        this.__logout();
      },
      navigate: ({ value: event }) => {
        this.__selectEncounter(event);
      },
      navigateManually: name => this.__navigateManually(name),
      userMenuOptions: (label, options) => this.__menuOptions[label](options),
    };
  }

  connectedCallback() {
    super.connectedCallback();
    initAppointmentsSubscribe();
    initLayoutMedia();
    initNavSubscribe();

    this.__collapsingPopupService.connect();
    this.__practiceSessionService.connect();
    this.__ddRumService.connect();

    window.addEventListener(EVENT_ROUTE_CHANGE, e => {
      store.dispatch(
        navigate(`#${e.detail}`, {
          sync: false,
          preventAppendHistory: false,
          replaceHistoryIfPrevented: false,
        }),
      );
    });

    store.dispatch(navigate(window.location.hash));
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    this.__collapsingPopupService.disconnect();
    this.__practiceSessionService.disconnect();
    this.__ddRumService.disconnect();
  }

  __getPageName(routeData) {
    if (!routeData.active) {
      return null;
    }

    if (this.__hasAdvancedPracticeMenuFeature) {
      const path = String(routeData.path);
      const level2MenuItem = APP_MENU_LEVEL2_ITEMS.find(x =>
        path.startsWith(x.pathHash),
      );

      if (level2MenuItem) {
        return level2MenuItem.name;
      }
    }

    return routeData.data.app;
  }

  _stateChanged({
    layoutMedia,
    route,
    session,
    popup,
    appointmentTypes: { items: appointmentTypes },
  }) {
    const routeData = ROUTE_PARSER.parse(route.hash);

    // These variables are for the charting overlay.
    this.__appointmentTypes = appointmentTypes;

    this.__layout = layoutMedia.layout;
    this.__session = session.item;

    if (this.__navigateToDefault(route.navigating, routeData, session.item)) {
      return;
    }

    this.__route = route.hash;
    this.__page = this.__getPageName(routeData);

    if (popup.main) {
      this.__numPopups = popup.main.length;
    }

    if (popup.overlays) {
      const stack = popup.overlays;
      const lastOverlay = stack[stack.length - 1];
      this.__currentOverlayKey = lastOverlay ? lastOverlay.key : '';
    }
  }

  __navigateToDefault(navigating, route, session) {
    if (navigating || !session || (route.active && route.data.app !== '')) {
      return false;
    }

    store.dispatch(navigate('#/patients'));
    return true;
  }

  async __sessionDataChanged() {
    if (this.__hasSession) {
      const { tenantId } = this.__session;

      await store.dispatch(fetchPracticeInformation(tenantId));

      store.dispatch(
        fetchAppointments(store.getState().appointments.targetDate),
      );

      store.dispatch(fetchProviderAvailability());
      store.dispatch(authFetchProviders());
      store.dispatch(fetchAppointmentTypes());
    }

    if (this.__hasSession || this.__requiresTenantSelection) {
      this.__startTimer();
    }
  }

  async __updateUser(userId) {
    const item = await getPracticeUser(userId);
    const result = await openOverlay(OVERLAY_KEYS.USER_ADD, {
      item,
    });

    if (result) {
      const { name, photo } = result;
      store.dispatch(
        setItem({
          ...store.getState().session.item,
          firstName: name.first,
          lastName: name.last,
          profileImgUrl: photo.imageUrl,
        }),
      );
    }
  }

  async __changePractice(tenantId) {
    window.location.hash = '#/';
    storeSessionToLocalStorage();
    await performRenew({ ...this.__session, tenantId });
    this.__appReload();
  }

  __logout() {
    performLogout();
    this.__appReload();
  }

  __appReload() {
    window.location.reload(true);
  }

  __startTimer() {
    if (this.getElementById('timeout')) {
      this.getElementById('timeout').startTimer();
    }
  }

  async updated(changedProps) {
    if (
      changedProps.has('__requiresTenantSelection') ||
      (changedProps.has('__hasSession') && this.__hasSession)
    ) {
      await this.__sessionDataChanged();
    }
  }

  update(changedProps) {
    if (changedProps.has('__route') && this.__numPopups) {
      popPopup();
    }

    super.update(changedProps);
  }

  async firstUpdated() {
    initDuplicateApiCallsLog();
    this.__hasAdvancedPracticeMenuFeature = await hasFeatureOrBeta(
      FEATURE_FLAGS.ADVANCED_PRACTICE_MENU,
    );
  }

  static get styles() {
    return css`
      ${unsafeCSS(importedFonts)}
      .neb-practice-app-layout {
        display: flex;
        width: 100%;
      }

      .neb-practice-app-login {
        display: flex;
        width: 100%;
        height: 100%;
        --neb-charting-agenda-view-width: 500px;
      }

      .neb-practice-app-global-loading-overlay {
        position: absolute;
        z-index: 200;
      }

      .neb-practice-app-popup-stack {
        z-index: 113;
        overflow-y: hidden;
      }

      .neb-practice-app-context-stack {
        z-index: 112;
      }

      [popupCollapsed] .neb-practice-app-popup-stack {
        flex: 1;
        width: 100%;
      }

      .neb-practice-app-banner-stack {
        z-index: 120;
      }
    `;
  }

  __renderApp() {
    return html`
      <neb-app-layout
        id="${ELEMENTS.appLayout.id}"
        class="neb-practice-app-layout"
        .appointmentTypes="${this.__appointmentTypes}"
        .currentOverlayKey="${this.__currentOverlayKey}"
        .layout="${this.__layout}"
        .page="${this.__page}"
        .popupCollapsed="${this.__popupCollapsed}"
        .route="${this.__route}"
        .onUserMenuOptions="${this.__handlers.userMenuOptions}"
        .user="${this.__user}"
        .customContext="${true}"
      >
      </neb-app-layout>
    `;
  }

  __renderLogin() {
    return html`
      <neb-login-controller
        id="${ELEMENTS.loginPage.id}"
        class="neb-practice-app-login"
        .layout="${this.__layout}"
        @neb-logout="${this.__handlers.logout}"
        require-tenant-context
      >
      </neb-login-controller>
    `;
  }

  renderContent() {
    return html`
      ${this.__hasSession ? this.__renderApp() : this.__renderLogin()}

      <neb-global-loading-indicator
        id="${ELEMENTS.globalLoadingOverlay.id}"
        class="neb-practice-app-global-loading-overlay"
      ></neb-global-loading-indicator>

      <zen-popup-stack
        class="neb-practice-app-popup-stack"
        .layout="${this.__layout}"
        .renderers="${RENDERER_POPUPS}"
      ></zen-popup-stack>

      <zen-popup-stack
        class="neb-practice-app-context-stack"
        key="context"
        hideBlocker
        .layout="${this.layout}"
        .renderers="${RENDERER_CONTEXT}"
      ></zen-popup-stack>

      <neb-banner-controller
        id="${ELEMENTS.bannerController.id}"
        class="neb-practice-app-banner-stack"
      ></neb-banner-controller>

      <neb-timeout
        id="timeout"
        @neb-timeout-end-session="${this.__handlers.logout}"
      ></neb-timeout>

      <neb-pendo></neb-pendo>
      <neb-userpilot></neb-userpilot>
    `;
  }
}

customElements.define('neb-practice-app', NebPracticeApp);
