import '../../../../neb-material-design/src/components/neb-md-textarea';
import '../../../../neb-material-design/src/components/neb-md-textfield';
import '../controls/neb-button-action';
import '../inputs/neb-select';
import '../tables/neb-table';
import '../tables/neb-table-encounter-charges';
import '../neb-encounter-balance';
import '../neb-cell-fee-schedule';
import '../neb-radio-button';
import '../neb-tooltip';
import '../neb-tab-old';
import '../neb-tabs-old';
import './neb-appointment-details-lit';
import '../../../../../src/components/misc/neb-action-bar-check-in-out';
import '../../../../neb-www-practice-charting/src/components/treatmentPlan/neb-charting-treatment-plan-summary';
import { inRange, isRequired } from '@neb/form-validators';
import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import equal from 'fast-deep-equal';
import { html, css } from 'lit';

import { fetchOne } from '../../../../../src/api-clients/clinical-codes';
import { getEncounterBalance } from '../../../../../src/api-clients/encounter-balance';
import { getLocations } from '../../../../../src/api-clients/locations';
import NebFormAppointment from '../../../../../src/components/forms/appointments/neb-form-appointment';
import { UpdateNotificationService } from '../../../../../src/services/update-notifications';
import {
  CSS_SPACING,
  CSS_COLOR_BLACK,
  CSS_COLOR_WHITE,
  CSS_COLOR_GREY_3,
  CSS_COLOR_GREY_7,
  CSS_COLOR_HIGHLIGHT,
  CSS_FONT_SIZE_HEADER,
  CSS_FONT_WEIGHT_BOLD,
  CSS_WARNING_COLOR,
  CSS_BANNER_ERROR_COLOR,
  CSS_BANNER_SUCCESS_COLOR,
  CSS_COLOR_YELLOW,
  CSS_COLOR_GREY_1,
  CSS_COLOR_ERROR,
} from '../../../../../src/styles';
import { ADD_ONS, hasAddOn } from '../../../../../src/utils/add-ons';
import {
  findLineItemAllocations,
  hasBillingInfoChanged,
  openRemoveAllocationPopup,
  shouldRepost,
} from '../../../../../src/utils/check-in-out';
import { getRTEStatusIcon } from '../../../../../src/utils/real-time-eligibility';
import { openDoctibleSsoLink } from '../../../../../src/utils/sso';
import {
  ROOM_CHECKED_IN_ERROR,
  SELECT_LOCATION_MESSAGE_FOR_APPTS,
  WARNING_MULTIPLE_INVOICES_ASSOCIATED_AT_APPOINTMENT_LEVEL,
  WARNING_SINGLE_INVOICE_ASSOCIATED_AT_APPOINTMENT_LEVEL,
} from '../../../../../src/utils/user-message';
import {
  updateAppointmentBillingInfo,
  createAppointmentBillingInfo,
} from '../../../../neb-api-client/src/appointment-billing-info-api-client';
import {
  getInvoicesFromEncounters,
  getEncounter,
} from '../../../../neb-api-client/src/encounters-api-client';
import {
  APPOINTMENT_BILLING_TYPE,
  APPOINTMENT_BILLING_TYPES,
  mapAppointmentBillingInfoToServerModel,
} from '../../../../neb-api-client/src/mappers/appointment-billing-info-mapper';
import {
  mapToPayerPlan,
  mapToPolicyHolder,
} from '../../../../neb-api-client/src/mappers/patient-case-mapper';
import * as patientApiClient from '../../../../neb-api-client/src/patient-api-client';
import { getPatientBalance } from '../../../../neb-api-client/src/patient-totals-api-client';
import { getElectronicPayment } from '../../../../neb-api-client/src/payments/electronic-payments-api-client';
import { getScheduleStatus } from '../../../../neb-api-client/src/payments/scheduled-payments-api-client';
import {
  printReceipts,
  getPayments,
  getPatientPaymentsForEncounter,
} from '../../../../neb-api-client/src/payments-api-client';
import { getChartingPermissions } from '../../../../neb-api-client/src/permissions-api-client';
import {
  getUpdatedBilledAmount,
  getSettingCharges,
} from '../../../../neb-api-client/src/services/encounter-charge';
import addCharges from '../../../../neb-api-client/src/services/encounter-charge/add-charges';
import checkUnallocationAuthorization from '../../../../neb-api-client/src/services/encounter-charge/check-unallocation-authorization';
import saveCharges from '../../../../neb-api-client/src/services/encounter-charge/save-charges';
import { PRACTICE_SETTINGS } from '../../../../neb-api-client/src/services/practice-settings';
import {
  openError,
  openSuccess,
  openWarning,
} from '../../../../neb-dialog/neb-banner-state';
import { FULL_DATE_FORMAT } from '../../../../neb-input/nebFormatUtils';
import { openDirtyPopup } from '../../../../neb-popup';
import { POPUP_RENDER_KEYS } from '../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../neb-redux/neb-redux-store';
import { AppointmentDetailsMomentService } from '../../../../neb-redux/services/appointment-details-moment';
import { AppointmentStoreService } from '../../../../neb-redux/services/appointment-store';
import { LAYOUT_TYPE } from '../../../../neb-redux/services/layout';
import { MdcProviderService } from '../../../../neb-redux/services/mdc-provider';
import { roomConflictOverridePopup } from '../../../../neb-utils/calendar-resources-util';
import { parseDate } from '../../../../neb-utils/date-util';
import { mapToModel } from '../../../../neb-utils/fee-schedule';
import {
  DEFAULT_NAME_OPTS,
  objToName,
  centsToCurrency,
  formatDollarAmount,
} from '../../../../neb-utils/formatters';
import { dateToIcd10Year } from '../../../../neb-utils/icd10-util';
import { BILL_TYPE } from '../../../../neb-utils/neb-ledger-util';
import {
  isElectronicPayment,
  formatRefundedSplitPayment,
} from '../../../../neb-utils/neb-payment-util';
import { printPdf } from '../../../../neb-utils/neb-pdf-print-util';
import {
  REFRESH_CHANGE_TYPE,
  sendRefreshNotification,
} from '../../../../neb-utils/neb-refresh';
import { mapToPatientModel } from '../../../../neb-utils/patient';
import {
  NO_REMAINING_AUTHORIZATIONS_MESSAGE,
  hasAuthorizationRemaining,
} from '../../../../neb-utils/patientAuthorization';
import {
  FINANCIAL_CLASSES,
  formatCoverageDates,
} from '../../../../neb-utils/patientInsurance';
import { rteEnabledInsurance } from '../../../../neb-utils/payer-plan-util';
import {
  getAppointmentListData,
  RECEIPT_APPOINTMENT_LIMIT,
} from '../../../../neb-utils/pdf/print-upcoming-appointments';
import { updateInsuranceFromRTE } from '../../../../neb-utils/real-time-eligibility';
import * as selectors from '../../../../neb-utils/selectors';
import { required } from '../../../../neb-utils/validators';
import { createModelBillingInfo } from '../../utils/appointment-billing-info-defaults-validator';
import { INSURANCE_LEVELS } from '../../utils/insurance-util';
import { generateNextApointmentMessage } from '../../utils/next-appointment-util';
import { openOverlay, OVERLAY_KEYS } from '../../utils/overlay-constants';
import printUpcomingAppointments from '../../utils/pdf/print-upcoming-appointments';
import { modifiersSelector } from '../field-groups/neb-modifiers';
import { ELEMENTS as ELEMENTS_BASE, NebFormOld } from '../forms/neb-form-old';
import { formatGuarantorName } from '../guarantor/guarantor-util';
import { BUTTON_ROLE } from '../neb-button';
import '../neb-loading-overlay';
import '../../../../neb-www-practice-charting/src/components/diagnosis/neb-encounter-diagnosis-table';
import {
  EMPTY_PACKAGE,
  SELF_GUARANTOR,
} from '../overlays/scheduling/neb-overlay-check-in-out';

export const ELEMENTS = {
  ...ELEMENTS_BASE,
  appointmentInfo: { id: 'appointment-info' },
  buttonAddCharge: { id: 'button-add-charge' },
  buttonAddDiagnosis: { id: 'button-add-diagnosis' },
  buttonAddGuarantor: { id: 'button-add-guarantor' },
  buttonAddInsuranceGuarantor: { id: 'button-add-insurance-guarantor' },
  buttonAddInsurance: { id: 'button-add-insurance' },
  buttonAddNewAppointment: { id: 'button-add-new-appointment' },
  buttonAddCase: { id: 'button-add-case' },
  printUpcomingApptsButton: { id: 'print-upcoming-appts-button' },
  buttonAddPackage: { id: 'button-add-package' },
  buttonAssociateFeeSchedule: { id: 'button-associate-fee-schedule' },
  buttonReceipt: { id: 'button-receipt' },
  buttonPayment: { id: 'button-payment' },
  buttonDiscount: { id: 'button-discount' },
  buttonPrintReceipt: { id: 'button-print-receipt' },
  chargeTable: { id: 'charge-table' },
  container: { id: 'container' },
  containerBillingRadioButtons: { id: 'container-radio-buttons' },
  containerForm: { id: 'container-form' },
  copayAmount: { id: 'copay-amount' },
  ctEngageFeedBackRequest: { id: 'ct-engage-feedback-request' },
  encounterBalance: { id: 'encounter-balance' },
  feeScheduleLabel: { id: 'label-fee-schedule' },
  feeSchedulesSelect: { id: 'select-fee-schedules' },
  iconAuthorizationWarning: {
    id: 'icon-authorization-warning',
  },
  buttonCheckRealTimeEligibility: { id: 'button-check-real-time-eligibility' },
  iconRealTimeEligibilityStatus: { id: 'icon-real-time-eligibility-status' },
  textRealTimeEligibilityDate: { id: 'text-real-time-eligibility-date-info' },
  textRealTimeEligibilityDesc: { id: 'text-real-time-eligibility-desc' },
  insuranceTabs: { id: 'insurance-tabs' },
  labelCoinsurance: { id: 'label-coinsurance' },
  labelCopay: { id: 'label-copay' },
  labelDeductible: { id: 'label-deductible' },
  labelOutOfPocket: { id: 'max-out-of-pocket' },
  labelPatientName: { id: 'label-patient-name' },
  labelTreatmentPlan: { id: 'label-treatment-plan' },
  patientBalanceLink: { id: 'patient-balance-link' },
  payerName: { id: 'payer-name' },
  planName: { id: 'plan-name' },
  coverageDates: { id: 'coverage-dates' },
  policyHolder: { id: 'policy-holder' },
  radioButtonCarePackage: { id: 'radio-button-care-package' },
  radioButtonCase: { id: 'radio-button-case' },
  radioButtonInsurance: { id: 'radio-button-insurance' },
  radioButtonSelfPay: { id: 'radio-button-self-pay' },
  requestNextAppointment: { id: 'request-next-appointment' },
  packageDescription: { id: 'package-description' },
  packageSummary: { id: 'package-summary' },
  selectAppointmentType: { id: 'select-appointment-type' },
  selectRoom: { id: 'select-room' },
  selectAuthorization: { id: 'select-authorization' },
  selectCase: { id: 'select-case' },
  selectGuarantor: { id: 'select-guarantor' },
  selectInsuranceGuarantor: { id: 'select-insurance-guarantor' },
  selectPrimaryInsurance: { id: 'select-primary-insurance' },
  selectProvider: { id: 'select-provider' },
  selectSecondaryInsurance: { id: 'select-secondary-insurance' },
  selectSubscription: { id: 'select-subscription' },
  selectTertiaryInsurance: { id: 'select-tertiary-insurance' },
  tableEncounterCharges: { id: 'table-encounter-charges' },
  tableEncounterDiagnosis: { id: 'table-encounter-diagnosis' },
  textNote: { id: 'text-note' },
  tooltipAppointmentType: { id: 'tooltip-appointment-type' },
  tooltipAuthorization: { id: 'tooltip-authorization' },
  tooltipCase: { id: 'tooltip-case' },
  tooltipFeeSchedules: { id: 'tooltip-fee-schedules' },
  tooltipGuarantor: { id: 'tooltip-guarantor' },
  tooltipInsuranceGuarantor: { id: 'tooltip-insurance-guarantor' },
  tooltipPrimaryInsurance: { id: 'tooltip-primary-insurance' },
  tooltipProvider: { id: 'tooltip-provider' },
  tooltipSecondaryInsurance: { id: 'tooltip-secondary-insurance' },
  tooltipSubscription: { id: 'tooltip-subscription' },
  tooltipSubscriptionInfo: { id: 'tooltip-subscription-info' },
  tooltipTertiaryInsurance: { id: 'tooltip-tertiary-insurance' },
  tooltipRoom: { id: 'tooltip-room' },
  treatmentPlan: { id: 'treatment-plan' },
  spinner: { id: 'spinner' },
  autoPointDiagnosesButton: {
    id: 'auto-point-diagnoses-button',
  },
  printAppointmentsButtonActions: {
    id: 'print-appointments-button-actions',
  },
  activePackagesRequiredWarning: {
    id: 'active-packages-required-warning',
  },
  noAvailablePackagesWarning: {
    id: 'no-available-packages-warning',
  },
  availablePackagesContainer: {
    id: 'available-packages-container',
  },
  availablePackagesLink: {
    id: 'available-packages-link',
  },
  printActionsDropdown: { id: 'print-actions-dropdown' },
};

const ITEM_EMPTY = {
  label: '',
  data: { id: null },
};

const ITEM_EMPTY_NO_DATA = { id: null, label: '' };

const NO_CHARGES_APPOINTMENT = 'There are no charges for this encounter.';

const NO_ENCOUNTER_APPOINTMENT =
  'There is no encounter associated with this appointment.';

const NO_DIAGNOSIS_APPOINTMENT = 'There are no diagnoses for this encounter.';

const LABEL_ENCOUNTER_EXISTS =
  'This field is only editable from the ledger if this appointment is associated with an encounter that has posted charges.';

const LABEL_ENCOUNTER_SIGNED =
  'This field is not editable if this appointment is associated with a signed encounter. To edit this field, the associated encounter must be reopened.';

const NO_LINKED_AUTH_TOOLTIP =
  'Link an Authorization to the Case selected in order to apply it to this appointment.';

export const INACTIVE_PRIMARY_PLAN_ERROR =
  'Active Primary Plan on Case required to';
export const INACTIVE_SECONDARY_PLAN_ERROR =
  'Active Secondary Plan on Case required to';
export const INACTIVE_PRIMARY_SECONDARY_PLAN_ERROR =
  'Active Primary and Secondary Plan on Case required to';
export const INACTIVE_PACKAGE_ERROR = 'Active Package required to';

export const NO_AVAILABLE_PACKAGE_ERROR = 'No Available Care Packages';

const validatePackage = (v, _, state) => {
  if (
    state.billingInfo.caseBillTypeOverride &&
    state.billingInfo.billType === APPOINTMENT_BILLING_TYPE.CarePackage
  ) {
    return !!v.data.active;
  }
  return true;
};

const validateInsurance = (v, _, state) => {
  if (
    state.billingInfo.caseBillTypeOverride &&
    state.billingInfo.billType === APPOINTMENT_BILLING_TYPE.Insurance
  ) {
    return !!v.data.active;
  }
  return true;
};

const validateSecondaryInsurance = (v, _, state) => {
  if (
    v.data.id &&
    state.billingInfo.caseBillTypeOverride &&
    state.billingInfo.billType === APPOINTMENT_BILLING_TYPE.Insurance
  ) {
    return !!v.data.active;
  }
  return true;
};

export class NebFormCheckInOut extends NebFormOld {
  static get properties() {
    return {
      __patientLoaded: Boolean,
      __hasAddOnCtEngage: Boolean,
      __isEncounterEditable: Boolean,
      __isEncounterSigned: Boolean,
      __selectedInsuranceTab: Boolean,
      __patientBalance: Number,
      __patient: Object,
      __encounterBalanceModel: Object,
      __providers: Array,
      __patientPayments: Array,
      __appointmentTypes: Array,
      __availableTypes: Array,
      __primaryInsuranceItems: Array,
      __secondaryInsuranceItems: Array,
      __tertiaryInsuranceItems: Array,
      __selectedAuthorization: Object,
      __displayAuthorizations: Array,
      __allocatedPayments: Array,
      __carePackageChargesId: Array,
      __loadingLabel: String,
      __settingCharges: Array,
      __checkingRTE: Boolean,
      __carePackageWithInsuranceEnabled: Boolean,
      __multiCarePackageEnabled: Boolean,
      __autoPostCharges: Boolean,
      __associatedInvoices: Array,
      __formattedCodes: Array,

      practiceSettings: Object,
      caseBillTypeOverride: Boolean,
      serverAppointmentBillingInfo: Object,
      disableChargeDetails: Boolean,
      savingError: Boolean,
      appointmentStart: Date,
      nextAppointment: Object,
      authorizations: Array,
      codes: Array,
      invoiceIds: Array,
      cases: Array,
      charges: Array,
      lineItems: Array,
      feeScheduleItems: Array,
      allFeeScheduleItems: Array,
      guarantors: Array,
      insuranceItems: Array,
      packages: Array,
      unmodifiedPackages: Array,
      activePackages: Array,
      usedCounts: Array,
      rooms: Array,
      checkedIn: {
        type: Boolean,
        reflect: true,
      },
      encounterCharges: Array,
      isLoading: Boolean,
      chartingPermission: {
        type: Boolean,
        reflect: true,
      },
      selectedLocation: Object,
      createEncounterAtCheckIn: {
        type: Boolean,
        reflect: true,
      },
      forceEncounterCreation: Boolean,
      treatmentPlanPhases: Array,
      __icd10Year: Number,
    };
  }

  static createModel() {
    return {
      id: null,
      patientId: null,
      patient: null,
      rrule: null,
      encounterId: null,
      appointmentTypeId: null,
      caseId: null,
      patientAuthorizationId: null,
      providerId: null,
      roomId: null,
      resourceId: null,
      note: '',
      details: {
        appointmentTypeName: '',
        durationDisplay: '',
        providerDisplayName: '',
        startDisplayDate: '',
      },
      billingInfo: createModelBillingInfo(),
      charges: [],
      codes: [],
      invoiceIds: [],
      lineItems: [],
      locationId: '',
      signed: false,
      createEncounter: false,
      selfCheckIn: false,
    };
  }

  initState() {
    super.initState();

    this.__patientLoaded = false;
    this.__hasAddOnCtEngage = false;
    this.__isEncounterEditable = false;
    this.__isEncounterSigned = false;
    this.__insuranceNavItems = this.__genInsuranceNavItems();
    this.__primaryInsuranceItems = [];
    this.__secondaryInsuranceItems = [];
    this.__tertiaryInsuranceItems = [];
    this.__providers = [];
    this.__selectedInsuranceTab = INSURANCE_LEVELS.PRIMARY;
    this.__patientBalance = 0;
    this.__patientPayments = [];
    this.__patient = null;
    this.__appointmentTypes = [];
    this.__availableTypes = [];
    this.__displayAuthorizations = [];
    this.__selectedAuthorization = null;
    this.__allocatedPayments = [];
    this.__carePackageChargesId = [];
    this.__hasAddOnCTVerify = false;
    this.__encounterBalanceModel = {
      billType: APPOINTMENT_BILLING_TYPE.SelfPay,
      payments: [],
      allocatedPayments: [],
      totalCharges: [],
      totalAllCharges: 0,
      adjustments: 0,
      patientOwed: 0,
      payerOwed: 0,
      totalPayments: 0,
      totalDiscounts: 0,
      totalBalance: 0,
      totalTaxes: 0,
      patientPackageId: null,
    };

    this.__loadingLabel = '';
    this.__settingCharges = [];
    this.__checkingRTE = false;
    this.__autoDiagnosisPointing = false;
    this.__icd10Year = null;
    this.__carePackageWithInsuranceEnabled = false;
    this.__multiCarePackageEnabled = false;
    this.__autoPostCharges = false;
    this.__associatedInvoices = [];
    this.__formattedCodes = [];

    this.caseBillTypeOverride = false;
    this.disableChargeDetails = false;
    this.savingError = false;
    this.checkedIn = false;
    this.nextAppointment = {};
    this.authorizations = [];
    this.cases = [];
    this.charges = [];
    this.lineItems = [];
    this.feeScheduleItems = [];
    this.allFeeScheduleItems = [];
    this.guarantors = [];
    this.insuranceItems = [];
    this.packages = [];
    this.unmodifiedPackages = [];
    this.usedCounts = [];
    this.activePackages = [];
    this.appointmentStart = null;
    this.rooms = [];
    this.encounterCharges = [];
    this.treatmentPlanPhases = [];
    this.isLoading = false;
    this.chartingPermission = false;
    this.forceEncounterCreation = false;
    this.serverAppointmentBillingInfo = null;
    this.practiceSettings = {};

    this.__menuItems = Object.freeze({
      SELECT_LOCATION: {
        label: 'Select Location',
        onSelect: () => this.handlers.printUpcomingAppointmentsWithLocation(),
      },
    });

    this.createEncounterAtCheckIn = false;

    this.BILLING_OPTIONS = Object.freeze({
      [APPOINTMENT_BILLING_TYPE.SelfPay]: {
        label: 'Self Pay',
        renderer: () => this.__renderSelfPay(),
      },
      [APPOINTMENT_BILLING_TYPE.CarePackage]: {
        label: 'Care Package',
        renderer: () => this.__renderCarePackage(),
      },
      [APPOINTMENT_BILLING_TYPE.Insurance]: {
        label: 'Insurance',
        renderer: () => this.__renderInsurance(),
      },
    });

    this.__printActionItems = Object.freeze({
      RECEIPT: {
        label: 'Receipt',
        onSelect: () => this.handlers.printReceipt(),
      },
      STATEMENT: {
        label: 'Statement',
        onSelect: () => this.handlers.openNewStatementPopup(),
      },
      SUPERBILL: {
        label: 'Superbill',
        onSelect: () => this.handlers.openNewSuperbillPopup(),
      },
    });

    this.onProviderChanged = () => {};

    this.onCreateEncounterChanged = () => {};

    this.onAppointmentModelUpdated = async () => {};

    this.onInsurancesChanged = () => {};

    this.onPackageTemplatesChanged = () => {};

    this.onGuarantorsChanged = () => {};

    this.onChangeDirty = () => {};

    this.onRefresh = async () => {};

    this.onFeeSchedulesChanged = () => {};

    this.onAuthorizationDetailChanged = () => {};

    this.onPatientBalanceChanged = () => {};

    this.onConfirm = () => {};

    this.__initServices();

    this.onUpdateLoading = () => {};

    this.onRepostPostedEncounterCharges = async () => {};

    this.onUpdateEncounterCurrentIllnessDate = async () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      openNewStatementPopup: async () => {
        await openPopup(POPUP_RENDER_KEYS.NEW_STATEMENT, {
          patientId: this.model.patientId,
          startDisplayDate: parseDate(this.appointmentStart),
          isCheckOut: true,
        });
      },
      openNewSuperbillPopup: async () => {
        const { id } = this.model.patient;

        const { first, last, preferred } = this.__getPatientName();

        const patientInfo = {
          id,
          preferredName: preferred,
          name: `${last}, ${first}`,
        };

        await openPopup(POPUP_RENDER_KEYS.NEW_SUPERBILL, {
          patientId: this.model.patientId,
          patient: patientInfo,
          startDisplayDate: parseDate(this.appointmentStart),
          isCheckOut: true,
        });
      },
      addGuarantor: () => this.__addGuarantor(),
      addCase: async () => {
        const newCase = await openOverlay(OVERLAY_KEYS.CASE, {
          context: {
            patientId: this.model.patientId,
            guarantors: this.guarantors.filter(g => g.relation !== 'Self'),
            isFirstCase: !this.cases.length,
          },
        });

        if (!newCase) return;

        await this.onRefresh({ isAddingCase: true });
        await this.updateComplete;

        const newCaseIndex = this.cases.findIndex(
          ({ id }) => id === newCase.id,
        );

        this.handlers.changeCase({
          id: 'select-case',
          event: 'select',
          value: this.cases[newCaseIndex],
          name: 'caseId',
          index: newCaseIndex + 1,
        });
      },
      addInsurance: e => this.__addInsurance(e),
      addNewAppointment: () => this.__addNewAppointment(),
      addPackage: () => this.__addPackage(),
      addDiagnosis: async () => {
        const previousDiagnoses = this.state.codes.map(c => c.code);

        const selectedDiagnoses = (
          await openOverlay(OVERLAY_KEYS.ADD_DIAGNOSIS, {
            showICD10Dropdown: true,
            selectedDiagnosis: this.__formatDiagnosesForAdd(this.state.codes),
          })
        ).filter(dx => !previousDiagnoses.includes(dx.diagnosisCode));

        this.formService.apply('codes', [
          ...this.state.codes,
          ...this.__unformatDiagnosesAfterAdd(selectedDiagnoses),
        ]);

        await this.__formatDiagnoses();

        return selectedDiagnoses;
      },
      reorderDiagnoses: async diagnoses => {
        this.formService.apply(
          'codes',
          diagnoses.map(({ showICD10Warning, ...dx }) => dx),
        );

        await this.__formatDiagnoses();
      },
      removeDiagnosis: async diagnosis => {
        this.removeDiagnosisFromCharges(diagnosis);

        const diagnosisIndex = this.state.codes
          .map(({ code }) => code)
          .indexOf(diagnosis.code);

        this.formService.removeItem('codes', diagnosisIndex);

        await this.__formatDiagnoses();
      },
      removeAllDiagnoses: async () => {
        this.state.charges.forEach((_, index) =>
          this.formService.apply(`charges.${index}.diagnosisPointers`, []),
        );

        this.formService.apply('codes', []);

        await this.__formatDiagnoses();
      },
      feeScheduleChanged: item => {
        const {
          data: { id: currItemId },
        } = this.state.billingInfo.feeScheduleId;

        if (!item || equal(item.value.data.id, currItemId)) return;

        const newFS = this.allFeeScheduleItems.find(
          fs => fs.data.id === item.value.data.id,
        );

        this.__feeScheduleChanged(newFS ? newFS.data : ITEM_EMPTY);
      },
      associateFeeSchedule: async () => {
        const item = await openOverlay(OVERLAY_KEYS.ASSOCIATE_FEE_SCHEDULE, {
          patientId: this.__patient.id,
          feeSchedules: this.feeScheduleItems
            .filter(item => item.data.id)
            .map(({ data }) => data),
        });

        this.__feeScheduleChanged(item);

        this.onFeeSchedulesChanged();
      },
      cancel: () => this.onCancel(false),

      changeBillType: value => {
        if (this.state.billingInfo.caseBillTypeOverride) {
          this.formService.apply('billingInfo.caseBillTypeOverride', false);
        }

        this.formService.apply('billingInfo.billType', value);

        if (value === APPOINTMENT_BILLING_TYPE.CarePackage) {
          this.__setDefaultCarePackage();
          this.__resetInsurances();
          this.__updateGuarantorDefault('guarantorId');
          this.__updateGuarantorDefault('insuranceGuarantorId');
          this.__setDefaultSelfCareFeeSchedule();
        } else {
          this.__clearCarePackageSelection();
        }

        if (value === APPOINTMENT_BILLING_TYPE.SelfPay) {
          this.__updateGuarantorDefault('guarantorId');
          this.__resetGuarantor('insuranceGuarantorId');
          this.__resetInsurances();
          this.__setDefaultSelfCareFeeSchedule();
        }

        if (value === APPOINTMENT_BILLING_TYPE.Insurance) {
          this.__selectedInsuranceTab = INSURANCE_LEVELS.PRIMARY;
          this.__updateGuarantorDefault('insuranceGuarantorId');
          this.__resetGuarantor('guarantorId');
          this.__setDefaultInsurances();
          const { primaryInsuranceId } = this.state.billingInfo;
          this.__setFeeScheduleByPayerPlan(primaryInsuranceId.data.payerPlanId);

          if (this.__carePackageWithInsuranceEnabled) {
            this.__setDefaultCarePackage();
          }
        }

        this.formService.validateKey(['caseId']);
      },
      changeProvider: value => {
        const { value: provider } = value;
        this.handlers.change(value);

        if (provider && provider.id !== this.state.providerId) {
          this.onProviderChanged(provider.id);
        }

        if (this.__selectedInsuranceTab === INSURANCE_LEVELS.PRIMARY) {
          const { primaryInsuranceId } = this.state.billingInfo;
          this.__setFeeScheduleByPayerPlan(
            primaryInsuranceId.data.payerPlanId,
            provider?.id,
          );

          if (this.__carePackageWithInsuranceEnabled) {
            this.__setDefaultCarePackage();
          }
        }
      },
      changeCaseOverride: (force = false) => {
        if (!this.state.billingInfo.caseBillTypeOverride || force) {
          this.formService.apply('billingInfo.caseBillTypeOverride', true);

          if (!this.cases.length) {
            this.formService.validateKey(['caseId']);
          }

          if (
            (!this.state.caseId || !this.state.caseId.id) &&
            this.cases.length > 0
          ) {
            const defaultCase = this.cases.find(c => c.isDefault);

            if (defaultCase) {
              this.handlers.changeCase({ value: defaultCase, name: 'caseId' });
            } else {
              this.formService.validateKey(['caseId']);
            }
          } else if (this.state.caseId && this.state.caseId.id) {
            this.__applyCaseBillingInfo(this.state.caseId);
          }

          this.validateCasePlans();
        }
      },
      changeCase: (value, updateAuth = true) => {
        const { value: newCase } = value;
        const validCase = newCase && !!newCase.id;

        if (newCase.id !== this.state.caseId.id) {
          this.handlers.change(value);
          this.__applyPatientCaseToFormServiceBillingInfo(newCase);

          if (updateAuth) {
            const caseId = validCase ? newCase.id : null;

            this.__changeCase(caseId);
          }

          if (this.state.billingInfo.caseBillTypeOverride && validCase) {
            this.formService.apply('billingInfo.billType', newCase.billType);
            this.__applyCaseBillingInfo(newCase);
          }
        }

        this.validateCasePlans();
      },
      checkEligibility: async () => {
        if (!this.__checkingRTE) await this.__checkEligibility();
      },
      updateAuthorization: ({ value: authorization }) => {
        if (
          !authorization ||
          (this.__selectedAuthorization &&
            this.__selectedAuthorization.id === authorization.id)
        ) {
          return;
        }

        this.__changeAuthorization(authorization.id);

        const [updateCase] = this.cases
          .filter(f => f.id === authorization.patientCaseId)
          .map(c => ({
            id: ELEMENTS.selectCase.id,
            name: 'caseId',
            value: { ...c },
          }));

        this.handlers.changeCase(updateCase, false);
      },
      onAuthorizationDetailChanged: () => {
        this.onAuthorizationDetailChanged();
      },
      changeInsurance: ({ name, value }) => {
        const isPrimary = name === 'billingInfo.primaryInsuranceId';
        const currentValue = this.state.billingInfo[name.split('.')[1]];

        if (!equal(value, currentValue)) {
          this.formService.apply(name, value);

          if (equal(value, ITEM_EMPTY)) {
            const isPrimaryOrSecondary =
              isPrimary || name === 'billingInfo.secondaryInsuranceId';

            if (isPrimaryOrSecondary) {
              this.formService.apply(
                'billingInfo.tertiaryInsuranceId',
                ITEM_EMPTY,
              );

              if (isPrimary) {
                this.formService.apply(
                  'billingInfo.secondaryInsuranceId',
                  ITEM_EMPTY,
                );
              }
            }
          }

          if (isPrimary) {
            this.__setFeeScheduleByPayerPlan(value.data.payerPlanId);
          }
        }

        this.__loadInsuranceItems();
      },
      changePackage: ({ name, value }) => this.formService.apply(name, value),

      authorizationBannerClick: async () => {
        const result = await openOverlay(OVERLAY_KEYS.AUTHORIZATION, {
          id: this.state.patientAuthorizationId,
          patientId: this.__patient.id,
          patientCaseId: this.state.caseId.id,
        });

        if (result) this.onAuthorizationDetailChanged();
      },
      authorizationWarningClick: () => this.__showAuthorizationWarning(),
      clickFirstButton: () => this.__saveAndRefreshEncounterBalance(),
      save: () => this.__saveAndCheckOut(),
      selectInsuranceTab: tab => {
        this.__selectedInsuranceTab = tab;
      },
      makePayment: () => this.__makePayment(),
      createDiscount: () => this.__createDiscount(),
      printReceipt: () => this.__printPatientPayment(),
      openPatientBalance: async () => {
        if (!this.isDirty()) {
          this.__navigateToPatientBalance();

          return;
        }

        if (await openDirtyPopup()) {
          this.onChangeDirty(false);
          this.__navigateToPatientBalance();
        }
      },
      addCharge: () => this.__addCharge(),
      autoPointDiagnoses: () => this.__autoPointDiagnoses(),
      updateCharge: ({ name, value }) => {
        const path = name.split('.');
        const key = path[2];
        const newVal = key === 'units' && value ? parseInt(value, 10) : value;

        this.formService.apply(name, newVal);

        const { billedAmountKey, billedAmountValue } = getUpdatedBilledAmount({
          name,
          value,
          charges: this.__stateCharges,
        });
        if (!billedAmountKey) return;

        this.formService.apply(billedAmountKey, billedAmountValue);
      },
      removeCharge: (name, index) => {
        this.formService.removeItem(name, index);
      },
      postAllToLedger: () => {
        const postAll = this.__stateCharges.some(({ posted }) => !posted);

        this.__stateCharges.forEach((_, idx) =>
          this.formService.apply(`charges.${idx}.posted`, postAll),
        );

        return this.handlers.clickFirstButton();
      },
      openFeedBackRequestLink: () =>
        openDoctibleSsoLink('/providers/review_management/feedback_requests'),
      printUpcomingAppointments: () =>
        printUpcomingAppointments({ patient: this.__patient }),
      printUpcomingAppointmentsWithLocation: async () => {
        const res = await openPopup(POPUP_RENDER_KEYS.SELECT_LOCATION, {
          message: SELECT_LOCATION_MESSAGE_FOR_APPTS,
        });

        if (!res.canceled) {
          await printUpcomingAppointments({
            patient: this.__patient,
            location: res.location,
          });
        }
      },
      updateLoading: loadingLabel => {
        this.__loadingLabel = loadingLabel;
        this.onUpdateLoading();
      },
      openVisitSummary: () =>
        openOverlay(OVERLAY_KEYS.VISIT_SUMMARY, {
          patientId: this.model.patientId,
          encounterId: this.model.encounterId,
        }),
      changeCreateEncounter: state => {
        this.formService.apply(state.name, state.value);
        this.onCreateEncounterChanged(state.value);
      },
      openPackagesSummary: async () => {
        await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGES_SUMMARY, {
          patientId: this.model.patientId,
          showAllActivePackages: true,
        });

        this.onPackageTemplatesChanged();
      },
    };
  }

  __applyPatientCaseToFormServiceBillingInfo(patientCase) {
    this.formService.apply('billingInfo.patientCaseId', patientCase);
  }

  __applyPatientAuthorizationToFormServiceBillingInfo() {
    const patientAuthorizationId = this.__selectedAuthorization?.id || null;
    this.formService.apply(
      'billingInfo.patientAuthorizationId',
      patientAuthorizationId,
    );
  }

  __applyPatientAuthorizationToFormServiceAppointment() {
    const patientAuthorizationId = this.__selectedAuthorization?.id || null;
    this.formService.apply('patientAuthorizationId', patientAuthorizationId);
  }

  __checkUnallocationAuthorization(repostPostedEncounterCharges) {
    return (
      !this.__chargesChanged ||
      checkUnallocationAuthorization({
        charges: this.__stateCharges,
        pristineCharges: this.charges,
        repostPostedEncounterCharges,
      })
    );
  }

  get __stateCharges() {
    const { charges } = this.formService.buildModel();
    return charges;
  }

  get __chargesDirty() {
    return (
      this.charges.length !== this.__stateCharges.length ||
      this.__stateCharges.some((charge, index) => {
        const { encounterId, billedAmount, ...pristineCharge } =
          this.charges[index];

        return !equal(charge, pristineCharge);
      })
    );
  }

  get __stateCodes() {
    const { codes } = this.formService.buildModel();

    return codes;
  }

  get __diagnosesDirty() {
    return !equal(this.model.codes, this.__stateCodes);
  }

  __applyCaseBillingInfo(caseId) {
    const { billType } = caseId;
    this.formService.apply('billingInfo.billType', billType);

    const guarantor = this.guarantors.find(g => g.id === caseId.guarantorId);

    switch (billType) {
      case APPOINTMENT_BILLING_TYPE.Insurance: {
        this.formService.apply('billingInfo.insuranceGuarantorId', guarantor);
        this.__resetGuarantor('guarantorId');
        this.__clearCarePackageSelection();

        const primaryItem = this.insuranceItems.find(
          i =>
            i.data &&
            i.data.id &&
            i.data.id === caseId.payerInsurance.primaryInsuranceId,
        );
        this.formService.apply('billingInfo.primaryInsuranceId', primaryItem);

        const secondaryItem =
          this.insuranceItems.find(
            i =>
              i.data &&
              i.data.id &&
              i.data.id === caseId.payerInsurance.secondaryInsuranceId,
          ) || ITEM_EMPTY;
        this.formService.apply(
          'billingInfo.secondaryInsuranceId',
          secondaryItem,
        );

        this.formService.apply('billingInfo.tertiaryInsuranceId', ITEM_EMPTY);
        this.__setFeeScheduleByPayerPlan(primaryItem.data.payerPlanId);

        this.formService.validateKey(['billingInfo', 'primaryInsuranceId']);
        this.formService.validateKey(['billingInfo', 'secondaryInsuranceId']);

        break;
      }

      case APPOINTMENT_BILLING_TYPE.CarePackage: {
        this.__resetGuarantor('guarantorId');
        this.__resetGuarantor('insuranceGuarantorId');

        const item = this.packages.find(
          p => p && p.data && p.data.id === caseId.patientPackageId,
        );
        this.formService.apply('billingInfo.patientPackageId', item);

        this.__setDefaultSelfCareFeeSchedule();
        this.formService.validateKey(['billingInfo', 'patientPackageId']);

        break;
      }

      default: {
        this.__clearCarePackageSelection();
        this.formService.apply('billingInfo.guarantorId', guarantor);
        this.__resetGuarantor('insuranceGuarantorId');
        this.__setDefaultSelfCareFeeSchedule();

        break;
      }
    }
  }

  async __updateAppointmentBillingInfoIfChanged(billingInfo, invoiceIds) {
    if (
      hasBillingInfoChanged({
        checkedIn: this.checkedIn,
        billingInfo,
        appointmentBillingInfo: this.model.billingInfo,
        serverAppointmentBillingInfo: this.serverAppointmentBillingInfo,
        multiCarePackage: this.__multiCarePackageEnabled,
      })
    ) {
      if (!billingInfo.id) {
        const newBillingInfo = await createAppointmentBillingInfo(
          this.model.id,
          {
            ...billingInfo,
            invoiceIds,
          },
        );

        this.state.billingInfo.id = newBillingInfo.id;
      } else {
        await updateAppointmentBillingInfo(billingInfo.appointmentId, {
          ...billingInfo,
          invoiceIds,
        });
      }
    }
  }

  validateCasePlans() {
    const { billingInfo } = this.state;
    const {
      billType,
      caseBillTypeOverride,
      patientPackageId,
      primaryInsuranceId,
      secondaryInsuranceId,
    } = billingInfo;

    if (
      !this.checkedIn &&
      caseBillTypeOverride &&
      billType === APPOINTMENT_BILLING_TYPE.CarePackage
    ) {
      const validPackage = validatePackage(patientPackageId, null, this.state);

      if (validPackage) {
        this.errors.billingInfo.patientPackageId = '';
        return true;
      }
      this.errors.billingInfo.patientPackageId = INACTIVE_PACKAGE_ERROR;
      return false;
    }

    if (
      caseBillTypeOverride &&
      billType === APPOINTMENT_BILLING_TYPE.Insurance
    ) {
      const validPrimary = validateInsurance(
        primaryInsuranceId,
        null,
        this.state,
      );

      if (validPrimary) {
        this.errors.billingInfo.primaryInsuranceId = '';
      } else {
        this.errors.billingInfo.primaryInsuranceId =
          INACTIVE_PRIMARY_PLAN_ERROR;
      }

      if (
        secondaryInsuranceId &&
        secondaryInsuranceId.data &&
        secondaryInsuranceId.data.id
      ) {
        const validSecondary = validateSecondaryInsurance(
          secondaryInsuranceId,
          null,
          this.state,
        );

        if (validSecondary) {
          this.errors.billingInfo.secondaryInsuranceId = '';
        } else {
          this.errors.billingInfo.secondaryInsuranceId =
            INACTIVE_SECONDARY_PLAN_ERROR;

          return false;
        }
      }

      return !this.errors.billingInfo.primaryInsuranceId;
    }

    return true;
  }

  __getAssociatedInvoiceIds() {
    return this.__associatedInvoices.map(({ invoiceId }) => invoiceId);
  }

  async __getAssociatedInvoicesWarningMessage() {
    if (!this.model.encounterId || !this.__caseHasChanged()) return null;

    this.__associatedInvoices = await getInvoicesFromEncounters([
      this.model.encounterId,
    ]);

    const hasNotAssociatedInvoices =
      this.__associatedInvoices && !this.__associatedInvoices.length;

    if (hasNotAssociatedInvoices) return null;

    const hasSingleAssociatedInvoice = this.__associatedInvoices.length === 1;

    return hasSingleAssociatedInvoice
      ? WARNING_SINGLE_INVOICE_ASSOCIATED_AT_APPOINTMENT_LEVEL(
          this.__associatedInvoices[0].invoiceNumber,
        )
      : WARNING_MULTIPLE_INVOICES_ASSOCIATED_AT_APPOINTMENT_LEVEL(
          this.__associatedInvoices.map(({ invoiceNumber }) => invoiceNumber),
        );
  }

  async __openAssociatedInvoicesWarningMessage({
    associatedInvoicesWarning,
    billingInfo,
  }) {
    const billingInfoHasChanged = hasBillingInfoChanged({
      checkedIn: this.checkedIn,
      billingInfo,
      appointmentBillingInfo: this.model.billingInfo,
      serverAppointmentBillingInfo: this.serverAppointmentBillingInfo,
      multiCarePackage: this.__multiCarePackageEnabled,
    });

    const hasLineItemsToAllocate = findLineItemAllocations(this.lineItems);

    if (billingInfoHasChanged && hasLineItemsToAllocate) return true;

    if (!billingInfoHasChanged) return true;

    const result = await openPopup(POPUP_RENDER_KEYS.DIALOG, {
      title: 'Warning',
      message: associatedInvoicesWarning,
      buttons: [
        { name: 'save', label: 'YES' },
        { name: 'discard', label: 'NO' },
      ],
    });

    return result === 'save';
  }

  async __saveAndRefreshEncounterBalance() {
    if (!this.validateCasePlans()) {
      return;
    }

    if (!this.formService.validate()) {
      return;
    }

    const {
      billingInfo: { billType },
    } = this.state;

    let repostPostedEncounterCharges = false;

    if (billType !== APPOINTMENT_BILLING_TYPE.Insurance) {
      this.__resetInsurances();
    }

    const model = this.formService.buildModel();
    model.billingInfo.patientCaseId = this.state.caseId.id;

    const billingInfo = mapAppointmentBillingInfoToServerModel(
      model.billingInfo,
      model.id,
    );

    const associatedInvoicesWarning =
      await this.__getAssociatedInvoicesWarningMessage();

    if (
      shouldRepost({
        checkedIn: this.checkedIn,
        lineItems: this.lineItems,
        billingInfo,
        appointmentBillingInfo: this.model.billingInfo,
        serverAppointmentBillingInfo: this.serverAppointmentBillingInfo,
        multiCarePackage: this.__multiCarePackageEnabled,
      })
    ) {
      repostPostedEncounterCharges = true;

      const res = await openRemoveAllocationPopup(
        this.lineItems,
        associatedInvoicesWarning,
      );

      if (!res) {
        return;
      }
    }

    if (associatedInvoicesWarning) {
      const res = await this.__openAssociatedInvoicesWarningMessage({
        associatedInvoicesWarning,
        billingInfo,
      });

      if (!res) return;
    }

    const authUnallocation = await this.__checkUnallocationAuthorization(
      repostPostedEncounterCharges,
    );

    if (!authUnallocation) {
      return;
    }

    this.__saving = true;

    const invoiceIds = this.__getAssociatedInvoiceIds();

    await this.__updateAppointmentBillingInfoIfChanged(billingInfo, invoiceIds);

    store.dispatch(openSuccess('Check out details saved successfully'));

    let savedCharges = false;

    try {
      savedCharges = await this.__saveUpdatedCharges(
        repostPostedEncounterCharges,
      );
    } catch (e) {
      console.error(e);
    }

    if (repostPostedEncounterCharges) {
      await this.onRepostPostedEncounterCharges({
        model,
        shouldRepost: repostPostedEncounterCharges,
      });
    }

    if (!savedCharges) {
      this.__saving = false;

      return;
    }

    if (this.model.encounterId !== null) {
      await this.onUpdateEncounterCurrentIllnessDate({ success: true, model });
      await this.onRefresh();
    } else {
      await this.__getEncounterBalanceData();
    }

    this.onChangeDirty(false);
    this.__saving = false;
  }

  async __saveAndCheckOut() {
    if (!this.validateCasePlans()) {
      return;
    }

    if (!this.formService.validate()) return;

    if (
      !this.checkedIn &&
      this.state.billingInfo.caseBillTypeOverride &&
      this.state.billingInfo.patientPackageId
    ) {
      const validPackage = validatePackage(
        this.state.billingInfo.patientPackageId,
        null,
        this.state,
      );

      if (validPackage) {
        this.errors.billingInfo.patientPackageId = '';
      } else {
        this.errors.billingInfo.patientPackageId = INACTIVE_PACKAGE_ERROR;
        return;
      }
    }

    const {
      billingInfo: { billType },
    } = this.state;

    let repostPostedEncounterCharges = false;

    if (billType !== APPOINTMENT_BILLING_TYPE.Insurance) {
      this.__resetInsurances();
    }

    const insurancesOutOfCoverage = this.__validateCoverageDates();

    if (insurancesOutOfCoverage.length) {
      const { selfCheckIn } = this.state;

      if (
        (!selfCheckIn && !this.checkedIn) ||
        (selfCheckIn && this.checkedIn) ||
        (!selfCheckIn && this.checkedIn && this.__insuranceHasChanged())
      ) {
        const res = await this.__renderOutOfCoverageInsurancesPopup(
          insurancesOutOfCoverage,
        );

        if (!res) {
          return;
        }
      }
    }

    const model = this.formService.buildModel();

    const billingInfo = mapAppointmentBillingInfoToServerModel(
      model.billingInfo,
      model.id,
    );

    const associatedInvoicesWarning =
      await this.__getAssociatedInvoicesWarningMessage();

    if (
      shouldRepost({
        checkedIn: this.checkedIn,
        lineItems: this.lineItems,
        billingInfo,
        appointmentBillingInfo: this.model.billingInfo,
        serverAppointmentBillingInfo: this.serverAppointmentBillingInfo,
        multiCarePackage: this.__multiCarePackageEnabled,
      })
    ) {
      repostPostedEncounterCharges = true;

      const res = await openRemoveAllocationPopup(
        this.lineItems,
        associatedInvoicesWarning,
      );

      if (!res) {
        return;
      }
    }

    if (associatedInvoicesWarning) {
      const res = await this.__openAssociatedInvoicesWarningMessage({
        associatedInvoicesWarning,
        billingInfo,
      });

      if (!res) return;
    }

    const invoiceIds = this.__getAssociatedInvoiceIds();

    this.formService.apply('invoiceIds', invoiceIds);

    const authUnallocation = await this.__checkUnallocationAuthorization(
      repostPostedEncounterCharges,
    );

    if (!authUnallocation) return;

    this.__saving = true;

    const {
      patientId,
      billingInfo: {
        patientPackageId: {
          data: { id: patientPackageId },
        },
      },
    } = this.state;

    if (patientPackageId && billType === APPOINTMENT_BILLING_TYPE.CarePackage) {
      if (!(this.activePackages.length < 1 || this.__isEncounterEditable)) {
        try {
          const status = await getScheduleStatus(patientId, {
            patientPackageId,
          });

          if (status.displayStatus === 'Failed' || status.error) {
            const res = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
              title: 'Subscription Payment Failed',
              message: html`
                The last payment attempt for this subscription failed. Payment
                for this subscription will not be received until the failed
                payment is re-processed, which may require a new payment method.
                <br /><br />Do you want to continue to apply this subscription
                to this appointment?
              `,
              confirmText: 'Yes',
              cancelText: 'No',
            });

            if (!res) {
              this.__saving = false;

              return;
            }
          }
        } catch (e) {
          console.error(e);

          this.__saving = false;

          return;
        }
      }
    }

    if (this.checkedIn) {
      let savedCharges = false;

      try {
        await this.__updateAppointmentBillingInfoIfChanged(
          billingInfo,
          invoiceIds,
        );

        savedCharges = await this.__saveUpdatedCharges(
          repostPostedEncounterCharges,
        );
      } catch (e) {
        console.error(e);
      }

      if (!savedCharges) {
        this.__saving = false;

        return;
      }
    }

    if (this.state.roomId && !this.checkedIn) {
      let override;

      try {
        override = await roomConflictOverridePopup(this.state.roomId.id);
      } catch (e) {
        store.dispatch(openError(ROOM_CHECKED_IN_ERROR));
        override = false;
      }

      if (!override) {
        this.__saving = false;

        return;
      }
    }

    this.onConfirm({
      model: this.formService.buildModel(),
      shouldRepost: repostPostedEncounterCharges,
    });
  }

  async __checkEligibility() {
    this.__checkingRTE = true;
    const appointmentDate = parseDate(this.appointmentStart);
    const { id: patientId } = this.__patient;
    const patientInsuranceId = this.__getInsuranceIdFromSelectedTab();
    const dateOfService = {
      beginningDateOfService: appointmentDate,
      endDateOfService: appointmentDate.add(1, 'days'),
    };

    try {
      await updateInsuranceFromRTE(patientInsuranceId, patientId, {
        dateOfService,
        providerId: this.model.providerId,
      });

      sendRefreshNotification([REFRESH_CHANGE_TYPE.SCHEDULING]);

      this.onInsurancesChanged();

      if (this.checkedIn) {
        await this.__saveAndRefreshEncounterBalance();

        await this.__loadPatientBalance();
      }
    } catch (e) {
      console.log(e);
    }

    this.__checkingRTE = false;
  }

  __setUpDx(charges) {
    return charges.map(ch => ({
      ...ch,
      diagnosisPointers: this.state.codes.filter(dx =>
        ch.diagnosisPointers.find(dp => dp.diagnosisCode === dx.code),
      ),
    }));
  }

  async __formatDiagnoses() {
    if (!this.state.codes || this.state.codes.length === 0) {
      this.__formattedCodes = [];
      return;
    }

    const icd10CodesLookup = await Promise.all(
      this.state.codes.map(diagnoses =>
        fetchOne({
          code: diagnoses.code,
          icd10year: this.__icd10Year,
        }),
      ),
    );

    this.__formattedCodes = this.state.codes.map((dx, i) => ({
      ...dx,
      showICD10Warning: !icd10CodesLookup[i],
    }));
  }

  __formatDiagnosesForAdd() {
    const result = this.state.codes.map(({ code, shortDescription }) => ({
      diagnosisCode: code,
      shortDescription,
    }));

    return result;
  }

  __unformatDiagnosesAfterAdd(selectedDiagnoses) {
    const result = selectedDiagnoses.map(
      ({ diagnosisCode, shortDescription }) => ({
        code: diagnosisCode,
        shortDescription,
        label: `${diagnosisCode} - ${shortDescription}`,
      }),
    );

    return result;
  }

  __feeScheduleChanged(item) {
    item = item.id ? mapToModel(item) : item;

    const label =
      !item.active && item.id ? `${item.name} (Inactive)` : item.name;

    this.formService.apply('billingInfo.feeScheduleId', {
      label: label || '',
      data: item,
    });

    const itemCharges = item.charges || [];

    const { charges } = this.formService.buildModel();

    const updatedCharges = charges.map(charge => {
      const itemCharge = itemCharges.find(ic => ic.id === charge.chargeId);

      const billedAmount =
        charge.units * (itemCharge ? itemCharge.amount : charge.unitCharge);

      let { modifiers } = charge;

      if (
        item.id &&
        itemCharge &&
        itemCharge.modifiers &&
        itemCharge.modifiers.length > 0
      ) {
        modifiers = itemCharge.modifiers;
      }

      return {
        ...charge,
        feeScheduleName: itemCharge ? item.name : null,
        feeScheduleCharge: itemCharge ? itemCharge.amount : null,
        allowedAmount: itemCharge ? itemCharge.allowedAmount : null,
        billedAmount,
        suppressFromClaim: itemCharge
          ? itemCharge.suppressFromClaim
          : charge.suppressFromClaim,
        modifiers,
      };
    });

    this.formService.apply(
      'charges',
      this.__formatCharges(this.__setUpDx(updatedCharges)),
    );
  }

  __insuranceHasChanged() {
    return (
      !equal(
        this.state.billingInfo.primaryInsuranceId.data.id,
        this.model.billingInfo.primaryInsuranceId,
      ) ||
      !equal(
        this.state.billingInfo.secondaryInsuranceId.data.id,
        this.model.billingInfo.secondaryInsuranceId,
      ) ||
      !equal(
        this.state.billingInfo.tertiaryInsuranceId.data.id,
        this.model.billingInfo.tertiaryInsuranceId,
      )
    );
  }

  __caseHasChanged() {
    return !equal(this.state.caseId.id, this.model.caseId);
  }

  __validateCoverageDates() {
    return [
      this.state.billingInfo.primaryInsuranceId,
      this.state.billingInfo.secondaryInsuranceId,
      this.state.billingInfo.tertiaryInsuranceId,
    ].reduce((memo, key) => {
      if (
        (key.data.start &&
          parseDate(key.data.start)
            .startOf('day')
            .isAfter(parseDate(this.appointmentStart))) ||
        (key.data.end &&
          parseDate(key.data.end)
            .endOf('day')
            .isBefore(parseDate(this.appointmentStart)))
      ) {
        memo.push({ name: key.label });
      }

      return memo;
    }, []);
  }

  async __saveCharges() {
    const { encounterId, patientId, signed } = this.model;
    const charges = this.__stateCharges;
    const pristineCharges = this.charges;
    const billingEncounterCharges = this.encounterCharges;
    const diagnoses = this.__stateCodes;

    const savedCharges = await saveCharges({
      pristineCharges,
      charges,
      encounterId,
      patientId,
      billingEncounterCharges,
      signed,
      diagnoses,
      diagnosesUpdated: this.__diagnosesChanged,
    });

    return savedCharges;
  }

  get __diagnosesChanged() {
    return this.checkedIn && this.__diagnosesDirty;
  }

  get __chargesChanged() {
    return this.checkedIn && this.__chargesDirty;
  }

  async __saveUpdatedCharges(repostPostedEncounterCharges) {
    if (
      (!this.__chargesChanged && !this.__diagnosesChanged) ||
      repostPostedEncounterCharges
    ) {
      return true;
    }

    const savedCharges = await this.__saveCharges();

    if (savedCharges) {
      if (
        this.__chargesChanged &&
        this.model.billingInfo.patientPackageId &&
        this.model.billingInfo.billType === APPOINTMENT_BILLING_TYPE.Insurance
      ) {
        await this.onPackageTemplatesChanged();
      }

      return true;
    }

    const res = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'An error occurred while updating encounter charges',
      message: html` Do you want to continue without last charges updates? `,
      confirmText: 'Yes',
      cancelText: 'No',
    });

    return res;
  }

  __initServices() {
    this.__appointmentService = new AppointmentDetailsMomentService(
      ({ appointmentTypes, appointment = {} }) => {
        this.__appointmentTypes = appointmentTypes
          .filter(type =>
            appointment.appointmentTypeId
              ? type.id === appointment.appointmentTypeId ||
                type.locations.includes(appointment.locationId)
              : true,
          )
          .map(appointmentType => ({
            id: appointmentType.id,
            label: appointmentType.name,
            active: appointmentType.active,
          }));

        this.__availableTypes = this.__appointmentTypes.filter(ap => ap.active);
      },
    );

    this.__appointmentStoreService = new AppointmentStoreService();

    this.__providerService = new MdcProviderService(({ activeProviders }) => {
      this.__providers = activeProviders.map(provider => ({
        id: provider.id,
        label: provider.value,
      }));
    });

    this.__updateNotificationService = new UpdateNotificationService({
      callback: () => this.__loadPatient(),
      query: {},
    });
  }

  async __loadPatient(patient = null) {
    if (patient) {
      this.__patient = { ...patient };
    } else {
      this.__patient = await patientApiClient.fetchOne(this.model.patientId);
    }

    if (
      this.__patient.caseBillTypeOverride &&
      !this.checkedIn &&
      (!this.model.billingInfo || !this.model.billingInfo.id) &&
      !this.lineItems.length
    ) {
      this.handlers.changeCaseOverride();
    }
  }

  __navigateToPatientBalance() {
    navigate(`/patients/${this.__patient.id}/ledger/balance`);
  }

  __genInsuranceNavItems() {
    return {
      [INSURANCE_LEVELS.PRIMARY]: {
        label: INSURANCE_LEVELS.PRIMARY,
        renderer: () => this.__renderPrimaryInsurance(),
      },
      [INSURANCE_LEVELS.SECONDARY]: {
        label: INSURANCE_LEVELS.SECONDARY,
        renderer: () => this.__renderSecondaryInsurance(),
      },
      [INSURANCE_LEVELS.TERTIARY]: {
        label: INSURANCE_LEVELS.TERTIARY,
        renderer: () => this.__renderTertiaryInsurance(),
      },
    };
  }

  __genPrintActionItems() {
    const { STATEMENT, RECEIPT, SUPERBILL } = this.__printActionItems;
    return this.__patientPayments.length > 0
      ? [RECEIPT, STATEMENT, SUPERBILL]
      : [STATEMENT, SUPERBILL];
  }

  buildSelectors() {
    return {
      appointmentTypeId: {
        clipErrors: true,
        format: v => this.__appointmentTypes.find(x => x.id === v),
        unformat: v => (v ? v.id : v || null),
        validators: [required()],
      },
      providerId: {
        clipErrors: true,
        format: v => this.__providers.find(x => x.id === v),
        unformat: v => (v ? v.id : v || null),
        validators: [required()],
      },
      roomId: {
        clipErrors: true,
        format: v => this.rooms.find(x => x.id === v && x.checkInAvailable),
        unformat: v => (v ? v.id : v || null),
      },
      caseId: {
        clipErrors: true,
        ignorePristine: true,
        format: v => this.cases.find(c => c.id === v) || ITEM_EMPTY_NO_DATA,
        unformat: v => (v ? v.id : v || null),
        validators: [
          {
            error: 'Required',
            validate: (v, _, state) =>
              state.billingInfo.caseBillTypeOverride ? !!(v && v.id) : true,
          },
        ],
      },
      billingInfo: {
        children: {
          billType: {
            format: v => v,
            unformat: v => v,
            validators: [
              {
                error: 'Must be selected',
                validate: v => APPOINTMENT_BILLING_TYPES.includes(v),
              },
            ],
          },
          guarantorId: {
            clipErrors: true,
            format: v =>
              this.guarantors.find(g => {
                if (this.model.billingInfo.caseBillTypeOverride) {
                  return g.id === v;
                }
                return g.id === v && g.active;
              }) ||
              this.guarantors.find(g => g.default && g.active) ||
              SELF_GUARANTOR,
            unformat: v => (v ? v.id : v || null),
            validators: [
              {
                error: 'Required',
                validate: (v, _, state) =>
                  state.billingInfo.billType !==
                    APPOINTMENT_BILLING_TYPE.SelfPay || v,
              },
            ],
          },
          insuranceGuarantorId: {
            clipErrors: true,
            format: v =>
              this.guarantors.find(g => {
                if (this.model.billingInfo.caseBillTypeOverride) {
                  return g.id === v;
                }
                return g.id === v && g.active;
              }) ||
              this.guarantors.find(g => g.default && g.active) ||
              SELF_GUARANTOR,
            unformat: v => (v ? v.id : v || null),
            validators: [
              {
                error: 'Required',
                validate: (v, _, state) =>
                  state.billingInfo.billType !==
                    APPOINTMENT_BILLING_TYPE.Insurance || v,
              },
            ],
          },
          patientPackageId: {
            clipErrors: true,
            format: v => {
              if (this.__multiCarePackageEnabled) {
                const activePackages = this.__getNonEmptyActivePackages();

                if (activePackages.length) {
                  return activePackages[0];
                }
              }

              if (
                this.model.billingInfo.caseBillTypeOverride ||
                this.checkedIn
              ) {
                return (
                  this.packages.find(p => p.data.id === v) || EMPTY_PACKAGE
                );
              }

              if (this.activePackages) {
                return (
                  this.activePackages.find(p => p.data.id === v) ||
                  EMPTY_PACKAGE
                );
              }

              return EMPTY_PACKAGE;
            },
            unformat: v => (v && v.data && v.data.id ? v.data.id : null),
            validators: [
              {
                error: 'Required',
                validate: (v, _, state) =>
                  state.billingInfo.billType !==
                    APPOINTMENT_BILLING_TYPE.CarePackage ||
                  (this.checkedIn && this.__multiCarePackageEnabled) ||
                  (v && v.data && v.data.id),
              },
              {
                error: INACTIVE_PACKAGE_ERROR,
                validate: (v, _, state) =>
                  this.checkedIn || validatePackage(v, _, state),
              },
            ],
          },
          primaryInsuranceId: {
            clipErrors: true,
            format: v =>
              this.insuranceItems.find(i => {
                if (this.model.billingInfo.caseBillTypeOverride) {
                  return i.data.id === v;
                }
                return i.data.id === v && i.data.active;
              }) || ITEM_EMPTY,
            unformat: v => (v && v.data && v.data.id ? v.data.id : null),
            validators: [
              {
                error: 'Required',
                validate: (v, _, state) =>
                  state.billingInfo.billType !==
                    APPOINTMENT_BILLING_TYPE.Insurance ||
                  (v && v.data && v.data.id),
              },
              {
                error: INACTIVE_PRIMARY_PLAN_ERROR,
                validate: validateInsurance,
              },
            ],
          },
          secondaryInsuranceId: {
            clipErrors: true,
            format: v =>
              this.insuranceItems.find(i => {
                if (this.model.billingInfo.caseBillTypeOverride) {
                  return i.data.id === v;
                }
                return i.data.id === v && i.data.active;
              }) || ITEM_EMPTY,
            unformat: v => (v && v.data && v.data.id ? v.data.id : null),
            validators: [
              {
                error: INACTIVE_SECONDARY_PLAN_ERROR,
                validate: validateSecondaryInsurance,
              },
            ],
          },
          tertiaryInsuranceId: selectors.select(
            this.__tertiaryInsuranceItems,
            ITEM_EMPTY,
          ),
          feeScheduleId: selectors.select(this.allFeeScheduleItems, ITEM_EMPTY),
          patientCaseId: {
            clipErrors: true,
            format: v => this.cases.find(c => c.id === v) || null,
            unformat: v => (v ? v.id : v || null),
          },
          caseBillTypeOverride: {
            clipErrors: true,
            format: v => !!v,
            unformat: v => (v ? 1 : 0),
          },
        },
      },
      charges: {
        genItem: () => ({
          chargeId: '',
          procedure: '',
          description: '',
          modifiers: ['', '', '', ''],
          units: '1',
          taxName: null,
          taxRate: null,
          diagnosisPointers: [],
          order: 0,
          unitCharge: 0,
          feeScheduleName: '',
          feeScheduleCharge: 0,
          allowedAmount: 0,
          billedAmount: 0,
          EPSDTCode: false,
          suppressFromClaim: false,
          billWithTreatmentInitiationDate: false,
          billWithXrayDate: false,
          postedToLedgerId: null,
          posted: false,
          repost: false,
        }),
        format: charges => this.__formatCharges(charges),
        unformat: charges =>
          charges.map(c => ({
            id: c.id,
            chargeId: c.chargeId,
            procedure: c.procedure,
            description: c.description,
            units: parseInt(c.units, 10),
            taxName: c.taxName,
            taxRate: c.taxRate,
            diagnosisPointers: c.diagnosisPointers,
            order: c.order,
            modifier_1: c.modifiers[0] || null,
            modifier_2: c.modifiers[1] || null,
            modifier_3: c.modifiers[2] || null,
            modifier_4: c.modifiers[3] || null,
            unitCharge: c.unitCharge,
            feeScheduleName: c.feeScheduleName,
            feeScheduleCharge: c.feeScheduleCharge,
            allowedAmount: c.allowedAmount,
            EPSDTCode: c.EPSDTCode,
            suppressFromClaim: c.suppressFromClaim,
            billWithTreatmentInitiationDate: c.billWithTreatmentInitiationDate,
            billWithXrayDate: c.billWithXrayDate,
            postedToLedgerId: c.postedToLedgerId,
            posted: !!c.posted,
            repost: !!c.repost,
          })),
        children: {
          diagnosisPointers: {
            clipErrors: true,
            unsafe: true,
            clipPristine: true,
            format: diagnosisPointers => this.__filterDx(diagnosisPointers),
            unformat: v =>
              v.map(dx => ({
                diagnosisCode: dx.code,
              })),
            validators: [],
          },
          modifiers: modifiersSelector,
          units: {
            format: v => v.toString(),
            unformat: v => parseInt(v, 10),
            validators: [isRequired('1 - 999'), inRange(1, 999)],
          },
        },
      },
      codes: {
        unsafe: true,
      },
    };
  }

  __formatCharges(charges) {
    return charges.map(c => ({
      id: c.id,
      chargeId: c.chargeId,
      procedure: c.procedure,
      description: c.description,
      units: c.units,
      taxName: c.taxName,
      taxRate: c.taxRate,
      diagnosisPointers: c.diagnosisPointers,
      order: c.order,
      modifiers: c.modifiers
        ? c.modifiers
        : [
            c.modifier_1 || '',
            c.modifier_2 || '',
            c.modifier_3 || '',
            c.modifier_4 || '',
          ],
      unitCharge: c.unitCharge,
      feeScheduleName: c.feeScheduleName,
      feeScheduleCharge: c.feeScheduleCharge,
      allowedAmount: c.allowedAmount,
      billedAmount: c.billedAmount,
      EPSDTCode: c.EPSDTCode,
      suppressFromClaim: c.suppressFromClaim,
      billWithTreatmentInitiationDate: c.billWithTreatmentInitiationDate,
      billWithXrayDate: c.billWithXrayDate,
      postedToLedgerId: c.postedToLedgerId,
      posted: !!c.posted,
      repost: !!c.repost,
    }));
  }

  __filterDx(dxPointers) {
    return this.state.codes.filter(dx =>
      dxPointers.find(dp => dp.diagnosisCode === dx.code),
    );
  }

  async connectedCallback() {
    super.connectedCallback();

    this.__appointmentService.connect();
    this.__providerService.connect();
    this.__updateNotificationService.connect();

    this.__hasAddOnCtEngage = await hasAddOn(ADD_ONS.CT_ENGAGE);
    this.__hasAddOnCTVerify = await hasAddOn(ADD_ONS.CT_VERIFY);
  }

  disconnectedCallback() {
    this.__providerService.disconnect();
    this.__appointmentService.disconnect();
    this.__updateNotificationService.disconnect();

    super.disconnectedCallback();
  }

  __getPackageIds() {
    if (this.state.billingInfo.billType !== BILL_TYPE.PACKAGE) {
      return [];
    }

    const packageItem = this.state.billingInfo.patientPackageId;
    const packageId =
      packageItem && packageItem.data.id ? packageItem.data.id : null;

    const multiCarePackageIds = this.__multiCarePackageEnabled
      ? this.activePackages.map(pkg => pkg.data.id)
      : [];

    const lineItemPackageIds = this.lineItems.length
      ? this.lineItems.map(li => li.patientPackageId)
      : [];

    const packageIds = [
      ...multiCarePackageIds,
      ...lineItemPackageIds,
      packageId,
    ];

    return [...new Set(packageIds)].filter(id => id);
  }

  __setCarePackageChargesIds() {
    const patientPackageIds = this.__getPackageIds();

    const charges = this.unmodifiedPackages
      .flatMap(pkg => (patientPackageIds.includes(pkg.id) ? pkg.charges : null))
      .filter(charge => charge);

    const chargeIds = charges.map(charge => charge.chargeId);

    this.__carePackageChargesId = chargeIds;
  }

  async __getEncounterBalanceData() {
    if (!this.model.encounterId) {
      this.__encounterBalanceModel = {
        billType: this.state.billingInfo.billType,
        encounterId: null,
        totalAllCharges: 0,
        adjustments: 0,
        patientOwed: 0,
        payerOwed: 0,
        totalPayments: 0,
        totalDiscounts: 0,
        totalBalance: 0,
        totalTaxes: 0,
        patientPackageId: null,
      };
    } else {
      const encounterBalance = await getEncounterBalance(this.model.patientId, {
        billType: this.state.billingInfo.billType,
        encounterId: this.model.encounterId,
        appointmentId: this.model.id,
        feeScheduleId: this.state.billingInfo.feeScheduleId.data.id,
        providerId: this.state.providerId.id,
        patientPackageId: this.state.billingInfo.patientPackageId.data.id,
        primaryInsuranceId: this.state.billingInfo.primaryInsuranceId.data.id,
      });

      this.__encounterBalanceModel = {
        ...encounterBalance,
        totalAllCharges:
          encounterBalance.totalAllCharges + encounterBalance.totalTaxes,
        patientPackageId: this.model.billingInfo.patientPackageId,
      };
    }
    this.__setCarePackageChargesIds();
  }

  removeDiagnosisFromCharges(diagnosis) {
    this.state.charges.forEach((charge, chargeIndex) => {
      if (charge.diagnosisPointers.length) {
        const dxPointerIndex = charge.diagnosisPointers.findIndex(
          ({ code }) => code === diagnosis.code,
        );

        if (dxPointerIndex !== -1) {
          this.formService.apply(
            `charges.${chargeIndex}.diagnosisPointers`,
            charge.diagnosisPointers.filter(
              dxPtr => dxPtr.code !== diagnosis.code,
            ),
          );
        }
      }
    });
  }

  __getInsuranceIdFromSelectedTab() {
    let insuranceId;

    switch (this.__selectedInsuranceTab) {
      case INSURANCE_LEVELS.PRIMARY:
        insuranceId = this.__getPrimaryInsuranceId();
        break;
      case INSURANCE_LEVELS.SECONDARY:
        insuranceId = this.__getSecondaryInsuranceId();
        break;
      case INSURANCE_LEVELS.TERTIARY:
        insuranceId = this.__getTertiaryInsuranceId();
        break;
      default:
    }

    return insuranceId;
  }

  __getCases() {
    const { caseBillTypeOverride } = this.state.billingInfo;
    const activeCases = this.cases.filter(c => c.active);

    return [
      ...(!caseBillTypeOverride ? [ITEM_EMPTY_NO_DATA] : []),
      ...activeCases,
    ];
  }

  __getRooms() {
    const rooms = [
      ITEM_EMPTY_NO_DATA,
      ...this.rooms.filter(room => room.checkInAvailable),
    ];

    return rooms;
  }

  __getSelectedCaseName() {
    const selectedCase = this.state.caseId;
    return selectedCase && this.checkedIn ? selectedCase.label : '';
  }

  __getSelectedAuthorizationName() {
    const selectedAuth = this.__selectedAuthorization;
    return selectedAuth && this.checkedIn ? selectedAuth.label : '';
  }

  __getScheduledRoomName() {
    const scheduledRoom = this.rooms.find(
      room => room.id === this.model.resourceId,
    );
    return scheduledRoom ? scheduledRoom.name : '';
  }

  __getPrimaryInsuranceId() {
    return this.state.billingInfo.primaryInsuranceId.data.id;
  }

  __getSecondaryInsuranceId() {
    return this.state.billingInfo.secondaryInsuranceId.data.id;
  }

  __getTertiaryInsuranceId() {
    return this.state.billingInfo.tertiaryInsuranceId.data.id;
  }

  __insuranceOptionDisabled(insurancePriority) {
    switch (insurancePriority) {
      case INSURANCE_LEVELS.PRIMARY:
        return false;

      case INSURANCE_LEVELS.SECONDARY:
        return !this.__getPrimaryInsuranceId();

      case INSURANCE_LEVELS.TERTIARY:
        return !(
          this.__getPrimaryInsuranceId() && this.__getSecondaryInsuranceId()
        );

      default:
        return false;
    }
  }

  __isInsuranceAssociatedWithLineItems({ insuranceId }) {
    return (
      this.lineItems.length &&
      this.lineItems.find(
        lineItem =>
          lineItem.primaryInsuranceId === insuranceId ||
          lineItem.secondaryInsuranceId === insuranceId ||
          lineItem.tertiaryInsuranceId === insuranceId,
      )
    );
  }

  __loadInsuranceItems() {
    const insuranceItems = this.insuranceItems
      .filter(
        f =>
          f.data.active ||
          this.__isInsuranceAssociatedWithLineItems({ insuranceId: f.data.id }),
      )
      .map(({ data, label }) => ({
        data,
        label: label || `(${data.payerPlan.alias}) ${data.payerPlan.payerName}`,
      }));

    this.__primaryInsuranceItems = [
      ITEM_EMPTY,
      ...insuranceItems.filter(
        item =>
          item.data.id !== this.__getSecondaryInsuranceId() &&
          item.data.id !== this.__getTertiaryInsuranceId(),
      ),
    ];

    this.__secondaryInsuranceItems = [
      ITEM_EMPTY,
      ...insuranceItems.filter(
        item =>
          item.data.id !== this.__getPrimaryInsuranceId() &&
          item.data.id !== this.__getTertiaryInsuranceId(),
      ),
    ];

    this.__tertiaryInsuranceItems = [
      ITEM_EMPTY,
      ...insuranceItems.filter(
        item =>
          item.data.id !== this.__getPrimaryInsuranceId() &&
          item.data.id !== this.__getSecondaryInsuranceId(),
      ),
    ];
  }

  __setFeeScheduleByPayerPlan(id, providerId = this.state.providerId?.id) {
    const feeScheduleItem = this.allFeeScheduleItems.find(
      item =>
        item.data.active &&
        item.data.payerPlans &&
        item.data.payerPlans.find(
          pp =>
            pp.id === id &&
            (pp.allProviders || pp.providerIds.find(pi => pi === providerId)),
        ),
    );

    this.formService.apply(
      'billingInfo.feeScheduleId',
      feeScheduleItem || ITEM_EMPTY,
    );

    this.__feeScheduleChanged(
      feeScheduleItem ? feeScheduleItem.data : ITEM_EMPTY.data,
    );
  }

  __setDefaultSelfCareFeeSchedule() {
    const defaultSelfFeeSchedule = this.feeScheduleItems.find(
      item => item.data.active && item.data.default,
    );

    const newFS = defaultSelfFeeSchedule
      ? this.allFeeScheduleItems.find(
          fs => fs.data.id === defaultSelfFeeSchedule.data.id,
        )
      : ITEM_EMPTY;

    this.formService.apply('billingInfo.feeScheduleId', newFS);

    this.__feeScheduleChanged(newFS.data);
  }

  async __printPatientPayment() {
    if (this.__patientPayments.length > 0) {
      const rowsWithReceipts = this.__patientPayments.map(async row => {
        if (isElectronicPayment(row)) {
          const ePayment = await getElectronicPayment(row.electronicPaymentId);

          if (ePayment && ePayment.receipt) {
            row.receipt = {
              text: JSON.parse(ePayment.receipt).text.customer_receipt,
            };
          }
        }

        return row;
      });

      const resolvedRows = await Promise.all(rowsWithReceipts);

      const finalizedPayments = resolvedRows
        .map(item =>
          item.originalPaymentId
            ? formatRefundedSplitPayment(item, item.payments)
            : item,
        )
        .sort((a, b) => a.paymentNumber - b.paymentNumber);

      let selectedLocation = null;
      let appointmentListData = null;

      selectedLocation = (await getLocations()).find(
        location => location.id === this.model.locationId,
      );

      appointmentListData = await getAppointmentListData(
        this.model.patientId,
        selectedLocation,
        RECEIPT_APPOINTMENT_LIMIT,
      );

      printPdf(
        printReceipts({
          patientPayments: finalizedPayments,
          selectedLocation,
          appointmentListData,
        }),
      );
    }
  }

  async __addCharge() {
    this.__settingCharges = await getSettingCharges(
      this.model.patientId,
      this.model.id,
      {
        useFeeSchedule: true,
        feeScheduleId: this.state.billingInfo.feeScheduleId.data.id,
      },
    );

    const formServiceMappedCharges = await addCharges({
      stateCharges: this.__stateCharges,
      settingCharges: this.__settingCharges,
      isDirty: this.__dirty,
    });

    formServiceMappedCharges.forEach(mappedCharge => {
      this.formService.addItem('charges');
      Object.entries(mappedCharge).forEach(([key, value]) => {
        if (key.endsWith('diagnosisPointers') && this.__autoDiagnosisPointing) {
          return this.formService.apply(key, this.__get4DiagnosisPointers());
        }
        return this.formService.apply(key, value);
      });
    });
  }

  __get4DiagnosisPointers() {
    return this.state.codes.slice(0, Math.min(this.state.codes.length, 4));
  }

  __autoPointDiagnoses() {
    if (!this.state.codes.length) {
      return;
    }
    const diags4 = this.__get4DiagnosisPointers();

    this.state.charges.forEach((item, index) => {
      if (!item.diagnosisPointers.length) {
        this.formService.apply(`charges.${index}.diagnosisPointers`, [
          ...diags4,
        ]);
      }
    });
  }

  async __addGuarantor() {
    const guarantor = await openOverlay(OVERLAY_KEYS.GUARANTOR, {
      context: {
        patientId: this.model.patientId,
      },
    });

    if (guarantor) {
      const guarantorItem = guarantor.organization
        ? {
            id: guarantor.id,
            label: guarantor.organization.name,
            default: guarantor.default,
          }
        : {
            id: guarantor.id,
            label: formatGuarantorName(guarantor),
            default: guarantor.default,
          };
      this.onGuarantorsChanged();
      this.formService.apply('billingInfo.guarantorId', guarantorItem);
      this.formService.apply('billingInfo.insuranceGuarantorId', guarantorItem);
    }
  }

  async __addInsurance(e) {
    let item;

    const context = {
      cancel: 'cancel',
      rteEnabled: true,
      showBackButton: true,
      hasAddOnCTVerify: this.__hasAddOnCTVerify,
    };

    if (this.__hasAddOnCTVerify) {
      item = await openOverlay(OVERLAY_KEYS.ADD_INSURANCE, {
        patientId: this.model.patientId,
        patientInsurances: this.__insurances,
        context,
        providerId: this.model.providerId,
      });
    } else {
      item = await openOverlay(OVERLAY_KEYS.PATIENT_INSURANCE_ADD, {
        patientId: this.model.patientId,
        patientInsurances: this.insuranceItems.map(({ data }) => data),
        context,
      });
    }

    if (item) {
      this.onInsurancesChanged();

      this.formService.apply(e.name, {
        label: item.planInfo ? item.planInfo.planName : item.planName,
        data: item,
      });

      if (e.name === 'billingInfo.primaryInsuranceId') {
        this.__setFeeScheduleByPayerPlan(item.payerPlanId);
      }
    }
  }

  __addNewAppointment() {
    return openOverlay(OVERLAY_KEYS.APPOINTMENT_FORM, {
      ...NebFormAppointment.createModel(),
      patientId: this.model.patientId,
      providerId: this.model.providerId,
    });
  }

  async __addPackage() {
    const validPackages = this.activePackages.filter(
      pkg => pkg && pkg.data && pkg.data.id,
    );
    const carePackage = await openOverlay(OVERLAY_KEYS.PATIENT_PACKAGE_ADD, {
      context: {
        patientId: this.model.patientId,
        filteredCount: validPackages.length,
      },
    });

    if (carePackage) {
      await this.onPackageTemplatesChanged();

      const newPackage = this.activePackages.find(
        x => x.data.id === carePackage.id,
      );

      if (newPackage) {
        this.formService.apply('billingInfo.patientPackageId', newPackage);

        if (this.checkedIn) {
          await this.__getEncounterBalanceData();
        }
      }
    }
  }

  async __loadEncounterStatus() {
    const { encounterId } = this.model;

    this.disableChargeDetails = false;
    this.__isEncounterEditable = false;

    if (encounterId) {
      const encounter = await getEncounter(encounterId);

      this.__isEncounterSigned = encounter && encounter.signed;

      if (this.__isEncounterSigned) {
        this.__isEncounterEditable = true;
      }
    }
  }

  async __loadPatientBalance() {
    const { balance } = await getPatientBalance(this.model.patientId);
    this.__patientBalance = balance;
    this.onPatientBalanceChanged(balance);
  }

  async __loadPatientPayments(appointmentId = this.model.id) {
    const REGEX_REAL_APPOINTMENT_UUID =
      /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    if (appointmentId.match(REGEX_REAL_APPOINTMENT_UUID)) {
      const payments = await getPayments(this.model.patientId, {
        appointmentId,
      });

      this.__patientPayments = payments.data.filter(
        p => !p.voidedAt && !p.refundedAt && !p.parentPaymentId,
      );
    }

    const { encounterId } = this.model;

    if (encounterId) {
      const allocatedPayments = await getPatientPaymentsForEncounter(
        this.model.patientId,
        encounterId,
      );

      this.__allocatedPayments = allocatedPayments.data;
    }
  }

  __changePatientAuthorizationId() {
    if (this.__selectedAuthorization && this.__selectedAuthorization.id) {
      this.__applyPatientAuthorizationToFormServiceAppointment();

      this.__applyPatientAuthorizationToFormServiceBillingInfo();
    } else {
      this.formService.apply('patientAuthorizationId', null);
      this.formService.apply('billingInfo.patientAuthorizationId', null);
    }
  }

  __loadAuthorizations() {
    if (this.state.caseId.id) {
      this.__displayAuthorizations = this.authorizations.filter(
        auth => auth.patientCaseId === this.state.caseId.id,
      );
    } else {
      this.__displayAuthorizations = [...this.authorizations];
    }

    this.__selectedAuthorization =
      this.__displayAuthorizations.find(
        auth => auth.id === this.state.patientAuthorizationId,
      ) || null;
  }

  __showAuthorizationWarning() {
    if (this.layout === 'small') {
      store.dispatch(openWarning(NO_REMAINING_AUTHORIZATIONS_MESSAGE));
    } else {
      store.dispatch(
        openWarning(
          NO_REMAINING_AUTHORIZATIONS_MESSAGE,
          this.handlers.authorizationBannerClick,
        ),
      );
    }
  }

  __changeCase(caseId) {
    if (caseId) {
      this.__displayAuthorizations = this.authorizations.filter(
        auth => auth.patientCaseId === caseId,
      );

      this.__selectedAuthorization = this.__displayAuthorizations.length
        ? this.__displayAuthorizations[0]
        : null;

      if (!hasAuthorizationRemaining(this.__selectedAuthorization)) {
        this.__showAuthorizationWarning();
      }
    } else {
      this.__displayAuthorizations = [...this.authorizations];
      this.__selectedAuthorization = null;
    }

    this.__changePatientAuthorizationId();
  }

  __changeAuthorization(authId) {
    this.__selectedAuthorization = this.__displayAuthorizations.length
      ? this.__displayAuthorizations.find(auth => auth.id === authId)
      : null;

    if (this.__selectedAuthorization) {
      const matchedCase = this.cases.find(
        c => c.id === this.__selectedAuthorization.patientCaseId,
      );

      this.formService.apply('caseId', matchedCase);
      this.__displayAuthorizations = this.authorizations.filter(
        auth =>
          auth.patientCaseId === this.__selectedAuthorization.patientCaseId,
      );
    }

    this.__changePatientAuthorizationId();
  }

  __resetGuarantor(field) {
    const nullId = this.guarantors.find(g => g.id === null);
    this.formService.apply(`billingInfo.${field}`, nullId);
  }

  __updateGuarantorDefault(field) {
    const guarantor =
      this.guarantors.find(g => g.default) || this.guarantors[0];
    this.formService.apply(`billingInfo.${field}`, guarantor);
  }

  __clearCarePackageSelection() {
    this.formService.apply(
      'billingInfo.patientPackageId',
      this.activePackages[0],
    );
  }

  __setDefaultCarePackage() {
    const validPackages = this.activePackages.filter(
      p => p && p.data && p.data.id,
    );
    const defaultCarePackage = validPackages.find(
      p => p && p.data && p.data.isDefault,
    );
    const singleCarePackage =
      validPackages.length === 1 ? validPackages[0] : this.activePackages[0];

    const {
      billingInfo: { patientPackageId },
    } = this.model;
    const checkedInCarePackage =
      this.checkedIn && this.packages.find(p => p.data.id === patientPackageId);

    const carePackage =
      checkedInCarePackage || defaultCarePackage || singleCarePackage;

    this.formService.apply('billingInfo.patientPackageId', carePackage);
  }

  __resetInsurances() {
    this.formService.apply('billingInfo.primaryInsuranceId', ITEM_EMPTY);

    this.formService.apply('billingInfo.secondaryInsuranceId', ITEM_EMPTY);

    this.formService.apply('billingInfo.tertiaryInsuranceId', ITEM_EMPTY);
  }

  __setDefaultInsurances() {
    this.__resetInsurances();
    this.__loadInsuranceItems();

    const primaryIns = this.__primaryInsuranceItems.find(
      i => i.data.defaultLevel === INSURANCE_LEVELS.PRIMARY,
    );
    const secondaryIns = primaryIns
      ? this.__secondaryInsuranceItems.find(
          i => i.data.defaultLevel === INSURANCE_LEVELS.SECONDARY,
        )
      : null;
    const tertiaryIns = secondaryIns
      ? this.__tertiaryInsuranceItems.find(
          i => i.data.defaultLevel === INSURANCE_LEVELS.TERTIARY,
        )
      : null;

    this.formService.apply(
      'billingInfo.primaryInsuranceId',
      primaryIns || ITEM_EMPTY,
    );

    this.formService.apply(
      'billingInfo.secondaryInsuranceId',
      secondaryIns || ITEM_EMPTY,
    );

    this.formService.apply(
      'billingInfo.tertiaryInsuranceId',
      tertiaryIns || ITEM_EMPTY,
    );

    if (primaryIns) {
      this.__setFeeScheduleByPayerPlan(primaryIns.data.payerPlanId);
    }
  }

  async __makePayment() {
    if (this.model.rrule) {
      let id;

      await openOverlay(OVERLAY_KEYS.ADD_PATIENT_PAYMENT, {
        patientId: this.model.patientId,
        appointmentId: this.model.id,
        locationId: this.model.locationId,
        doNotDismissAll: true,
        recurrenceResolver: async () => {
          const result =
            await this.__appointmentStoreService.instantiateRecurrence(
              this.model.id,
            );

          if (result && result.success) {
            await this.onAppointmentModelUpdated(result.model);
            id = result.model.id;
            return id;
          }

          throw new Error('Could not resolve recurrence appointment');
        },
      });

      if (id) {
        if (this.checkedIn) {
          await this.__saveAndRefreshEncounterBalance();

          if (this.model.encounterId !== null) return;
        }

        await this.__loadPatientPayments(id);
        this.__loadPatientBalance();
      }
    } else {
      const result = await openOverlay(OVERLAY_KEYS.ADD_PATIENT_PAYMENT, {
        patientId: this.model.patientId,
        appointmentId: this.model.id,
        patient: this.__patient,
        locationId: this.model.locationId,
        encounterId: this.model.encounterId,
        doNotDismissAll: true,
      });

      if (this.checkedIn && result.paid === true) {
        await this.__saveAndRefreshEncounterBalance();

        if (this.model.encounterId) return;
      }

      await this.__loadPatientPayments();
      await this.__loadPatientBalance();
    }
  }

  async __createDiscount() {
    if (!this.formService.validate()) return;

    if (!this.validateCasePlans()) {
      return;
    }

    if (this.checkedIn && this.__dirty) {
      await this.__saveAndRefreshEncounterBalance();
    }

    await openOverlay(OVERLAY_KEYS.DISCOUNT, {
      patientId: this.__patient.id,
      transactionDateFrom: this.appointmentStart,
      transactionDateTo: this.appointmentStart,
    });

    await this.onRefresh();
  }

  get __authorizedProcedures() {
    return this.__selectedAuthorization
      ? this.__selectedAuthorization.charges.map(({ procedure }) => procedure)
      : null;
  }

  __setMultiCarePackageEnabledSetting() {
    if (this.checkedIn) {
      this.__multiCarePackageEnabled = !!(
        this.serverAppointmentBillingInfo &&
        this.serverAppointmentBillingInfo.multiCarePackage
      );
    } else {
      this.__multiCarePackageEnabled =
        !!this.practiceSettings[PRACTICE_SETTINGS.MULTI_CARE_PACKAGE];
    }
  }

  update(changedProps) {
    if (changedProps.has('practiceSettings')) {
      this.__autoDiagnosisPointing =
        !!this.practiceSettings[PRACTICE_SETTINGS.AUTO_DIAGNOSIS_POINTING];

      this.__carePackageWithInsuranceEnabled =
        !!this.practiceSettings[PRACTICE_SETTINGS.CARE_PACKAGE_WITH_INSURANCE];

      this.__multiCarePackageEnabled =
        !!this.practiceSettings[PRACTICE_SETTINGS.MULTI_CARE_PACKAGE];

      this.__autoPostCharges =
        !!this.practiceSettings[PRACTICE_SETTINGS.AUTO_POST_CHARGES];
    }

    if (changedProps.has('model') && this.model && this.model.id) {
      this.__updateNotificationService.update({
        patient: { id: this.model.patientId },
      });

      this.__icd10Year = dateToIcd10Year(this.model.start);

      this.__loadPatient(mapToPatientModel(this.model.patient));

      this.__appointmentService.update(this.model.id);

      this.__loadPatientBalance();
      this.__loadPatientPayments();
      this.__loadEncounterStatus();
      this.__patientLoaded = true;
    }

    if (changedProps.has('checkedIn')) {
      if (this.checkedIn) {
        this.__confirmLabel = 'Save and Check Out';
      } else {
        this.__confirmLabel = 'Complete Check In';
      }
    }

    if (changedProps.has('savingError') && this.savingError) {
      this.__saving = false;
    }

    super.update(changedProps);
  }

  async updated(changedProps) {
    if (changedProps.has('model') && this.model && this.model.id) {
      this.__loadInsuranceItems();

      this.initForm();

      this.__loadAuthorizations();

      this.__getEncounterBalanceData();

      if (
        !this.checkedIn &&
        this.model.billingInfo &&
        !this.model.billingInfo.id
      ) {
        if (this.state.caseId?.id) {
          this.__applyPatientCaseToFormServiceBillingInfo(this.state.caseId);
          this.__applyPatientAuthorizationToFormServiceAppointment();
          this.__applyPatientAuthorizationToFormServiceBillingInfo();

          this.__dirty = false;
        }

        if (!this.model.caseId && this.model.billingInfo.caseBillTypeOverride) {
          this.handlers.changeCaseOverride(true);
          this.__dirty = false;
        }
      }

      this.formService.validate();
      this.validateCasePlans();

      await this.__formatDiagnoses();

      if (
        this.state.billingInfo.billType ===
          APPOINTMENT_BILLING_TYPE.Insurance &&
        this.__carePackageWithInsuranceEnabled &&
        !this.__multiCarePackageEnabled &&
        !this.checkedIn
      ) {
        this.__setDefaultCarePackage();
      }
    }

    if (changedProps.has('authorizations')) {
      this.__loadAuthorizations();
    }

    if (changedProps.has('insuranceItems')) {
      this.__loadInsuranceItems();
    }

    if (changedProps.has('serverAppointmentBillingInfo')) {
      await this.__setMultiCarePackageEnabledSetting();
      this.__setMultiCarePackageEnabledSetting();
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host {
          display: block;
          width: 100%;
          height: 100%;
        }

        .form {
          display: block;
          padding: 0;
        }

        .container {
          display: flex;
          width: 100%;
          height: 100%;
        }

        .container-form {
          flex: 1 0 0;
          display: flex;
          width: 100%;
          height: 100%;
          padding: 0 ${CSS_SPACING} ${CSS_SPACING} ${CSS_SPACING};
          min-height: 0;
          overflow: auto;
          background-color: ${CSS_COLOR_WHITE};
        }

        :host([layout='small']) .container-form {
          flex-direction: column;
        }

        :host([checkedIn]) .container-form {
          flex-direction: column;
        }

        :host(:not([chartingPermission])) .container-diagnosis-table {
          margin-top: ${CSS_SPACING};
        }

        .row-top {
          display: flex;
          margin-bottom: 40px;
        }

        .row-bottom {
          display: block;
        }

        .column-left {
          width: 50%;
          margin-right: ${CSS_SPACING};
        }

        .column-right {
          width: 50%;
        }

        .container-item-details {
          display: flex;
        }

        .container-item-details-child {
          flex: 1;
        }

        :host([layout='small']) .column-left {
          width: 100%;
          margin: 0 0 40px 0;
        }

        :host([layout='small']) .column-right {
          width: 100%;
          margin: 0;
        }

        .select,
        .text {
          display: flex;
          width: 100%;
          margin-bottom: 14px;
        }

        .select-fee-schedules,
        .select-guarantor,
        .select-case,
        .select-insurance-guarantor,
        .select-subscription,
        .select-primary-insurance,
        .select-secondary-insurance,
        .select-tertiary-insurance {
          margin: 0;
        }

        .select-padding-top {
          padding-top: 12px;
        }

        .select-padding-bottom {
          padding-bottom: 12px;
        }

        .appointment-details {
          display: flex;
          margin-bottom: 43px;
          max-width: 387px;
          min-height: 44px;
        }

        :host([layout='medium']) .appointment-details {
          margin-bottom: 28px;
        }

        .container-radio-buttons {
          display: flex;
          flex-flow: wrap;
          width: 100%;
          margin-left: -10px;
          margin-bottom: 5px;
        }

        :host([layout='medium']) .container-radio-buttons {
          display: grid;
          grid-template-columns: auto auto;
          margin-bottom: 28px;
        }

        :host([layout='small']) .container-radio-buttons {
          display: grid;
          grid-template-columns: auto auto;
        }

        .container-patient-balance {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 26px;
        }

        :host([layout='medium']) .container-patient-balance {
          margin-bottom: 21px;
        }

        :host([layout='medium']) .label-billing {
          margin-bottom: 10px;
        }

        .label-header {
          margin-bottom: 2px;
          color: ${CSS_COLOR_HIGHLIGHT};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .label-patient-name {
          min-height: 22px;
          margin-bottom: ${CSS_SPACING};
          font-size: ${CSS_FONT_SIZE_HEADER};
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .label-container {
          min-height: 40px;
          border-bottom: 1px solid ${CSS_COLOR_GREY_7};
          margin: 0px -${CSS_SPACING};
        }

        .label-diagnoses,
        .label-encounter-charges {
          padding-left: ${CSS_SPACING};
        }

        .label-diagnoses {
          margin-bottom: ${CSS_SPACING};
        }

        .text-note {
          height: 212px;
          margin-bottom: 14px;
        }

        :host([checkedIn]) .text-note {
          height: 240px;
        }

        .encounter-balance {
          padding: 10px;
          background-color: ${CSS_COLOR_GREY_3};
        }

        .request-next-appointment {
          max-width: 387px;
          overflow-wrap: break-word;
        }

        .container-label {
          display: flex;
          justify-content: space-between;
        }

        .container-care-package-summary {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 10px;
        }

        .container-label-balance,
        .label-care-package-summary {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .label-care-package-summary-tooltip {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
          padding-bottom: 20px;
        }

        .container-label-payments,
        .label-appointment,
        .label-encounter-balance,
        .label-request-next-appointment,
        .label-request-next-appointment-description {
          margin-bottom: 10px;
        }

        .container-diagnosis-table {
          margin-bottom: 40px;
        }

        .label-treatment-plan {
          margin-top: 20px;
          margin-bottom: 10px;
        }

        .container-eligibility-status,
        .container-encounter-balance,
        .container-encounter-diagnosis,
        .container-billing-details,
        .container-plan-name,
        .container-coverage-dates,
        .container-co-pay,
        .container-deductible,
        .container-max-out-of-pocket,
        .container-coinsurance,
        .container-fee-schedule,
        .container-policyholder,
        .label-appointment,
        .button-add-insurance {
          margin-bottom: ${CSS_SPACING};
        }

        .button-rte {
          white-space: nowrap;
        }

        :host([layout='small']) .button-rte {
          margin-bottom: ${CSS_SPACING};
        }

        :host([layout='medium']) .container-encounter-diagnosis {
          margin-bottom: ${CSS_SPACING};
          width: 600px;
          overflow: auto;
        }

        :host([layout='medium']) .container-encounter-charges {
          width: 600px;
          overflow: auto;
        }

        .container-select {
          display: flex;
          align-items: center;
          width: 100%;
          height: fit-content;
        }

        .tooltip {
          margin-left: 10px;
        }

        .tooltip-slot {
          width: 320px;
        }

        .tooltip-subscription-slot {
          width: 180px;
        }

        .tooltip-provider {
          margin-bottom: 16px;
        }

        .tooltip-appointment-type {
          margin-bottom: 5px;
        }

        .tooltip-authorization {
          margin-bottom: 12px;
        }

        .tooltip-fee-schedules,
        .tooltip-guarantor,
        .tooltip-subscription,
        .tooltip-subscription-info,
        .tooltip-primary-insurance,
        .tooltip-secondary-insurance,
        .tooltip-tertiary-insurance,
        .tooltip-insurance-guarantor {
          margin-top: 14px;
        }

        .container-payer {
          margin-bottom: 10px;
        }

        .label-payer,
        .label-plan-name,
        .label-coverage-dates,
        .label-co-pay,
        .label-deductible,
        .label-max-out-of-pocket,
        .label-coinsurance,
        .label-fee-schedule,
        .label-available-packages,
        .label-policyholder {
          color: ${CSS_COLOR_BLACK};
        }

        .table {
          margin: 0 -${CSS_SPACING};
        }

        .table-encounter-charges {
          padding-top: 20px;
          margin-top: -5px;
        }

        :host([layout='medium']) .table {
          padding-right: 12px;
        }

        .tabs {
          margin-top: 12px;
          padding: 0 ${CSS_SPACING};
          margin-bottom: 32px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);
        }

        .button {
          margin-right: 10px;
          margin-top: 10px;
          min-width: 165px;
        }

        .button-add-insurance-guarantor {
          margin-bottom: -15px;
        }

        .button-add-charge {
          margin-bottom: ${CSS_SPACING};
        }

        .button-add-diagnosis {
          margin-bottom: 15px;
        }

        .button-auto-point-diagnoses {
          margin-bottom: ${CSS_SPACING};
        }

        :host([layout='medium']) .button-make-payment {
          margin-bottom: 5px;
        }

        :host([layout='small']) .button-discount {
          display: none;
        }

        .button-secondary-container {
          display: flex;
          flex-wrap: wrap;
          align-content: stretch;
        }

        .button-add-guarantor {
          margin-bottom: 12px;
        }

        .button-add-package {
          margin-bottom: ${CSS_SPACING};
        }

        .patient-balance {
          margin-bottom: 10px;
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        @supports (-moz-appearance: none) {
          .select-fee-schedules,
          .select-guarantor,
          .select-case,
          .select-subscription,
          .select-insurance-guarantor {
            margin-top: 2px;
          }
        }

        @supports (-moz-appearance: none) {
          .tabs {
            margin-bottom: 32px;
          }
        }

        :host([layout='medium']) .button-secondary-container {
          flex-direction: column;
        }

        :host([layout='small']) .button-secondary-container {
          margin-top: 10px;
        }

        .icon-authorization-warning {
          margin: 0 0 12px 10px;
          display: block;
          cursor: pointer;
          width: 24px;
          height: 24px;

          fill: ${CSS_WARNING_COLOR};
        }

        .ct-engage-feedback-request-link {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: ${CSS_COLOR_HIGHLIGHT};
          text-decoration: underline;
          margin-bottom: 30px;
        }

        .ct-engage-feedback-request-icon {
          cursor: pointer;
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_HIGHLIGHT};
        }

        .trailing-icon {
          margin-left: 5px;
        }

        .leading-icon {
          margin-right: 5px;
        }

        .icon-status {
          display: block;
          width: 20px;
          height: 20px;
          fill: ${CSS_BANNER_ERROR_COLOR};
        }

        .icon-warning {
          width: 20px;
          height: 20px;
          fill: ${CSS_COLOR_YELLOW};
        }

        .icon-validated,
        .icon-partially-validated {
          width: 20px;
          height: 20px;
          fill: ${CSS_BANNER_SUCCESS_COLOR};
        }

        .container-icon {
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          margin-bottom: 5px;
        }

        .grid-2 {
          display: grid;
          align-items: center;
          grid-template-columns: 1fr 1fr;
        }

        .grid-rte {
          grid-template-columns: auto 1fr;
          column-gap: ${CSS_SPACING};
        }

        :host([layout='small']) .print-upcoming-appts-button {
          margin-top: 10px;
        }

        .button-add-new-appointment {
          margin-right: 20px;
        }

        .group-receipt-buttons {
          display: flex;
          align-items: center;
        }

        .print-upcoming-appts-ellipses-button {
          margin-left: -15px;
        }

        .treatment-plan {
          max-height: 225px;
          overflow-y: auto;
          overflow-x: hidden;
        }

        .billing-info {
          border-top: 1px solid ${CSS_COLOR_GREY_7};
          border-bottom: 1px solid ${CSS_COLOR_GREY_7};
          padding: ${CSS_SPACING} 0;
          margin-bottom: ${CSS_SPACING};
        }

        .billing-info-insurance {
          border-top: 1px solid ${CSS_COLOR_GREY_7};
          padding-top: ${CSS_SPACING};
        }

        .margin-bottom {
          margin-bottom: 10px;
        }

        .bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .spacer {
          margin: ${CSS_SPACING} 0;
        }

        .no-available-packages-message {
          color: ${CSS_COLOR_GREY_1};
          font-style: italic;
        }

        .active-packages-required-message {
          color: ${CSS_COLOR_ERROR};
        }

        .list-packages {
          margin: 0;
        }

        .payment-checkout-actions-container {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        :host(:not([layout='large'])) .discount-print-container {
          display: flex;
          margin-left: 10px;
          margin-top: 5px;
        }

        .discount-print-container {
          display: flex;
          margin-left: 10px;
          margin-top: 14px;
          gap: 15px;
        }
      `,
    ];
  }

  __handleCreateEncounterAtCheckIn() {
    if (this.checkedIn || this.model.encounterId !== null) return false;
    return this.createEncounterAtCheckIn || this.model.createEncounter;
  }

  __formatInsurances(insurances) {
    if (insurances.length === 1) return insurances[0];
    const firsts = insurances.slice(0, insurances.length - 1);
    const last = insurances[insurances.length - 1];

    if (insurances.length === 2) return `${firsts} and ${last}`;

    return `${firsts.join(', ')}, and ${last}`;
  }

  __filterActiveInsuranceItems(items) {
    return items.filter(item => item.data.id === null || item.data.active);
  }

  __getEligibilityDateInfo(date) {
    return `Eligibility was checked on
    ${parseDate(date).format(FULL_DATE_FORMAT)}`;
  }

  __getErrorMode() {
    return this.checkedIn ? 'check-out' : 'check-in';
  }

  __getCaseInsuranceError() {
    if (
      this.errors.billingInfo.primaryInsuranceId &&
      this.errors.billingInfo.secondaryInsuranceId
    ) {
      return `${INACTIVE_PRIMARY_SECONDARY_PLAN_ERROR} ${this.__getErrorMode()}`;
    }

    if (this.errors.billingInfo.primaryInsuranceId) {
      return `${
        this.errors.billingInfo.primaryInsuranceId
      } ${this.__getErrorMode()}`;
    }

    if (this.errors.billingInfo.secondaryInsuranceId) {
      return `${
        this.errors.billingInfo.secondaryInsuranceId
      } ${this.__getErrorMode()}`;
    }
    return '';
  }

  __getCaseError() {
    const { billType, caseBillTypeOverride } = this.state.billingInfo;

    if (caseBillTypeOverride) {
      if (
        billType === APPOINTMENT_BILLING_TYPE.Insurance &&
        (this.errors.billingInfo.primaryInsuranceId ||
          this.errors.billingInfo.secondaryInsuranceId)
      ) {
        return this.__getCaseInsuranceError();
      }

      if (
        billType === APPOINTMENT_BILLING_TYPE.CarePackage &&
        this.errors.billingInfo.patientPackageId
      ) {
        return `${
          this.errors.billingInfo.patientPackageId
        } ${this.__getErrorMode()}`;
      }
    }

    return this.errors.caseId || '';
  }

  async __renderOutOfCoverageInsurancesPopup(insurancesOutOfCoverage) {
    const insurances = insurancesOutOfCoverage.map(item => `${item.name}`);

    const res = await openPopup(POPUP_RENDER_KEYS.CONFIRM, {
      title: 'Insurance Coverage Dates',
      message: html`
        The start or term dates on insurance plan(s)
        ${this.__formatInsurances(insurances)} indicates this appointment is
        outside of the covered date range. <br /><br />Are you sure you want to
        use this insurance?
      `,
      confirmText: 'Yes',
      cancelText: 'No',
    });

    return res;
  }

  __renderTooltip(name, defaultAnchor = 'left', type = 'editable') {
    const condition =
      type === 'editable'
        ? this.__isEncounterEditable
        : this.__isEncounterSigned;

    const text =
      type === 'editable' ? LABEL_ENCOUNTER_EXISTS : LABEL_ENCOUNTER_SIGNED;

    return condition
      ? html`
          <neb-tooltip
            id="${ELEMENTS[name].id}"
            class="tooltip ${ELEMENTS[name].id}"
            defaultAnchor="${defaultAnchor}"
            ><div class="tooltip-slot" slot="tooltip">${text}</div>
          </neb-tooltip>
        `
      : '';
  }

  __renderChargesEncounterMessage() {
    if (this.model.encounterId === null) return NO_ENCOUNTER_APPOINTMENT;

    if (this.model.encounterId !== null && this.charges.length === 0) {
      return NO_CHARGES_APPOINTMENT;
    }
    return '';
  }

  __renderDiagnosesEncounterMessage() {
    if (this.model.encounterId === null) return NO_ENCOUNTER_APPOINTMENT;

    if (this.model.encounterId !== null && this.state.codes.length === 0) {
      return NO_DIAGNOSIS_APPOINTMENT;
    }
    return '';
  }

  __renderSelfPay() {
    const guarantors = this.guarantors.filter(g => g.active);

    const caseBillTypeOverride = !!this.state.billingInfo.caseBillTypeOverride;

    if (caseBillTypeOverride && (!this.state.caseId || !this.state.caseId.id)) {
      return '';
    }

    return caseBillTypeOverride
      ? html`
          <div class="billing-info">
            <div class="bold">Case Bill Type</div>
            <div class="margin-bottom">Self Pay</div>
            <div class="bold">Guarantor</div>
            <div>
              ${this.state.billingInfo
                ? this.state.billingInfo.guarantorId.label
                : ''}
            </div>
          </div>
        `
      : html`
          <div class="container-select">
            <neb-select
              id="${ELEMENTS.selectGuarantor.id}"
              class="select select-guarantor"
              name="billingInfo.guarantorId"
              label="Guarantor"
              .items="${guarantors}"
              .value="${this.state.billingInfo.guarantorId}"
              .error="${this.errors.billingInfo.guarantorId}"
              .onChange="${this.handlers.change}"
              ?disabled="${guarantors.length < 1 || this.__isEncounterEditable}"
            ></neb-select>

            ${this.__renderTooltip('tooltipGuarantor')}
          </div>

          <neb-button-action
            id="${ELEMENTS.buttonAddGuarantor.id}"
            class="button button-add-guarantor"
            label="Add Patient Guarantor"
            leadingIcon="plus"
            .onClick="${this.handlers.addGuarantor}"
            ?disabled="${this.__isEncounterEditable}"
          ></neb-button-action>
        `;
  }

  __renderCarePackageLabel() {
    const { patientPackageId } = this.state.billingInfo;
    return (
      patientPackageId.label +
      (patientPackageId.data && patientPackageId.data.active
        ? ''
        : ' (Inactive)')
    );
  }

  __renderCarePackageSummaryIcon() {
    return this.state.billingInfo.billType ===
      APPOINTMENT_BILLING_TYPE.Insurance &&
      this.state.billingInfo.patientPackageId.data.id !== null
      ? html`
          <neb-tooltip
            id="${ELEMENTS.tooltipSubscriptionInfo.id}"
            class="tooltip ${ELEMENTS.tooltipSubscriptionInfo.id}"
            defaultAnchor="left"
            icon="neb:info"
            ><div class="tooltip-subscription-slot" slot="tooltip">
              ${this.__renderPackageBalance(
                this.state.billingInfo.patientPackageId,
                true,
              )}
            </div>
          </neb-tooltip>
        `
      : '';
  }

  __getNonEmptyActivePackages() {
    return this.activePackages.filter(pkg => pkg !== EMPTY_PACKAGE);
  }

  __renderActivePackageRequiredMessage() {
    return html`
      <div
        id="${ELEMENTS.activePackagesRequiredWarning.id}"
        class="active-packages-required-message"
      >
        ${INACTIVE_PACKAGE_ERROR} ${this.__getErrorMode()}
      </div>
    `;
  }

  __renderNoAvailablePackagesMessage() {
    return html`
      <div
        id="${ELEMENTS.noAvailablePackagesWarning.id}"
        class="no-available-packages-message"
      >
        ${NO_AVAILABLE_PACKAGE_ERROR}
      </div>
    `;
  }

  __renderNoActivePackageError() {
    if (this.__getNonEmptyActivePackages().length) return '';

    return this.state.billingInfo.billType ===
      APPOINTMENT_BILLING_TYPE.CarePackage && !this.checkedIn
      ? this.__renderActivePackageRequiredMessage()
      : this.__renderNoAvailablePackagesMessage();
  }

  __renderPackagesList() {
    const activePackages = this.__getNonEmptyActivePackages();

    return activePackages.length
      ? html`
          <ul class="list-packages">
            ${activePackages.map(({ label }) => html` <li>${label}</li> `)}
          </ul>
        `
      : this.__renderNoActivePackageError();
  }

  __renderViewDetailsLink() {
    const activePackages = this.__getNonEmptyActivePackages();

    return activePackages.length
      ? html`
          <neb-text
            id="${ELEMENTS.availablePackagesLink.id}"
            link
            .onClick="${this.handlers.openPackagesSummary}"
          >
            View Details
          </neb-text>
        `
      : '';
  }

  __renderAvailablePackagesContainer() {
    return html`
      <div id="${ELEMENTS.availablePackagesContainer.id}">
        <span class="label-header label-available-packages">
          Available Packages:
        </span>
        ${this.__renderViewDetailsLink()} ${this.__renderPackagesList()}
      </div>
    `;
  }

  __renderCarePackageDropdown() {
    return html`
      <neb-select
        id="${ELEMENTS.selectSubscription.id}"
        class="select select-subscription"
        name="billingInfo.patientPackageId"
        label="Care Package/Subscription"
        .items="${this.activePackages}"
        .value="${this.state.billingInfo.patientPackageId}"
        .error="${this.errors.billingInfo.patientPackageId}"
        .onChange="${this.handlers.changePackage}"
        ?disabled="${this.activePackages.length < 1 ||
        this.__isEncounterEditable}"
      ></neb-select>
    `;
  }

  __renderCarePackageDropdownContainer() {
    return this.__multiCarePackageEnabled
      ? ''
      : html`
          <div class="container-select">
            ${this.__renderCarePackageDropdown()}
            ${this.__renderTooltip('tooltipSubscription')}
            ${this.__renderCarePackageSummaryIcon()}
          </div>
        `;
  }

  __renderCarePackage() {
    const caseBillTypeOverride = !!this.state.billingInfo.caseBillTypeOverride;

    if (caseBillTypeOverride && (!this.state.caseId || !this.state.caseId.id)) {
      return '';
    }

    return caseBillTypeOverride
      ? html`
          <div class="billing-info">
            <div class="bold">Case Bill Type</div>
            <div class="margin-bottom">Care Package</div>
            <div class="bold">Care Package/Subscription</div>
            <div class="margin-bottom">
              ${this.state.billingInfo.patientPackageId
                ? this.__renderCarePackageLabel()
                : ''}
            </div>

            ${this.__renderPackageBalance(
              this.state.billingInfo.patientPackageId,
            )}
          </div>
        `
      : html`
          ${this.__renderCarePackageDropdownContainer()}
          ${this.__multiCarePackageEnabled
            ? this.__renderAvailablePackagesContainer()
            : ''}

          <neb-button-action
            id="${ELEMENTS.buttonAddPackage.id}"
            class="button button-add-package"
            label="Add Package"
            icon="plus"
            .onClick="${this.handlers.addPackage}"
            ?disabled="${this.__isEncounterEditable}"
          ></neb-button-action>

          ${this.state.billingInfo.billType ===
            APPOINTMENT_BILLING_TYPE.CarePackage &&
          !this.__multiCarePackageEnabled
            ? this.__renderPackageBalance(
                this.state.billingInfo.patientPackageId,
              )
            : ''}
        `;
  }

  __renderInsurance() {
    const caseBillTypeOverride = !!this.state.billingInfo.caseBillTypeOverride;

    if (caseBillTypeOverride && (!this.state.caseId || !this.state.caseId.id)) {
      return '';
    }

    return html`
      ${this.__carePackageWithInsuranceEnabled
        ? this.__renderCarePackage()
        : ''}
      ${caseBillTypeOverride
        ? html`
            <div class="billing-info-insurance">
              <div class="bold">Case Bill Type</div>
              <div class="margin-bottom">Insurance</div>
              <div class="bold">Guarantor</div>
              <div>
                ${this.state.billingInfo
                  ? this.state.billingInfo.insuranceGuarantorId.label
                  : ''}
              </div>
              <div></div>
            </div>
          `
        : this.__renderInsuranceGuarantor()}

      <neb-tabs-old
        id="${ELEMENTS.insuranceTabs.id}"
        class="tabs"
        .selected="${this.__selectedInsuranceTab}"
        .onChange="${this.handlers.selectInsuranceTab}"
      >
        ${Object.entries(this.__insuranceNavItems).map(([k, v]) => {
          const disabled = this.__insuranceOptionDisabled(k);

          if (caseBillTypeOverride && k === INSURANCE_LEVELS.TERTIARY) {
            return '';
          }

          return html`
            <neb-tab-old
              id="${k}"
              class="tab"
              .name="${k}"
              .selected="${this.__selectedInsuranceTab === k}"
              ?disabled="${disabled}"
              >${v.label}</neb-tab-old
            >
          `;
        })}
      </neb-tabs-old>

      ${this.__renderInsuranceTabContent()}
    `;
  }

  __renderInsuranceGuarantor() {
    const guarantors = this.guarantors.filter(g => g.active);

    return html`
      <div class="container-select">
        <neb-select
          id="${ELEMENTS.selectInsuranceGuarantor.id}"
          class="select select-insurance-guarantor"
          name="billingInfo.insuranceGuarantorId"
          label="Guarantor"
          .items="${guarantors}"
          .value="${this.state.billingInfo.insuranceGuarantorId}"
          .error="${this.errors.billingInfo.insuranceGuarantorId}"
          .onChange="${this.handlers.change}"
          ?disabled="${guarantors.length < 1 || this.__isEncounterEditable}"
        ></neb-select>

        ${this.__renderTooltip('tooltipInsuranceGuarantor')}
      </div>

      <neb-button-action
        id="${ELEMENTS.buttonAddInsuranceGuarantor.id}"
        class="button button-add-insurance-guarantor"
        label="Add Patient Guarantor"
        icon="plus"
        .onClick="${this.handlers.addGuarantor}"
        ?disabled="${this.__isEncounterEditable}"
      ></neb-button-action>
    `;
  }

  __renderAddInsuranceButton(type) {
    return html`
      <neb-button-action
        id="${ELEMENTS.buttonAddInsurance.id}"
        class="button button-add-insurance"
        name="billingInfo.${type}"
        label="Add Insurance"
        icon="plus"
        .onClick="${this.handlers.addInsurance}"
        ?disabled="${this.__isEncounterEditable}"
      ></neb-button-action>
    `;
  }

  __renderPrimaryInsurance() {
    const caseBillTypeOverride = !!this.state.billingInfo.caseBillTypeOverride;

    return html`
      <div class="container-insurance container-primary-insurance">
        <div class="container-select">
          ${caseBillTypeOverride
            ? ''
            : html`
                <neb-select
                  id="${ELEMENTS.selectPrimaryInsurance.id}"
                  class="select select-primary-insurance"
                  name="billingInfo.primaryInsuranceId"
                  label="Plan Name"
                  .error="${this.errors.billingInfo.primaryInsuranceId}"
                  .items="${this.__filterActiveInsuranceItems(
                    this.__primaryInsuranceItems,
                  )}"
                  .value="${this.state.billingInfo.primaryInsuranceId}"
                  .onChange="${this.handlers.changeInsurance}"
                  ?disabled="${!this.__primaryInsuranceItems.length ||
                  this.__isEncounterEditable}"
                ></neb-select>
              `}
          ${this.__renderTooltip('tooltipPrimaryInsurance')}
        </div>

        ${caseBillTypeOverride
          ? ''
          : this.__renderAddInsuranceButton('primaryInsuranceId')}
        ${this.__renderInsuranceDetails(this.__getPrimaryInsuranceId(), true)}
      </div>
    `;
  }

  __renderSecondaryInsurance() {
    const caseBillTypeOverride = !!this.state.billingInfo.caseBillTypeOverride;

    return html`
      <div class="container-insurance container-secondary-insurance">
        <div class="container-select">
          ${caseBillTypeOverride
            ? ''
            : html`
                <neb-select
                  id="${ELEMENTS.selectSecondaryInsurance.id}"
                  class="select select-secondary-insurance"
                  name="billingInfo.secondaryInsuranceId"
                  label="Plan Name"
                  .items="${this.__filterActiveInsuranceItems(
                    this.__secondaryInsuranceItems,
                  )}"
                  .value="${this.state.billingInfo.secondaryInsuranceId}"
                  .onChange="${this.handlers.changeInsurance}"
                  ?disabled="${!this.__secondaryInsuranceItems.length ||
                  this.__isEncounterEditable}"
                ></neb-select>
              `}
          ${this.__renderTooltip('tooltipSecondaryInsurance')}
        </div>

        ${caseBillTypeOverride
          ? ''
          : this.__renderAddInsuranceButton('secondaryInsuranceId')}
        ${this.__renderInsuranceDetails(this.__getSecondaryInsuranceId())}
      </div>
    `;
  }

  __renderTertiaryInsurance() {
    return html`
      <div class="container-insurance container-tertiary-insurance">
        <div class="container-select">
          <neb-select
            id="${ELEMENTS.selectTertiaryInsurance.id}"
            class="select select-tertiary-insurance"
            name="billingInfo.tertiaryInsuranceId"
            label="Plan Name"
            .items="${this.__filterActiveInsuranceItems(
              this.__tertiaryInsuranceItems,
            )}"
            .value="${this.state.billingInfo.tertiaryInsuranceId}"
            .onChange="${this.handlers.changeInsurance}"
            ?disabled="${!this.__tertiaryInsuranceItems.length ||
            this.__isEncounterEditable}"
          ></neb-select>

          ${this.__renderTooltip('tooltipTertiaryInsurance')}
        </div>

        ${this.__renderAddInsuranceButton('tertiaryInsuranceId')}
        ${this.__renderInsuranceDetails(this.__getTertiaryInsuranceId())}
      </div>
    `;
  }

  __renderFeeSchedulesSelect() {
    return this.state.billingInfo.billType !==
      APPOINTMENT_BILLING_TYPE.Insurance
      ? html`
          <div class="container-select">
            <neb-select
              id="${ELEMENTS.feeSchedulesSelect.id}"
              name="billingInfo.feeScheduleId"
              class="select select-fee-schedules"
              label="Fee Schedule"
              .items="${this.feeScheduleItems}"
              .value="${this.state.billingInfo.feeScheduleId}"
              .onChange="${this.handlers.feeScheduleChanged}"
              ?disabled="${this.__isEncounterEditable}"
            ></neb-select>

            ${this.__renderTooltip('tooltipFeeSchedules')}
          </div>

          <neb-button-action
            id="${ELEMENTS.buttonAssociateFeeSchedule.id}"
            class="button"
            label="Associate Fee Schedule"
            icon="plus"
            .onClick="${this.handlers.associateFeeSchedule}"
            ?disabled="${this.__isEncounterEditable}"
          ></neb-button-action>
        `
      : '';
  }

  __renderNoLinkedAuthTooltip() {
    const linkedAuth = this.__displayAuthorizations.find(
      auth => this.state.caseId && auth.patientCaseId === this.state.caseId.id,
    );

    if (linkedAuth || !this.state.caseId || !this.state.caseId.id) return '';

    return html`
      <neb-tooltip
        id="${ELEMENTS.tooltipAuthorization.id}"
        class="tooltip tooltip-authorization"
      >
        <div slot="tooltip">${NO_LINKED_AUTH_TOOLTIP}</div>
      </neb-tooltip>
    `;
  }

  __renderPatientAuthorization() {
    return html`
      <div class="container-select">
        <neb-select
          id="${ELEMENTS.selectAuthorization.id}"
          class="select"
          label="Authorization"
          helper=" "
          ?disabled="${!this.cases ||
          this.cases.length < 1 ||
          this.__isEncounterEditable}"
          .items="${this.__displayAuthorizations}"
          .value="${this.__selectedAuthorization}"
          .onChange="${this.handlers.updateAuthorization}"
          showTitle
        ></neb-select>
        ${hasAuthorizationRemaining(this.__selectedAuthorization)
          ? this.__renderNoLinkedAuthTooltip()
          : html`
              <neb-icon
                id="${ELEMENTS.iconAuthorizationWarning.id}"
                class="icon-authorization-warning"
                icon="neb:warning"
                @click="${this.handlers.authorizationWarningClick}"
              ></neb-icon>
            `}
      </div>
    `;
  }

  __renderAppointmentInputs() {
    return html`
      <div class="container-select">
        <neb-select
          id="${ELEMENTS.selectProvider.id}"
          class="select"
          name="providerId"
          label="Provider"
          helper="Required"
          .items="${this.__providers}"
          .value="${this.state.providerId}"
          .error="${this.errors.providerId}"
          .onChange="${this.handlers.changeProvider}"
          ?disabled="${this.__providers.length < 1 || this.model.encounterId}"
          wrapText
        ></neb-select>

        ${this.__renderTooltip('tooltipProvider', 'right')}
      </div>

      <div class="container-select">
        <neb-select
          id="${ELEMENTS.selectAppointmentType.id}"
          class="select select-padding-top"
          name="appointmentTypeId"
          label="Appointment Type"
          helper="Required"
          .items="${this.__availableTypes}"
          .value="${this.state.appointmentTypeId}"
          .error="${this.errors.appointmentTypeId}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.__isEncounterSigned}"
        ></neb-select>

        ${this.__renderTooltip('tooltipAppointmentType', 'right', 'signed')}
      </div>

      <div class="container-select">
        <neb-select
          id="${ELEMENTS.selectRoom.id}"
          class="select select-padding-top select-padding-bottom"
          name="roomId"
          label="Check-In Room"
          .items="${this.__getRooms()}"
          .value="${this.state.roomId}"
          .error="${this.errors.roomId}"
          .onChange="${this.handlers.change}"
          ?disabled="${this.rooms.length < 1}"
        ></neb-select>
      </div>

      <neb-textarea
        id="${ELEMENTS.textNote.id}"
        class="text-note"
        name="note"
        label="Note"
        .value="${this.state.note}"
        .onChange="${this.handlers.change}"
        maxLength="500"
        showCount
      ></neb-textarea>
    `;
  }

  __renderBillingRadioButtons() {
    return html`
      <div
        id="${ELEMENTS.containerBillingRadioButtons.id}"
        class="container-radio-buttons"
      >
        <neb-radio-button
          id="${ELEMENTS.radioButtonSelfPay.id}"
          class="radio-button radio-button-self-pay"
          name="billingInfo.billType"
          .value="${APPOINTMENT_BILLING_TYPE.SelfPay}"
          .label="${this.BILLING_OPTIONS[APPOINTMENT_BILLING_TYPE.SelfPay]
            .label}"
          .checked="${this.state.billingInfo.billType ===
            APPOINTMENT_BILLING_TYPE.SelfPay &&
          !this.state.billingInfo.caseBillTypeOverride}"
          .onSelect="${this.handlers.changeBillType}"
          ?disabled="${this.__isEncounterEditable}"
        >
        </neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.radioButtonCarePackage.id}"
          class="radio-button radio-button-care-package"
          name="billingInfo.billType"
          .value="${APPOINTMENT_BILLING_TYPE.CarePackage}"
          .label="${this.BILLING_OPTIONS[APPOINTMENT_BILLING_TYPE.CarePackage]
            .label}"
          .checked="${this.state.billingInfo.billType ===
            APPOINTMENT_BILLING_TYPE.CarePackage &&
          !this.state.billingInfo.caseBillTypeOverride}"
          .onSelect="${this.handlers.changeBillType}"
          ?disabled="${this.__isEncounterEditable}"
        >
        </neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.radioButtonInsurance.id}"
          class="radio-button radio-button-insurance"
          name="billingInfo.billType"
          .value="${APPOINTMENT_BILLING_TYPE.Insurance}"
          .label="${this.BILLING_OPTIONS[APPOINTMENT_BILLING_TYPE.Insurance]
            .label}"
          .checked="${this.state.billingInfo.billType ===
            APPOINTMENT_BILLING_TYPE.Insurance &&
          !this.state.billingInfo.caseBillTypeOverride}"
          .onSelect="${this.handlers.changeBillType}"
          ?disabled="${this.__isEncounterEditable}"
        >
        </neb-radio-button>

        <neb-radio-button
          id="${ELEMENTS.radioButtonCase.id}"
          class="radio-button"
          name="billingInfo.caseOverride"
          label="Case"
          .checked="${this.state.billingInfo.caseBillTypeOverride}"
          .onSelect="${this.handlers.changeCaseOverride}"
          ?disabled="${this.__isEncounterEditable}"
        >
        </neb-radio-button>
      </div>
    `;
  }

  __renderEncounterBalance() {
    return html`
      <neb-encounter-balance
        class="container-encounter-balance"
        id="${ELEMENTS.encounterBalance.id}"
        .layout="${this.layout}"
        .model="${this.__encounterBalanceModel}"
        .onOpenVisitSummary="${this.handlers.openVisitSummary}"
      >
      </neb-encounter-balance>
    `;
  }

  __renderChargesTable() {
    return html`
      <neb-table-encounter-charges
        id="${ELEMENTS.chargeTable.id}"
        class="table table-encounter-charges"
        name="charges"
        .layout="${this.layout}"
        .diagnoses="${this.state.codes}"
        .dirty="${this.__dirty}"
        .errors="${this.errors.charges}"
        .model="${this.state.charges}"
        .hasAutoPostCharges="${this.__autoPostCharges}"
        .onChange="${this.handlers.updateCharge}"
        .onAddAll="${this.handlers.postAllToLedger}"
        .onRemove="${this.handlers.removeCharge}"
        .reorder="${this.layout !== 'small'}"
        .carePackageChargesId="${this.__carePackageChargesId}"
        .showCarePackage="${true}"
        .showCost="${true}"
        .disableChargeDetails="${this.disableChargeDetails}"
        .isLoading="${this.isLoading}"
        .onUpdateLoading="${this.handlers.updateLoading}"
        .isEncounterSigned="${this.__isEncounterSigned}"
        .authorizedProcedures="${this.__authorizedProcedures}"
        .chartingPermission="${this.chartingPermission}"
        .checkedIn="${this.checkedIn}"
        >${this.__renderChargesEncounterMessage()}</neb-table-encounter-charges
      >
    `;
  }

  __renderLoading() {
    return this.isLoading
      ? html`
          <neb-loading-overlay
            id="${ELEMENTS.spinner.id}"
            title="${this.__loadingLabel}..."
            showDelay="0"
            .show="${true}"
          ></neb-loading-overlay>
        `
      : html``;
  }

  __renderEncounterCharges() {
    return html`
      <div class="label-container">
        <div class="label-header label-encounter-charges">
          Encounter Charges
        </div>
      </div>

      <div class="container-encounter-charges">
        <neb-button-action
          id="${ELEMENTS.buttonAddCharge.id}"
          class="button button-add-charge"
          label="Add New Charge"
          .onClick="${this.handlers.addCharge}"
          ?disabled="${!this.model.encounterId}"
        ></neb-button-action>

        <neb-button-action
          id="${ELEMENTS.autoPointDiagnosesButton.id}"
          class="buttons button-auto-point-diagnoses"
          leadingIcon="autoPoint"
          label="Auto Point Diagnoses"
          .onClick="${this.handlers.autoPointDiagnoses}"
          ?disabled="${!this.model.encounterId}"
        ></neb-button-action>

        ${this.__renderChargesTable()} ${this.__renderLoading()}
      </div>
    `;
  }

  __renderAddDiagnosisButton() {
    return this.chartingPermission
      ? html`
          <div class="button-add-diagnosis">
            <neb-button-action
              id="${ELEMENTS.buttonAddDiagnosis.id}"
              class="button button-add-diagnosis"
              label="Add Diagnosis"
              .onClick="${this.handlers.addDiagnosis}"
              .chartingPermission="${this.chartingPermission}"
              ?disabled="${!this.model.encounterId}"
            ></neb-button-action>
          </div>
        `
      : '';
  }

  __renderEncounterDiagnosis() {
    const emptyMessage = this.__renderDiagnosesEncounterMessage();

    return html`
      <div class="label-container">
        <div class="label-header label-diagnoses">Diagnoses</div>
      </div>
      ${this.__renderAddDiagnosisButton()}

      <div class="container-diagnosis-table">
        <neb-encounter-diagnosis-table
          class="table"
          id="${ELEMENTS.tableEncounterDiagnosis.id}"
          name="codes"
          .savedCodes="${this.__formattedCodes}"
          .onRemoveDiagnosis="${this.handlers.removeDiagnosis}"
          .onRemoveAllDiagnoses="${this.handlers.removeAllDiagnoses}"
          .onReorderDiagnoses="${this.handlers.reorderDiagnoses}"
          .emptyMessage="${emptyMessage}"
        ></neb-encounter-diagnosis-table>
      </div>
    `;
  }

  __renderInsuranceTabContent() {
    const item = this.__insuranceNavItems[this.__selectedInsuranceTab];
    return item ? item.renderer() : '';
  }

  __renderCoverageDates(patientInsurance) {
    return html`
      <div class="container-coverage-dates">
        <div class="label-header label-coverage-dates">Coverage Dates</div>

        <div id="${ELEMENTS.coverageDates.id}">
          ${formatCoverageDates(patientInsurance)}
        </div>
      </div>
    `;
  }

  __renderInsuranceCopay(copay, isPrimary, providerIsInNetwork) {
    const copayAmount = providerIsInNetwork
      ? copay.inNetworkAmount
      : copay.outOfNetworkAmount;

    return copayAmount !== null
      ? html`
          <div class="container-co-pay">
            <div class="label-header label-co-pay">Co-pay</div>

            <div id="${ELEMENTS.labelCopay.id}">
              ${centsToCurrency(copayAmount)}
              ${this.__getSecondaryInsuranceId() && isPrimary
                ? ' - Bill to secondary'
                : ''}
            </div>
          </div>
        `
      : '';
  }

  __renderInsuranceDeductible(deductible, providerIsInNetwork) {
    const deductibleAmount = providerIsInNetwork
      ? deductible.inNetworkAmount
      : deductible.outOfNetworkAmount;
    const deductibleRemaining = providerIsInNetwork
      ? deductible.inNetworkRemaining
      : deductible.outOfNetworkRemaining;

    return deductibleAmount !== null
      ? html`
          <div class="container-deductible">
            <div class="label-header label-deductible">Deductible</div>

            <div id="${ELEMENTS.labelDeductible.id}">
              ${centsToCurrency(deductibleAmount)} /
              ${deductibleRemaining !== null
                ? centsToCurrency(deductibleRemaining)
                : '-'}
            </div>
          </div>
        `
      : '';
  }

  __renderInsuranceOutOfPocket(outOfPocket, providerIsInNetwork) {
    const outOfPocketAmount = providerIsInNetwork
      ? outOfPocket.inNetworkAmount
      : outOfPocket.outOfNetworkAmount;
    const outOfPocketRemaining = providerIsInNetwork
      ? outOfPocket.inNetworkRemaining
      : outOfPocket.outOfNetworkRemaining;

    return outOfPocketAmount !== null
      ? html`
          <div class="container-max-out-of-pocket">
            <div class="label-header label-max-out-of-pocket">
              Max Out of Pocket
            </div>

            <div id="${ELEMENTS.labelOutOfPocket.id}">
              ${`${centsToCurrency(outOfPocketAmount)} /
            ${
              outOfPocketRemaining !== null
                ? centsToCurrency(outOfPocketRemaining)
                : '-'
            }`}
            </div>
          </div>
        `
      : '';
  }

  __renderInsuranceCoinsurance(coinsurance, providerIsInNetwork) {
    const coinsurancePercent = providerIsInNetwork
      ? coinsurance.inNetworkPercent
      : coinsurance.outOfNetworkPercent;

    return coinsurancePercent !== null
      ? html`
          <div class="container-coinsurance">
            <div class="label-header label-coinsurance">Coinsurance %</div>

            <div id="${ELEMENTS.labelCoinsurance.id}">
              ${coinsurancePercent} / ${100 - coinsurancePercent}
            </div>
          </div>
        `
      : '';
  }

  __renderInsuranceFeeSchedule(isPrimary) {
    return isPrimary && this.state.billingInfo.feeScheduleId.data.id
      ? html`
          <div class="container-fee-schedule">
            <div class="label-header label-fee-schedule">Fee Schedule</div>

            <div id="${ELEMENTS.feeScheduleLabel.id}">
              ${this.state.billingInfo.feeScheduleId.label}
            </div>
          </div>
        `
      : '';
  }

  __renderInsuranceDetails(id, isPrimary = false) {
    const item =
      this.insuranceItems.find(item => item.data.id === id) || ITEM_EMPTY;

    if (!item.data.id) {
      return '';
    }

    const providerData =
      this.state.providerId &&
      item.data.payerPlan.providerInOutOfNetwork.find(
        provider => provider.providerId === this.state.providerId.id,
      );

    const providerIsInNetwork = providerData
      ? providerData.isInNetwork !== false
      : true;

    let copay = {};
    let deductible = {};
    let outOfPocket = {};
    let coinsurance = {};

    if (item.data.copays.length) {
      copay =
        item.data.copays.find(copay => copay.isDefault) || item.data.copays[0];
    }

    if (item.data.deductibles.length) {
      deductible =
        item.data.deductibles.find(deductible => deductible.isDefault) ||
        item.data.deductibles[0];
    }

    if (item.data.outOfPockets.length) {
      outOfPocket =
        item.data.outOfPockets.find(outOfPocket => outOfPocket.isDefault) ||
        item.data.outOfPockets[0];
    }

    if (item.data.coinsurances.length) {
      coinsurance =
        item.data.coinsurances.find(coinsurance => coinsurance.isDefault) ||
        item.data.coinsurances[0];
    }

    return html`
      <div class="container-item-details">
        <div class="container-item-details-child">
          ${this.layout === 'small'
            ? this.__renderPayerAndRTESmall(item.data.payerPlan)
            : this.__renderPayerAndRTE(item.data.payerPlan)}

          <div class="container-plan-name">
            <div class="label-header label-plan-name">Plan Name</div>

            <div id="${ELEMENTS.planName.id}">
              ${item.data.active ? item.label : `${item.label} (Inactive)`}
            </div>
          </div>

          ${this.__renderCoverageDates(item.data)}
          ${this.__renderStatusRTE(item.data)}
          ${this.__renderInsuranceCopay(copay, isPrimary, providerIsInNetwork)}
          ${this.__renderInsuranceCoinsurance(coinsurance, providerIsInNetwork)}
          ${this.__renderInsuranceDeductible(deductible, providerIsInNetwork)}
          ${this.__renderInsuranceOutOfPocket(outOfPocket, providerIsInNetwork)}
          ${this.__renderInsuranceFeeSchedule(isPrimary, providerIsInNetwork)}

          <div class="container-policyholder">
            <div class="label-header label-policyholder">Policyholder</div>

            <div id="${ELEMENTS.policyHolder.id}">
              ${item.data.payerPlan.financialClass ===
              FINANCIAL_CLASSES.WorkersCompensation
                ? item.data.policyHolder.insuredName
                : mapToPolicyHolder(item.data.policyHolder).label}
            </div>
          </div>
        </div>
      </div>
    `;
  }

  __renderPayerAndRTE(payerPlan) {
    return html`
      <div class="grid-2 grid-rte container-payer">
        <div>
          <div class="label-header label-payer">Payer</div>
          <div id="${ELEMENTS.payerName.id}">
            ${mapToPayerPlan(payerPlan).label}
          </div>
        </div>

        <div class="button">${this.__renderCheckRTE(payerPlan)}</div>
      </div>
    `;
  }

  __renderPayerAndRTESmall(payerPlan) {
    return html`
      ${this.__renderCheckRTE(payerPlan)}
      <div class="container-payer">
        <div class="label-header label-payer">Payer</div>
        <div id="${ELEMENTS.payerName.id}">
          ${mapToPayerPlan(payerPlan).label}
        </div>
      </div>
    `;
  }

  __renderStatusRTE(patientInsurance) {
    return this.__hasAddOnCTVerify && patientInsurance.realTimeEligibilityStatus
      ? html`
          <div class="container-eligibility-status">
            <div class="label-header label-fee-schedule">
              Eligibility Status
            </div>

            <div class="container-icon eligibility-info">
              <neb-icon
                id="${ELEMENTS.iconRealTimeEligibilityStatus.id}"
                class="icon-status icon-${patientInsurance.realTimeEligibilityStatus} leading-icon"
                .icon="${patientInsurance.realTimeEligibilityStatus
                  ? getRTEStatusIcon(patientInsurance.realTimeEligibilityStatus)
                  : ''}"
              ></neb-icon>

              <div id="${ELEMENTS.textRealTimeEligibilityDesc.id}">
                ${patientInsurance.realTimeEligibilityRecord
                  ? patientInsurance.realTimeEligibilityRecord.description
                  : ''}
              </div>
            </div>

            <div id="${ELEMENTS.textRealTimeEligibilityDate.id}">
              ${patientInsurance.realTimeEligibilityRecord
                ? this.__getEligibilityDateInfo(
                    patientInsurance.realTimeEligibilityRecord.submittedAt,
                  )
                : ''}
            </div>
          </div>
        `
      : '';
  }

  __renderCheckRTE(payerPlan) {
    return this.__hasAddOnCTVerify && rteEnabledInsurance(payerPlan)
      ? html`
          <neb-button
            id="${ELEMENTS.buttonCheckRealTimeEligibility.id}"
            label="Check Eligibility"
            class="button-rte"
            ?disabled="${this.__checkingRTE}"
            .onClick="${this.handlers.checkEligibility}"
          ></neb-button>
        `
      : '';
  }

  __renderPackageBalance(packageItem, isToolTip = false) {
    const carePackage =
      packageItem && packageItem.data && packageItem.data.id
        ? this.packages.find(
            x => x && x.data && x.data.id === packageItem.data.id,
          )
        : null;

    return carePackage
      ? html`
          <div
            id="${ELEMENTS.packageSummary.id}"
            class="container-care-package-summary"
          >
            <div
              class="${isToolTip
                ? 'label-care-package-summary-tooltip'
                : 'label-care-package-summary'}"
            >
              Summary
            </div>

            <div id="${ELEMENTS.packageDescription.id}" class="label-visits">
              ${carePackage && carePackage.data
                ? carePackage.data.descriptionLines.map(
                    data => html` <div>${data}</div> `,
                  )
                : ''}
            </div>
          </div>
        `
      : '';
  }

  __renderPatientBalance() {
    return html`
      <div class="container-patient-balance">
        <neb-button-action
          id="${ELEMENTS.patientBalanceLink.id}"
          class="patient-balance"
          .label="Patient Balance: ${formatDollarAmount(this.__patientBalance)}"
          trailingIcon="open"
          showUnderline
          .onClick="${this.handlers.openPatientBalance}"
        ></neb-button-action>

        <div class="payment-checkout-actions-container">
          ${this.__renderMakePayment()}${this.__renderCheckOutPrintActions()}
        </div>
      </div>
    `;
  }

  __renderMakePayment() {
    return html`
      <neb-button
        id="${ELEMENTS.buttonPayment.id}"
        class="button button-make-payment"
        label="MAKE PAYMENT"
        .role="${BUTTON_ROLE.CONFIRM}"
        .onClick="${this.handlers.makePayment}"
        ?disabled="${!this.__patientLoaded}"
      ></neb-button>
    `;
  }

  __renderDiscount() {
    return this.checkedIn && this.layout === 'large'
      ? html`
          <div class="button-container">
            <neb-button-action
              class="discount bold"
              id="${ELEMENTS.buttonDiscount.id}"
              label="Discount"
              leadingIcon="discount"
              .onClick="${this.handlers.createDiscount}"
              ?disabled="${!this.__patientLoaded}"
            ></neb-button-action>
          </div>
        `
      : '';
  }

  __getMenuItems() {
    return [this.__menuItems.SELECT_LOCATION];
  }

  __renderPrintReceiptButton() {
    return html`
      <neb-button
        id="${ELEMENTS.buttonPrintReceipt.id}"
        class="button"
        label="PRINT RECEIPT"
        .role="${BUTTON_ROLE.OUTLINE}"
        .onClick="${this.handlers.printReceipt}"
      ></neb-button>
    `;
  }

  __renderPrintReceipt() {
    return this.__patientPayments.length > 0 && !this.checkedIn
      ? html`
          <div class="group-receipt-buttons">
            ${this.__renderPrintReceiptButton()}
          </div>
        `
      : html``;
  }

  __renderPrintDropdown() {
    const menuItems = this.__genPrintActionItems();
    return this.checkedIn
      ? html`
          <neb-dropdown-menu
            id="${ELEMENTS.printActionsDropdown.id}"
            label="Print"
            leadingIcon="print"
            .items="${menuItems}"
            align="${this.layout !== 'large' ? 'left' : 'right'}"
          ></neb-dropdown-menu>
        `
      : '';
  }

  __renderAddNewAppointmentButton() {
    return html`
      <neb-button-action
        id="${ELEMENTS.buttonAddNewAppointment.id}"
        class="button button-add-new-appointment"
        label="Add New Appointment"
        icon="plus"
        .onClick="${this.handlers.addNewAppointment}"
      ></neb-button-action>
    `;
  }

  __renderPrintUpcomingAppointmentsButton() {
    return html`
      <neb-button-action
        id="${ELEMENTS.printUpcomingApptsButton.id}"
        class="${ELEMENTS.printUpcomingApptsButton.id}"
        label="Print Upcoming Appointments"
        leadingIcon="print"
        .onClick="${this.handlers.printUpcomingAppointments}"
      ></neb-button-action>
    `;
  }

  __renderPrintAppointmentsDropdownMenu() {
    return html`
      <neb-button-actions
        id="${ELEMENTS.printAppointmentsButtonActions.id}"
        class="print-upcoming-appts-ellipses-button"
        vertical
        maxVisibleItems="10"
        iconHeight="20px"
        iconWidth="20px"
        .value="${this.__getMenuItems()}"
      ></neb-button-actions>
    `;
  }

  __renderAppointmentActionButtons() {
    return html`
      <div class="appointment-action-buttons">
        ${this.__renderAddNewAppointmentButton()}
        ${this.__renderPrintUpcomingAppointmentsButton()}
        ${this.__renderPrintAppointmentsDropdownMenu()}
      </div>
    `;
  }

  __renderCheckOutPrintActions() {
    return this.checkedIn
      ? html`
          <div class="discount-print-container">
            ${this.__renderDiscount()}${this.__renderPrintDropdown()}
          </div>
        `
      : this.__renderPrintReceipt();
  }

  __renderRequestNextAppointment() {
    return html`
      <div class="container-request-next-appointment">
        <div class="label-header label-request-next-appointment">
          Request Next Appointment
        </div>

        <div
          id="${ELEMENTS.requestNextAppointment.id}"
          class="label-request-next-appointment-description request-next-appointment"
        >
          ${generateNextApointmentMessage(this.nextAppointment)}
        </div>

        ${this.__renderAppointmentActionButtons()}
      </div>
    `;
  }

  __renderFeedbackRequestLink() {
    return this.__hasAddOnCtEngage
      ? html`
          <div
            id="${ELEMENTS.ctEngageFeedBackRequest.id}"
            class="ct-engage-feedback-request-link"
            @click="${this.handlers.openFeedBackRequestLink}"
          >
            <neb-icon
              class="ct-engage-feedback-request-icon leading-icon"
              icon="neb:requestReview"
            ></neb-icon>

            <span>Request a Practice Review</span>

            <neb-icon
              class="ct-engage-feedback-request-icon trailing-icon"
              icon="neb:open"
            ></neb-icon>
          </div>
        `
      : '';
  }

  __getPatientName() {
    let name = {};

    if (this.__patient && this.__patient.name) {
      name = this.__patient.name;
    } else if (this.model.patient) {
      name = {
        first: this.model.patient.firstName || '',
        last: this.model.patient.lastName || '',
        middle: this.model.patient.middleName || '',
        preferred: this.model.patient.preferredName || '',
        suffix: this.model.patient.suffix || '',
      };
    }

    return name;
  }

  __renderColumnLeft() {
    return html`
      <div class="column-left">
        <div class="label-header label-appointment">Appointment Details</div>

        <div id="${ELEMENTS.labelPatientName.id}" class="label-patient-name">
          ${objToName(this.__getPatientName(), DEFAULT_NAME_OPTS)}
        </div>

        <neb-appointment-details-lit
          id="${ELEMENTS.appointmentInfo.id}"
          class="appointment-details"
          .layout="${this.layout}"
          .appointmentDetails="${this.model.details}"
          .hideProvider="${!this.checkedIn}"
          .patientCaseName="${this.__getSelectedCaseName()}"
          .patientAuthorizationName="${this.__getSelectedAuthorizationName()}"
          .selectedAuthorization="${this.__selectedAuthorization}"
          .onAuthorizationDetailChanged="${this.handlers
            .onAuthorizationDetailChanged}"
          .scheduledRoomName="${this.__getScheduledRoomName()}"
        >
        </neb-appointment-details-lit>

        ${this.checkedIn ? this.__renderFeedbackRequestLink() : ''}
        ${this.checkedIn ? this.__renderEncounterBalance() : ''}
        ${this.checkedIn
          ? this.__renderRequestNextAppointment()
          : this.__renderAppointmentInputs()}
        ${this.layout === LAYOUT_TYPE.SMALL
          ? this.__renderTreatmentPlanTable()
          : ''}
      </div>
    `;
  }

  __renderTreatmentPlanTable() {
    if (
      !this.checkedIn ||
      !this.treatmentPlanPhases.length ||
      !getChartingPermissions()
    ) {
      return '';
    }

    return html`
      <div class="label-header label-treatment-plan" id="label-treatment-plan">
        Treatment Plan (${this.treatmentPlanPhases.length})
      </div>

      <neb-treatment-plan-table
        id="${ELEMENTS.treatmentPlan.id}"
        class="treatment-plan"
        .items="${this.treatmentPlanPhases}"
        summary
        sticky-total
      ></neb-treatment-plan-table>
    `;
  }

  __renderColumnRight() {
    return html`
      <div class="column-right">
        ${this.__renderPatientBalance()}
        <div class="container-select">
          <div class="label-header label-billing">Billing Details</div>
        </div>

        ${this.__renderBillingRadioButtons()}
        <div class="container-select">
          <neb-select
            id="${ELEMENTS.selectCase.id}"
            class="select select-case"
            name="caseId"
            label="Case"
            .helper="${this.state.billingInfo.caseBillTypeOverride
              ? 'Required'
              : ''}"
            .items="${this.__getCases()}"
            .value="${this.state.caseId}"
            .error="${this.__getCaseError()}"
            .onChange="${this.handlers.changeCase}"
          ></neb-select>

          ${this.__renderTooltip('tooltipCase', 'right')}
        </div>

        ${this.__renderAddCaseButton()} ${this.__renderPatientAuthorization()}
        ${this.BILLING_OPTIONS[this.state.billingInfo.billType].renderer()}
        ${this.__renderFeeSchedulesSelect()}
        ${this.layout !== LAYOUT_TYPE.SMALL
          ? this.__renderTreatmentPlanTable()
          : ''}
      </div>
    `;
  }

  __renderAddCaseButton() {
    return html`
      <neb-button-action
        id="${ELEMENTS.buttonAddCase.id}"
        class="button"
        label="Add Case"
        leadingIcon="plus"
        .onClick="${this.handlers.addCase}"
        ?disabled="${this.__isEncounterEditable}"
      ></neb-button-action>
    `;
  }

  __renderCheckInLarge() {
    return html` ${this.__renderColumnLeft()} ${this.__renderColumnRight()} `;
  }

  __renderCheckInSmall() {
    return html` ${this.__renderColumnLeft()} ${this.__renderColumnRight()} `;
  }

  __renderCheckOutLarge() {
    return html`
      <div class="row-top">
        ${this.__renderColumnLeft()} ${this.__renderColumnRight()}
      </div>

      <div class="row-bottom">
        ${this.__renderEncounterDiagnosis()} ${this.__renderEncounterCharges()}
      </div>
    `;
  }

  __renderCheckOutSmall() {
    return html`
      ${this.__renderColumnLeft()}
      ${this.__renderColumnRight()}${this.__renderEncounterDiagnosis()}
      ${this.__renderEncounterCharges()}
    `;
  }

  __renderLarge() {
    return html`
      ${this.checkedIn
        ? this.__renderCheckOutLarge()
        : this.__renderCheckInLarge()}
    `;
  }

  __renderSmall() {
    return html`
      ${this.checkedIn
        ? this.__renderCheckOutSmall()
        : this.__renderCheckInSmall()}
    `;
  }

  renderActionBar() {
    const hideSummary = this.layout === 'small' || !this.checkedIn;

    const isFutureAppointment = this.appointmentStart
      ? parseDate(this.appointmentStart).isAfter(
          parseDate().startOf('day'),
          'day',
        )
      : false;

    return html`
      <neb-action-bar-check-in-out
        id="${ELEMENTS.actionBar.id}"
        confirmLabel="${this.__confirmLabel}"
        .createEncounterDisabled="${this.model.encounterId !== null}"
        .createEncounter="${this.__handleCreateEncounterAtCheckIn()}"
        .confirmDisabled="${isFutureAppointment ||
        !this.__patientLoaded ||
        (!this.checkedIn &&
          !this.__getNonEmptyActivePackages().length &&
          this.state.billingInfo.billType ===
            APPOINTMENT_BILLING_TYPE.CarePackage)}"
        .onConfirm="${this.handlers.save}"
        .onCancel="${this.handlers.cancel}"
        .onSave="${this.handlers.clickFirstButton}"
        .onChangeCreateEncounter="${this.handlers.changeCreateEncounter}"
        ?displayCreateEncounter="${!this.checkedIn &&
        !this.forceEncounterCreation}"
        ?hideSaveButton="${hideSummary}"
      ></neb-action-bar-check-in-out>
    `;
  }

  renderContent() {
    return html`
      <div id="${ELEMENTS.containerForm.id}" class="container-form">
        ${this.layout === 'small' ? this.__renderSmall() : this.__renderLarge()}
      </div>
    `;
  }
}
customElements.define('neb-form-check-in-out', NebFormCheckInOut);
