import '../../../../packages/neb-lit-components/src/components/neb-popup-header';
import '../../../../packages/neb-material-design/src/components/neb-loading-spinner';
import '../../forms/payment/neb-form-preallocate-payment';

import { html, css } from 'lit';

import { getPatientInsurances } from '../../../../packages/neb-api-client/src/patient-insurance-api-client';
import { getPatientRelationships } from '../../../../packages/neb-api-client/src/patient-relationship-api-client';
import { getPatientBillingTotals } from '../../../../packages/neb-api-client/src/patient-totals-api-client';
import { getProviderUsers } from '../../../../packages/neb-api-client/src/practice-users-api-client';
import { openError } from '../../../../packages/neb-dialog/neb-banner-state';
import Overlay from '../../../../packages/neb-lit-components/src/components/overlays/neb-overlay';
import { store } from '../../../../packages/neb-redux/neb-redux-store';
import { getPreallocateLineItems } from '../../../api-clients/preallocate-payment';
import { CSS_SPACING, CSS_COLOR_GREY_2 } from '../../../styles';
import {
  ERROR_BANNER_MESSAGE,
  filterAllocatedLineItems,
  mapLineItemsToModel,
  selectLineItemsAhead,
} from '../../../utils/preallocate-payment-util';

export const ELEMENTS = {
  header: {
    id: 'header',
  },
  form: {
    id: 'form',
  },
  loadingSpinner: {
    id: 'loading-spinner',
  },
};

class NebOverlayPreallocatePayment extends Overlay {
  static get properties() {
    return {
      patient: Object,
      loading: Boolean,
      __preallocateItems: Array,
      __relationships: Array,
      __remainingPatientBalance: Number,
    };
  }

  initState() {
    super.initState();
    this.loading = true;
    this.__preallocateItems = [];
    this.__relationships = [];
    this.__remainingPatientBalance = 0;
  }

  connectedCallback() {
    super.connectedCallback();

    return this.__fetch();
  }

  async __fetch() {
    if (this.model.patient) {
      try {
        const [
          { lineItems, encounters },
          insurances,
          relationships,
          providers,
          { payments: remainingPatientBalance },
        ] = await Promise.all([
          getPreallocateLineItems(this.model.patient.id, true),
          getPatientInsurances(this.model.patient.id, {}, true),
          getPatientRelationships(this.model.patient.id, {}, undefined, true),
          getProviderUsers(),
          getPatientBillingTotals(this.model.patient.id),
        ]);

        this.__preallocateItems = selectLineItemsAhead(
          mapLineItemsToModel({
            lineItems: filterAllocatedLineItems(lineItems),
            encounters,
            insurances,
            providers,
            relationships,
          }),
        );

        this.__remainingPatientBalance = remainingPatientBalance;
      } catch (e) {
        store.dispatch(openError(ERROR_BANNER_MESSAGE));
        this.__preallocateItems = null;
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        .header {
          padding: ${CSS_SPACING};
          border-bottom: 1px solid ${CSS_COLOR_GREY_2};
        }

        .content {
          width: 100%;
        }

        #loading-spinner {
          margin: auto;
          height: 100px;
          width: 100px;
        }

        .form {
          height: 93%;
          overflow-y: scroll;
        }
      `,
    ];
  }

  dismissWithBlocker() {
    const result = undefined;
    const dismissAll = true;
    this.dismiss(result, dismissAll);
  }

  __renderSpinnyWheel() {
    return html`
      <neb-loading-spinner
        id="${ELEMENTS.loadingSpinner.id}"
      ></neb-loading-spinner>
    `;
  }

  __renderForm() {
    return html`
      <neb-form-preallocate-payment
        id="${ELEMENTS.form.id}"
        class="form"
        .patient="${this.model.patient}"
        .preallocateItems="${this.__preallocateItems}"
        .remainingPatientBalance="${this.__remainingPatientBalance}"
        .onDismiss="${() => this.dismissWithBlocker()}"
      >
      </neb-form-preallocate-payment
    `;
  }

  renderContent() {
    return html`
      <neb-popup-header
        id="${ELEMENTS.header.id}"
        class="header"
        title="Outstanding Charges"
        showBackButton
        .onBack="${this.handlers.dismiss}"
        .onCancel="${this.handlers.dismissAll}"
      >
      </neb-popup-header>
      ${this.loading ? this.__renderSpinnyWheel() : this.__renderForm()}
      </neb-form-preallocate-payment>
    `;
  }
}

customElements.define(
  'neb-overlay-preallocate-payment',
  NebOverlayPreallocatePayment,
);
